import React, { useEffect, useState } from 'react';

import Calendar from './Calendar';
import ActionSection from './ActionSection';
import { Holiday, useHolidaysQuery } from '../../graphql/graphql';

const Holidays: React.FC = () => {
  const { data, refetch } = useHolidaysQuery({
    fetchPolicy: 'no-cache'
  });
  const [holidays, setHolidays] = useState<Holiday[]>([]);
  useEffect(() => {
    if (data?.holidays.__typename === 'Holidays') {
      const hdays: any = [];
      data.holidays.entities.forEach((h) => {
        if (h.__typename === 'Holiday') hdays.push(h);
      });
      setHolidays(hdays);
    }
  }, [data]);

  return (
    <div className='holidays-view'>
      <ActionSection refetchHolidays={refetch} />
      <Calendar holidays={holidays} refetchHolidays={refetch} />
    </div>
  );
};

export default Holidays;
