import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { message } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

import { LOGIN_EMPLOYEE } from '../../ApolloClient/mutations/userAction';
import { ValidationsType, LoginRequestTypeName, LoginUserResponse } from '../../types/main';
import { Flex, Button, Label, Input, Spiner } from '../../components/core';
import { RegExpEmail, MessageDuration } from '../../utils/constants';
import ResendPassDialog from './ResendPassDialog';
import { info, errors as errorMessages } from '../../resources/strings';
import logo from '../../icons/Logos/Logo_black.svg';

import './LoginPage.styles.scss';

type FormData = {
  email: string;
  password: string;
};

const Login: React.FC = () => {
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [logInEmployee, { loading }] = useMutation(LOGIN_EMPLOYEE);
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>();
  const [visible, setVisible] = React.useState(false);
  const onSubmit = (formData: FormData) => {
    const variables = {
      password: formData.password,
      username: formData.email
    };

    logInEmployee({ variables })
      .then((result: LoginUserResponse) => {
        const resultData = result?.data?.login;
        if (resultData?.__typename === LoginRequestTypeName.UserWithCredentials) {
          localStorage.setItem('accessToken', resultData.authTokens.accessToken);
          localStorage.setItem('refreshToken', resultData.authTokens.refreshToken);
          history.push('/currentTime');
          return;
        }
      })
      .catch((e) => {
        if (e) {
          message.error(e.message, MessageDuration);
        }
      });
  };

  return (
    <Flex className='login_page' center='cross-axis'>
      <Flex direction='column' center='cross-axis'>
        <Flex className='logo_content' space='around' center='cross-axis'>
          <div className='logo'>
            <img src={logo} alt='funday'></img>
          </div>
        </Flex>
        <div className='timesheets'>{info.timesheets}</div>
        <h4 style={{ color: '#fff' }}>{info.logIn}</h4>
        <Flex align='start-cross-axis' style={{ marginTop: 30 }}>
          <Label mb={5} type='small'>
            {info.email}
          </Label>
          <Controller
            name='email'
            control={control}
            rules={{
              required: true,
              pattern: RegExpEmail
            }}
            render={({ field: { ref, ...otherFieldProps } }) => (
              <Input
                colorType={'black'}
                required={true}
                inputRef={ref}
                withError={!!errors.email}
                onPressEnter={handleSubmit(onSubmit)}
                {...otherFieldProps}
              />
            )}
          />
          {errors.email?.type === ValidationsType.required && (
            <span className='errorText'>{errorMessages.emailIsRequired}</span>
          )}
          {errors.email?.type === ValidationsType.pattern && (
            <span className='errorText'>{errorMessages.emailIsIncorrect}</span>
          )}
          <Label mt={30} mb={5} type='small'>
            {info.password}
          </Label>
          <Controller
            name={'password'}
            control={control}
            rules={{ required: true }}
            render={({ field: { ref, ...otherFieldProps } }) => (
              <Input
                type={showPassword ? 'text' : 'password'}
                inputRef={ref}
                maxLength={64}
                withError={!!errors.password}
                onPressEnter={handleSubmit(onSubmit)}
                className='password-input'
                suffix={
                  showPassword ? (
                    <EyeOutlined onClick={() => setShowPassword(!showPassword)} />
                  ) : (
                    <EyeInvisibleOutlined onClick={() => setShowPassword(!showPassword)} />
                  )
                }
                {...otherFieldProps}
              />
            )}
          />
          {errors.password?.type === ValidationsType.required && (
            <span className='errorText'>{errorMessages.passwordRequired}</span>
          )}
          <Flex direction='row' align='end-main-axis' style={{ width: '100%' }}>
            <div className='forgot_pass' onClick={() => setVisible(true)}>
              <Label>{'Forgot Password?'}</Label>
            </div>
          </Flex>
        </Flex>
        <Button
          style={{ width: 150, marginTop: 60 }}
          onClick={handleSubmit(onSubmit)}
          text={'Log In'}
          colorType={'pink'}
        />
      </Flex>
      {loading && <Spiner />}
      <ResendPassDialog visible={visible} rejectAction={() => setVisible(false)} />
    </Flex>
  );
};

export default Login;
