import moment, { Moment } from 'moment';
import produce from 'immer';
import {
  ReportDataType,
  ProjectReportDataType,
  RateReportDataType,
  TimeSheetReport
} from '../../types/main';
import { getEndOfWeek, getStartOfWeek } from '../../utils/helpers';
import { ResponseDataType } from '../../components/DynamicSelect/DebouncedList';

const ADD_EMPLOYEE_REPORT = 'ADD_EMPLOYEE_REPORT';
const UPDATE_EMPLOYEE_REPORT_FILTERS = 'UPDATE_EMPLOYEE_REPORT_FILTERS';
const DELETE_EMPLOYEE_REPORT_FILTERS_EMPLOYEE_ID = 'DELETE_EMPLOYEE_REPORT_FILTERS_EMPLOYEE_ID';
const ADD_PROJECT_REPORT = 'ADD_PROJECT_REPORT';
const UPDATE_PROJECT_REPORT_FILTERS = 'UPDATE_PROJECT_REPORT_FILTERS';
const DELETE_PROJECT_REPORT_FILTERS_CLIENT_ID = 'DELETE_PROJECT_REPORT_FILTERS_CLIENT_ID';
const ADD_RATE_REPORT = 'ADD_RATE_REPORT';
const UPDATE_RATE_REPORT_FILTERS = 'UPDATE_RATE_REPORT_FILTERS';
const ADD_TIME_SHEET_REPORT = 'ADD_TIME_SHEET_REPORT';
const UPDATE_TIME_SHEET_REPORT_FILTERS = 'UPDATE_TIME_SHEET_REPORT_FILTERS';
const DELETE_TIME_SHEET_REPORT_FILTERS_EMPLOYEE_ID = 'DELETE_TIME_SHEET_REPORT_FILTERS_EMPLOYEE_ID';
const UPDATE_TIME_SHEET_REPORT_DATE_RANGE = 'UPDATE_TIME_SHEET_REPORT_DATE_RANGE';
const UPDATE_SELECTED_EMPLOYEE_NAME = 'UPDATE_SELECTED_EMPLOYEE_NAME';
const SELECTED_EMPLOYEE = 'SELECTED_EMPLOYEE';
const SELECTED_EMPLOYEES = 'SELECTED_EMPLOYEES';

moment.locale('en', {
  week: {
    dow: 1
  }
});
const startOfMonth = moment().utc().startOf('month');
const dateNow = moment();
const startOfWeek = getStartOfWeek();
const startOfPreviousWeek = getStartOfWeek(moment().subtract(1, 'week'));
const endOfPreviousWeek = getEndOfWeek(moment().subtract(1, 'week'));
const endOfWeek = getEndOfWeek();

interface UpdateEmployeeFilters {
  dateRange: Moment[];
  employeeIds: number[];
}

interface UpdateClientFilters {
  dateRange: Moment[];
  clientIds: number[];
}

export interface UpdateRateReportFilters {
  dateRange: Moment[];
  clientIds: number[];
  jobIds: number[];
  locationIds: number[];
  employmentStatusIds: number[];
  departmentIds: number[];
  conversionRate: number;
}

export interface UpdateTimesheetFilters {
  dateRange: Moment[];
  employeeId: number | null;
}

export interface IAppReducerState {
  employeeReport: {
    fetched: boolean;
    filters: UpdateEmployeeFilters;
    data: ReportDataType[];
    selectedEmployees: ResponseDataType[];
  };
  projectHoursReport: {
    fetched: boolean;
    filters: UpdateClientFilters;
    data: ProjectReportDataType[];
  };
  rateReport: {
    fetched: boolean;
    filters: UpdateRateReportFilters;
    data: RateReportDataType[];
  };
  timesheetReport: {
    fetched: boolean;
    filters: UpdateTimesheetFilters;
    data: TimeSheetReport | null;
    dateRange: Moment[];
    selectedEmployeeName: string;
    selectedEmployee: ResponseDataType | null;
  };
}

export function addEmployeeReport(data: ReportDataType[]): {
  type: string;
  data: ReportDataType[];
} {
  return {
    type: ADD_EMPLOYEE_REPORT,
    data
  };
}

export function updateEmployeeReportFilters(data: UpdateEmployeeFilters): {
  type: string;
  data: UpdateEmployeeFilters;
} {
  return {
    type: UPDATE_EMPLOYEE_REPORT_FILTERS,
    data
  };
}

export function deleteEmployeeReportFiltersEmployeeId(data: number): {
  type: string;
  data: number;
} {
  return {
    type: DELETE_EMPLOYEE_REPORT_FILTERS_EMPLOYEE_ID,
    data
  };
}

export function addProjectReport(data: ProjectReportDataType[]): {
  type: string;
  data: ProjectReportDataType[];
} {
  return {
    type: ADD_PROJECT_REPORT,
    data
  };
}

export function updateProjectReportFilters(data: UpdateClientFilters): {
  type: string;
  data: UpdateClientFilters;
} {
  return {
    type: UPDATE_PROJECT_REPORT_FILTERS,
    data
  };
}

export function deleteProjectReportFiltersClientId(data: number): {
  type: string;
  data: number;
} {
  return {
    type: DELETE_PROJECT_REPORT_FILTERS_CLIENT_ID,
    data
  };
}

export function addRateReport(data: RateReportDataType[]): {
  type: string;
  data: RateReportDataType[];
} {
  return {
    type: ADD_RATE_REPORT,
    data
  };
}

export function updateRateReportFilters(data: UpdateRateReportFilters): {
  type: string;
  data: UpdateRateReportFilters;
} {
  return {
    type: UPDATE_RATE_REPORT_FILTERS,
    data
  };
}

export function addTimeSheetReport(data: TimeSheetReport | null): {
  type: string;
  data: TimeSheetReport | null;
} {
  return {
    type: ADD_TIME_SHEET_REPORT,
    data
  };
}

export function updateTimeSheetReportFilters(data: UpdateTimesheetFilters): {
  type: string;
  data: UpdateTimesheetFilters;
} {
  return {
    type: UPDATE_TIME_SHEET_REPORT_FILTERS,
    data
  };
}

export const deleteTimeSheetReportFiltersEmployeeId = (
  data: number
): {
  type: string;
  data: number;
} => ({
  type: DELETE_TIME_SHEET_REPORT_FILTERS_EMPLOYEE_ID,
  data
});

export function updateTimeSheetReportDateRange(data: Moment[]): {
  type: string;
  data: Moment[];
} {
  return {
    type: UPDATE_TIME_SHEET_REPORT_DATE_RANGE,
    data
  };
}

export function updateSelectedEmployeeName(data: string): {
  type: string;
  data: string;
} {
  return {
    type: UPDATE_SELECTED_EMPLOYEE_NAME,
    data
  };
}

export function selectedEmployee(data: ResponseDataType | null): {
  type: string;
  data: ResponseDataType | null;
} {
  return {
    type: SELECTED_EMPLOYEE,
    data
  };
}

export function selectedEmployees(data: ResponseDataType[]): {
  type: string;
  data: ResponseDataType[];
} {
  return {
    type: SELECTED_EMPLOYEES,
    data
  };
}

export const initialState: IAppReducerState = {
  employeeReport: {
    fetched: false,
    filters: {
      dateRange: [startOfMonth, dateNow],
      employeeIds: []
    },
    data: [],
    selectedEmployees: []
  },
  projectHoursReport: {
    fetched: false,
    filters: {
      dateRange: [startOfMonth, dateNow],
      clientIds: []
    },
    data: []
  },
  rateReport: {
    fetched: false,
    filters: {
      dateRange: [startOfMonth, dateNow],
      clientIds: [],
      jobIds: [],
      locationIds: [],
      employmentStatusIds: [],
      departmentIds: [],
      conversionRate: 1
    },
    data: []
  },
  timesheetReport: {
    fetched: false,
    filters: {
      dateRange: [startOfPreviousWeek, endOfPreviousWeek],
      employeeId: null
    },
    data: null,
    dateRange: [startOfWeek, endOfWeek],
    selectedEmployeeName: '',
    selectedEmployee: null
  }
};

type ReducerAction =
  | { type: 'ADD_EMPLOYEE_REPORT'; data: ReportDataType[] }
  | { type: 'UPDATE_EMPLOYEE_REPORT_FILTERS'; data: UpdateEmployeeFilters }
  | { type: 'DELETE_EMPLOYEE_REPORT_FILTERS_EMPLOYEE_ID'; data: number }
  | { type: 'ADD_PROJECT_REPORT'; data: ProjectReportDataType[] }
  | { type: 'UPDATE_PROJECT_REPORT_FILTERS'; data: UpdateClientFilters }
  | { type: 'DELETE_PROJECT_REPORT_FILTERS_CLIENT_ID'; data: number }
  | { type: 'ADD_RATE_REPORT'; data: RateReportDataType[] }
  | { type: 'UPDATE_RATE_REPORT_FILTERS'; data: UpdateRateReportFilters }
  | { type: 'ADD_TIME_SHEET_REPORT'; data: TimeSheetReport }
  | { type: 'UPDATE_TIME_SHEET_REPORT_FILTERS'; data: UpdateTimesheetFilters }
  | { type: 'DELETE_TIME_SHEET_REPORT_FILTERS_EMPLOYEE_ID'; data: number }
  | { type: 'UPDATE_TIME_SHEET_REPORT_DATE_RANGE'; data: Moment[] }
  | { type: 'UPDATE_SELECTED_EMPLOYEE_NAME'; data: string }
  | { type: 'SELECTED_EMPLOYEE'; data: ResponseDataType | null }
  | { type: 'SELECTED_EMPLOYEES'; data: ResponseDataType[] };

const reportsReducer = (state = initialState, action: ReducerAction): IAppReducerState => {
  return produce(state, (draft: IAppReducerState) => {
    switch (action.type) {
      case ADD_EMPLOYEE_REPORT:
        draft.employeeReport.fetched = true;
        draft.employeeReport.data = action.data;
        break;
      case UPDATE_EMPLOYEE_REPORT_FILTERS:
        draft.employeeReport.filters = action.data;
        break;
      case DELETE_EMPLOYEE_REPORT_FILTERS_EMPLOYEE_ID:
        draft.employeeReport.filters.employeeIds = draft.employeeReport.filters.employeeIds.filter(
          (id) => id !== action.data
        );
        break;
      case ADD_PROJECT_REPORT:
        draft.projectHoursReport.fetched = true;
        draft.projectHoursReport.data = action.data;
        break;
      case UPDATE_PROJECT_REPORT_FILTERS:
        draft.projectHoursReport.filters = action.data;
        break;
      case DELETE_PROJECT_REPORT_FILTERS_CLIENT_ID:
        draft.projectHoursReport.filters.clientIds =
          draft.projectHoursReport.filters.clientIds.filter((id) => id !== action.data);
        break;
      case ADD_RATE_REPORT:
        draft.rateReport.fetched = true;
        draft.rateReport.data = action.data;
        break;
      case UPDATE_RATE_REPORT_FILTERS:
        draft.rateReport.filters = action.data;
        break;
      case ADD_TIME_SHEET_REPORT:
        draft.timesheetReport.fetched = true;
        draft.timesheetReport.data = action.data;
        break;
      case UPDATE_TIME_SHEET_REPORT_FILTERS:
        draft.timesheetReport.filters = action.data;
        break;
      case DELETE_TIME_SHEET_REPORT_FILTERS_EMPLOYEE_ID:
        draft.timesheetReport.filters.employeeId = null;
        break;
      case UPDATE_TIME_SHEET_REPORT_DATE_RANGE:
        draft.timesheetReport.dateRange = action.data;
        break;
      case UPDATE_SELECTED_EMPLOYEE_NAME:
        draft.timesheetReport.selectedEmployeeName = action.data;
        break;
      case SELECTED_EMPLOYEE:
        draft.timesheetReport.selectedEmployee = action.data;
        break;
      case SELECTED_EMPLOYEES:
        draft.employeeReport.selectedEmployees = action.data;
        break;
    }
  });
};

export default reportsReducer;
