import React from 'react';

import { Flex } from '../../components/core';
import Label from '../../components/core/Label';
import OverlappedNumber from '../../components/core/OverlappedNumber';

interface IProps {
  totalHours: number;
  summaryHours: number;
  onChange: (value: number) => void;
}

const TotalHours: React.FC<IProps> = ({ summaryHours, totalHours, onChange }) => {
  const summaryPersent = ((summaryHours * 100) / totalHours).toFixed(2);
  const deviation = +(summaryHours - totalHours).toFixed(2);
  const deviationText = deviation > 0 ? `+${deviation} hours` : `${deviation} hours`;
  const cn = +summaryHours?.toFixed(2) == +totalHours?.toFixed(2) ? 'success' : 'warning';

  return (
    <Flex direction='row' center='cross-axis' space='between' className='separator'>
      <h3>{'Total'}</h3>
      <h3 className={`summaryPercent ${cn}`}>{`${summaryPersent}%`}</h3>
      <Flex direction='column' align='end-cross-axis' className={deviation ? 'hoursContent' : ''}>
        <OverlappedNumber
          label={`${summaryHours?.toFixed(2)}h / ${totalHours?.toFixed(2)}h`}
          onChange={onChange}
          value={totalHours}
          textClassName={`summaryHours ${cn}`}
        />
        {+deviation !== 0 && <Label mr={5}>{deviationText}</Label>}
      </Flex>
    </Flex>
  );
};

export default TotalHours;
