import { FC, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';

import { Steps } from '../../types/main';
import Step1 from './Step1';
import Step2 from './Step2';
import { Spiner } from '../../components/core';
import { usePreviousTimeSheetLazyQuery } from '../../graphql/graphql';

const Previous: FC = () => {
  const [step, setStep] = useState(Steps.Step1);
  const [selectedDays, setSelectedDays] = useState<Moment | null>(moment().subtract(1, 'week'));

  const [getPrevData, { data: previousData, loading }] = usePreviousTimeSheetLazyQuery({
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (previousData?.previousTimeSheet.__typename === 'TimeSheets') {
      setStep(step + 1);
    }
  }, [previousData]);

  return (
    <div className='site-layout-background'>
      {step === Steps.Step1 && (
        <Step1
          currentStep={step}
          setStep={setStep}
          setSelectedDays={setSelectedDays}
          selectedDays={selectedDays}
          getPrevData={getPrevData}
        />
      )}
      {step === Steps.Step2 && (
        <Step2
          currentStep={step}
          setStep={setStep}
          selectedDays={selectedDays}
          previousData={previousData}
        />
      )}
      {loading && <Spiner className='small past-timesheet' />}
    </div>
  );
};

export default Previous;
