import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { Flex, Button, Label, Spiner, ConfirmationDialog } from '../../components/core';
import { DaysOfWeekType } from '../../types/main';
import { info } from '../../resources/strings';
import { changeTimesheetEditMode } from '../../redux/reducers/TimeFrameReducer';

import Smiles from '../../icons/Logos/Smiles.png';

interface IProps {
  currentStep: number;
  setStep: (step: number) => void;
  skipWeek: (weekStart: string) => void;
  weekDays: DaysOfWeekType[];
  fetching: boolean;
}

const Step1: React.FC<IProps> = ({ currentStep, skipWeek, setStep, weekDays, fetching }) => {
  const dispatch = useDispatch();
  const [showSkipModal, setShowSkipModal] = useState(false);

  const fillDateInfo = React.useMemo(() => {
    const lastWeekStartDate = moment(weekDays[0]?.date).utc().format('LL');
    const lastWeekEndDate = moment(weekDays[weekDays.length - 1]?.date)
      .utc()
      .format('LL');
    if (weekDays.length) {
      return (
        <div className='wrapper start'>
          <Flex center='cross-axis'>
            <h1>{info.welcomeFunTimes}</h1>
            <Label>{info.oneMinuteTimesheet}</Label>
            <div className='dateContent'>
              <span>{info.doTimesheet}</span>
              <div>
                {lastWeekStartDate} - {lastWeekEndDate}
              </div>
            </div>
            <Flex className='week-btn-conatiner' direction='row'>
              <Button
                className='btn-week'
                colorType='white'
                onClick={() => setShowSkipModal(true)}
                text={info.skipThisWeek}
              />
              <Button
                className='btn-week'
                colorType='black'
                onClick={() => {
                  dispatch(changeTimesheetEditMode(true));
                  setStep(currentStep + 1);
                }}
                text={info.start}
              />
            </Flex>
          </Flex>
        </div>
      );
    } else {
      return (
        <div className='wrapper finish'>
          <Flex center='cross-axis'>
            <div className='smiles'>
              <img src={Smiles} alt='smiles'></img>
            </div>
            <div className='congratulations'>{info.funTimes}</div>
            <span>{info.haveNoTimesheets}</span>
          </Flex>
        </div>
      );
    }
  }, [currentStep, setStep, weekDays]);

  return (
    <Flex className='content welcomePage' center='both-axis'>
      {fetching ? <Spiner /> : fillDateInfo}
      {showSkipModal && (
        <ConfirmationDialog
          visible={true}
          confirmAction={() => {
            skipWeek(weekDays[0].date);
            setShowSkipModal(false);
          }}
          rejectAction={() => setShowSkipModal(false)}
          noTitle={info.cancel}
          yesTitle={info.skip}
        >
          <span>{info.wantToSkip}</span>
        </ConfirmationDialog>
      )}
    </Flex>
  );
};

export default Step1;
