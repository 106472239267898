import React from 'react';
import * as ReactDOM from 'react-dom';
import { Flex } from '..';
import './Spiner.styles.scss';

interface IProps {
  className?: string;
  size?: number;
  local?: boolean;
  style?: React.CSSProperties;
}

const Spiner: React.FC<IProps> = ({ className = '', local, style = {} }) => {
  const content = (
    <span className={`spinner ${className}`}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </span>
  );

  if (local) {
    return (
      <Flex direction='row' center='both-axis' className='local-spinner' style={style}>
        {content}
      </Flex>
    );
  }

  return ReactDOM.createPortal(
    <Flex className='global-spinner' center='both-axis'>
      {content}
    </Flex>,
    document.body
  );
};

export default Spiner;
