import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { Modal, message, Radio } from 'antd';
import { useForm, Controller } from 'react-hook-form';

import { useCurrenciesQuery, useDepartmentsQuery } from '../../../graphql/graphql';
import { ADD_EMPLOYEE, EDIT_EMPLOYEE } from '../../../ApolloClient/mutations/employeeAction';
import { Flex, Button, Label, Input, Spiner, ConfirmationDialog } from '../../../components/core';
import { RegExpEmail, MessageDuration, RegexpEmployeeName } from '../../../utils/constants';
import {
  ValidationsType,
  RequestTypeName,
  AddEployeeResponse,
  UpdateEployeeResponse,
  EmployeeDataType,
  EmployeeViewType
} from '../../../types/main';
import { errors as errorMessages, info } from '../../../resources/strings';
import EditManagersList from './EditManagersList';
import ManagersList from './ManagersList';
import DebouncedList from '../../../components/DynamicSelect';
import {
  useLocationsQuery,
  useJobsQuery,
  useEmploymentStatusesQuery,
  EmployeeStatus,
  EmployeeRole
} from '../../../graphql/graphql';
import { RootState } from '../../../redux/reducers/index';
import { getLastHourlyRateIndex } from '../../../utils/helpers';

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  hourlyRate: number;
  managerId: number | string;
  locationId: number | string;
  employmentStatusId: number | string;
  jobId: number | string;
  role: EmployeeRole;
  departmentId: number | string;
  currencyId: number | string;
};

interface IProps {
  type: EmployeeViewType;
  width?: number | string;
  onCancel: () => void;
  visible: boolean;
  dataValues?: EmployeeDataType;
  reFetchEmployees: () => void;
}

const AddEditEmployee: React.FC<IProps> = ({
  width,
  onCancel,
  visible,
  type,
  dataValues,
  reFetchEmployees
}) => {
  const userId = useSelector((state: RootState) => state.user.userInfo.id);
  const [addEmployee, { loading: addLoading }] = useMutation(ADD_EMPLOYEE);
  const [editEmployee, { loading: editLoading }] = useMutation(EDIT_EMPLOYEE);
  const [emailChanged, setEmailChanged] = useState(false);
  const [editedEmployeeData, setEditedEmployeeData] = useState<any>(null);
  const [isSubmitSuccessfull, setIsSubmitSuccessfull] = useState(false);

  const getDefaultValues = () => {
    // changed ids to string values for showing data quickly in edit mode
    const data = {
      id: dataValues?.id,
      firstName: dataValues?.firstName,
      lastName: dataValues?.lastName,
      email: dataValues?.email,
      hourlyRate: dataValues?.hourlyRates[getLastHourlyRateIndex(dataValues)]?.rate,
      managerId: dataValues?.manager
        ? dataValues?.manager?.firstName + ' ' + dataValues?.manager?.lastName
        : undefined,
      employmentStatusId: dataValues?.employmentStatus?.name,
      locationId: dataValues?.location?.name,
      jobId: dataValues?.job?.title,
      role: dataValues?.role || EmployeeRole.Employee,
      departmentId: dataValues?.department?.name,
      currencyId: dataValues?.currency?.name
    };
    return data;
  };
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<FormData>({ defaultValues: getDefaultValues() });

  function editAction(variables) {
    editEmployee({ variables })
      .then((result: UpdateEployeeResponse) => {
        if (result?.data?.updateEmployee.__typename === RequestTypeName.employee) {
          message.success(info.employeeEdited, MessageDuration);
          onCancel();
          setEditedEmployeeData(null);
          reFetchEmployees();
          return;
        }
      })
      .catch((e) => {
        if (e) {
          message.error(e.message, MessageDuration);
        }
      });
  }
  function confirmAction() {
    editAction(editedEmployeeData);
    setEmailChanged(false);
  }

  const onSubmit = (fieldData: FormData) => {
    const employeeNamePattern = new RegExp(RegexpEmployeeName, 'i');
    const nameResult = employeeNamePattern.test(fieldData.firstName);
    const surnameResult = employeeNamePattern.test(fieldData.lastName);
    if (
      !nameResult ||
      !surnameResult ||
      fieldData.firstName.length < 3 ||
      fieldData.lastName.length < 3
    ) {
      message.destroy();
      message.error(errorMessages.employeeName);
      return;
    }

    const variables = {
      ...fieldData,
      employmentStatusId:
        typeof fieldData.employmentStatusId === 'string'
          ? dataValues?.employmentStatus?.id
          : fieldData.employmentStatusId,
      locationId:
        typeof fieldData.locationId === 'string' ? dataValues?.location?.id : fieldData.locationId,
      jobId: typeof fieldData.jobId === 'string' ? dataValues?.job?.id : fieldData.jobId,
      departmentId:
        typeof fieldData.departmentId === 'string'
          ? dataValues?.department?.id
          : fieldData.departmentId,
      currencyId:
        typeof fieldData.currencyId === 'string' ? dataValues?.currency?.id : fieldData.currencyId,
      manager:
        typeof fieldData.managerId === 'string' ? dataValues?.manager?.id : fieldData.managerId,
      hourlyRate: +fieldData.hourlyRate,
      managerId:
        typeof fieldData.managerId === 'string' ? dataValues?.manager?.id : fieldData.managerId
    };
    if (type === EmployeeViewType.add) {
      addEmployee({ variables })
        .then((result: AddEployeeResponse) => {
          if (result?.data?.createEmployee.__typename === RequestTypeName.employee) {
            message.success(info.newEmployeeCreate, MessageDuration);
            onCancel();
            reFetchEmployees();
            setIsSubmitSuccessfull(!isSubmitSuccessfull);
            return;
          }
        })
        .catch((e) => {
          if (e) {
            console.log('err', e);
          }
        });
    } else if (dataValues?.email !== variables.email) {
      setEmailChanged(true);
      setEditedEmployeeData(variables);
    } else {
      editAction(variables);
    }
  };

  const handleCancel = (viewType: EmployeeViewType) => {
    onCancel();
    if (viewType === EmployeeViewType.add) {
      reset();
    } else {
      reset(getDefaultValues());
    }
  };

  useEffect(() => {
    if (reset) reset();
  }, [isSubmitSuccessfull, reset]);

  const content = () => {
    return (
      <Flex className='modalContent'>
        <Flex direction='row' style={{ height: 80 }} space='between'>
          <Flex direction='column' style={{ width: 350 }}>
            <Label type='small'>Name</Label>
            <Controller
              name='firstName'
              control={control}
              rules={{
                required: true
              }}
              render={({ field: { ref, ...otherFieldProps } }) => (
                <Input
                  autoFocus
                  colorType='primary'
                  autoComplete='off'
                  inputRef={ref}
                  {...otherFieldProps}
                />
              )}
            />
            {errors.firstName?.type === ValidationsType.required && (
              <span className='errorText'>{errorMessages.requiredField}</span>
            )}
          </Flex>
          <Flex direction='column' style={{ width: 350 }}>
            <Label type='small'>Surname</Label>
            <Controller
              name='lastName'
              control={control}
              rules={{
                required: true
              }}
              render={({ field: { ref, ...otherFieldProps } }) => (
                <Input colorType='primary' autoComplete='off' inputRef={ref} {...otherFieldProps} />
              )}
            />
            {errors.lastName?.type === ValidationsType.required && (
              <span className='errorText'>{errorMessages.requiredField}</span>
            )}
          </Flex>
        </Flex>
        <Flex direction='row' style={{ height: 80 }} space='between'>
          <Flex direction='column' style={{ width: 350 }}>
            <Label type='small'>Email</Label>
            <Controller
              name='email'
              control={control}
              rules={{
                required: true,
                pattern: RegExpEmail
              }}
              render={({ field: { ref, ...otherFieldProps } }) => (
                <Input
                  colorType='primary'
                  autoComplete='off'
                  disabled={dataValues?.status === EmployeeStatus.Deactivated}
                  required={true}
                  inputRef={ref}
                  {...otherFieldProps}
                />
              )}
            />
            {errors.email?.type === ValidationsType.required && (
              <span className='errorText'>{errorMessages.emailIsRequired}</span>
            )}
            {errors.email?.type === ValidationsType.pattern && (
              <span className='errorText'>{errorMessages.emailIsIncorrect}</span>
            )}
          </Flex>
          <Flex direction='column' style={{ width: 350 }}>
            <Controller
              name='locationId'
              control={control}
              rules={{
                required: true
              }}
              render={() => (
                <DebouncedList
                  control={control}
                  style={{ width: 350 }}
                  controllerName='locationId'
                  labelText={info.location}
                  dataText='name'
                  dataValue='id'
                  queryDataKey='locations'
                  query={useLocationsQuery}
                />
              )}
            />
            {errors.locationId?.type === ValidationsType.required && (
              <span className='errorText'>{errorMessages.locationIsRequired}</span>
            )}
          </Flex>
        </Flex>
        <Flex direction='row' style={{ height: 80 }} space='between'>
          <Flex direction='column' style={{ width: 350 }}>
            <Controller
              name='jobId'
              control={control}
              rules={{
                required: true
              }}
              render={() => (
                <DebouncedList
                  control={control}
                  style={{ width: 350 }}
                  controllerName='jobId'
                  labelText={info.jobTitle}
                  dataText='title'
                  dataValue='id'
                  queryDataKey='jobs'
                  query={useJobsQuery}
                />
              )}
            />
            {errors.jobId?.type === ValidationsType.required && (
              <span className='errorText'>{errorMessages.jobTitleIsRequired}</span>
            )}
          </Flex>
          <Flex direction='column' style={{ width: 350 }}>
            <Controller
              name='employmentStatusId'
              control={control}
              rules={{
                required: true
              }}
              render={() => (
                <DebouncedList
                  control={control}
                  style={{ width: 350 }}
                  controllerName='employmentStatusId'
                  labelText={info.employmentStatuses}
                  dataText='name'
                  dataValue='id'
                  queryDataKey='employmentStatuses'
                  query={useEmploymentStatusesQuery}
                />
              )}
            />
            {errors.employmentStatusId?.type === ValidationsType.required && (
              <span className='errorText'>{errorMessages.employmentStatusIsRequired}</span>
            )}
          </Flex>
        </Flex>
        <Flex direction='row' style={{ height: 80 }} space='between'>
          <Flex direction='column' style={{ width: 350 }}>
            <Controller
              name='departmentId'
              control={control}
              rules={{
                required: true
              }}
              render={() => (
                <DebouncedList
                  control={control}
                  style={{ width: 350 }}
                  controllerName='departmentId'
                  labelText={info.departments}
                  dataText='name'
                  dataValue='id'
                  queryDataKey='departments'
                  query={useDepartmentsQuery}
                />
              )}
            />
            {errors.departmentId?.type === ValidationsType.required && (
              <span className='errorText'>{errorMessages.departmentIsRequired}</span>
            )}
          </Flex>
          {type === EmployeeViewType.add && <ManagersList control={control} width={350} />}
          {type === EmployeeViewType.edit && (
            <EditManagersList control={control} id={dataValues?.id} />
          )}
        </Flex>
        <Flex direction='row' style={{ height: 80 }} space='between'>
          <Flex direction='column' style={{ width: 350 }}>
            <Label type='small'>Hourly Rate</Label>
            <Controller
              name='hourlyRate'
              control={control}
              rules={{
                required: true
              }}
              render={({ field: { ref, ...otherFieldProps } }) => (
                <Input
                  colorType='primary'
                  inputRef={ref}
                  type='number'
                  min={0}
                  {...otherFieldProps}
                  onBlur={(e) => otherFieldProps.onChange(Number(e.target.value).toFixed(2))}
                />
              )}
            />
            {errors.hourlyRate?.type === ValidationsType.required && (
              <span className='errorText'>{errorMessages.hourlyRateIsRequired}</span>
            )}
          </Flex>
          <Flex direction='column' style={{ width: 350 }}>
            <Controller
              name='currencyId'
              control={control}
              rules={{
                required: true
              }}
              render={() => (
                <DebouncedList
                  control={control}
                  style={{ width: 350 }}
                  controllerName='currencyId'
                  labelText={info.currency}
                  dataText='name'
                  dataValue='id'
                  queryDataKey='currencies'
                  query={useCurrenciesQuery}
                />
              )}
            />
            {errors.currencyId?.type === ValidationsType.required && (
              <span className='errorText'>{errorMessages.currencyIsRequired}</span>
            )}
          </Flex>
        </Flex>
        <Flex direction='row' grow={true} center={'cross-axis'} className='roleSection'>
          <Controller
            name='role'
            control={control}
            render={({ field: { value, ...otherFieldProps } }) => (
              <Radio.Group value={value} disabled={dataValues?.id === userId} {...otherFieldProps}>
                <Radio value={EmployeeRole.Employee}>{info.employee}</Radio>
                <Radio value={EmployeeRole.EngagementManager}>{info.engagementManager}</Radio>
                <Radio value={EmployeeRole.Manager}>{info.manager}</Radio>
                <Radio value={EmployeeRole.Admin}>{info.admin}</Radio>
              </Radio.Group>
            )}
          />
        </Flex>
      </Flex>
    );
  };

  const footer = () => {
    return (
      <Flex className='footer' direction='row' center='main-axis' style={{ marginBottom: 30 }}>
        <Flex align='end-cross-axis'>
          <Button
            colorType='white'
            onClick={() => handleCancel(type)}
            text={info.cancel}
            style={{ marginRight: 60, width: 150 }}
          />
        </Flex>
        <Flex align='start-cross-axis'>
          <Button
            colorType='black'
            onClick={handleSubmit(onSubmit)}
            text={type === EmployeeViewType.add ? info.add : info.save}
            style={{ width: 150 }}
          />
        </Flex>
      </Flex>
    );
  };

  return (
    <div
      onKeyPress={(e) => {
        if (e.code === 'Enter' && visible) {
          handleSubmit(onSubmit)();
        }
      }}
    >
      <Modal
        title={type === EmployeeViewType.add ? info.addEmployee : info.editEmployee}
        wrapClassName='modalContent'
        onCancel={() => handleCancel(type)}
        visible={visible}
        centered={true}
        footer={footer()}
        width={width || 800}
      >
        {content()}
      </Modal>
      <ConfirmationDialog
        title={info.warning}
        noTitle={info.cancel}
        yesTitle={info.save}
        confirmAction={confirmAction}
        rejectAction={() => setEmailChanged(false)}
        visible={emailChanged}
      >
        {info.setupEmailPassword}
      </ConfirmationDialog>
      {(addLoading || editLoading) && <Spiner />}
    </div>
  );
};

export default AddEditEmployee;
