import React from 'react';

import ConfigsCrudList from '../../../components/ConfigsCrudList';
import {
  LocationsDocument,
  useCreateLocationMutation,
  useRemoveLocationMutation,
  useLocationsLazyQuery,
  useUpdateLocationMutation,
  JobsDocument,
  useCreateJobMutation,
  useJobsLazyQuery,
  useRemoveJobMutation,
  useUpdateJobMutation,
  EmploymentStatusesDocument,
  useCreateEmploymentStatusMutation,
  useDeleteEmploymentStatusMutation,
  useEmploymentStatusesLazyQuery,
  useUpdateEmploymentStatusMutation,
  DepartmentsDocument,
  useCreateDepartmentMutation,
  useDepartmentsLazyQuery,
  useRemoveDepartmentMutation,
  useUpdateDepartmentMutation,
  CurrenciesDocument,
  useCreateCurrencyMutation,
  useRemoveCurrencyMutation,
  useUpdateCurrencyMutation,
  useCurrenciesLazyQuery
} from '../../../graphql/graphql';
import { info } from '../../../resources/strings';

import './styles.scss';

const Administrative: React.FC = () => {
  return (
    <div className='administrativeDataContainer'>
      <ConfigsCrudList
        configsDocumnet={LocationsDocument}
        useCreateConfigMutation={useCreateLocationMutation}
        useRemoveConfigMutation={useRemoveLocationMutation}
        useConfigsLazyQuery={useLocationsLazyQuery}
        useUpdateConfigMutation={useUpdateLocationMutation}
        configType='locations'
        typeName='Locations'
        title={info.locations}
        modalTitle={info.deleteLocation}
      />
      <ConfigsCrudList
        configsDocumnet={JobsDocument}
        useCreateConfigMutation={useCreateJobMutation}
        useRemoveConfigMutation={useRemoveJobMutation}
        useConfigsLazyQuery={useJobsLazyQuery}
        useUpdateConfigMutation={useUpdateJobMutation}
        configType='jobs'
        typeName='Jobs'
        title={info.jobTitles}
        modalTitle={info.deleteJob}
      />
      <ConfigsCrudList
        configsDocumnet={EmploymentStatusesDocument}
        useCreateConfigMutation={useCreateEmploymentStatusMutation}
        useRemoveConfigMutation={useDeleteEmploymentStatusMutation}
        useConfigsLazyQuery={useEmploymentStatusesLazyQuery}
        useUpdateConfigMutation={useUpdateEmploymentStatusMutation}
        configType='employmentStatuses'
        typeName='EmploymentStatuses'
        title={info.employmentStatuses}
        modalTitle={info.deleteEmploymentStatus}
      />
      <ConfigsCrudList
        configsDocumnet={DepartmentsDocument}
        useCreateConfigMutation={useCreateDepartmentMutation}
        useRemoveConfigMutation={useRemoveDepartmentMutation}
        useConfigsLazyQuery={useDepartmentsLazyQuery}
        useUpdateConfigMutation={useUpdateDepartmentMutation}
        configType='departments'
        typeName='Departments'
        title={info.departments}
        modalTitle={info.deleteDepartment}
      />
      <ConfigsCrudList
        configsDocumnet={CurrenciesDocument}
        useCreateConfigMutation={useCreateCurrencyMutation}
        useRemoveConfigMutation={useRemoveCurrencyMutation}
        useConfigsLazyQuery={useCurrenciesLazyQuery}
        useUpdateConfigMutation={useUpdateCurrencyMutation}
        configType='currencies'
        typeName='Currencies'
        title={info.currencies}
        modalTitle={info.deleteCurrency}
      />
    </div>
  );
};

export default Administrative;
