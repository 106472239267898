import React, { FC, useState } from 'react';
import { Moment } from 'moment';
import { CSVLink } from 'react-csv';
import { useSelector } from 'react-redux';

import { Flex, Button } from '../../../components/core';
import { RateReportClientType, RateReportDataType } from '../../../types/main';
import { getRoundedTotal } from '../helpers';
import { RootState } from '../../../redux/reducers';
import { info } from '../../../resources/strings';

const headersFirst = [
  { label: info.employee, key: 'employeeFullName' },
  { label: info.employmentStatus, key: 'employmentStatus' },
  { label: info.location, key: 'location' },
  { label: info.currency, key: 'currency' },
  { label: info.ratePH, key: 'ratePerHour' }
];

const headersLast = [
  { label: info.totalHoursPerEE, key: 'totalHoursPerEE' },
  { label: info.grandTotalPerEE, key: 'grandTotalPerEE' }
];

type DataForCSV = {
  employeeFullName: string;
  employmentStatus: string;
  location: string;
  currency: string;
  ratePerHour: number;
  totalHoursPerEE: number | string;
  grandTotalPerEE: string;
};

interface IProps {
  data: RateReportDataType[];
  dates: Moment[];
}

const ExportCSV: FC<IProps> = ({ data, dates }) => {
  const [csvData, setCsvData] = useState<DataForCSV[]>([]);
  const { conversionRate } = useSelector((state: RootState) => state.reports.rateReport.filters);

  const { clients, headers } = React.useMemo(() => {
    const clientsMemo: RateReportClientType[] = [];

    data.forEach((item) => {
      item.clients.forEach((client) => {
        if (!clientsMemo.find((c) => c.id === client.id)) {
          clientsMemo.push(client);
        }
      });
    });

    const headersClients = clientsMemo.map((client) => ({
      label: client.name,
      key: `${client.id}`
    }));

    return { headers: [...headersFirst, ...headersClients, ...headersLast], clients: clientsMemo };
  }, [data]);

  const csvReport = React.useMemo(() => {
    return {
      data: csvData,
      headers: headers,
      filename: `${info.rateReport}-${dates[0].format('YYYY/MM/DD')}-${dates[1].format(
        'YYYY/MM/DD'
      )}.csv`
    };
  }, [csvData, dates, headers]);

  const handleClick = () => {
    const dataForCSV: DataForCSV[] = [];
    for (let i = 0; i < data.length; i++) {
      const rowData = {
        employeeFullName: `${data[i].firstName} ${data[i].lastName}`,
        employmentStatus: data[i].employmentStatus.name,
        location: data[i].location.name,
        currency: data[i].currency.name,
        ratePerHour: data[i].rate
      } as DataForCSV;
      for (let j = 0; j < clients.length; j++) {
        const totalH = getRoundedTotal(
          data[i].clients.reduce((sum, current) => sum + current.hoursPerDay, 0)
        );
        const totalS = data[i].clients.reduce((sum, current) => sum + current.salary, 0);
        rowData.totalHoursPerEE = totalH;
        rowData.grandTotalPerEE = getRoundedTotal(
          totalS * (data[i].currency.name === 'USD' ? conversionRate : 1)
        );

        const empClient = data[i].clients.find((c) => c.id === clients[j].id);
        if (empClient) {
          rowData[`${clients[j].id}`] = Number(
            empClient.salary * (data[i].currency.name === 'USD' ? conversionRate : 1)
          ).toFixed(2);
        } else {
          rowData[`${clients[j].id}`] = 0;
        }
      }
      dataForCSV.push(rowData);
    }
    for (let i = 0; i < 2; i++) {
      const rowData = {
        employeeFullName: '',
        employmentStatus: '',
        location: '',
        currency: ''
      } as DataForCSV;
      for (let j = 0; j < clients.length; j++) {
        let totalHoursPerClient = 0;
        let grandTotalPerClient = 0;
        data.forEach((employee) => {
          const empClient = employee.clients.find((c) => c.id === clients[j].id);
          if (empClient) {
            if (i === 0) {
              totalHoursPerClient += empClient.hoursPerDay;
            } else {
              grandTotalPerClient += parseFloat(
                getRoundedTotal(
                  empClient.salary * (employee.currency.name === 'USD' ? conversionRate : 1)
                )
              );
            }
          }
        });
        if (i === 0) {
          rowData[`${clients[j].id}`] = Number(totalHoursPerClient.toFixed(2));
        } else {
          rowData[`${clients[j].id}`] = grandTotalPerClient;
        }
      }
      if (i === 0) {
        rowData.grandTotalPerEE = 'Total Hours per Client';
      } else {
        rowData.grandTotalPerEE = 'Grand Total per Client';
      }
      dataForCSV.push(rowData);
    }
    setCsvData(dataForCSV);
  };

  return (
    <Flex direction='row' style={{ marginTop: 20 }}>
      <CSVLink {...csvReport}>
        <Button
          colorType={'black'}
          onClick={handleClick}
          text={info.export}
          style={{ width: '150px' }}
        />
      </CSVLink>
    </Flex>
  );
};

export default ExportCSV;
