import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { throttle } from 'lodash';
import Apollo from '@apollo/client';

import CrudList from '../CrudList';
import { CrudListItemInterface, EditableCrudListItem } from '../CrudList/CrudListItems';
import { maxTakeCount } from '../../utils/constants';
import { ConfirmationDialog } from '../core';
import { info } from '../../resources/strings';
import { RootState } from '../../redux/reducers';
import { updateRateReportFilters } from '../../redux/reducers/Reports';

interface ConfigsCrudListProps {
  configsDocumnet: Apollo.DocumentNode;
  useConfigsLazyQuery: (
    baseOptions?: Apollo.LazyQueryHookOptions<any, any>
  ) => Apollo.QueryTuple<any, any>;
  useCreateConfigMutation: (
    baseOptions?: Apollo.MutationHookOptions<any, any>
  ) => Apollo.MutationTuple<any, any>;
  useUpdateConfigMutation: (
    baseOptions?: Apollo.MutationHookOptions<any, any>
  ) => Apollo.MutationTuple<any, any>;
  useRemoveConfigMutation: (
    baseOptions?: Apollo.MutationHookOptions<any, any>
  ) => Apollo.MutationTuple<any, any>;
  configType: string;
  typeName: string;
  title: string;
  modalTitle: string;
}

const ConfigsCrudList: React.FC<ConfigsCrudListProps> = ({
  configsDocumnet,
  useConfigsLazyQuery,
  useCreateConfigMutation,
  useUpdateConfigMutation,
  useRemoveConfigMutation,
  configType,
  typeName,
  title,
  modalTitle
}): ReactElement => {
  // TODO: Optimize this to avoid refetching.
  // TODO: Maybe add spinner
  const [getConfigs, { data: fetchedConfigs }] = useConfigsLazyQuery({
    variables: { take: maxTakeCount },
    fetchPolicy: 'cache-and-network'
  });
  const [createConfigMutation] = useCreateConfigMutation({
    refetchQueries: [configsDocumnet, configType]
  });
  const [updateConfig] = useUpdateConfigMutation({ refetchQueries: [configsDocumnet, configType] });
  const [deleteConfigMutation] = useRemoveConfigMutation({
    refetchQueries: [configsDocumnet, configType]
  });
  const configs = useRef<CrudListItemInterface[]>([]);
  const dispatch = useDispatch();
  const rateReportFilters = useSelector((state: RootState) => state.reports.rateReport.filters);

  useEffect(() => {
    getConfigs();
  }, [getConfigs]);

  // Move all this logic to different place
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [deletedItem, setDeletedItem] = useState<any>(null);

  const onEdit = (item: EditableCrudListItem) => {
    updateConfig({
      variables: {
        id: item.id,
        name: item.title,
        title: item.title
      }
    });
  };

  const onSearch = throttle(
    (value) => {
      getConfigs({ variables: { term: value } });
    },
    20000,
    { trailing: true }
  );

  if (fetchedConfigs?.[configType].__typename === typeName) {
    const entitiesWithActions = fetchedConfigs[configType].entities.map((config) => ({
      id: config.id,
      title: config.name || config.title,
      // TODO: This delete part would be handled better context is used
      onDelete: () => {
        setIsDialogOpen(true);
        setDeletedItem({ id: config.id, title: config.name || config.title });
      }
    }));
    configs.current = entitiesWithActions;
  }

  return (
    <div className='administrativeDataItem'>
      <CrudList
        title={title}
        onAdd={(data: string) => createConfigMutation({ variables: { name: data, title: data } })}
        onSearch={onSearch}
        onEdit={onEdit}
        items={configs.current}
      />
      <ConfirmationDialog
        title={modalTitle}
        confirmAction={() => {
          deleteConfigMutation({
            variables: { id: deletedItem ? deletedItem.id : null }
          }).then(() => {
            switch (configType) {
              case 'locations':
                dispatch(
                  updateRateReportFilters({
                    ...rateReportFilters,
                    locationIds: rateReportFilters.locationIds.filter((id) => id !== deletedItem.id)
                  })
                );
                break;
              case 'jobs':
                dispatch(
                  updateRateReportFilters({
                    ...rateReportFilters,
                    jobIds: rateReportFilters.jobIds.filter((id) => id !== deletedItem.id)
                  })
                );
                break;
              case 'employmentStatuses':
                dispatch(
                  updateRateReportFilters({
                    ...rateReportFilters,
                    employmentStatusIds: rateReportFilters.employmentStatusIds.filter(
                      (id) => id !== deletedItem.id
                    )
                  })
                );
                break;
              case 'departments':
                dispatch(
                  updateRateReportFilters({
                    ...rateReportFilters,
                    departmentIds: rateReportFilters.departmentIds.filter(
                      (id) => id !== deletedItem.id
                    )
                  })
                );
                break;
              default:
                break;
            }
          });
          setIsDialogOpen(false);
        }}
        rejectAction={() => setIsDialogOpen(false)}
        noTitle='Cancel'
        yesTitle='Delete'
        visible={isDialogOpen}
      >
        {info.deleteData(deletedItem?.title)}
      </ConfirmationDialog>
    </div>
  );
};

export default ConfigsCrudList;
