import React from 'react';
import { Table } from 'antd';

import { Flex } from '../../../components/core';
import { ProjectReportDataType } from '../../../types/main';
import { getRowStyle, getNestedRowStyle, getRoundedTotal } from '../helpers';
import { info } from '../../../resources/strings';

interface IProps {
  data: ProjectReportDataType[];
}

const ReportTable: React.FC<IProps> = ({ data }) => {
  const columns = [
    {
      key: 'client',
      title: info.client,
      className: 'clientCell',
      ellipsis: true,
      render: (rowData: ProjectReportDataType) => (
        <div className='ellipsis' key={rowData.id}>
          {rowData.name}
        </div>
      )
    },
    {
      key: 'task',
      title: info.task,
      className: 'taskCell',
      ellipsis: true,
      render: (rowData: ProjectReportDataType) => {
        return rowData.tasks.map((task, index) => {
          return (
            <div
              key={task.id}
              title={task.summary}
              className='perTaskRow ellipsis'
              style={getRowStyle(rowData.tasks.length, task.employees.length, index)}
            >
              {task.summary}
            </div>
          );
        });
      }
    },
    {
      key: 'employeeName',
      title: info.employeeName,
      className: 'employeeCell',
      ellipsis: true,
      render: (rowData: ProjectReportDataType) => {
        return rowData.tasks.map((task, index) => {
          return (
            <div key={task.id} style={getNestedRowStyle(rowData.tasks.length, index)}>
              {task.employees.map((employee) => {
                return (
                  <div
                    key={employee.id}
                    title={`${employee.lastName} ${employee.firstName}`}
                    className={'employeeRow ellipsis'}
                  >
                    {`${employee.lastName} ${employee.firstName}`}
                  </div>
                );
              })}
            </div>
          );
        });
      }
    },
    {
      key: 'hoursPerEmployee',
      title: info.hoursPerEmployee,
      className: 'employeeCell',
      width: 150,
      render: (rowData: ProjectReportDataType) => {
        return rowData.tasks.map((task, index) => {
          return (
            <div key={task.id} style={getNestedRowStyle(rowData.tasks.length, index)}>
              {task.employees.map((employee) => {
                return (
                  <div key={employee.id} className={'employeeRow ellipsis'}>
                    {employee.hoursPerDay}
                  </div>
                );
              })}
            </div>
          );
        });
      }
    },
    {
      key: 'hoursPerTask',
      title: info.hoursPerTask,
      className: 'taskHoursCell',
      width: 150,
      render: (rowData: ProjectReportDataType) => {
        return rowData.tasks.map((task, index) => {
          return (
            <div
              key={task.id}
              style={getRowStyle(rowData.tasks.length, task.employees.length, index)}
            >
              {task.hoursPerDay}
            </div>
          );
        });
      }
    },
    {
      key: 'clientTotal',
      title: info.clientGrandTotal,
      className: 'clientTotalCell',
      render: (rowData: ProjectReportDataType) => {
        const grandTotal = rowData.tasks.reduce((sum, task) => {
          return sum + task.hoursPerDay;
        }, 0);
        return <div key={rowData.id}>{getRoundedTotal(grandTotal)}</div>;
      }
    }
  ];

  return (
    <Flex style={{ marginTop: 20 }}>
      <Table
        id='projHoursReportTable'
        rowKey='id'
        size='small'
        bordered
        dataSource={data}
        columns={columns}
        pagination={false}
        scroll={{ y: '420px' }}
      />
    </Flex>
  );
};

export default ReportTable;
