import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { Layout, Menu, Modal } from 'antd';
import { useQuery } from '@apollo/client';

import { client } from '../../ApolloClient/client';
import { GET_USER } from '../../ApolloClient/queries/userQueries';
import { Button } from '../../components/core';
import { RequestTypeName } from '../../types/main';
import { addUserInfo } from '../../redux/reducers/UserReducer';
import { logout } from '../../redux/reducers';
import { EmployeeRole } from '../../graphql/graphql';
import { RootState } from '../../redux/reducers/index';
import { getProtectedComponent } from '../ProtectedComponent';
import { info } from '../../resources/strings';
import { Flex } from '../core';

import logo from '../../icons/Logos/Logo_white.svg';
import './Navbar.scss';

const { Sider } = Layout;
const { SubMenu } = Menu;

const NavBar: React.FC = () => {
  const user = useSelector((state: RootState) => state.user.userInfo);
  const isAdmin = user.role === EmployeeRole.Admin;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { data } = useQuery(GET_USER);
  const { step } = useSelector((state: RootState) => state.timeSheetLookup);
  const [visible, setVisible] = useState(false);

  const logOut = React.useCallback(() => {
    localStorage.clear();
    client.resetStore();
    dispatch(logout());
    history.push('/login');
  }, [dispatch, history]);

  React.useEffect(() => {
    if (data?.me.__typename === RequestTypeName.employee) {
      dispatch(addUserInfo(data.me));
    }
    if (data?.me.__typename === RequestTypeName.AuthenticationError) {
      logOut();
    }
  }, [dispatch, data, logOut]);

  return (
    <>
      <div onClick={step === 2 ? () => setVisible(true) : undefined}>
        <Sider
          width='250px'
          style={{ minHeight: '100%', background: 'black' }}
          className={step === 2 ? 'disable-click' : ''}
        >
          <div className='sidebar-container'>
            <div className='logo'>
              <img src={logo} alt='funday'></img>
            </div>
            <div className='menu-wrapper'>
              <Menu theme='dark' mode='inline' defaultSelectedKeys={[location.pathname]}>
                <SubMenu key='sub0' title={info.timesheets}>
                  <Menu.Item key='/currentTime'>
                    <NavLink to='/currentTime' activeClassName={'active'}>
                      <span className='circle'></span>
                      <span className='nav-item'>{info.currentTiemsheet}</span>
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key='/pastTime'>
                    <NavLink to='/pastTime' activeClassName={'active'}>
                      <span className='circle'></span>
                      <span className='nav-item'>{info.pastTimesheets}</span>
                    </NavLink>
                  </Menu.Item>
                </SubMenu>
                {isAdmin && (
                  <SubMenu key='sub1' title={info.systemData}>
                    {getProtectedComponent({
                      roles: ['Admin'],
                      component: (
                        <Menu.Item key='/employees'>
                          <NavLink to='/employees' activeClassName={'active'}>
                            <span className='circle'></span>
                            <span className='nav-item'>{info.employees}</span>
                          </NavLink>
                        </Menu.Item>
                      )
                    })}
                    {getProtectedComponent({
                      roles: ['Admin'],
                      component: (
                        <Menu.Item key='/clients'>
                          <NavLink to='/clients' activeClassName={'active'}>
                            <span className='circle'></span>
                            <span className='nav-item'>{info.clients}</span>
                          </NavLink>
                        </Menu.Item>
                      )
                    })}
                    {getProtectedComponent({
                      roles: ['Admin'],
                      component: (
                        <Menu.Item key='/holidays'>
                          <NavLink to='/holidays' activeClassName={'active'}>
                            <span className='circle'></span>
                            <span className='nav-item'>{info.holidays}</span>
                          </NavLink>
                        </Menu.Item>
                      )
                    })}
                    {getProtectedComponent({
                      roles: ['Admin'],
                      component: (
                        <Menu.Item key='/administrative'>
                          <NavLink to='/administrative'>
                            <span className='circle'></span>
                            <span className='nav-item'>{info.adminData}</span>
                          </NavLink>
                        </Menu.Item>
                      )
                    })}
                    {getProtectedComponent({
                      roles: ['Admin'],
                      component: (
                        <Menu.Item key='/timeSheetLookup'>
                          <NavLink to='/timeSheetLookup' activeClassName={'active'}>
                            <span className='circle'></span>
                            <span className='nav-item'>{info.timesheetLookup}</span>
                          </NavLink>
                        </Menu.Item>
                      )
                    })}
                  </SubMenu>
                )}
                {[
                  EmployeeRole.Admin,
                  EmployeeRole.Manager,
                  EmployeeRole.EngagementManager
                ].includes(user.role as EmployeeRole) && (
                  <SubMenu key='sub2' title={info.reports}>
                    {getProtectedComponent({
                      roles: ['Admin'],
                      component: (
                        <Menu.Item key='/rateReport'>
                          <NavLink to='/rateReport' activeClassName={'active'}>
                            <span className='circle'></span>
                            <span className='nav-item'>{info.rateReport}</span>
                          </NavLink>
                        </Menu.Item>
                      )
                    })}
                    {getProtectedComponent({
                      roles: ['Admin', 'Manager', 'EngagementManager'],
                      component: (
                        <Menu.Item key='/projectHours'>
                          <NavLink to='/projectHours' activeClassName={'active'}>
                            <span className='circle'></span>
                            <span className='nav-item'>{info.projectHours}</span>
                          </NavLink>
                        </Menu.Item>
                      )
                    })}
                    {getProtectedComponent({
                      roles: ['Admin', 'Manager'],
                      component: (
                        <Menu.Item key='/employeeProjectHours'>
                          <NavLink to='/employeeProjectHours' activeClassName={'active'}>
                            <span className='circle'></span>
                            <span className='nav-item'>{info.employeeProjectHours}</span>
                          </NavLink>
                        </Menu.Item>
                      )
                    })}
                  </SubMenu>
                )}
              </Menu>
            </div>
            <div className='nav-footer'>
              {user?.firstName?.length + user?.lastName?.length > 15 ? (
                <Flex style={{ marginBottom: 10 }}>
                  <span className='username'>{user?.firstName}</span>{' '}
                  <span className='username'>{user?.lastName}</span>
                </Flex>
              ) : (
                <Flex direction='row' center='both-axis' style={{ marginBottom: 10 }}>
                  <span style={{ marginRight: 4 }}>{user?.firstName}</span>{' '}
                  <span>{user?.lastName}</span>
                </Flex>
              )}
              <Button
                style={{ width: 150, marginRight: 30 }}
                onClick={logOut}
                text={info.logOut}
                colorType={'pink'}
                block
              />
            </div>
          </div>
        </Sider>
      </div>
      <Modal
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        visible={visible}
        cancelButtonProps={{ style: { display: 'none' } }}
        centered
        className='ok-btn'
        width={350}
      >
        {info.saveOrDiscardChnages}
      </Modal>
    </>
  );
};

export default NavBar;
