import moment from 'moment';
import default_logo from '../../../icons/Logos/Default_Client_Logo.svg';
import {
  ClientLookup,
  ClientTimeFrame,
  EmployeeTimeSheetClientsDataType,
  EmployeeTimeSheetDataType,
  TaskLookup,
  TaskTimeFrame
} from '../../../types/main';
import { UID } from '../../../redux/reducers/TimeFrameReducer';
import { PreviousTimeSheetQuery } from '../../../graphql/graphql';

export const getInitialSelectedDays = (): string[] => {
  const lastWeekDate = moment().utcOffset(0, true).subtract(1, 'weeks').startOf('isoWeek');
  const workDays: string[] = [];
  for (let i = 0; i < 5; i++) {
    workDays.push(moment(lastWeekDate).add(i, 'day').toISOString());
  }
  return workDays;
};

export const getClientLogoUrl = (url: string | null): string => {
  const imageUrl = url ? url : default_logo;
  return `url(${imageUrl})`;
};

export const splitHoursEvenly = (
  total: number,
  counts: number
): { splitedHours: number; correction: number | null } => {
  let correction: number | null = null;
  const splitedHours = +(total / counts).toFixed(2);
  const sumAfterSplit = splitedHours * counts;
  const shift = total - sumAfterSplit;
  if (shift) {
    correction = +(splitedHours + shift).toFixed(2);
  }
  return {
    splitedHours,
    correction
  };
};

export const splitTimeFramesEvenly = (
  timeFrames: ClientTimeFrame[] | TaskTimeFrame[],
  totalHours: number
): void => {
  const { splitedHours, correction } = splitHoursEvenly(totalHours, timeFrames.length);
  timeFrames.forEach((c) => (c.hours = splitedHours));

  if (correction) {
    timeFrames[timeFrames.length - 1].hours = correction;
  }
};

export const getDefaultTaskParams = (id: number): TaskTimeFrame => {
  return {
    id: -1,
    name: '',
    hours: 0,
    UID: id
  };
};

type PrevData = {
  __typename?: 'TimeSheets' | undefined;
  count: number;
  entities: any[];
};

export const getNewClientsDataFromLastTimeSheet = (
  prevData: PreviousTimeSheetQuery['previousTimeSheet'],
  totalHours: number
): ClientTimeFrame[] => {
  const oldTotalHours = (prevData as PrevData)?.entities[0]?.totalHours;
  let sumOfClientHours = 0;
  const newClientsData = (prevData as PrevData)?.entities[0]?.clients.map((item, i) => {
    const newCl = {} as ClientTimeFrame;
    let clientNewTotalHours = +((totalHours * item.totalHours) / oldTotalHours).toFixed(2);
    newCl.id = item.client.id;
    newCl.name = item.client.name;
    newCl.status = item.client.status;
    newCl.splitTaskHoursEvenly = false;
    i === (prevData as PrevData)?.entities[0]?.clients.length - 1
      ? (newCl.hours = clientNewTotalHours = +(totalHours - sumOfClientHours).toFixed(2))
      : (newCl.hours = clientNewTotalHours);

    newCl.UID = ++UID.uid;
    sumOfClientHours += +clientNewTotalHours.toFixed(2);
    let sumOfTaskHours = 0;
    newCl.tasks = item.tasks.map((tk, index) => {
      const newTask = {} as TaskTimeFrame;
      newTask.id = tk.task.id;
      newTask.UID = ++UID.uid;
      newTask.name = tk.task.summary;
      newTask.status = tk.task.status;
      if (index === item.tasks.length - 1) {
        newTask.hours = +(clientNewTotalHours - sumOfTaskHours).toFixed(2);
      } else {
        newTask.hours = +((clientNewTotalHours * tk.hours) / item.totalHours).toFixed(2);
        sumOfTaskHours += +((clientNewTotalHours * tk.hours) / item.totalHours).toFixed(2);
      }
      return newTask;
    });
    return newCl;
  });
  return newClientsData;
};

export const getClientsDataFromTimeSheet = (
  data: EmployeeTimeSheetDataType
): EmployeeTimeSheetClientsDataType[] => {
  const clientsData = data?.clients.map((item) => {
    const client = {} as EmployeeTimeSheetClientsDataType;
    client.id = item.id;
    client.totalHours = item.totalHours;
    client.name = item.client.name;
    client.status = item.client.status;

    client.tasks = item.tasks.map((tk) => {
      const newTask = {} as { id: number; hours: number; name: string; status: string };
      newTask.id = tk.task.id;
      newTask.hours = tk.hours;
      newTask.name = tk.task.summary;
      newTask.status = tk.task.status;
      return newTask;
    });
    return client;
  });
  return clientsData;
};

export const getClientsDataForTimeSheetLookup = (
  data: EmployeeTimeSheetDataType
): ClientLookup[] => {
  const clientsData = data?.clients.map((item) => {
    const client = {} as ClientLookup;
    client.id = item.client.id;
    client.hours = item.totalHours;
    client.name = item.client.name;
    client.status = item.client.status;
    client.UID = ++UID.uid;

    let sumOfTaskHours = 0;
    client.tasks = item.tasks.map((tk, index) => {
      const task = {} as TaskLookup;
      task.id = tk.task.id;
      task.UID = ++UID.uid;
      task.name = tk.task.summary;
      task.hours = tk.hours;
      task.status = tk.task.status;

      if (index === item.tasks.length - 1) {
        task.hours = +(item.totalHours - sumOfTaskHours).toFixed(2);
      } else {
        task.hours = +tk.hours.toFixed(2);
        sumOfTaskHours += +tk.hours.toFixed(2);
      }

      return task;
    });
    return client;
  });
  return clientsData;
};
