import * as React from 'react';
import classNames from 'classnames';

import styles from './Flex.module.scss';

interface FlexProps {
  grow?: boolean;
  wrap?: boolean;
  center?: 'cross-axis' | 'main-axis' | 'both-axis';
  direction?: 'column' | 'row' | 'row-reverse';
  space?: 'between' | 'around';
  align?: 'start-main-axis' | 'end-main-axis' | 'start-cross-axis' | 'end-cross-axis' | 'baseline';
  style?: React.CSSProperties;
  className?: string;
  children?: React.ReactNode;
}

const Flex = React.forwardRef<HTMLDivElement, FlexProps>(
  ({ children, className = '', align, space, direction, wrap, grow, center, ...props }, ref) => {
    const classN = classNames(styles.flex, {
      [styles.flex_1]: grow,
      [styles.flex_row]: direction === 'row',
      [styles.flex_row_reverse]: direction === 'row-reverse',
      [styles.flex_wrap]: wrap,
      [styles.flex_center_cross_axis]: center === 'cross-axis',
      [styles.flex_center_main_axis]: center === 'main-axis',
      [styles.flex_center_both_axis]: center === 'both-axis',
      [styles.flex_space_between]: space === 'between',
      [styles.flex_space_around]: space === 'around',
      [styles.flex_end_main_axis]: align === 'end-main-axis',
      [styles.flex_start_main_axis]: align === 'start-main-axis',
      [styles.flex_end_cross_axis]: align === 'end-cross-axis',
      [styles.flex_start_cross_axis]: align === 'start-cross-axis',
      [styles.flex_baseline]: align === 'baseline',
      [className]: Boolean(className)
    });

    return (
      <div className={classN} ref={ref} {...props}>
        {children}
      </div>
    );
  }
);

export default Flex;
