import React from 'react';
import classNames from 'classnames';
import './Input.styles.scss';
import { Input, InputProps } from 'antd';
import { RefCallBack } from 'react-hook-form';

export interface IInputUIProps extends InputProps {
  colorType?: 'primary' | 'black';
  width?: string | number;
  withError?: boolean;
  inputRef?: RefCallBack;
}

type IInputProps = IInputUIProps;

const cInput: React.FC<IInputProps> = (props) => {
  const { colorType, className, width = 350, inputRef, withError, ...otherProps } = props;
  const cn = classNames('default', colorType, className, withError ? 'withError' : '');

  return <Input className={cn} {...otherProps} ref={inputRef} width={width} />;
};

export default cInput;
