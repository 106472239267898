import React from 'react';
import moment from 'moment';
import { Modal, ModalProps } from 'antd';

import { Flex, Button } from '../../components/core';
import Balloons from '../../icons/Logos/Balloons.svg';
import { DaysOfWeekType } from '../../types/main';
import { info } from '../../resources/strings';

interface IProps extends ModalProps {
  confirmAction: () => void;
  onCancel: () => void;
  visible: boolean;
  weekDays: DaysOfWeekType[] | any;
}

const CongratulationPopup: React.FC<IProps> = ({ confirmAction, onCancel, visible, weekDays }) => {
  const lastWeekStartDate = moment(weekDays[0]?.date).format('LL');
  const lastWeekEndDate = moment(weekDays[weekDays.length - 1]?.date).format('LL');
  const content = () => {
    return (
      <Flex className='modalContent'>
        <Flex center='cross-axis'>
          <Flex className='logo_content' space='around' center='cross-axis'>
            <div className='logo'>
              <img src={Balloons} alt='congratulations'></img>
            </div>
          </Flex>
          <div className='congratulations bold'>{info.congratulations}</div>
          <div className='dateText'>{info.successfullyEnteredData}</div>
          <div className='dateContent bold'>
            {lastWeekStartDate} - {lastWeekEndDate}
          </div>
        </Flex>
      </Flex>
    );
  };

  const footer = () => {
    return (
      <Flex className='footer' direction='row' center='main-axis' style={{ marginBottom: 30 }}>
        <Flex align='start-cross-axis'>
          <Button colorType='black' onClick={confirmAction} text={info.done} style={{ width: 150 }} />
        </Flex>
      </Flex>
    );
  };

  return (
    <Modal
      title=''
      onCancel={onCancel}
      visible={visible}
      centered={true}
      footer={footer()}
      width={480}
    >
      {content()}
    </Modal>
  );
};

export default CongratulationPopup;
