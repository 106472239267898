import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { message, Switch } from 'antd';

import {
  addClient,
  deleteClient,
  editClientData,
  splitClientsHoursEvenly,
  setClientHoursEvenly,
  cascadeUpdateTotalHours
} from '../../redux/reducers/TimeFrameReducer';
import { Flex, BackButton, Button, ConfirmationDialog, Spiner } from '../../components/core';
import Label from '../../components/core/Label/Label';
import Card from '../../components/Card';
import { ClientTimeFrame } from '../../types/main';
import TotalHours from './TotalHours';
import { info } from '../../resources/strings';
import { useFindActiveClientsWithActiveTasksQuery } from '../../graphql/graphql';
import { maxWeekHours, minTaskHours } from '../../utils/constants';

interface IProps {
  currentStep: number;
  setStep: (step: number) => void;
  totalHours: number;
  clientList: ClientTimeFrame[];
  splitEvenly: boolean;
}

const AddClient: React.FC<IProps> = ({
  currentStep,
  setStep,
  totalHours,
  clientList,
  splitEvenly
}) => {
  const dispatch = useDispatch();
  const [showTotalHoursDialog, setShowTotalHoursDialog] = useState(false);
  const [newTotalHours, setNewTotalHours] = useState(totalHours);
  const { data: allClientTasks, loading: allClientTasksLoading } =
    useFindActiveClientsWithActiveTasksQuery({ fetchPolicy: 'network-only' });

  useEffect(() => {
    if (splitEvenly) {
      dispatch(splitClientsHoursEvenly());
    }
  }, [dispatch, splitEvenly]);

  function isOptionDisabled(item) {
    if (allClientTasks?.findActiveClientsWithActiveTasks?.__typename === 'Tasks') {
      const result = allClientTasks.findActiveClientsWithActiveTasks.entities.some(
        (task) => task.client.id === item.id
      );
      return result ? clientList.map((client) => client.id).includes(item.id as number) : true;
    }
    return clientList.map((client) => client.id).includes(item.id as number);
  }

  function addNewClient() {
    dispatch(addClient());
  }

  function removeClient(index: number) {
    dispatch(deleteClient(index));
  }

  function editClient(index: number, clientData: ClientTimeFrame) {
    dispatch(editClientData({ index, clientData }));
  }

  function handleSwitchChange(checked: boolean) {
    dispatch(setClientHoursEvenly(checked));
  }

  const getClientsCards = () => {
    return clientList.map((item, index) => (
      <Card
        key={item.UID}
        item={item}
        itemIndex={index}
        totalHours={totalHours}
        counts={clientList.length}
        onDelete={removeClient}
        onEdit={editClient}
        optionDisable={isOptionDisabled}
      />
    ));
  };

  const checkMinHours = () => {
    let isLessThanMinHours = false;
    clientList.forEach((client) => {
      if (client.hours < minTaskHours) isLessThanMinHours = true;
    });
    if (isLessThanMinHours) {
      message.destroy();
      message.warn(info.minTaskHours);
      return true;
    }
    return false;
  };

  const isDisabled = React.useMemo(() => {
    const summaryHours = Number(
      clientList.reduce((sum, current) => sum + current.hours, 0).toFixed(2)
    );
    const isClientsDataCorect = !clientList.some(
      (client) => !client.id || client.id < 0 || !client.hours
    );
    const isHoursCorrect = summaryHours.toFixed(2) == totalHours.toFixed(2);
    return !(isHoursCorrect && isClientsDataCorect);
  }, [clientList, totalHours]);

  const summaryHours = clientList.reduce((sum, current) => sum + current.hours, 0);

  if (allClientTasksLoading) return <Spiner />;

  return (
    <Flex className='content addClient'>
      <Flex direction='column' style={{ width: '100%' }}>
        <Flex direction='row' center='cross-axis'>
          <BackButton
            style={{ marginBottom: '8px' }}
            onClick={() => setStep(currentStep - 1)}
            text={info.back}
          />
          <div>{info.addClientWorkedWith}</div>
        </Flex>
        <Flex className='setClientsContent'>
          <Flex direction='row' space='between'>
            <Label>{info.addPercentageWorked}</Label>
            <Flex direction='row' align='start-cross-axis' style={{ marginBottom: 15 }}>
              <h3 style={{ margin: '2px 10px 0 0' }}>{info.splitEvenly}</h3>
              <Switch checked={splitEvenly} onChange={handleSwitchChange} />
            </Flex>
          </Flex>
          {getClientsCards()}
          <Flex direction='row' center='both-axis' style={{ marginTop: 10 }}>
            <Button
              style={{ width: 150 }}
              onClick={addNewClient}
              colorType={'gray'}
              text={info.addClientPlus}
              disabled={false}
            />
          </Flex>
          <TotalHours
            summaryHours={summaryHours}
            totalHours={totalHours}
            onChange={(value) => {
              value && setNewTotalHours(value);
              value && setShowTotalHoursDialog(true);
            }}
          />
          {showTotalHoursDialog && (
            <ConfirmationDialog
              visible
              title={info.totalHours}
              noTitle={info.cancel}
              yesTitle={info.confirm}
              confirmAction={() => {
                dispatch(cascadeUpdateTotalHours(newTotalHours));
                setShowTotalHoursDialog(false);
              }}
              rejectAction={() => setShowTotalHoursDialog(false)}
            >
              {info.editTotalWorkedHours}
            </ConfirmationDialog>
          )}
        </Flex>
        <Flex direction='row' center='both-axis' style={{ margin: 30 }}>
          <Button
            style={{ width: 150 }}
            onClick={() => {
              if (checkMinHours()) return;
              if (totalHours > maxWeekHours) {
                message.destroy();
                message.warning(info.maxWeekHours);
              } else {
                setStep(currentStep + 1);
              }
            }}
            colorType={'black'}
            text={info.next}
            disabled={isDisabled}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AddClient;
