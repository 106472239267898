import React from 'react';
import { Table } from 'antd';
import { useHistory } from 'react-router-dom';

import { Flex } from '../../../components/core';
import ActionCell from './ActionCell';
import { ClientDataType } from '../../../types/main';
import default_logo from '../../../icons/Logos/Default_Client_Logo.svg';
import { info } from '../../../resources/strings';

interface IProps {
  data: ClientDataType[];
  reFetchClients: () => void;
}

const ClientsTable: React.FC<IProps> = ({ data, reFetchClients }) => {
  const history = useHistory();
  const columns = [
    {
      key: 'logo',
      title: info.clientName,
      render: (rowdata: ClientDataType) => {
        const imageUrl = rowdata.logoUrl ? rowdata.logoUrl : default_logo;
        return (
          <Flex direction='row' center={'cross-axis'}>
            <div className='imageWrapper' style={{ backgroundImage: `url(${imageUrl})` }} />
            <div>{rowdata.name}</div>
          </Flex>
        );
      }
    },
    {
      key: 'status',
      title: info.status,
      width: '150px',
      render: (rowdata: ClientDataType) => {
        return <span className={`client ${rowdata.status}`}>{rowdata.status}</span>;
      }
    },
    {
      key: 'action',
      width: '50px',
      render: (rowdata: ClientDataType) => (
        <ActionCell rowdata={rowdata} reFetchClients={reFetchClients} />
      )
    }
  ];

  return (
    <Table
      onRow={(rowData) => {
        return {
          onClick: () => {
            const path = `/clients/${rowData.id}`;
            history.push(path, rowData);
          }
        };
      }}
      id={'clientsTable'}
      rowKey='id'
      rowClassName='pointer'
      size={'small'}
      dataSource={data}
      columns={columns}
      pagination={{ hideOnSinglePage: true, pageSize: 15 }}
      scroll={{ y: 400 }}
    />
  );
};

export default ClientsTable;
