import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker, message } from 'antd';
import moment, { Moment } from 'moment';
import locale from 'antd/es/date-picker/locale/en_GB';
import { Controller, useForm } from 'react-hook-form';

import { Button, Flex } from '../../../components/core';
import { customWeekStartEndFormat, getEndOfWeek, getStartOfWeek } from '../../../utils/helpers';
import DebouncedList from '../../../components/DynamicSelect';
import {
  Employee,
  EmployeePastTimeSheetQueryVariables,
  useEmployeesLazyQuery,
  useEmployeesQuery
} from '../../../graphql/graphql';
import { RootState } from '../../../redux/reducers';
import {
  selectedEmployee,
  updateSelectedEmployeeName,
  UpdateTimesheetFilters,
  updateTimeSheetReportDateRange,
  updateTimeSheetReportFilters
} from '../../../redux/reducers/Reports';
import { customFormatStartEnd } from '../../Previous/Step2';
import { errors, info } from '../../../resources/strings';

import 'moment/locale/en-gb';
import { ResponseDataType } from '../../../components/DynamicSelect/DebouncedList';

type FormData = {
  dateRange: any[];
  employeeId: number | null;
};

interface IProps {
  getReport: (data: { variables: EmployeePastTimeSheetQueryVariables }) => void;
}

const FilterSection: FC<IProps> = ({ getReport }) => {
  const dispatch = useDispatch();
  const reportData = useSelector((state: RootState) => state.reports.timesheetReport);
  const { dateRange: datePickerRange } = useSelector(
    (state: RootState) => state.reports.timesheetReport.filters
  );
  const [getEmployeeById, { data: selectedEmployeeData }] = useEmployeesLazyQuery();
  const { control, handleSubmit, setValue, getValues } = useForm<FormData>({
    defaultValues: {
      dateRange: reportData.filters.dateRange,
      employeeId: reportData.filters.employeeId
    }
  });

  const updateSelectedEmployee = (data: ResponseDataType | null) => {
    dispatch(selectedEmployee(data));
  };

  const updateEmployeeName = (name: string) => {
    dispatch(updateSelectedEmployeeName(name));
  };

  useEffect(() => {
    if (reportData.filters.employeeId) {
      getEmployeeById({ variables: { ids: [reportData.filters.employeeId] } });
    }
  }, [reportData.filters.employeeId]);

  useEffect(() => {
    if (selectedEmployeeData?.employees.__typename === 'Employees') {
      updateSelectedEmployee(selectedEmployeeData.employees.entities[0]);
      updateEmployeeName(
        `${selectedEmployeeData.employees.entities[0].firstName} 
        ${selectedEmployeeData.employees.entities[0].lastName}`
      );
    }
  }, [selectedEmployeeData]);

  const updateFilters = (filtersData: UpdateTimesheetFilters) => {
    dispatch(updateTimeSheetReportFilters(filtersData));
  };

  const updateDateRange = (dateRange: Moment[]) => {
    dispatch(updateTimeSheetReportDateRange(dateRange));
  };

  const onChange = (date: Moment | null) => {
    if (date) {
      updateFilters({
        dateRange: [getStartOfWeek(date), getEndOfWeek(date)],
        employeeId: getValues().employeeId
      });
    } else {
      updateFilters({
        dateRange: [getStartOfWeek(), getEndOfWeek()],
        employeeId: getValues().employeeId
      });
    }
  };

  const onSubmit = (fieldData: FormData) => {
    if (!fieldData.employeeId) {
      return message.error(errors.selectEmployee);
    }

    const { startDate, endDate } = customFormatStartEnd(datePickerRange[0]);

    const variables = {
      startDate,
      endDate,
      employeeId: fieldData.employeeId
    };

    updateFilters({
      dateRange: [moment(startDate), moment(endDate)],
      employeeId: fieldData.employeeId
    });

    getReport({ variables });
    updateDateRange([moment(startDate), moment(endDate)]);
  };

  const onClear = () => {
    setValue('employeeId', null);
    const [startDate, endDate] = reportData.filters.dateRange;

    updateFilters({
      dateRange: [startDate, endDate],
      employeeId: null
    });
    updateSelectedEmployee(null);
  };

  const optionRender = (data: Employee) => {
    const fullName = data.firstName + ' ' + data.lastName;
    const result = fullName.length > 20 ? fullName.slice(0, 20) + '...' : fullName;
    return result;
  };

  return (
    <Flex wrap={true} direction='row' align='end-cross-axis' style={{ marginTop: 30 }}>
      <Flex style={{ marginRight: 20 }}>
        <Controller
          control={control}
          name='dateRange'
          render={() => (
            <DatePicker
              defaultValue={datePickerRange[0]}
              format={customWeekStartEndFormat}
              picker='week'
              onChange={onChange}
              value={datePickerRange[0]}
              locale={locale}
              style={{ background: '#D7D7D7' }}
            />
          )}
        />
      </Flex>
      <Flex style={{ marginRight: 20 }}>
        <DebouncedList
          control={control}
          controllerName='employeeId'
          labelText={info.employee}
          dataText='firstName'
          dataValue='id'
          queryDataKey='employees'
          query={useEmployeesQuery}
          sectionName='TimeSheetLookup'
          optionRender={optionRender}
          style={{ width: 400 }}
        />
      </Flex>
      <Flex direction='row' style={{ marginRight: 20, marginTop: 10 }}>
        <Button
          style={{ marginRight: 10 }}
          colorType='white'
          onClick={onClear}
          text={info.clearX}
        />
        <Button colorType='black' onClick={handleSubmit(onSubmit)} text={info.generateReport} />
      </Flex>
    </Flex>
  );
};

export default FilterSection;
