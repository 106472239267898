import produce from 'immer';
import moment from 'moment';

import { Holiday } from '../../graphql/graphql';

const SET_HOLIDAYS = 'SET_HOLIDAYS';
const GET_HOLIDAYS_BY_YEAR = 'GET_HOLIDAYS_BY_YEAR';

export interface IAppReducerState {
  holidays: Holiday[];
}

export function setHolidays(data: Holiday[]): { type: string; data: Holiday[] } {
  return {
    type: SET_HOLIDAYS,
    data
  };
}

export function getHolidaysByYear(data: number): { type: string; data: number } {
  return {
    type: GET_HOLIDAYS_BY_YEAR,
    data
  };
}

export const initialState: IAppReducerState = {
  holidays: []
};

type ReducerAction =
  | { type: 'SET_HOLIDAYS'; data: Holiday[] }
  | { type: 'GET_HOLIDAYS_BY_YEAR'; data: number };

const holidayReducer = (
  state: IAppReducerState = initialState,
  action: ReducerAction
): IAppReducerState => {
  return produce(state, (draft: IAppReducerState) => {
    switch (action.type) {
      case SET_HOLIDAYS: {
        draft.holidays = action.data.map((holiday) => {
          return {
            ...holiday,
            startDate: new Date(holiday.startDate),
            endDate: new Date(holiday.endDate)
          };
        });
        break;
      }
      case GET_HOLIDAYS_BY_YEAR:
        const year = action.data;

        const holidays = state.holidays.map((holiday) => {
          return {
            ...holiday,
            startDate: holiday.isAnnual
              ? new Date(moment(holiday.startDate).utc().set('year', year).toDate())
              : new Date(holiday.startDate),
            endDate: holiday.isAnnual
              ? new Date(moment(holiday.endDate).utc().set('year', year).toDate())
              : new Date(holiday.endDate)
          };
        });
        draft.holidays = holidays;
    }
  });
};

export default holidayReducer;
