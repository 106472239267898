import React from 'react';
import { Moment } from 'moment';
import { DatePicker, TimeRangePickerProps } from 'antd';
import { SwapRightOutlined } from '@ant-design/icons';
import { Flex, Label } from '../../components/core';
import { info } from '../../resources/strings';

import './DateRangePicker.scss';

const { RangePicker } = DatePicker;

interface IProps extends TimeRangePickerProps {
  onDateChange: (date: Moment[], dateString: string[]) => void;
  customClassName?: boolean;
}

const DateRangePicker: React.FC<IProps> = ({ onDateChange, customClassName, ...props }) => {
  function onChange(dateMoment, dateString) {
    onDateChange(dateMoment, dateString);
  }

  return (
    <Flex direction='column' center={'cross-axis'} className='dateRangePicker'>
      <Flex
        direction='row'
        style={{ width: '100%' }}
        className={customClassName ? `${props.className}` : 'labelContent'}
      >
        <Label type='small'>{info.dateFrom}</Label>
        <Label type='small'>{info.dateTo}</Label>
      </Flex>
      <RangePicker
        bordered={false}
        allowClear={false}
        suffixIcon={null}
        separator={<SwapRightOutlined />}
        onChange={onChange}
        {...props}
      />
      <Flex direction='row' style={{ width: '100%' }}>
        <div className='footer first'></div>
        <div className='footer second'></div>
      </Flex>
    </Flex>
  );
};

export default DateRangePicker;
