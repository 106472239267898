import { gql } from '@apollo/client';

export const GET_CLIENTS = gql`
  query clients($term: String, $skip: Int, $status: GenericStatus, $take: Int) {
    clients(filter: { term: $term, skip: $skip, status: $status, take: $take }) {
      __typename
      ... on Clients {
        count
        entities {
          id
          name
          status
          logoUrl
        }
      }
      ... on BadRequestError {
        message
      }
      ... on AuthenticationError {
        message
      }
    }
  }
`;

export const GET_TASKS = gql`
  query tasks($skip: Int, $status: GenericStatus, $take: Int, $summary: String) {
    tasks(filter: { skip: $skip, status: $status, take: $take, summary: $summary }) {
      __typename
      ... on Tasks {
        count
        entities {
          id
          status
          summary
        }
      }
      ... on BadRequestError {
        message
      }
      ... on AuthenticationError {
        message
      }
    }
  }
`;

export const GET_CLIENT_TASKS = gql`
  query findClientTasks(
    $skip: Int
    $status: GenericStatus
    $take: Int
    $summary: String
    $clientIds: [Int!]!
  ) {
    findClientTasks(
      input: { skip: $skip, status: $status, take: $take, summary: $summary, clientIds: $clientIds }
    ) {
      __typename
      ... on Tasks {
        count
        entities {
          id
          status
          summary
          client {
            id
            status
            logoUrl
            name
          }
        }
      }
      ... on BadRequestError {
        message
      }
      ... on AuthenticationError {
        message
      }
    }
  }
`;
