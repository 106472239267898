import React, { ChangeEventHandler, KeyboardEventHandler, useState } from 'react';
import { Input } from 'antd';

import { EditableCrudListItem } from './CrudListItems';
import CrossIcon from '../../icons/CrossIcon';
import { info } from '../../resources/strings';

interface Props {
  item: EditableCrudListItem;
  onEdit: (item: EditableCrudListItem) => void;
  onCancel: () => void;
}

const CrudListEditInput: React.FC<Props> = (props) => {
  const [editableItemTitle, setEditableItemTitle] = useState<string>(props.item.title);
  const onAddInputChange: ChangeEventHandler<HTMLInputElement> = (event) =>
    setEditableItemTitle(event.target.value);
  const onClearAddInput = () => {
    setEditableItemTitle('');
    props.onCancel();
  };
  const onAddInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter') {
      props.onEdit({ ...props.item, title: editableItemTitle });
      onClearAddInput();
    }
  };
  const onAddItem = () => {
    props.onEdit({ ...props.item, title: editableItemTitle });
    onClearAddInput();
  };

  return (
    <Input
      placeholder={info.editItem}
      className='crudListCreateField'
      value={editableItemTitle}
      onChange={onAddInputChange}
      onKeyDown={onAddInputKeyDown}
      addonAfter={
        editableItemTitle.length > 0 ? (
          <div>
            <span className='crudListAddItem' onClick={onAddItem}>
              {info.save}
            </span>
            <CrossIcon className='crudListClearItem' onClick={onClearAddInput} />
          </div>
        ) : (
          <div></div>
        )
      }
    />
  );
};

export default CrudListEditInput;
