import { FC, useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { InputNumber } from 'antd';
import { useForm } from 'react-hook-form';
import { useFindClientTasksQuery } from '../../graphql/graphql';
import { TaskLookup } from '../../types/main';
import { Flex, Label } from '../core';
import DebouncedList from '../DynamicSelect/DebouncedList';

interface IProps {
  item: TaskLookup;
  itemIndex: number;
  clientId: number;
  onDelete: (index: number) => void;
  onEdit: (index: number, data: TaskLookup) => void;
  optionDisable?: (data: any) => boolean;
  isSubmitSuccessful: boolean;
}

const Task: FC<IProps> = ({
  item,
  itemIndex,
  clientId,
  onDelete,
  onEdit,
  optionDisable,
  isSubmitSuccessful
}) => {
  const { control, reset } = useForm<{ id: number }>();
  const [inputValue, setInputValue] = useState(0);

  useEffect(() => {
    reset();
  }, [isSubmitSuccessful, reset]);

  function deleteItem() {
    onDelete(itemIndex);
  }

  function editItem(data: TaskLookup) {
    onEdit(itemIndex, data);
  }

  function onTaskChange(data: any) {
    editItem({ ...item, id: data?.value, name: data?.label });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function onCompleted(data: any) {
    reset();
  }

  function onTaskHoursChange(data: any) {
    editItem({ ...item, hours: data });
  }

  const defaultValueId = { value: item.id > 0 ? item.id : '', label: item.id > 0 ? item.name : '' };

  return (
    <>
      <Flex direction='row' style={{ marginLeft: 10 }}>
        <Flex style={{ marginBottom: '30px' }}>
          <DebouncedList
            control={control}
            style={{ width: 200 }}
            controllerName='id'
            dataText='summary'
            dataValue='id'
            labelInValue
            defaultValueId={defaultValueId}
            queryDataKey='findClientTasks'
            labelText='Task'
            query={useFindClientTasksQuery}
            onChange={onTaskChange}
            onCompleted={onCompleted}
            otherVarebales={{ clientIds: clientId }}
            optionDisable={optionDisable}
            allowClear={false}
          />
        </Flex>
        <Flex direction='column' align='start-cross-axis' style={{ marginLeft: 50 }}>
          <Label type='small'>Task Hours</Label>
          <InputNumber
            onChange={(value) => (value == null ? setInputValue(0) : setInputValue(value))}
            onBlur={() => onTaskHoursChange(Number(inputValue.toFixed(2)))}
            onPressEnter={() => onTaskHoursChange(Number(inputValue.toFixed(2)))}
            value={item.hours ? item.hours : 0}
            min={0}
            step={0.01}
            precision={2}
            size='middle'
          />
        </Flex>
        <Flex style={{ marginLeft: 10, paddingTop: 30 }}>
          <CloseOutlined style={{ color: '#C4C4C4' }} onClick={deleteItem} title={'Remove Task'} />
        </Flex>
      </Flex>
    </>
  );
};

export default Task;
