import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Select } from 'antd';
import { useForm } from 'react-hook-form';
import RCCalendar from 'rc-year-calendar';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';

import { Button as SearchButton, Flex } from '../../components/core';
import DebouncedList from '../../components/DynamicSelect';
import AddEditHoliday from './AddEditHoliday';
import DeletePopup from './DeletePopup';
import { Holiday, useLocationsQuery } from '../../graphql/graphql';
import { DeleteIcon, EditIcon } from '../../icons';
import { HolidayViewType } from '../../types/main';
import { getHolidaysByYear, setHolidays } from '../../redux/reducers/HolidaysReducer';
import { RootState } from '../../redux/reducers';
import './Holidays.styles.scss';

interface FormData {
  locationIds?: number[];
}

interface IProps {
  holidays: Holiday[];
  refetchHolidays: (variables?: any) => void;
}

const Calendar: React.FC<IProps> = ({ holidays, refetchHolidays }) => {
  const dispatch = useDispatch();
  const [modalTop, setModalTop] = useState(0);
  const [modalLeft, setModalLeft] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [holidayId, setHolidayId] = useState<number>();
  const [holiday, setHoliday] = useState<any>(null);
  const [locations, setLocations] = useState<string[]>([]);
  const { control, handleSubmit } = useForm<FormData>();
  const [datasource, setDatasource] = useState<any>([]);
  const [visible, setVisible] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [currYear, setCurrYear] = useState(new Date().getFullYear());
  const [repeatedHoliday, setRepeatedHoliday] = useState<any>(null);

  const holidaysRedux = useSelector((state: RootState) => state.holidays.holidays);

  useEffect(() => {
    dispatch(setHolidays(holidays));
    dispatch(getHolidaysByYear(currYear));
  }, [holidays]);

  useEffect(() => {
    const d = holidaysRedux.map((obj) => {
      return {
        color: 'black',
        id: obj.id,
        name: obj.name,
        startDate: new Date(moment(obj.startDate).utc().format('YYYY-MM-DD HH:mm:ss')),
        endDate: new Date(moment(obj.endDate).utc().format('YYYY-MM-DD HH:mm:ss'))
      };
    });
    setDatasource(d);
  }, [holidaysRedux]);

  useEffect(() => {
    const h = holidays.find((el) => el.id === holidayId) as Holiday;
    setHoliday(h);
    const locationNames = h?.locations.map((l) => l.name);
    setLocations(locationNames);
  }, [holidayId, holidays]);

  const onSubmit = (fieldData: FormData) => {
    fieldData?.locationIds?.length !== 0
      ? refetchHolidays({ locationIds: fieldData.locationIds })
      : refetchHolidays({ locationIds: null });
  };
  const handleClick = () => {
    setVisible(true);
  };

  useEffect(() => {
    if (visible || visibleDelete) {
      setShowModal(false);
    }
  }, [visible, visibleDelete]);

  const footer = () => {
    return (
      <Flex direction='row' style={{ width: '100%' }}>
        <Button icon={<EditIcon size={18} />} onClick={() => handleClick()} />
        <Button icon={<DeleteIcon size={18} />} onClick={() => setVisibleDelete(true)} />
      </Flex>
    );
  };

  const handleChange = (value: string) => {
    setHolidayId(+value);
  };
  const showHolidays = () => {
    if (repeatedHoliday) {
      return (
        <div className='holiday-name'>
          <Select
            defaultValue={repeatedHoliday[0].id}
            style={{ width: 120 }}
            onChange={handleChange}
          >
            {repeatedHoliday?.map((item) => {
              return <Select.Option value={item.id}>{item.name}</Select.Option>;
            })}
          </Select>
        </div>
      );
    } else {
      return holiday.name;
    }
  };
  return (
    <div className='calendar-modal'>
      {showModal && (
        <Modal
          title={showHolidays()}
          visible={true}
          style={{
            position: 'absolute',
            top: modalTop,
            left: modalLeft
          }}
          onOk={() => setShowModal(false)}
          onCancel={() => setShowModal(false)}
          footer={footer()}
          width='180px'
          mask={false}
          className='edit-holiday'
          closable={false}
        >
          {locations?.map((name) => {
            return <div key={name}>{name}</div>;
          })}
        </Modal>
      )}

      <Flex className='content welcomePage' style={{ marginTop: 10 }}>
        <Flex direction='row' align='end-main-axis' style={{ marginBottom: -38, marginRight: 6 }}>
          <DebouncedList
            control={control}
            mode='multiple'
            maxTagCount='responsive'
            controllerName='locationIds'
            dataText='name'
            dataValue='id'
            queryDataKey='locations'
            query={useLocationsQuery}
            style={{ width: 250 }}
            placeholder='Location'
            fetchPolicy='network-only'
          />
          <SearchButton
            colorType={'black'}
            onClick={handleSubmit(onSubmit)}
            shape='circle'
            icon={<SearchOutlined />}
            style={{ borderRadius: '4px', marginLeft: 10 }}
          />
        </Flex>
        <RCCalendar
          onDayClick={(e) => {
            if (e.date < new Date(e.date.getFullYear(), 6, 1)) {
              setModalLeft(e.element.offsetLeft + e.element.offsetParent.offsetLeft - 155);
              setModalTop(
                e.element.offsetTop + e.element.offsetParent.offsetTop + e.element.clientHeight
              );
            } else {
              const timer = setInterval(() => {
                if (document.getElementsByClassName('ant-modal-content')[0]) {
                  const height =
                    document.getElementsByClassName('ant-modal-content')[0].clientHeight;
                  setModalTop(e.element.offsetParent.offsetTop + e.element.offsetTop - height);
                  clearInterval(timer);
                }
              }, 10);
              setModalLeft(e.element.offsetParent.offsetLeft + e.element.offsetLeft - 155);
            }
            if (e.events.length === 1) {
              setRepeatedHoliday(null);
              setHolidayId(e.events[0]?.id);
              setShowModal(true);
            } else if (e.events?.length > 1) {
              setRepeatedHoliday(e.events);
              setHolidayId(e.events[0]?.id);
              setShowModal(true);
            }
          }}
          dataSource={datasource}
          onYearChanged={(currentYear) => {
            dispatch(getHolidaysByYear(currentYear.currentYear));
            setCurrYear(currentYear.currentYear);
          }}
          weekStart={1}
          style='background'
        />
        {visible && (
          <AddEditHoliday
            holiday={holiday}
            type={HolidayViewType.edit}
            onCancel={() => setVisible(false)}
            visible={visible}
            refetchHolidays={refetchHolidays}
          />
        )}
        {visibleDelete && (
          <DeletePopup
            holiday={holiday}
            onCancel={() => setVisibleDelete(false)}
            visible={visibleDelete}
            refetchHolidays={refetchHolidays}
          />
        )}
      </Flex>
    </div>
  );
};

export default Calendar;
