export const employeeStatus = [
  { dataValue: 'Active', dataText: 'Active' },
  { dataValue: 'Deactivated', dataText: 'Inactive' },
  { dataValue: 'Pending', dataText: 'Pending' }
];

export const genericStatus = [
  { dataValue: 'Active', dataText: 'Active' },
  { dataValue: 'Inactive', dataText: 'Inactive' }
];
