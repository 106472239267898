import React from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { message } from 'antd';

import { addTotalHours, updateWeekDays } from '../../redux/reducers/TimeFrameReducer';
import { Flex, Button, Input, Label } from '../../components/core';
import OneWeekDays from '../../components/OneWeekDays/OneWeek';
import { DaysOfWeekType } from '../../types/main';
import { info } from '../../resources/strings';
import { maxWeekHours, minTaskHours } from '../../utils/constants';

interface IProps {
  currentStep: number;
  setStep: (step: number) => void;
  hours: number;
  selectedDays: string[];
  weekDays: DaysOfWeekType[];
}

const Step2: React.FC<IProps> = ({ currentStep, setStep, hours, selectedDays, weekDays }) => {
  const currentDate = `${moment(weekDays[0].date).format('MMMM')}, ${moment(
    weekDays[0].date
  ).format('YYYY')}`;
  const dispatch = useDispatch();

  function onWorkHoursChange(value: React.ChangeEvent<HTMLInputElement>) {
    dispatch(addTotalHours(+value.target.value));
  }

  function onWeekDaysChange(value: string[]) {
    dispatch(updateWeekDays(value));
  }

  return (
    <Flex className='content weekDays' center='both-axis'>
      <Flex direction='column' center='cross-axis' className='today'>
        <h1>{currentDate}</h1>
      </Flex>
      <OneWeekDays onChange={onWeekDaysChange} selectedDays={selectedDays} weekDays={weekDays} />
      <Flex align='start-cross-axis' style={{ marginTop: 60, width: 425 }}>
        <Label type='bold'>{info.howManyHoursWorked}</Label>
        <Input
          id='numberOfHours'
          type='number'
          colorType='primary'
          min={1}
          onChange={onWorkHoursChange}
          placeholder={info.numberOfHours}
          value={hours ? hours : ''}
          autoComplete='off'
        />
      </Flex>
      <Flex style={{ width: 200, margin: 40 }}>
        <Button
          colorType='black'
          onClick={() => {
            if (hours > maxWeekHours) {
              message.destroy();
              message.warning(info.maxWeekHours);
            } else if (hours < minTaskHours) {
              message.destroy();
              message.warning(info.minTaskHours);
            } else {
              setStep(currentStep + 1);
            }
          }}
          text={info.next}
          disabled={!(+hours > 0) || !selectedDays.length}
        />
      </Flex>
    </Flex>
  );
};

export default Step2;
