import { message } from 'antd';

import { ConfirmationDialog, Flex } from '../../components/core';
import { Holiday, useRemoveHolidayMutation } from '../../graphql/graphql';
import { info } from '../../resources/strings';

interface IProps {
  onCancel: () => void;
  visible: boolean;
  holiday: Holiday;
  refetchHolidays: (variables?: any) => void;
}

const DeleteHoliday: React.FC<IProps> = ({ onCancel, visible, holiday, refetchHolidays }) => {
  const [deleteHoliday] = useRemoveHolidayMutation({
    fetchPolicy: 'no-cache'
  });

  const onRemove = () => {
    deleteHoliday({ variables: { id: holiday.id } }).then(() => {
      refetchHolidays();
      onCancel();
      message.success(info.deleteHolidaySuccessMsg, 1);
    });
  };

  return (
    <ConfirmationDialog
      noTitle={info.cancel}
      yesTitle={info.remove}
      confirmAction={onRemove}
      rejectAction={() => {
        onCancel();
      }}
      visible={visible}
      className='delete-holiday-popup'
    >
      <Flex direction='row' wrap center='both-axis'>
        {info.permanentlyDelete}&nbsp;&nbsp;
        <div className='holiday-name'>{`'${holiday?.name}'`}</div> {info.holiday}?
      </Flex>
    </ConfirmationDialog>
  );
};

export default DeleteHoliday;
