import React from 'react';
import { Checkbox, message, Modal } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import moment, { Moment } from 'moment';

import { Button, Flex, Input, Label } from '../../components/core';
import { HolidayViewType, ValidationsType } from '../../types/main';
import { errors as errorMessages } from '../../resources/strings';
import DebouncedList from '../../components/DynamicSelect/DebouncedList';
import {
  Holiday,
  useCreateHolidayMutation,
  useLocationsQuery,
  useUpdateHolidayMutation
} from '../../graphql/graphql';
import DateRangePicker from '../../components/DateRangePicker';

import { maxTakeCount, MessageDuration, RegExpName } from '../../utils/constants';
import { info } from '../../resources/strings';
import '../../components/DateRangePicker/DateRangePicker.scss';
import '../../components/core/Dialog/Dialog.styles.scss';

export interface HolidayDataType {
  name: string;
  dateRange: any[];
  location: Location;
  isAnnual: boolean;
}

type FormData = {
  id: number;
  name: string;
  locationIds: number[];
  dateRange: any[];
  isAnnual: boolean;
};

const dateFormat = 'YYYY-MM-DD';

interface IProps {
  type: HolidayViewType;
  onCancel: () => void;
  visible: boolean;
  rowData?: HolidayDataType;
  holiday?: Holiday;
  refetchHolidays: () => void;
}

const getDefaultValues = (val: Holiday | undefined) => ({
  name: val ? val.name : '',
  locationIds: val ? val.locations.map((l) => l.id) : [],
  dateRange: val
    ? [moment(val.startDate, dateFormat), moment(val.endDate, dateFormat)]
    : [moment(), moment()],
  isAnnual: val ? val.isAnnual : false
});

const AddEditHoliday: React.FC<IProps> = ({
  onCancel,
  visible,
  type,
  holiday,
  refetchHolidays
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>({ defaultValues: getDefaultValues(holiday) });

  const [addHoliday] = useCreateHolidayMutation({
    fetchPolicy: 'no-cache'
  });
  const [editHoliday] = useUpdateHolidayMutation({
    fetchPolicy: 'no-cache'
  });

  const editAction = (variables) => {
    variables.id = holiday?.id;

    editHoliday({ variables })
      .then(() => {
        message.success(info.holidayEditedMsg, MessageDuration);
        refetchHolidays();
        onCancel();
        return;
      })
      .catch((e) => {
        if (e) {
          message.error(e.message, MessageDuration);
        }
      });
  };

  const onSubmit = (fieldData: FormData) => {
    const [startDate, endDate] = fieldData.dateRange;
    const variables = {
      id: fieldData.id,
      startDate: startDate.format(dateFormat),
      endDate: endDate.format(dateFormat),
      name: fieldData.name,
      isAnnual: fieldData.isAnnual,
      locationIds: fieldData.locationIds
    };
    if (type === HolidayViewType.add) {
      addHoliday({ variables })
        .then(() => {
          message.success(info.newHolidayCreateMsg, MessageDuration);
          refetchHolidays();
          onCancel();
          return;
        })
        .catch((e) => {
          if (e) {
            console.log('err', e);
          }
        });
    } else {
      editAction(variables);
    }
  };

  const content = () => {
    return (
      <Flex className='modalContent' center='both-axis'>
        <Flex style={{ height: '80px', width: 350 }} className='margBtm'>
          <Label type='small'>{info.name}</Label>
          <Controller
            name='name'
            control={control}
            rules={{ required: true, pattern: RegExpName }}
            render={({ field: { ref, ...otherFieldProps } }) => (
              <Input
                autoFocus
                width={'100%'}
                colorType='primary'
                autoComplete={'off'}
                inputRef={ref}
                {...otherFieldProps}
              />
            )}
          />
          {errors.name?.type === ValidationsType.required && (
            <span className='errorText'>{errorMessages.requiredField}</span>
          )}
          {errors.name?.type === ValidationsType.pattern && (
            <span className='errorText'>{errorMessages.namePattern('holiday')}</span>
          )}
        </Flex>
        <Flex style={{ width: 350 }} className='margBtm'>
          <Controller
            control={control}
            name='dateRange'
            render={({ field }) => (
              <DateRangePicker
                onDateChange={(date, dateToString) => field.onChange(date, dateToString)}
                value={[...field.value] as [Moment, Moment]}
                style={{ marginTop: 2, width: '100%' }}
                className='holidayLabel'
                customClassName
                bordered={false}
              />
            )}
          />
        </Flex>

        <Flex style={{ width: 350 }} className='margBtm'>
          <Controller
            name='locationIds'
            control={control}
            rules={{
              required: true
            }}
            render={() => (
              <DebouncedList
                control={control}
                mode='multiple'
                style={{ width: 350 }}
                controllerName='locationIds'
                labelText={info.location}
                dataText='name'
                dataValue='id'
                queryDataKey='locations'
                query={useLocationsQuery}
                otherVarebales={{ take: maxTakeCount }}
                fetchPolicy='network-only'
              />
            )}
          />
          {errors?.locationIds && (
            <span className='errorText'>{errorMessages.locationIsRequired}</span>
          )}
        </Flex>
        <Flex style={{ width: 350 }}>
          <Controller
            name='isAnnual'
            control={control}
            render={({ field: { onChange, value } }) => (
              <Checkbox className='annual' onChange={onChange} checked={value}>
                {info.applyForUpcomingYears}
              </Checkbox>
            )}
          />
        </Flex>
      </Flex>
    );
  };

  const footer = () => {
    return (
      <Flex className='footer' direction='row' center='main-axis' style={{ marginBottom: 30 }}>
        <Flex align='end-cross-axis'>
          <Button
            colorType={'white'}
            onClick={onCancel}
            text={info.cancel}
            style={{ marginRight: 60, width: 150 }}
          />
        </Flex>
        <Flex align='start-cross-axis'>
          <Button
            colorType={'black'}
            onClick={handleSubmit(onSubmit)}
            text={type === HolidayViewType.add ? info.add : info.save}
            style={{ width: 150 }}
          />
        </Flex>
      </Flex>
    );
  };
  return (
    <>
      {visible && (
        <Modal
          title={type === HolidayViewType.add ? info.holidayCreated : info.holidayEdited}
          wrapClassName='modalContent'
          onCancel={onCancel}
          visible={visible}
          centered={true}
          footer={footer()}
          width={500}
        >
          {content()}
        </Modal>
      )}
    </>
  );
};

export default AddEditHoliday;
