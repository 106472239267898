import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { employeeStatus } from '../../../constants/employee';
import { Flex, Label, Select, Button, ControllerInput } from '../../../components/core';
import { SearchOutlined } from '@ant-design/icons';
import ManagersList from './ManagersList';
import DebouncedList from '../../../components/DynamicSelect';
import { initialIndex } from '../../../utils/constants';
import { useDepartmentsQuery, useLocationsQuery } from '../../../graphql/graphql';
import { info } from '../../../resources/strings';

type FormData = {
  firstName?: string;
  lastName?: string;
  locationIds?: number[];
  managerId?: number;
  statuses?: string[];
  departmentIds?: number[];
};

interface IProps {
  reFetchEmployees: (data: FormData) => void;
}

const FilterSection: React.FC<IProps> = ({ reFetchEmployees }) => {
  const { control, handleSubmit, reset, getValues } = useForm<FormData>();

  const onSubmit = (fieldData: FormData) => {
    const variables = { ...fieldData, skip: initialIndex };
    reFetchEmployees(variables);
  };

  const onClear = () => {
    reset();
    reFetchEmployees({
      firstName: getValues('firstName'),
      lastName: getValues('lastName'),
      locationIds: getValues('locationIds'),
      statuses: getValues('statuses'),
      managerId: getValues('managerId'),
      departmentIds: getValues('departmentIds')
    });
  };

  return (
    <Flex wrap>
      <Flex style={{ marginTop: 20, width: '95%' }}>
        <Flex direction='row' align='start-cross-axis'>
          <ControllerInput
            labelName={info.name}
            controlName='firstName'
            control={control}
            wrapperStyle={{ width: 260, marginRight: 40, marginBottom: 3 }}
          />
          <ControllerInput
            labelName={info.surname}
            controlName='lastName'
            control={control}
            wrapperStyle={{ width: 260, marginRight: 40, marginBottom: 3 }}
          />
          <ManagersList control={control} width={260} />
        </Flex>
        <Flex wrap space='between' direction='row' align='start-cross-axis'>
          <Flex direction='row' wrap>
            <Flex style={{ marginRight: 40 }}>
              <DebouncedList
                control={control}
                mode='multiple'
                maxTagCount='responsive'
                controllerName='departmentIds'
                labelText={info.department}
                dataText='name'
                dataValue='id'
                queryDataKey='departments'
                query={useDepartmentsQuery}
                fetchPolicy='network-only'
                style={{ width: 260 }}
              />
            </Flex>
            <Flex style={{ marginRight: 40 }}>
              <DebouncedList
                control={control}
                mode='multiple'
                maxTagCount='responsive'
                controllerName='locationIds'
                labelText={info.location}
                dataText='name'
                dataValue='id'
                queryDataKey='locations'
                query={useLocationsQuery}
                fetchPolicy='network-only'
                style={{ width: 260 }}
              />
            </Flex>
            <Flex style={{ marginRight: 40 }}>
              <Label type='small'>{'Status'}</Label>
              <Controller
                name='statuses'
                control={control}
                render={({ field: { ref, ...otherFieldProps } }) => (
                  <Select
                    data={employeeStatus}
                    style={{ width: 260 }}
                    mode='multiple'
                    maxTagCount='responsive'
                    dataText='dataText'
                    dataValue='dataValue'
                    allowClear
                    {...otherFieldProps}
                  />
                )}
              />
            </Flex>
          </Flex>
          <Flex direction='row' style={{ marginTop: 10 }}>
            <Button
              style={{ marginRight: 10 }}
              colorType='white'
              onClick={onClear}
              text={info.clearX}
            />
            <Button
              colorType='black'
              onClick={handleSubmit(onSubmit)}
              shape='circle'
              icon={<SearchOutlined />}
              style={{ borderRadius: '4px' }}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FilterSection;
