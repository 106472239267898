/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component, ErrorInfo } from 'react';

import { errors, info } from '../../resources/strings';

interface IErrorBoundaryProps {
  children: JSX.Element;
}

interface IErrorBoundaryState {
  error?: Error;
  errorInfo?: ErrorInfo;
  hasError: boolean;
}

export default class ErrorBoundary extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ errorInfo, error });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <div>
          <div>
            <p>
              {errors.pageLoading}
              <span
                className='link'
                onClick={() => {
                  global.window.location.reload();
                }}
              >
                {info.reloadPage}
              </span>
            </p>
          </div>
        </div>
      );
    }

    return children;
  }
}
