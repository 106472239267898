import produce from 'immer';
import { getDefaultTaskParams } from '../../modules/TimeSheet/helpers/helpers';
import { ClientLookup, TaskLookup } from '../../types/main';
import { UID } from './TimeFrameReducer';

const ADD_CLIENT_LOOKUP = 'ADD_CLIENT_LOOKUP';
const EDIT_CLIENT_LOOKUP = 'EDIT_CLIENT_LOOKUP';
const DELETE_CLIENT_LOOKUP = 'DELETE_CLIENT_LOOKUP';
const ADD_TASK_LOOKUP = 'ADD_TASK_LOOKUP';
const EDIT_TASK_LOOKUP = 'EDIT_TASK_LOOKUP';
const DELETE_TASK_LOOKUP = 'DELETE_TASK_LOOKUP';
const REPLACE_TIME_SHEET_LOOKUP = 'REPLACE_TIME_SHEET_LOOKUP';
const CURRENT_STEP = 'CURRENT_STEP';
const SKIPPED = 'SKIPPED';

interface EditClientDataType {
  index: number;
  clientData: ClientLookup;
}

interface EditTaskDataType {
  clientId: number;
  index: number;
  taskData: TaskLookup;
}

export interface IAppReducerState {
  totalHours: number;
  clients: ClientLookup[];
  step: number;
  dates: string[];
  id: number | null;
  skipped: boolean;
}

export function addClient(data?: ClientLookup): { type: string; data?: ClientLookup } {
  return {
    type: ADD_CLIENT_LOOKUP,
    data
  };
}

export function editClient(data: EditClientDataType): {
  type: string;
  data: EditClientDataType;
} {
  return {
    type: EDIT_CLIENT_LOOKUP,
    data
  };
}

export function deleteClient(data: number): { type: string; data: number } {
  return {
    type: DELETE_CLIENT_LOOKUP,
    data
  };
}

export function addTask(data: number): { type: string; data: number } {
  return {
    type: ADD_TASK_LOOKUP,
    data
  };
}

export function editTask(data: EditTaskDataType): { type: string; data: EditTaskDataType } {
  return {
    type: EDIT_TASK_LOOKUP,
    data
  };
}

export function deleteTask(data: { clientId: number; index: number }): {
  type: string;
  data: { clientId: number; index: number };
} {
  return {
    type: DELETE_TASK_LOOKUP,
    data
  };
}

export function replaceTimeSheetLookup(data: IAppReducerState): {
  type: string;
  data: IAppReducerState;
} {
  return {
    type: REPLACE_TIME_SHEET_LOOKUP,
    data
  };
}

export function setCurrentStep(data: number): { type: string; data: number } {
  return {
    type: CURRENT_STEP,
    data
  };
}

export function setSkipped(data: boolean): { type: string; data: boolean } {
  return {
    type: SKIPPED,
    data
  };
}

export const initialState: IAppReducerState = {
  totalHours: 0,
  clients: [],
  step: 1,
  dates: [],
  id: null,
  skipped: false
};

type ReducerAction =
  | { type: 'ADD_CLIENT_LOOKUP'; data: ClientLookup }
  | { type: 'EDIT_CLIENT_LOOKUP'; data: EditClientDataType }
  | { type: 'DELETE_CLIENT_LOOKUP'; data: number }
  | { type: 'ADD_TASK_LOOKUP'; data: number }
  | { type: 'EDIT_TASK_LOOKUP'; data: EditTaskDataType }
  | { type: 'DELETE_TASK_LOOKUP'; data: { clientId: number; index: number } }
  | { type: 'REPLACE_TIME_SHEET_LOOKUP'; data: IAppReducerState }
  | { type: 'CURRENT_STEP'; data: number }
  | { type: 'SKIPPED'; data: boolean };

export default function TimeSheetLookupReducer(
  state = initialState,
  action: ReducerAction
): IAppReducerState {
  return produce(state, (draft: IAppReducerState) => {
    switch (action.type) {
      case ADD_CLIENT_LOOKUP: {
        draft.clients.push({
          id: action.data?.id || -1,
          name: action.data?.name || '',
          hours: 0,
          UID: ++UID.uid,
          tasks: [getDefaultTaskParams(++UID.uid) as TaskLookup]
        });
        break;
      }
      case EDIT_CLIENT_LOOKUP: {
        const { clientData: data, index } = action.data;
        const client = draft.clients[index];

        if (data.id !== client.id) {
          data.tasks = [getDefaultTaskParams(++UID.uid)];
        }

        draft.clients[index] = data;
        break;
      }
      case DELETE_CLIENT_LOOKUP: {
        const deleteIndex = draft.clients.findIndex((c) => c.UID === action.data);
        draft.clients.splice(deleteIndex, 1);
        break;
      }
      case ADD_TASK_LOOKUP: {
        const client = draft.clients.find((elem) => elem.id == action.data) as ClientLookup;
        client.tasks.push(getDefaultTaskParams(++UID.uid));
        break;
      }
      case EDIT_TASK_LOOKUP: {
        const { clientId, taskData, index } = action.data;
        const client = draft.clients.find((cl) => cl.id == clientId) as ClientLookup;
        client.tasks[index] = taskData;
        break;
      }
      case DELETE_TASK_LOOKUP: {
        const { clientId, index } = action.data;
        const client = draft.clients.find((cl) => cl.id == clientId) as ClientLookup;
        client.tasks.splice(index, 1);
        break;
      }
      case REPLACE_TIME_SHEET_LOOKUP: {
        draft.totalHours = action.data.totalHours;
        draft.clients = action.data.clients;
        draft.step = action.data.step;
        draft.dates = action.data.dates;
        draft.id = action.data.id;
        draft.skipped = action.data.skipped;
        break;
      }
      case CURRENT_STEP: {
        draft.step = action.data;
        break;
      }
      case SKIPPED: {
        draft.skipped = action.data;
        break;
      }
    }
  });
}
