import { gql } from '@apollo/client';

export const ACTIVATE_EMPLOYEE = gql`
  mutation activateEmployee($activationToken: String!, $employeeId: Int!, $password: String!) {
    activatePendingEmployee(
      activatePendingEmployeeInput: {
        activationToken: $activationToken
        employeeId: $employeeId
        password: $password
      }
    ) {
      __typename
      ... on Employee {
        id
        firstName
        lastName
        email
      }
      ... on BadRequestError {
        message
      }
    }
  }
`;

export const LOGIN_EMPLOYEE = gql`
  mutation logInEmployee($username: String!, $password: String!) {
    login(credentials: { username: $username, password: $password }) {
      ... on UserWithCredentials {
        authTokens {
          accessToken
          refreshToken
        }
        user {
          email
          employmentStatus {
            id
            name
          }
          firstName
          hourlyRates {
            id
            rate
            createdAt
            updatedAt
          }
          id
          job {
            id
            title
          }
          lastName
          location {
            id
            name
          }
          role
          status
          manager {
            id
            firstName
          }
        }
      }
      ... on AuthenticationError {
        message
      }
      ... on BadRequestError {
        message
      }
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation initiateResetPassword($email: String!) {
    initiateResetPassword(email: $email) {
      __typename
      ... on Success {
        result
      }
      ... on BadRequestError {
        message
      }
    }
  }
`;

export const SET_NEW_PASSWORD = gql`
  mutation resetPassword($token: String!, $employeeId: Int!, $password: String!) {
    resetPassword(input: { token: $token, employeeId: $employeeId, password: $password }) {
      __typename
      ... on Success {
        result
      }
      ... on BadRequestError {
        message
      }
    }
  }
`;
