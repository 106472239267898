import React from 'react';

import { Flex, Button } from '../../../components/core';
import AddEditEmployee from './AddEditEmployee';
import { EmployeeViewType } from '../../../types/main';
import { info } from '../../../resources/strings';

interface IProps {
  reFetchEmployees: () => void;
}

const ActionSection: React.FC<IProps> = ({ reFetchEmployees }) => {
  const [visible, setVisible] = React.useState(false);

  return (
    <Flex direction='column' style={{ width: '100%' }}>
      <Flex align='start-cross-axis'>
        <Button colorType={'white'} onClick={() => setVisible(true)} text={info.addEmployeePlus} />
      </Flex>
      {visible && <AddEditEmployee
        type={EmployeeViewType.add}
        onCancel={() => setVisible(false)}
        visible={true}
        reFetchEmployees={reFetchEmployees}
      />}
    </Flex>
  );
};

export default ActionSection;
