import React, { useState } from 'react';
import { Moment } from 'moment';
import { CSVLink } from 'react-csv';

import { Flex, Button } from '../../../components/core';
import { ProjectReportDataType } from '../../../types/main';
import { getRoundedTotal } from '../helpers';
import { info } from '../../../resources/strings';

const headers = [
  { label: info.client, key: 'clientName' },
  { label: info.task, key: 'taskName' },
  { label: info.employeeName, key: 'employeeFullName' },
  { label: info.hoursPerEmployee, key: 'hoursPerEmployee' },
  { label: info.hoursPerTask, key: 'hoursPerTask' },
  { label: info.clientGrandTotal, key: 'grandTotal' }
];

type DataForCSV = {
  clientName: string;
  taskName: string;
  employeeFullName: string;
  hoursPerEmployee: number;
  hoursPerTask: number;
  grandTotal: string;
};

interface IProps {
  data: ProjectReportDataType[];
  dates: Moment[];
}

const ExportCSV: React.FC<IProps> = ({ data, dates }) => {
  const [csvData, setCsvData] = useState<DataForCSV[]>([]);

  const csvReport = React.useMemo(() => {
    return {
      data: csvData,
      headers: headers,
      filename: `${info.projectHours}-${dates[0].format('YYYY/MM/DD')}-${dates[1].format(
        'YYYY/MM/DD'
      )}.csv`
    };
  }, [csvData, dates]);

  const handleClick = () => {
    const dataForCSV: DataForCSV[] = [];
    for (let i = 0; i < data.length; i++) {
      const total = data[i].tasks.reduce((sum, current) => sum + current.hoursPerDay, 0);
      const rowForGrandTotal = {
        clientName: data[i].name,
        grandTotal: getRoundedTotal(total)
      } as DataForCSV;
      dataForCSV.push(rowForGrandTotal);

      for (let j = 0; j < data[i].tasks.length; j++) {
        for (let k = 0; k < data[i].tasks[j].employees.length; k++) {
          const rowData = {
            employeeFullName: `${data[i].tasks[j].employees[k].firstName} ${data[i].tasks[j].employees[k].lastName}`,
            hoursPerEmployee: data[i].tasks[j].employees[k].hoursPerDay,
            clientName: data[i].name,
            taskName: data[i].tasks[j].summary
          } as DataForCSV;
          if (k == 0) {
            rowData.hoursPerTask = data[i].tasks[j].hoursPerDay;
          }
          dataForCSV.push(rowData);
        }
      }
    }
    setCsvData(dataForCSV);
  };

  return (
    <Flex direction='row' style={{ marginTop: 20 }}>
      <CSVLink {...csvReport}>
        <Button
          colorType={'black'}
          onClick={handleClick}
          text={info.export}
          style={{ width: '150px' }}
        />
      </CSVLink>
    </Flex>
  );
};

export default ExportCSV;
