import React from 'react';

import { Button, Flex } from '../../components/core';
import AddEditHoliday from './AddEditHoliday';

import { HolidayViewType } from '../../types/main';
import { info } from '../../resources/strings';



interface IProps {
  refetchHolidays: () => void;
}
const ActionSection: React.FC<IProps> = ({ refetchHolidays }) => {
  const [visible, setVisible] = React.useState(false);

  return (
    <Flex direction='column' style={{ width: '100%' }}>
    <Flex align='start-cross-axis'>
      <Button
        colorType={'white'}
        onClick={() => setVisible(true)}
        text={info.addHolidayPlus}
      />
    </Flex>
    {visible && <AddEditHoliday
        type={HolidayViewType.add}
        onCancel={() => setVisible(false)}
        visible={true}
        refetchHolidays = {refetchHolidays}
    />}
  </Flex>
  );
};

export default ActionSection;
