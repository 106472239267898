import React from 'react';
import { Flex, Button } from '../../../components/core';
import AddEditTask from './AddEditTaskPopup';
import { TaskViewType } from '../../../types/main';
import { info } from '../../../resources/strings';

interface IProps {
  reFetchTasks: () => void;
  clientId: number;
}

const ActionSection: React.FC<IProps> = ({ reFetchTasks, clientId }) => {
  const [visible, setVisible] = React.useState(false);

  return (
    <Flex direction='column' style={{ width: '100%' }}>
      <Flex align='start-cross-axis'>
        <Button colorType={'white'} onClick={() => setVisible(true)} text={info.addTaskPlus} />
      </Flex>
      {visible && <AddEditTask
        type={TaskViewType.create}
        onCancel={() => setVisible(false)}
        visible={true}
        reFetchTasks={reFetchTasks}
        clientId={clientId}
      />}
    </Flex>
  );
};

export default ActionSection;
