import React, { FC, useState } from 'react';
import { Table } from 'antd';
import moment, { Moment } from 'moment';

import { BackButton, Flex } from '../../components/core';
import { PreviousTimeSheetQuery } from '../../graphql/graphql';
import { EmployeeTimeSheetClientsDataType, EmployeeTimeSheetDataType } from '../../types/main';
import { getClientsDataFromTimeSheet } from '../TimeSheet/helpers/helpers';
import { columns, tableFooter } from './Table';
import { displayCustomWeekStartEnd } from '../../utils/helpers';
import { info } from '../../resources/strings';

export const customFormatStartEnd = (
  date: Moment | null
): {
  startDate: string;
  endDate: string;
} => {
  const startDate = new Date(`${moment(date).utc().startOf('week')}`).toISOString();
  const endDate = new Date(`${moment(date).utc().endOf('week')}`).toISOString();

  return { startDate, endDate };
};

interface IProps {
  currentStep: number;
  setStep: (step: number) => void;
  selectedDays: Moment | null;
  previousData: PreviousTimeSheetQuery | undefined;
}

const Step2: FC<IProps> = ({ currentStep, setStep, selectedDays, previousData }) => {
  const [dataSource, setDataSource] = useState<EmployeeTimeSheetClientsDataType[]>([]);
  const [skipped, setSkipped] = useState(false);
  React.useEffect(() => {
    if (previousData?.previousTimeSheet.__typename === 'TimeSheets') {
      if (previousData?.previousTimeSheet.count) {
        const data: EmployeeTimeSheetDataType = previousData.previousTimeSheet.entities[0];
        const newData = getClientsDataFromTimeSheet(data);
        if (data.totalHours === 0) {
          setSkipped(true);
        }
        setDataSource(newData);
      }
    }
  }, [previousData, currentStep, setStep]);
  return (
    <Flex direction='row' center='main-axis' className='content previousTimeSheet'>
      <Flex direction='row' style={{ width: '1000px' }}>
        <Flex direction='row'>
          <BackButton
            style={{ marginBottom: '8px' }}
            onClick={() => setStep(currentStep - 1)}
            text={info.back}
          />
        </Flex>
        <Flex direction='column' className='dataWrapper'>
          <div>
            <span>{info.timesheetFor} </span>
            <span className='headerText'>{displayCustomWeekStartEnd(selectedDays)}</span>
          </div>

          {skipped ?
            <div
              className='wrapper'
              style={{ width: 600 }}
            >
              {info.timesheetHasBeenSkipped}
            </div> :
            <Table
              id='summaryTable'
              rowKey='id'
              size='small'
              dataSource={dataSource}
              columns={columns}
              footer={() => tableFooter(previousData)}
              pagination={{ hideOnSinglePage: true, pageSize: 20 }}
            />}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Step2;
