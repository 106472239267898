import { List } from 'antd';
import React from 'react';
import { CrudListItem } from './CrudListItem';
import './styles.scss';

export interface CrudListItemInterface {
  id: number;
  title: string;
  onDelete: (item) => void; // TOOD: Should be converted into generic
}

export type EditableCrudListItem = Omit<CrudListItemInterface, 'onDelete'>;

interface CrudListItemsInterface {
  items: CrudListItemInterface[];
  onEdit: (item: EditableCrudListItem) => void;
}

const CrudListItems: React.FC<CrudListItemsInterface> = (props) => {
  return (
    <>
      <List
        itemLayout='horizontal'
        dataSource={props.items}
        renderItem={(item) => <CrudListItem item={item} onEdit={props.onEdit} />}
        className='crudListItems'
        rowKey='id'
      />
    </>
  );
};

export default CrudListItems;
