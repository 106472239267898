import { gql } from '@apollo/client';

export const ADD_CLIENT = gql`
  mutation createClient($name: String!, $logo: Upload) {
    createClient(createClientInput: { name: $name }, logo: $logo) {
      __typename
      ... on Client {
        id
        logoUrl
        name
      }
      ... on BadRequestError {
        message
      }
      ... on AuthenticationError {
        message
      }
    }
  }
`;

export const EDIT_CLIENT = gql`
  mutation updateClient($id: Int!, $name: String, $logo: Upload) {
    updateClient(updateClientInput: { id: $id, name: $name }, logo: $logo) {
      __typename
      ... on Client {
        id
        name
        status
        logoUrl
      }
      ... on BadRequestError {
        message
      }
      ... on AuthenticationError {
        message
      }
    }
  }
`;

export const CREATE_TASK = gql`
  mutation createTask($clientId: Int!, $summary: String!) {
    createTask(createTaskInput: { clientId: $clientId, summary: $summary }) {
      __typename
      ... on Task {
        client {
          id
          status
          logoUrl
          name
        }
        id
        status
        summary
      }
      ... on BadRequestError {
        message
      }
      ... on AuthenticationError {
        message
      }
    }
  }
`;

export const DELETE_TASK = gql`
  mutation removeTask($id: Int!) {
    removeTask(id: $id) {
      __typename
      ... on Task {
        id
        status
        summary
      }
      ... on BadRequestError {
        message
      }
      ... on Error {
        message
      }
    }
  }
`;

export const EDIT_TASK = gql`
  mutation updateTask($clientId: Int, $id: Int!, $summary: String!) {
    updateTask(updateTaskInput: { clientId: $clientId, id: $id, summary: $summary }) {
      __typename
      ... on Task {
        client {
          id
          status
          logoUrl
          name
        }
        id
        status
        summary
      }
      ... on BadRequestError {
        message
      }
      ... on AuthenticationError {
        message
      }
    }
  }
`;
