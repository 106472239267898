import * as React from 'react';
import './Select.styles.scss';
import { InputKeyType, LabeledValue } from '../../../types/main';

import { Select } from 'antd';
import { EmployeeStatus } from '../../../graphql/graphql';
import Flex from '../Flex';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/reducers';

const { Option } = Select;

interface IProps {
  id?: string;
  showSearch?: boolean;
  mode?: 'multiple' | 'tags';
  maxTagCount?: number | 'responsive';
  style?: React.CSSProperties;
  onChange?: (event) => void;
  onSearch?: (event) => void;
  onPopupScroll?: (event) => void;
  defaultValue?: string | number | LabeledValue | LabeledValue[];
  data: any;
  filterOption?: boolean;
  dataValue: string | number;
  dataText: string;
  bordered?: boolean;
  placeholder?: string;
  dropdownClassName?: string;
  allowClear?: boolean;
  labelInValue?: boolean;
  optionDisable?: (data) => boolean;
  optionRender?: (data) => React.ReactNode;
  disabled?: boolean;
  sectionName?: string;
}

const Dropdown: React.FC<IProps> = ({
  id,
  style,
  onChange,
  onSearch,
  onPopupScroll,
  showSearch = false,
  filterOption = true,
  data,
  dataValue,
  dataText,
  defaultValue,
  placeholder,
  dropdownClassName,
  allowClear = true,
  bordered = false,
  labelInValue = false,
  optionDisable,
  optionRender,
  sectionName,
  ...props
}) => {
  const { selectedEmployee } = useSelector((state: RootState) => state.reports.timesheetReport);
  const { selectedEmployees } = useSelector((state: RootState) => state.reports.employeeReport);
  const [inputKeyDown, setInputKeyDown] = React.useState('');
  const isOptionDisable = React.useCallback(
    (item): boolean => {
      if (optionDisable) {
        return optionDisable(item);
      }
      return false;
    },
    [optionDisable]
  );

  const list = React.useMemo(() => {
    if (!data[0]) return;

    if (optionRender) {
      if (sectionName === 'TimeSheetLookup') {
        const employeeOption = selectedEmployee && (
          <Option
            value={selectedEmployee.id}
            key={selectedEmployee.id}
            disabled={isOptionDisable(selectedEmployee)}
            label={optionRender(selectedEmployee)}
          >
            <Flex direction='row' space='between'>
              <Flex>{optionRender(selectedEmployee)}</Flex>
              <Flex>
                {selectedEmployee.status === EmployeeStatus.Deactivated ? (
                  <span className='employee-name'>Inactive</span>
                ) : selectedEmployee.status === EmployeeStatus.Pending ? (
                  <span className='employee-name'>{selectedEmployee.status}</span>
                ) : (
                  <></>
                )}
              </Flex>
            </Flex>
          </Option>
        );
        const filteredData = selectedEmployee
          ? data?.filter((item) => item.id !== selectedEmployee.id)
          : data;

        const options = filteredData?.map((item, index) => {
          return (
            <Option
              value={item[dataValue]}
              key={item.id || index}
              disabled={isOptionDisable(item)}
              label={optionRender(item)}
            >
              <Flex direction='row' space='between'>
                <Flex>{optionRender(item)}</Flex>
                <Flex>
                  {item.status === EmployeeStatus.Deactivated ? (
                    <span className='employee-name'>Inactive</span>
                  ) : item.status === EmployeeStatus.Pending ? (
                    <span className='employee-name'>{item.status}</span>
                  ) : (
                    <></>
                  )}
                </Flex>
              </Flex>
            </Option>
          );
        });

        return [employeeOption, ...options];
      } else if (sectionName === 'EmployeeProjectHours') {
        const employeesOptions = selectedEmployees?.map((item, index) => {
          return (
            <Option
              value={item[dataValue]}
              key={item.id || index}
              disabled={isOptionDisable(item)}
              label={optionRender(item)}
            >
              <Flex direction='row' space='between'>
                <Flex>{optionRender(item)}</Flex>
                <Flex>
                  {item.status === EmployeeStatus.Deactivated ? (
                    <span className='employee-name'>Inactive</span>
                  ) : item.status === EmployeeStatus.Pending ? (
                    <span className='employee-name'>{item.status}</span>
                  ) : (
                    <></>
                  )}
                </Flex>
              </Flex>
            </Option>
          );
        });

        const selectedEmployeesIds = selectedEmployees?.map((e) => e.id as number);
        const filteredData = data?.filter((item) => !selectedEmployeesIds.includes(item.id));

        const options = filteredData?.map((item, index) => {
          return (
            <Option
              value={item[dataValue]}
              key={item.id || index}
              disabled={isOptionDisable(item)}
              label={optionRender(item)}
            >
              <Flex direction='row' space='between'>
                <Flex>{optionRender(item)}</Flex>
                <Flex>
                  {item.status === EmployeeStatus.Deactivated ? (
                    <span className='employee-name'>Inactive</span>
                  ) : item.status === EmployeeStatus.Pending ? (
                    <span className='employee-name'>{item.status}</span>
                  ) : (
                    <></>
                  )}
                </Flex>
              </Flex>
            </Option>
          );
        });
        return [...employeesOptions, ...options];
      }
    }
    return data?.map((item, index) => {
      return (
        <Option value={item[dataValue]} key={item.id || index} disabled={isOptionDisable(item)}>
          {item[dataText]}
        </Option>
      );
    });
  }, [data, dataValue, dataText, isOptionDisable, optionRender]);

  function handleChange(value) {
    if (onChange) {
      if (value) {
        return onChange(value);
      }

      onChange(null);
    }
  }

  // here we need to check the inputKeyDown because the antd Select works differently
  // depended on props 'mode' and invokes onSearch function with empty string value
  // when we click outside of the dropdown, which makes our custom component
  // not to work properly
  function handleSearch(value: string) {
    if (
      onSearch &&
      (value || inputKeyDown === InputKeyType.Backspace || inputKeyDown === InputKeyType.Delete)
    ) {
      onSearch(value);
    }
  }

  function handleScroll(value) {
    if (onPopupScroll) {
      onPopupScroll(value);
    }
  }

  return (
    <Select
      id={id}
      style={style}
      onChange={handleChange}
      onSearch={handleSearch}
      onPopupScroll={handleScroll}
      labelInValue={labelInValue}
      showSearch={showSearch}
      filterOption={filterOption}
      defaultValue={defaultValue}
      placeholder={placeholder}
      dropdownClassName={dropdownClassName}
      bordered={bordered}
      allowClear={allowClear}
      optionLabelProp={optionRender ? 'label' : undefined}
      onInputKeyDown={(v) => setInputKeyDown(v.code)}
      {...props}
    >
      {list}
    </Select>
  );
};

export default Dropdown;
