import React, { ComponentType } from 'react';
import { Route, RouteProps } from 'react-router-dom';

import { Spiner } from '../../components/core';
import { EmployeeRole } from '../../graphql/graphql';
import { errors } from '../../resources/strings';

interface IProps extends RouteProps {
  allowedRoles?: EmployeeRole[],
  role?: EmployeeRole
}

const ProtectedRoute: React.FC<IProps> = ({
  component,
  allowedRoles,
  role,
  ...rest
}) => {
  const Component = component as ComponentType;
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!allowedRoles || allowedRoles?.includes(role as EmployeeRole)) {
          return <Component {...rest} {...props} />;
        } else if (!role) {
          return <Spiner local={true} />;
        } else {
          return <div style={{ margin: 'auto' }}>{errors.accessDeniedForPage}</div>;
        }
      }}
    />
  );
};

export default ProtectedRoute;
