import React from 'react';
import { useForm } from 'react-hook-form';
import { Slider } from 'antd';

import { Flex, Label } from '../../components/core';
import OverlappedNumber from '../../components/core/OverlappedNumber';
import DebouncedList from '../DynamicSelect/DebouncedList';
import { useClientsQuery, GenericStatus } from '../../graphql/graphql';
import { ClientTimeFrame } from '../../types/main';
import { info } from '../../resources/strings';

import './Card.styles.scss';

interface IProps {
  item: ClientTimeFrame;
  itemIndex: number;
  totalHours: number;
  counts: number;
  max?: number;
  onEdit: (index: number, data: ClientTimeFrame) => void;
  onDelete: (index: number) => void;
  optionDisable?: (data) => boolean;
}

const Card: React.FC<IProps> = ({
  totalHours,
  max,
  onDelete,
  onEdit,
  item,
  itemIndex,
  optionDisable
}) => {
  const { control } = useForm<{ id: number }>();
  const { data: clientsData } = useClientsQuery({
    onCompleted: void 0,
    variables: { status: GenericStatus.Active },
    fetchPolicy: 'network-only'
  });

  const clients = clientsData?.clients.__typename === 'Clients' ? clientsData.clients.entities : [];

  function deleteItem() {
    onDelete(item.UID);
  }

  function editItem(data) {
    onEdit(itemIndex, data);
  }

  function handleSliderChange(value: any) {
    editItem({ ...item, hours: +((totalHours / 100) * value).toFixed(2) });
  }

  function onClientChange(data) {
    const client = clients.find((c) => c.id === data?.value);

    editItem({
      ...item,
      id: data?.value,
      name: data?.label,
      status: client?.status
    });
  }

  const handlePercentChange = (value: any) => {
    if (value > 100) {
      value = 100;
    }
    handleSliderChange(value);
  };

  const handlePercentFormat = (value: number | undefined) => {
    if (value && value > 100) {
      return '100';
    }
    return value ? value.toString() : '';
  };

  const handleHoursChange = (value: any) => {
    if (value) {
      editItem({ ...item, hours: +value.toFixed(2) });
    }
  };

  const handleHoursFormat = (value: number | undefined) => {
    if (value) {
      return value.toString();
    }
    return '';
  };

  const defaultValueId = {
    value: item.id > 0 ? item.id : '',
    label: item.id > 0 ? item.name : ''
  };

  return (
    <Flex
      className={`app-item ${item.status}`}
      direction='row'
      center='cross-axis'
      space='between'
      wrap={true}
    >
      <Flex style={{ marginBottom: '30px', width: 350 }}>
        <DebouncedList
          control={control}
          controllerName='id'
          dataText='name'
          dataValue='id'
          queryDataKey='clients'
          labelInValue
          defaultValueId={defaultValueId}
          query={useClientsQuery}
          onChange={onClientChange}
          optionDisable={optionDisable}
          otherVarebales={{ status: GenericStatus.Active }}
          fetchPolicy={'cache-and-network'}
        />
      </Flex>
      <Flex>
        <Label mt={-20} type='small'>
          {info.timePercentage}
        </Label>
        <Flex direction='row' style={{ width: '300px' }}>
          <Slider
            tooltipVisible={false}
            onChange={handleSliderChange}
            value={(item.hours * 100) / totalHours}
            step={5}
            max={max}
          />
          <OverlappedNumber
            formatter={handlePercentFormat}
            onChange={handlePercentChange}
            value={Number(((item.hours * 100) / totalHours).toFixed(2))}
            label={`${((item.hours * 100) / totalHours).toFixed(2)}%`}
            textClassName='edit-percentage'
            inputClassName='edit-percentage-input'
          />
        </Flex>
      </Flex>
      <Flex direction='column' align='end-cross-axis' className='client-card-hours'>
        <Label type='small'>{info.totalHours}</Label>
        <OverlappedNumber
          formatter={handleHoursFormat}
          onChange={handleHoursChange}
          value={item.hours}
          label={`${item.hours}h`}
          inputClassName='edit-hours-input'
          textClassName='edit-hours'
        />
      </Flex>

      <Flex direction='column' align='end-cross-axis'>
        <div onClick={deleteItem} className='removeClientBtn' title={'Remove Client'}>
          X
        </div>
      </Flex>
    </Flex>
  );
};

export default Card;
