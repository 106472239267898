import { createUploadLink } from 'apollo-upload-client';
import { ApolloLink, HttpOptions } from '@apollo/client';
import { URL } from '../../config';
import { setContext } from '@apollo/client/link/context';

const httpOptions: HttpOptions = { uri: `${URL}/graphql` };
let uploadLink: ApolloLink = createUploadLink(httpOptions);

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('accessToken');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : ''
    }
  };
});

uploadLink = authLink.concat(uploadLink);

export { uploadLink };
