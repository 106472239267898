import moment, { Moment } from 'moment';
import { RateReportQuery } from '../graphql/graphql';
import {
  EmployeeDataType,
  RateReportClientType,
  RateReportDataType,
  SalaryOrHourType
} from '../types/main';

export const getLastHourlyRateIndex = (rowData: EmployeeDataType): number => {
  let lastHourlyRateIndex = 0;
  let minDiff = +new Date() - +new Date(rowData.hourlyRates[0]?.createdAt);
  rowData.hourlyRates.forEach((hourlyRate, index) => {
    if (+new Date() - +new Date(hourlyRate.createdAt) < minDiff) {
      minDiff = +new Date() - +new Date(hourlyRate.createdAt);
      lastHourlyRateIndex = index;
    }
  });
  return lastHourlyRateIndex;
};

export const displayCustomWeekStartEnd = (date: Moment | null): string =>
  `${moment(date).startOf('week').format('DD MMM, YYYY')} - ${moment(date)
    .endOf('week')
    .format('DD MMM, YYYY')}`;

export const customWeekStartEndFormat = (value: Moment | null): string => {
  return `${moment(value).startOf('week').format('DD/MM')} - ${moment(value)
    .endOf('week')
    .format('DD/MM')}`;
};

export const getStartOfWeek = (date?: Moment): Moment => {
  return date ? moment(date).utc().startOf('week') : moment().utc().startOf('week');
};

export const getEndOfWeek = (date?: Moment): Moment => {
  return date ? moment(date).utc().endOf('week') : moment().utc().endOf('week');
};

export const changeSalariesOrHours = (
  data: string,
  rate: number,
  clients: RateReportClientType[],
  type: SalaryOrHourType
): RateReportClientType[] => {
  const obj = JSON.parse(data);
  const keys = Object.keys(obj);
  const newClients: RateReportClientType[] = [];

  clients.forEach((client) => {
    if (keys.includes(`${rate} ${client.name}`)) {
      type === SalaryOrHourType.salary
        ? (client.salary = obj[`${rate} ${client.name}`])
        : (client.hoursPerDay = obj[`${rate} ${client.name}`]);
      newClients.push({ ...client });
    }
  });
  return newClients;
};

export const changeDataForDisplayingInSeparateRows = (
  data: RateReportQuery
): RateReportDataType[] => {
  const newData: RateReportDataType[] = [];
  let uniqueID = 0;
  if (data.rateReport.__typename === 'RatesReport') {
    data.rateReport.entities.forEach((entity) => {
      entity.hourlyRates.forEach(({ rate }) => {
        entity.hourlyRates = [];
        newData.push({ ...entity, rate, id: uniqueID });
        uniqueID++;
      });
    });
    newData.forEach((entity) => {
      entity.clients = changeSalariesOrHours(
        entity.groupedHoursByHourlyRateAndClient,
        entity.rate as number,
        entity.clients,
        SalaryOrHourType.hoursPerDay
      );
      entity.clients = changeSalariesOrHours(
        entity.groupedSalariesByHourlyRateAndClient,
        entity.rate as number,
        entity.clients,
        SalaryOrHourType.salary
      );
    });
  }
  return newData;
};
