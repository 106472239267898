import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Moment } from 'moment';

import { RootState } from '../../../redux/reducers/index';
import { updateProjectReportFilters } from '../../../redux/reducers/Reports';
import { useForm, Controller } from 'react-hook-form';
import { Flex, Button } from '../../../components/core';
import DateRangePicker from '../../../components/DateRangePicker';
import DebouncedList from '../../../components/DynamicSelect';
import { useClientsQuery, EmployeeReportQueryVariables } from '../../../graphql/graphql';
import { info } from '../../../resources/strings';
import { maxTakeCount } from '../../../utils/constants';

const dateFormat = 'YYYY-MM-DD';

type FormData = {
  dateRange: any[];
  clientIds: number[];
};

interface IProps {
  getReport: (data: { variables: EmployeeReportQueryVariables }) => void;
}

const FilterSection: React.FC<IProps> = ({ getReport }) => {
  const dispatch = useDispatch();
  const reportData = useSelector((state: RootState) => state.reports.projectHoursReport);
  const { control, handleSubmit, setValue, getValues } = useForm<FormData>({
    defaultValues: {
      dateRange: reportData.filters.dateRange,
      clientIds: reportData.filters.clientIds
    }
  });

  const updateFilters = (filtersData) => {
    dispatch(updateProjectReportFilters(filtersData));
  };

  const onSubmit = (fieldData: FormData) => {
    const [startDate, endDate] = fieldData.dateRange;
    const variables = {
      startDate: startDate.format(dateFormat),
      endDate: endDate.format(dateFormat),
      clientIds: fieldData.clientIds
    };
    updateFilters({
      dateRange: [startDate, endDate],
      clientIds: fieldData.clientIds
    });
    getReport({ variables });
  };

  const onClear = () => {
    setValue('clientIds', []);
    const [start, end] = getValues('dateRange');
    const variables = {
      startDate: start.format(dateFormat),
      endDate: end.format(dateFormat),
      clientIds: []
    };
    updateFilters({
      dateRange: [start, end],
      clientIds: []
    });
    getReport({ variables });
  };

  return (
    <Flex>
      <Flex wrap={true} direction='row' align='end-cross-axis' style={{ marginTop: 30 }}>
        <Flex style={{ marginRight: 20 }}>
          <Controller
            control={control}
            name='dateRange'
            render={({ field }) => (
              <DateRangePicker
                onDateChange={(date, dateToString) => field.onChange(date, dateToString)}
                value={[...field.value] as [Moment, Moment]}
              />
            )}
          />
        </Flex>
        <Flex style={{ marginRight: 20 }}>
          <DebouncedList
            control={control}
            mode='multiple'
            maxTagCount='responsive'
            controllerName='clientIds'
            labelText={info.client}
            dataText='name'
            dataValue='id'
            queryDataKey='clients'
            query={useClientsQuery}
            otherVarebales={{ take: maxTakeCount }}
            style={{ width: 400 }}
          />
        </Flex>
        <Flex direction='row' style={{ marginRight: 20, marginTop: 10 }}>
          <Button
            style={{ marginRight: 10 }}
            colorType={'white'}
            onClick={onClear}
            text={info.clearX}
          />
          <Button colorType={'black'} onClick={handleSubmit(onSubmit)} text={info.generateReport} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FilterSection;
