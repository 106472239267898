/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from 'antd';

import {
  splitTasksHoursEvenly,
  setTaskHoursEvenly,
  cascadeUpdateClientHours,
  addTotalHours
} from '../../redux/reducers/TimeFrameReducer';
import { Flex, Button, Label, ConfirmationDialog } from '../../components/core';
import { ClientTimeFrame, TaskTimeFrame } from '../../types/main';
import TaskCard from '../../components/Card/TaskCard';
import { correctionForRound } from '../../utils/constants';
import TotalHours from './TotalHours';
import { RootState } from '../../redux/reducers/index';
import { info } from '../../resources/strings';

interface IProps {
  addNewTask: (id: number) => void;
  removeTask: (index: number) => void;
  editTask: (index: number, data: TaskTimeFrame) => void;
  clientData: ClientTimeFrame;
  clinetIndex: number;
}

const TasksView: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const totalHours = useSelector((state: RootState) => state.timeFrame.totalHours);
  const [showTotalHoursDialog, setShowTotalHoursDialog] = useState(false);
  const [newTotalHours, setNewTotalHours] = useState(props.clientData?.hours);

  useEffect(() => {
    if (props.clientData?.splitTaskHoursEvenly) {
      dispatch(splitTasksHoursEvenly(props.clientData));
    }
  }, [dispatch, props.clientData?.id, props.clientData?.splitTaskHoursEvenly]);

  function isOptionDisabled(item: TaskTimeFrame) {
    return props.clientData?.tasks?.map((client) => client.id).includes(item.id as number);
  }

  function handleSwitchChange(checked: boolean) {
    dispatch(setTaskHoursEvenly({ checked, clientId: props.clientData?.id }));
  }

  const getTasksCards = () => {
    return props.clientData?.tasks?.map((item, index) => (
      <TaskCard
        key={item.UID}
        item={item}
        itemIndex={index}
        clientId={props.clientData.id}
        totalHours={props.clientData.hours}
        onDelete={props.removeTask}
        onEdit={props.editTask}
        optionDisable={isOptionDisabled}
      />
    ));
  };

  const summaryHours =
    props.clientData?.tasks?.reduce((sum, current) => sum + current.hours * correctionForRound, 0) /
    correctionForRound;

  return (
    <Flex className='setTasksContent'>
      <Flex direction='row' space='between'>
        <Label>{info.addPercentageForSubcategory}</Label>
        <Flex direction='row' align='start-cross-axis' style={{ marginBottom: 15 }}>
          <h3 style={{ margin: '2px 10px 0 0' }}>{info.splitEvenly}</h3>
          <Switch checked={props.clientData?.splitTaskHoursEvenly} onChange={handleSwitchChange} />
        </Flex>
      </Flex>
      {getTasksCards()}
      <Flex direction='row' center='both-axis' style={{ marginTop: 10 }}>
        <Button
          style={{ width: 150 }}
          onClick={() => props.addNewTask(props.clientData?.id)}
          colorType={'gray'}
          text={info.addTaskPlus}
          disabled={false}
        />
      </Flex>
      <TotalHours
        summaryHours={summaryHours}
        totalHours={props.clientData?.hours}
        onChange={(value) => {
          value && setNewTotalHours(value);
          value && setShowTotalHoursDialog(true);
        }}
      />
      {showTotalHoursDialog && (
        <ConfirmationDialog
          visible
          title={info.clientTotalHours}
          noTitle={info.cancel}
          yesTitle={info.confirm}
          confirmAction={() => {
            const newClient = { ...props.clientData };
            const totalHoursDiff = newTotalHours - newClient.hours;
            newClient.hours = newTotalHours;
            dispatch(addTotalHours(totalHours + totalHoursDiff));
            dispatch(
              cascadeUpdateClientHours({
                index: props.clinetIndex,
                hours: newTotalHours
              })
            );
            setShowTotalHoursDialog(false);
          }}
          rejectAction={() => setShowTotalHoursDialog(false)}
        >
          {info.editClientTotalWorkedHours}
        </ConfirmationDialog>
      )}
    </Flex>
  );
};

export default TasksView;
