import React from 'react';
import { ModalProps } from 'antd';

import { Flex, ConfirmationDialog } from '../../../components/core';
import { DialogType } from './ActionCell';
import { EmployeeDataType } from '../../../types/main';
import { info } from '../../../resources/strings';

interface IProps extends ModalProps {
  type: string;
  data: EmployeeDataType;
  confirmAction: () => void;
  rejectAction: () => void;
}

const EmployeeActionDialog: React.FC<IProps> = ({
  type,
  confirmAction,
  rejectAction,
  visible,
  data
}) => {
  const dialogParams = {
    [DialogType.delete]: {
      title: info.deleteEmployee,
      confirmationText: info.delete,
      dialogBodyText: info.deleteParmanently
    },
    [DialogType.resend]: {
      title: info.resendEmail,
      confirmationText: info.send,
      dialogBodyText: info.resendForsetupPass
    },
    [DialogType.stausChange]: {
      title: info.statusChange,
      confirmationText: info.yes,
      dialogBodyText: info.changeEmployeeStatus
    }
  };

  const getContent = () => {
    if (type === DialogType.delete) {
      return (
        <Flex>
          <div>{dialogParams[type]?.dialogBodyText}</div>
          <h3>{`${data.firstName} ${data.lastName} ?`}</h3>
        </Flex>
      );
    }
    return (
      <Flex>
        <div>{dialogParams[type]?.dialogBodyText}</div>
      </Flex>
    );
  };

  return (
    <ConfirmationDialog
      title={dialogParams[type]?.title}
      noTitle={info.cancel}
      yesTitle={dialogParams[type]?.confirmationText}
      confirmAction={confirmAction}
      rejectAction={rejectAction}
      visible={visible}
    >
      {getContent()}
    </ConfirmationDialog>
  );
};

export default EmployeeActionDialog;
