/* eslint-disable */
import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools, composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';

import rootReducer from './reducers/';

function setupStore(initialState = {}) {
  const middlewares = [thunkMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);
  const enhancers = [middlewareEnhancer];
  const composedEnhancers =
    process.env.NODE_ENV == 'production'
      ? composeWithDevToolsDevelopmentOnly(...enhancers)
      : composeWithDevTools(...enhancers);

  const store = createStore(rootReducer, initialState, composedEnhancers);

  return store;
}

export default setupStore;
