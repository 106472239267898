import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';

import { Flex, BackButton, Button } from '../../components/core';
import { ClientTimeFrame } from '../../types/main';
import { useClientLazyQuery } from '../../graphql/graphql';
import { addTask, deleteTask, editTaskData } from '../../redux/reducers/TimeFrameReducer';
import TasksView from './TasksView';
import { RootState } from '../../redux/reducers';
import { correctionForRound, maxWeekHours, minTaskHours } from '../../utils/constants';
import { info } from '../../resources/strings';

interface IProps {
  currentStep: number;
  setStep: (step: number) => void;
  clientStep: number;
  setClientStep: (step: number) => void;
  setShowSummaryBackBtn: (value: boolean) => void;
  clientList: ClientTimeFrame[];
}

const AddTasks: React.FC<IProps> = ({
  currentStep,
  setStep,
  clientStep,
  setClientStep,
  setShowSummaryBackBtn,
  clientList
}) => {
  const dispatch = useDispatch();
  const totalHours = useSelector((state: RootState) => state.timeFrame.totalHours);
  const [getClient, { data: fetchedClient }] = useClientLazyQuery();
  const currentClient = clientList[clientStep];

  React.useEffect(() => {
    getClient({ variables: { id: currentClient?.id } });
  }, [getClient, currentClient]);

  function addNewTask(id: number) {
    dispatch(addTask(id));
  }

  function removeTask(index: number) {
    dispatch(deleteTask({ clientId: currentClient.id, index }));
  }

  function editTask(index: number, editableTask) {
    const clientId = currentClient.id;
    dispatch(editTaskData({ clientId, index, editableTask }));
  }

  function onBack() {
    if (clientStep) {
      setClientStep(clientStep - 1);
    } else {
      setStep(currentStep - 1);
    }
  }

  function onNext() {
    if (clientStep < clientList.length - 1) {
      setClientStep(clientStep + 1);
    } else {
      setShowSummaryBackBtn(true);
      setStep(currentStep + 1);
    }
  }

  const checkMinHours = () => {
    let isLessThanMinHours = false;
    currentClient.tasks.forEach((task) => {
      if (task.hours < minTaskHours) {
        isLessThanMinHours = true;
      }
    });
    if (isLessThanMinHours) {
      message.destroy();
      message.warn(info.minTaskHours);
      return true;
    }
    return false;
  };

  const isDisabled = () => {
    const summaryHours =
      currentClient?.tasks.reduce((sum, current) => sum + current.hours * correctionForRound, 0) /
      correctionForRound;
    const isTasksDataCorect = !currentClient?.tasks.some(
      (task) => !task.id || task.id < 0 || !task.hours
    );
    const isHoursCorect = +summaryHours.toFixed(2) == currentClient?.hours;
    return !(isHoursCorect && isTasksDataCorect);
  };

  return (
    <Flex className='content addTasks'>
      <Flex direction='column' style={{ width: '100%' }}>
        <Flex direction='row' align='start-cross-axis'>
          <BackButton style={{ marginBottom: '8px' }} onClick={onBack} text={info.back} />
          {fetchedClient?.client.__typename === 'Client' && (
            <Flex direction='column' className=''>
              <Flex className='clientLogo-section'>
                <div className='clientName'>{fetchedClient.client.name}</div>
                {fetchedClient.client.logoUrl && (
                  <div
                    className='imageWrapper'
                    style={{ backgroundImage: `url(${fetchedClient.client.logoUrl})` }}
                  />
                )}
              </Flex>
            </Flex>
          )}
        </Flex>
        <TasksView
          addNewTask={addNewTask}
          removeTask={removeTask}
          editTask={editTask}
          clientData={currentClient}
          clinetIndex={clientStep}
        />
        <Flex direction='row' center='both-axis' style={{ margin: 30 }}>
          <Button
            style={{ width: 150 }}
            onClick={() => {
              if (checkMinHours()) return;
              if (totalHours > maxWeekHours) {
                message.destroy();
                message.warning(info.maxWeekHours);
              } else {
                onNext();
              }
            }}
            colorType={'black'}
            text={info.next}
            disabled={isDisabled()}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AddTasks;
