import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';

import { Flex } from '../../../components/core';
import { RateReportDataType } from '../../../types/main';
import { getRoundedTotal } from '../helpers';
import useTableData from './reportDataHook';
import { RootState } from '../../../redux/reducers';
import { info } from '../../../resources/strings';

interface IProps {
  data: RateReportDataType[];
}

const ReportTable: FC<IProps> = ({ data }) => {
  const { conversionRate } = useSelector((state: RootState) => state.reports.rateReport.filters);
  const { clients, columns, columnsFirstPartLength } = useTableData(data, conversionRate);

  return (
    <Flex style={{ marginTop: 20 }}>
      <Table
        id='projHoursReportTable'
        rowKey='id'
        size='small'
        bordered
        dataSource={data}
        columns={columns as any}
        pagination={false}
        scroll={{ y: 370, x: 1000 }}
        style={{ width: '100%' }}
        className='reportTable'
        summary={(pageData) => {
          let totalHoursPerClient = 0;
          let grandTotalPerClient = 0;
          const totalHoursPerClientArr: number[] = [];
          const grandTotalPerClientArr: number[] = [];

          clients.forEach((client) => {
            pageData.forEach((employee) => {
              const empClient = employee.clients.find((c) => c.id === client.id);
              if (empClient) {
                totalHoursPerClient += empClient.hoursPerDay;
                grandTotalPerClient += Number(
                  getRoundedTotal(
                    empClient.salary * (employee.currency.name === 'USD' ? conversionRate : 1)
                  )
                );
              }
            });
            totalHoursPerClientArr.push(Number(totalHoursPerClient.toFixed(2)));
            grandTotalPerClientArr.push(grandTotalPerClient);
            totalHoursPerClient = 0;
            grandTotalPerClient = 0;
          });

          return (
            <Table.Summary>
              <Table.Summary.Row>
                {columns
                  .filter((c, index) => index !== columns.length - 1)
                  .map((col, index) => {
                    if (index === columns.length - 2) {
                      return (
                        <Table.Summary.Cell
                          key={col.key}
                          index={index}
                          colSpan={2}
                          align='right'
                          className='total-second'
                        >
                          {info.totalHoursPerClient}
                        </Table.Summary.Cell>
                      );
                    } else if (col.key.includes('client')) {
                      return (
                        <Table.Summary.Cell
                          key={col.key}
                          index={index}
                          className='total-second'
                          align='center'
                        >
                          {totalHoursPerClientArr[index - columnsFirstPartLength]}
                        </Table.Summary.Cell>
                      );
                    }
                    return <Table.Summary.Cell key={col.key} index={index}></Table.Summary.Cell>;
                  })}
              </Table.Summary.Row>
              <Table.Summary.Row>
                {columns
                  .filter((c, index) => index !== columns.length - 1)
                  .map((col, index) => {
                    if (index === columns.length - 2) {
                      return (
                        <Table.Summary.Cell
                          key={col.key}
                          index={index}
                          colSpan={2}
                          align='right'
                          className='total-second'
                        >
                          {info.grandTotalPerClient}
                        </Table.Summary.Cell>
                      );
                    } else if (col.key.includes('client')) {
                      return (
                        <Table.Summary.Cell
                          key={col.key}
                          index={index}
                          className='total-second'
                          align='center'
                        >
                          {grandTotalPerClientArr[index - columnsFirstPartLength]}
                          {' CAD'}
                        </Table.Summary.Cell>
                      );
                    }
                    return <Table.Summary.Cell key={col.key} index={index}></Table.Summary.Cell>;
                  })}
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      />
    </Flex>
  );
};

export default ReportTable;
