import React from 'react';

interface CurdListHeaderProps {
  title: string;
}

const CrudListHeader: React.FC<CurdListHeaderProps> = (props) => (
  <div>
    <header className='crudListHeader'>{props.title}</header>
  </div>
);

export default CrudListHeader;
