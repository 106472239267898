import React, { MouseEventHandler } from 'react';
import IconWrapper from './IconWrapper';

interface IProps {
  fill?: string;
  size?: number;
  className?: string;
  onClick?: MouseEventHandler<SVGSVGElement>;
}

const Icon: React.FC<IProps> = ({ className, onClick }) => {
  return (
    <svg
      width='23'
      height='23'
      viewBox='0 0 23 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
      onMouseDown={onClick}
    >
      <rect width='23' height='23' rx='11.5' fill='white' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.31279 6.25323C5.97385 6.59217 5.97384 7.14171 6.31279 7.48065L10.3027 11.4705L6.25421 15.519C5.91526 15.858 5.91526 16.4075 6.25421 16.7464C6.59315 17.0854 7.14268 17.0854 7.48162 16.7464L11.5301 12.698L15.5184 16.6863C15.8574 17.0252 16.4069 17.0252 16.7458 16.6863C17.0848 16.3473 17.0848 15.7978 16.7458 15.4589L12.7575 11.4705L16.6873 7.54081C17.0262 7.20187 17.0262 6.65234 16.6873 6.31339C16.3483 5.97445 15.7988 5.97445 15.4598 6.31339L11.5301 10.2431L7.54021 6.25323C7.20126 5.91429 6.65173 5.91429 6.31279 6.25323Z'
        fill='black'
      />
    </svg>
  );
};

const CrossIcon: React.FC<IProps> = (props) => {
  return <IconWrapper Icon={() => <Icon {...props} />} />;
};

export default CrossIcon;
