import { gql } from '@apollo/client';

export const GET_USER = gql`
  query me {
    me {
      ... on AuthenticationError {
        message
      }
      ... on Employee {
        email
        employmentStatus {
          id
          name
        }
        firstName
        hourlyRates {
          id
          rate
          createdAt
          updatedAt
        }
        id
        job {
          id
          title
        }
        lastName
        location {
          id
          name
        }
        role
        status
      }
    }
  }
`;

export const REFRESH_TOKENS = gql`
  query refreshTokens($refreshToken: String!) {
    refreshTokens(refreshToken: $refreshToken) {
      __typename
      ... on AuthTokens {
        accessToken
        refreshToken
      }
      ... on BadRequestError {
        message
      }
    }
  }
`;
