import React from 'react';
import { useQuery } from '@apollo/client';

import { GET_EMPLOYEES } from '../../../ApolloClient/queries/queries';
import { Flex, Spiner, Paginations } from '../../../components/core';
import FilterSection from './FilterSection';
import EmployeeTable from './EmployeeTable';
import ActionSection from './ActionSection';
import { dataCounts } from '../../../utils/constants';

import './Employees.styles.scss';

const Employees: React.FC = () => {
  const { loading, data, refetch } = useQuery(GET_EMPLOYEES, {
    variables: {}
  });

  function handlePageChange(page: number) {
    refetch({ skip: (page - 1) * dataCounts });
  }

  return (
    <Flex className='site-layout-background employee-content' style={{ overflow: 'inherit' }}>
      <ActionSection reFetchEmployees={refetch} />
      <FilterSection reFetchEmployees={refetch} />
      {data && (
        <Flex style={{ width: '95%' }}>
          <EmployeeTable data={data.employees.entities} reFetchEmployees={refetch} />
        </Flex>
      )}
      <Flex direction='row' align='end-main-axis' style={{ marginTop: 20, width: '95%' }}>
        <Paginations
          defaultCurrent={1}
          defaultPageSize={15}
          total={data?.employees.count}
          onChange={handlePageChange}
          showSizeChanger={false}
        />
      </Flex>
      {loading && <Spiner />}
    </Flex>
  );
};

export default Employees;
