import { Flex } from '../../../components/core';
import { info } from '../../../resources/strings';
import { EmployeeTimeSheetDataType, TimeSheetReportClient } from '../../../types/main';

export const columns = [
  {
    key: 'client',
    className: 'clientCell',
    title: info.clients,
    render: (rowdata: TimeSheetReportClient): JSX.Element => {
      return <div className='clientName'>{rowdata.client.name}</div>;
    }
  },
  {
    key: 'totalHours',
    title: info.clientTotalHours,
    className: 'totalHoursCell',
    render: (rowdata: TimeSheetReportClient): JSX.Element => {
      return <div>{rowdata.totalHours}</div>;
    }
  },
  {
    key: 'tasks',
    title: info.tasks,
    render: (rowdata: TimeSheetReportClient): JSX.Element[] => {
      return rowdata.tasks.map((task) => {
        return (
          <div key={task.id} className='taskName'>
            {task.task.summary}
          </div>
        );
      });
    }
  },
  {
    key: 'tasksHours',
    title: info.taskHours,
    width: '100px',
    render: (rowdata: TimeSheetReportClient): JSX.Element[] => {
      return rowdata.tasks.map((task) => {
        return (
          <div key={task.id} className='sumHours bold'>
            {task.hours}
          </div>
        );
      });
    }
  }
];

export const tableFooter = (previousData: EmployeeTimeSheetDataType | null): JSX.Element => {
  return (
    <Flex direction='row' space={'between'} style={{ width: '100%' }}>
      <div className='bold'>{info.totalHours}</div>
      <div className='totalHours bold'>{`${previousData && previousData.totalHours}h`}</div>
    </Flex>
  );
};
