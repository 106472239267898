/* eslint-disable */
import printf from 'printf';

export const info = {
  employeDeleted: 'The employee has been successfully deleted',
  clientDeleted: 'The client has been successfully deleted',
  taskDeleted: 'The task has been successfully deleted',
  emailSent: 'Email was sent successfully',
  newEmployeeCreate: 'New employee has been successfully created',
  employeeEdited: 'Employee has been successfully edited',
  newHolidayCreateMsg: 'New holiday has been successfully created',
  holidayEditedMsg: 'Holiday has been successfully edited',
  holidayCreated: 'Add public holidays',
  holidayEdited: 'Edit public holidays',
  applyForUpcomingYears: 'Apply to the upcoming years',
  permanentlyDelete: 'Do you want to permanently delete',
  holiday: 'holiday',
  resetPassword: 'Please, check your email',
  activateEmployee: 'New Password has been successfully created',
  employeeStatusChange: "Employee's status has been successfully edited",
  timePercentage: 'Percentage of Time',
  totalHours: 'Total Hours',
  clinetNoActiveTasks:
    'The selected client has no active tasks. Please, go Back to select other clients.',
  deleteData: (...args) => printf('Are you sure you want to delete %s?', ...args),
  add: 'Add',
  addNew: (...args) => printf('Add new %s', ...args),
  editItem: 'Edit item',
  save: 'Save',
  dateFrom: 'Date From',
  dateTo: 'Date To',
  reloadPage: 'Reload this page',
  timesheets: 'Timesheets',
  removeTimesheet: 'Remove Timesheet',
  wantRemoveTimesheet: 'Do you want to remove the timesheet?',
  currentTiemsheet: 'Current Timesheet',
  pastTimesheets: 'Past Timesheets',
  systemData: 'System Data',
  employees: 'Employees',
  employee: 'Employee',
  clients: 'Clients',
  holidays: 'Holidays',
  client: 'Client',
  adminData: 'Admin Data',
  reports: 'Reports',
  rateReport: 'Rate Report',
  projectHours: 'Project Hours',
  employeeProjectHours: 'Employee Project Hours',
  logOut: 'Log Out',
  logIn: 'Log In',
  daysWorked: 'Checkmark the days worked',
  locations: 'Locations',
  location: 'Location',
  deleteLocation: 'Delete Location',
  jobTitles: 'Job Titles',
  jobTitle: 'Job Title',
  deleteJob: 'Delete Job',
  employmentStatuses: 'Employment Statuses',
  employmentStatus: 'Employment Status',
  deleteEmploymentStatus: 'Delete Employment Status',
  departments: 'Departments',
  department: 'Department',
  deleteDepartment: 'Delete Department',
  currencies: 'Currencies',
  currency: 'Currency',
  deleteCurrency: 'Delete Currency',
  delete: 'Delete',
  deleteEmployee: 'Delete Employee',
  deleteClient: 'Delete Client',
  deleteParmanently: 'Do you want to permanently delete',
  statusChange: 'Status Change',
  yes: 'Yes',
  no: 'No',
  changeClientStatus: "Do you want to change the client's status?",
  cancel: 'Cancel',
  addClientPlus: 'Add Client +',
  clientName: 'Client Name',
  dragAndDropFileOr: 'Drag & Drop file or',
  upload: 'Upload',
  addClient: 'Add Client',
  editClient: 'Edit Client',
  status: 'Status',
  edit: 'Edit',
  resendEmail: 'Resend e-mail',
  engagementManager: 'Engagement Manager',
  manager: 'Manager',
  admin: 'Admin',
  addEmployee: 'Add Employee',
  editEmployee: 'Edit Employee',
  warning: 'Warning',
  setupEmailPassword:
    'The employee will get a new email for setting up a password and will not be able to log in with the previous email address',
  name: 'Name',
  surname: 'Surname',
  jobStatus: 'Job Status',
  rateH: 'Rate/H',
  ratePH: 'Rate P/H',
  clearX: 'Clear X',
  send: 'Send',
  resendForsetupPass: 'Do you want to resend the email for setting up a password?',
  changeEmployeeStatus: "Do you want to change the employee's status?",
  deleteTask: 'Delete Task',
  changeTaskStatus: "Do you want to change the task's status?",
  addTaskPlus: 'Add Task +',
  addEmployeePlus: 'Add Employees +',
  addHolidayPlus: 'Add Holidays +',
  addTask: 'Add Task',
  editTask: 'Edit Task',
  taskSummary: 'Task Summary',
  backToClient: 'Back to Clients',
  email: 'Email',
  password: 'Password',
  insertEmailForPass: 'Please, insert your email for setting up a new password.',
  setPasswordForLogin: 'Please set your password to be able to log in.',
  confirmPassword: 'Confirm Password',
  setPassword: 'Set Password',
  welcomeFunTimes: 'Welcome to Funtimes!',
  selectTimesheet: 'Select which timesheet you want to view',
  viewTimesheets: 'View Timesheet',
  back: 'Back',
  timesheetFor: 'Timesheet for',
  clientTotalHours: 'Client Total Hours',
  tasks: 'Tasks',
  task: 'Task',
  taskHours: 'Task Hours',
  hoursPerTask: 'Hours per task',
  hoursPerClient: 'Hours per client',
  hoursPerEmployee: 'Hours per employee',
  employeeGrandTotal: 'Employee Grand Total',
  clientGrandTotal: 'Client Grand Total',
  generateReport: 'Generate Report',
  selectDateRange: 'Select a date range to generate report',
  employeeName: 'Employee Name',
  export: 'Export',
  totalHoursPerEE: 'Total Hours per EE',
  grandTotalPerEE: 'Grand Total per EE',
  totalHoursPerClient: 'Total Hours per Client',
  grandTotalPerClient: 'Grand Total per Client',
  dataNotFound: 'Data Not Found',
  addClientWorkedWith: 'Add the Clients that you worked on last week',
  addPercentageWorked: 'Add the percentage of time you worked for each client during last week',
  chooseClientWorkedWith: 'Choose Clients that you have worked with last week',
  splitEvenly: 'Split the % Evenly',
  next: 'Next',
  congratulations: 'Congratulations!',
  successfullyEnteredData: 'You have successfully entered data for',
  done: 'Done',
  lastWeekSummary: "Last Week's Summary",
  markedInRed: '* Inactive clients and tasks are marked in red.',
  startOnLatOrNew: "Do you want to start based on the last timesheet's data or create a new one?",
  new: 'New',
  oneMinuteTimesheet: 'Your 1 minute timesheet',
  doTimesheet: 'You need to do your timesheet for',
  skipThisWeek: 'Skip this week',
  start: 'Start',
  funTimes: 'Fun Times!',
  haveNoTimesheets: 'You have no timesheets left to do!',
  skip: 'Skip',
  wantToSkip:
    'Are you sure you want to skip? This will mean that you have not worked during the week.',
  howManyHoursWorked: 'How many hours did you work during this period?',
  numberOfHours: 'Number of hours',
  summary: 'Summary',
  startOver: 'Start Over',
  cantSubmitInactive: "* You can't submit inactive client and/or task.",
  confirm: 'Confirm',
  wantToStartOver: 'Are you sure you want to start over? It will drop all the information.',
  addPercentageForSubcategory: 'Add the % of time for each subcategory',
  timesheetLookup: 'Timesheet Lookup',
  saveOrDiscardChnages: 'Please, save or discard your changes.',
  editTotalWorkedHours: 'Are you sure you want to edit Total worked hours?',
  editClientTotalWorkedHours: 'Are you sure you want to edit the hours worked on the client?',
  forThePeriodOf: 'for the period of',
  timesheetHasBeenSkipped: 'The timesheet has been skipped',
  selectTimeFrameAndEmployee: 'Select a time frame and employee to view',
  remove: 'Remove',
  wantToLeave: 'Are you sure you want to leave this page? It will drop all the information.',
  deleteHoliday: 'Are you sure you want to delete Victory Day Holiday?',
  deleteHolidaySuccessMsg: 'Holiday sucseffuly deleted',
  addTaskWarning: 'Please, add a task for the client.',
  clientTaskInfo: 'The client has no active tasks.',
  maxWeekHours: 'Weekly hours cannot be more than 168 hours.',
  minTaskHours: 'Client or task hours cannot be less than 0.04 hour.'
};

export const errors = {
  patternTooltip:
    'Your password must be at least 8 characters long, contain at least one number, one symbol, one uppercase and one lowercase letters.',
  emailIsRequired: 'Email is required',
  passwordPattern: 'Use 8 symbols "Aa12345%"',
  passwordNotMatch: 'The passwords do not match',
  requiredField: 'This field is required',
  emailIsIncorrect: 'Email is incorrect',
  uploadImage: 'Please, upload a .jpg or .png file up to 2MB and up to 500x500px',
  locationIsRequired: 'Location is required',
  jobTitleIsRequired: 'Job title is required',
  employmentStatusIsRequired: 'Employment status is required',
  departmentIsRequired: 'Department is required',
  currencyIsRequired: 'Currency is required',
  hourlyRateIsRequired: 'Hourly rate is required',
  graphQLError: (...args) =>
    printf('[GraphQL error]: Message: %s, Location: %s, Path: %s', ...args),
  accessDeniedForPage: "You don't have access to this page",
  pageLoading: 'There was an error in loading this page.',
  passwordRequired: 'Password is required',
  forgotPassword: 'Forgot Password?',
  selectEmployee: 'Please, select an employee.',
  namePattern: (...args) => printf('The %s name cannot contain only spaces', ...args),
  employeeName:
    'Name and Surname must be longer or equal to 2 characters and contain letters [a-zA-Z]'
};
