import React, { useState } from 'react';
import { Button, List } from 'antd';
import { DeleteIcon, EditIcon } from '../../icons';
import {
  CrudListItemInterface as CrudListItemInterface,
  EditableCrudListItem
} from './CrudListItems';
import classNames from 'classnames';

interface CrudListItemProps {
  item: CrudListItemInterface;
  onEdit: (item: EditableCrudListItem) => void;
}

export const CrudListItem: React.FC<CrudListItemProps> = (props) => {
  const [displayActions, setDisplayActions] = useState(false);
  const itemClasses = classNames('crudListItem', { crudListItemClosed: !displayActions });
  const onEdit = () => props.onEdit(props.item);
  const onDelete = () => props.item.onDelete(props.item);

  return (
    <List.Item
      className={itemClasses}
      onMouseEnter={() => setDisplayActions(true)}
      onMouseLeave={() => setDisplayActions(false)}
      actions={
        displayActions
          ? [
              <Button
                size={'large'}
                icon={<EditIcon size={18} />}
                className='crudListActionButton'
                onClick={onEdit}
              />,
              <Button
                icon={<DeleteIcon size={18} />}
                className='crudListActionButton'
                onClick={onDelete}
              />
            ]
          : []
      }
    >
      <List.Item.Meta title={props.item.title} />
    </List.Item>
  );
};
