import React from 'react';
import { useSelector } from 'react-redux';

import useLocalStorage from '../../hooks/useLocalStorage';
import { RootState } from '../../redux/reducers/index';
import { store } from '../../index';

type PropsType = {
  allowAnuthorized?: boolean,
  roles?: string[],
  component?: React.ReactElement,
} & {
  [prop: string]: any
};

const ProtectedComponent: React.FC<PropsType> = ({ allowAnuthorized, roles, children }) => {
  const token = useLocalStorage('accessToken');
  const userInfo = useSelector((state: RootState) => state.user.userInfo);

  if (!allowAnuthorized && !token) return null;

  if (roles?.length && !roles.includes(userInfo.role)) return null;

  return children as React.ReactElement;
};

export const getProtectedComponent: React.FC<PropsType> = ({ allowAnuthorized, roles, component }) => {
  const token = localStorage.getItem('accessToken');
  const { userInfo } = store.getState().user;

  if (!allowAnuthorized && !token) return null;

  if (roles?.length && !roles.includes(userInfo.role)) return null;

  return component as React.ReactElement;
};

export default ProtectedComponent;


