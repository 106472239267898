import { FC, ComponentType } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

const PublicRoute: FC<RouteProps> = ({ component, ...rest }) => {
  const Component = component as ComponentType;
  return (
    <Route
      {...rest}
      render={(props) => {
        if (localStorage.getItem('accessToken')) {
          return <Redirect to='/currentTime' />;
        }
        return <Component {...rest} {...props} />;
      }}
    />
  );
};

export default PublicRoute;
