import React from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { Breadcrumb, BreadcrumbProps } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import './GoBack.scss';

interface IProps extends BreadcrumbProps {
  backLink: string;
  backText: string;
}

const GoBack: React.FC<IProps> = ({ backLink, backText, className, ...otherProps }) => {
  const history = useHistory();
  const cn = classNames('goBack', className);
  return (
    <Breadcrumb {...otherProps} className={cn}>
      <Breadcrumb.Item onClick={() => history.push(backLink)}>
        <ArrowLeftOutlined />
        <h3>{backText}</h3>
      </Breadcrumb.Item>
    </Breadcrumb>
  );
};

export default GoBack;
