import React from 'react';
import { ModalProps } from 'antd';

import { Flex, ConfirmationDialog } from '../../../components/core';
import { TaskDataType, DialogType } from '../../../types/main';
import { info } from '../../../resources/strings';

interface IProps extends ModalProps {
  type: string;
  data: TaskDataType;
  confirmAction: () => void;
  rejectAction: () => void;
}

const ActionDialog: React.FC<IProps> = ({ type, confirmAction, rejectAction, visible, data }) => {
  const dialogParams = {
    [DialogType.delete]: {
      title: info.deleteTask,
      confirmationText: info.delete,
      dialogBodyText: info.deleteParmanently
    },
    [DialogType.stausChange]: {
      title: info.statusChange,
      confirmationText: info.yes,
      dialogBodyText: info.changeTaskStatus
    }
  };

  const getContent = () => {
    return (
      <Flex>
        <div>{dialogParams[type]?.dialogBodyText}</div>
        {type === DialogType.delete && <h3>{`${data.summary}?`}</h3>}
      </Flex>
    );
  };

  return (
    <ConfirmationDialog
      title={dialogParams[type]?.title}
      noTitle='Cancel'
      yesTitle={dialogParams[type]?.confirmationText}
      confirmAction={confirmAction}
      rejectAction={rejectAction}
      visible={visible}
    >
      {getContent()}
    </ConfirmationDialog>
  );
};

export default ActionDialog;
