import { message, Table } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, ConfirmationDialog, Flex } from '../../../components/core';
import { useRemoveTimeSheetMutation } from '../../../graphql/graphql';
import { RootState } from '../../../redux/reducers';
import { addTimeSheetReport } from '../../../redux/reducers/Reports';
import { replaceTimeSheetLookup, setCurrentStep } from '../../../redux/reducers/TimeSheetLookup';
import { info } from '../../../resources/strings';
import { ClientLookup, TimeSheetReport } from '../../../types/main';
import { displayCustomWeekStartEnd } from '../../../utils/helpers';
import { getClientsDataForTimeSheetLookup } from '../../TimeSheet/helpers/helpers';
import { columns, tableFooter } from './Table';

interface IProps {
  data: TimeSheetReport;
}

const ReportTimeSheet: FC<IProps> = ({ data }) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [clients, setClients] = useState<ClientLookup[]>([]);
  const [removeTimeSheet] = useRemoveTimeSheetMutation();
  const { step, skipped } = useSelector((state: RootState) => state.timeSheetLookup);
  const { dateRange: datePickerRange } = useSelector(
    (state: RootState) => state.reports.timesheetReport
  );
  const { selectedEmployeeName } = useSelector((state: RootState) => state.reports.timesheetReport);

  useEffect(() => {
    setClients(getClientsDataForTimeSheetLookup(data));
  }, [data]);

  const handleEdit = () => {
    const firstDate = new Date(data.dates[0]);
    const skippedWeek = new Array<string>(5).fill('').map((el, i) => {
      const date = new Date(firstDate);
      date.setUTCDate(date.getUTCDate() + i);
      return date.toISOString();
    });

    const replaceData = {
      totalHours: data.totalHours,
      clients,
      step,
      dates: skipped ? skippedWeek : data.dates,
      id: data.id,
      skipped
    };
    dispatch(replaceTimeSheetLookup(replaceData));
    dispatch(setCurrentStep(step + 1));
  };
  const handleRemove = () => {
    setVisible(true);
  };

  const onRemove = () => {
    removeTimeSheet({ variables: { id: data?.id } }).then(() => {
      dispatch(addTimeSheetReport(null));
      setVisible(false);
      message.success(info.timesheetHasBeenSkipped, 1);
    });
  };

  return (
    <Flex direction='row' center='main-axis' className='content previousTimeSheet'>
      <Flex direction='column' className='dataWrapper'>
        <div className='report-header'>
          <span>{info.timesheetFor} </span>
          <span className='headerTextLookup'>{selectedEmployeeName}</span>
          <span> {info.forThePeriodOf} </span>
          <span className='headerTextLookup'>{displayCustomWeekStartEnd(datePickerRange[0])}</span>
        </div>
        {skipped ? (
          <div className='wrapper' style={{ width: 600 }}>
            {info.timesheetHasBeenSkipped}
          </div>
        ) : (
          <Table
            id='summaryTable'
            rowKey='id'
            size='small'
            dataSource={data?.clients}
            columns={columns}
            footer={() => tableFooter(data)}
            pagination={{ hideOnSinglePage: true, pageSize: 20 }}
            style={{ width: 600 }}
          />
        )}
        <Flex direction='row' center='both-axis' style={{ marginTop: 20 }}>
          <Button
            style={{ marginRight: 10, width: 150 }}
            colorType='white'
            onClick={handleRemove}
            disabled={skipped}
            text={info.remove}
          />
          <Button colorType='black' onClick={handleEdit} style={{ width: 150 }} text={info.edit} />
        </Flex>
      </Flex>

      <ConfirmationDialog
        noTitle={info.cancel}
        yesTitle={info.remove}
        confirmAction={onRemove}
        rejectAction={() => {
          setVisible(false);
        }}
        visible={visible}
        title={info.removeTimesheet}
      >
        {info.wantRemoveTimesheet}
      </ConfirmationDialog>
    </Flex>
  );
};

export default ReportTimeSheet;
