import React from 'react';
import { ModalProps } from 'antd';

import { Flex, ConfirmationDialog } from '../../../components/core';
import { ClientDataType, DialogType } from '../../../types/main';
import { info } from '../../../resources/strings';

interface IProps extends ModalProps {
  type: string;
  data: ClientDataType;
  confirmAction: () => void;
  rejectAction: () => void;
}

const ActionDialog: React.FC<IProps> = ({ type, confirmAction, rejectAction, visible, data }) => {
  const dialogParams = {
    [DialogType.delete]: {
      title: info.deleteClient,
      confirmationText: info.delete,
      dialogBodyText: info.deleteParmanently
    },
    [DialogType.stausChange]: {
      title: info.statusChange,
      confirmationText: info.yes,
      dialogBodyText: info.changeClientStatus
    }
  };

  const getContent = () => {
    if (type === DialogType.delete) {
      return (
        <Flex>
          <div>{dialogParams[type]?.dialogBodyText}</div>
          <h3>{`${data.name}?`}</h3>
        </Flex>
      );
    }
    return (
      <Flex>
        <div>{dialogParams[type]?.dialogBodyText}</div>
      </Flex>
    );
  };

  return (
    <ConfirmationDialog
      title={dialogParams[type]?.title}
      noTitle={info.cancel}
      yesTitle={dialogParams[type]?.confirmationText}
      confirmAction={confirmAction}
      rejectAction={rejectAction}
      visible={visible}
    >
      {getContent()}
    </ConfirmationDialog>
  );
};

export default ActionDialog;
