import { FC, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Controller } from 'react-hook-form';
import debounce from 'lodash/debounce';

import { GET_MANAGERS } from '../../../ApolloClient/queries/queries';
import { Flex, Label, Select } from '../../../components/core';
import { EmployeeDataType } from '../../../types/main';
import { EmployeeStatus } from '../../../graphql/graphql';
import { debounceTimeout } from '../../../utils/constants';
import { info } from '../../../resources/strings';

export type ManagerType = Pick<
  EmployeeDataType,
  'id' | 'firstName' | 'lastName' | 'fullName' | 'status'
>;

interface IProps {
  control: any;
  id?: number;
}

const EditManagersList: FC<IProps> = ({ control, id }) => {
  const [managers, setManagers] = useState<ManagerType[]>([]);
  const [searchQuerie, setSearchQuerie] = useState('');
  const { data, refetch } = useQuery(GET_MANAGERS, { variables: { id: id, term: '' } });

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      setSearchQuerie(value);
      refetch({ id: id, term: value });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [refetch, id]);

  function onScroll(e) {
    const current = e.currentTarget;
    const isScrollEnds = current.offsetHeight + current.scrollTop >= current.scrollHeight;
    if (isScrollEnds && data.counts > managers.length) {
      //will be refetche logick
    }
  }

  useEffect(() => {
    if (data) {
      const result = data?.getManagersForEmployee?.entities;
      const newData: ManagerType[] = result?.map((item: ManagerType) => {
        const fullName = `${item.firstName} ${item.lastName}`;
        return { ...item, fullName };
      });
      setManagers(newData);
    }
  }, [data, searchQuerie]);

  function optionDisable(employee: ManagerType) {
    return employee.status === EmployeeStatus.Deactivated;
  }

  const filteredManagers = managers.filter((m) => m.status === 'Active');
  filteredManagers.unshift({ fullName: ' ', id: 0 } as ManagerType);

  return (
    <Flex direction='column' style={{ width: 350 }}>
      <Label type='small'>{info.manager}</Label>
      <Controller
        name='managerId'
        control={control}
        render={({ field: { ref, ...otherFieldProps } }) => (
          <Select
            id={'editManagerslist'}
            data={filteredManagers}
            defaultValue={managers[0]?.id}
            onSearch={debounceFetcher}
            onPopupScroll={onScroll}
            optionDisable={optionDisable}
            style={{ width: 350 }}
            dataText='fullName'
            dataValue='id'
            filterOption={false}
            showSearch
            {...otherFieldProps}
          />
        )}
      />
    </Flex>
  );
};

export default EditManagersList;
