import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addProjectReport } from '../../../redux/reducers/Reports';
import { RootState } from '../../../redux/reducers/index';
import { useClientReportLazyQuery } from '../../../graphql/graphql';
import { Flex, Spiner } from '../../../components/core';
import FilterSection from './FilterSection';
import ReportTable from './ReportTable';
import ExportCSV from './ExportCSV';
import { info } from '../../../resources/strings';

import './Report.scss';

const ProjectHours: React.FC = () => {
  const dispatch = useDispatch();
  const [getProjectReports, { data, loading }] = useClientReportLazyQuery({
    fetchPolicy: 'no-cache'
  });
  const projectHoursReport = useSelector((state: RootState) => state.reports.projectHoursReport);

  useEffect(() => {
    if (data?.clientReport.__typename === 'ClientsReport') {
      dispatch(addProjectReport(data.clientReport.entities));
    } else if (data?.clientReport.__typename) {
      dispatch(addProjectReport([]));
    }
  }, [data, dispatch]);

  return (
    <Flex className='site-layout-background projectHours projectHours-content'>
      <Flex>{info.selectDateRange}</Flex>
      <FilterSection getReport={getProjectReports} />
      {projectHoursReport.fetched && (
        <>
          <ReportTable data={projectHoursReport.data} />
          <ExportCSV data={projectHoursReport.data} dates={projectHoursReport.filters.dateRange} />
        </>
      )}
      {loading && <Spiner />}
    </Flex>
  );
};

export default ProjectHours;
