import React from 'react';
import { useQuery } from '@apollo/client';

import { Flex, Spiner, Paginations } from '../../../components/core';
import FilterSection from './FilterSection';
import ClientsTable from './ClientsTable';
import ActionSection from './ActionSection';
import { GET_CLIENTS } from '../../../ApolloClient/queries/clientsQueries';
import { dataCounts } from '../../../utils/constants';

import './Clients.styles.scss';

const ClientsView: React.FC = () => {
  const { loading, data, refetch } = useQuery(GET_CLIENTS, { variables: {} });

  function handlePageChange(page: number) {
    refetch({ skip: (page - 1) * dataCounts });
  }

  return (
    <Flex className='site-layout-background'>
      <ActionSection reFetchClients={refetch} />
      <FilterSection reFetchEmployees={refetch} />
      <ClientsTable data={data?.clients?.entities} reFetchClients={refetch} />
      <Flex direction='row' align='end-main-axis' style={{ marginTop: 20, width: '800px' }}>
        <Paginations
          defaultCurrent={1}
          defaultPageSize={15}
          total={data?.clients.count}
          onChange={handlePageChange}
          showSizeChanger={false}
        />
      </Flex>
      {loading && <Spiner />}
    </Flex>
  );
};

export default ClientsView;
