import React from 'react';
import debounce from 'lodash/debounce';
import { dataCounts, debounceTimeout, initialIndex } from '../../utils/constants';
import DynamicList from './DynamicList';
import { Control } from 'react-hook-form';
import { LabeledValue } from '../../types/main';

export interface ResponseDataType {
  [k: string]: string | number;
}

export interface IDebouncedListProps {
  control?: Control<any, any>;
  onChange?: (id: number) => void;
  width?: string | number;
  style?: React.CSSProperties;
  query: any;
  withLabel?: boolean;
  labelInValue?: boolean;
  labelText?: string;
  controllerName: string;
  dataText: string;
  dataValue: string;
  queryDataKey: string;
  defaultValueId?: string | number | LabeledValue | LabeledValue[];
  otherVarebales?: { [key: string]: string | number };
  onCompleted?: (data) => void;
  optionDisable?: (data) => boolean;
  optionRender?: (data) => React.ReactNode;
  mode?: 'multiple' | 'tags';
  maxTagCount?: number | 'responsive';
  fetchPolicy?: string;
  disabled?: boolean;
  allowClear?: boolean;
  placeholder?: string;
  sectionName?: string;
}

const DebouncedList: React.FC<IDebouncedListProps> = (props) => {
  const [dataSelect, setDataSelect] = React.useState<ResponseDataType[]>([]);
  const [skip, setSkip] = React.useState(initialIndex);
  const [searchText, setSearchText] = React.useState('');
  const { data, refetch } = props.query({
    variables: { term: searchText, skip, ...props.otherVarebales },
    onCompleted: (d) => (props.onCompleted ? props.onCompleted(d) : void 0),
    fetchPolicy: props.fetchPolicy ? props.fetchPolicy : void 0
  });
  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (term: string) => {
      setSearchText(term);
      setSkip(initialIndex);
      refetch({ term, skip: initialIndex });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [refetch]);

  const onScrollDebounceFetcher = React.useMemo(() => {
    const loadOptions = (e: React.ChangeEvent<HTMLInputElement>) => {
      const current = e.target;
      const isScrollEnds = current.offsetHeight + current.scrollTop >= current.scrollHeight;
      if (isScrollEnds && data[props.queryDataKey].count > dataSelect.length) {
        setSkip(skip + dataCounts);
        refetch({ term: searchText, skip: skip + dataCounts });
      }
    };
    return debounce(loadOptions, debounceTimeout);
  }, [refetch, data, dataSelect, skip, props.queryDataKey]);

  React.useEffect(() => {
    if (data) {
      const result = data?.[props.queryDataKey]?.entities;
      if (!skip && !!result) {
        setDataSelect(result);
      } else {
        setDataSelect(dataSelect.concat(result));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, skip]);

  return (
    <DynamicList
      data={dataSelect}
      debounceFetcher={debounceFetcher}
      onScrollDebounceFetcher={onScrollDebounceFetcher}
      {...props}
    />
  );
};

export default DebouncedList;
