import React from 'react';
import ActionCell from './ActionCell';
import { Table } from 'antd';
import { EmployeeDataType, Status } from '../../../types/main';
import { getLastHourlyRateIndex } from '../../../utils/helpers';
import { info } from '../../../resources/strings';

interface IProps {
  data: EmployeeDataType[];
  reFetchEmployees: () => void;
}

const EmployeeTable: React.FC<IProps> = ({ data, reFetchEmployees }) => {
  const columns = [
    {
      key: 'firstName',
      title: info.name,
      dataIndex: 'firstName',
      filtered: true
    },
    {
      key: 'lastName',
      title: info.surname,
      dataIndex: 'lastName',
      filtered: true
    },
    {
      key: 'location',
      title: info.location,
      render: (rowData: EmployeeDataType) => {
        const location = rowData.location ? rowData.location.name : '';
        return <span title={location}>{location}</span>;
      }
    },
    {
      key: 'jobTitle',
      title: info.jobTitle,
      width: '100px',
      render: (rowData: EmployeeDataType) => {
        const jobTitle = rowData.job ? rowData.job.title : '';
        return <span title={jobTitle}>{jobTitle}</span>;
      }
    },
    {
      key: 'employmentStatus',
      title: info.jobStatus,
      render: (rowData: EmployeeDataType) => {
        const employmentStatus = rowData.employmentStatus ? rowData.employmentStatus.name : '';
        return <span title={employmentStatus}>{employmentStatus}</span>;
      }
    },
    {
      key: 'department',
      title: info.department,
      render: (rowData: EmployeeDataType) => {
        const department = rowData.department ? rowData.department.name : '';
        return <span title={department}>{department}</span>;
      }
    },
    {
      key: 'manager',
      title: info.manager,
      render: (rowdata: EmployeeDataType) => {
        const manager = rowdata.manager
          ? `${rowdata.manager?.firstName} ${rowdata.manager?.lastName}`
          : '';
        return <span title={manager}>{manager}</span>;
      }
    },
    {
      key: 'hourlyRate',
      title: info.rateH,
      width: '65px',
      render: (rowdata: EmployeeDataType) => {
        return <span>{rowdata.hourlyRates[getLastHourlyRateIndex(rowdata)]?.rate}</span>;
      }
    },
    {
      key: 'currency',
      title: info.currency,
      width: '74px',
      render: (rowdata: EmployeeDataType) => {
        const currency = rowdata.currency ? rowdata.currency.name : '';
        return <span title={currency}>{currency}</span>;
      }
    },
    {
      key: 'status',
      title: info.status,
      render: (rowdata: EmployeeDataType) => {
        const status = rowdata.status === Status.Deactivated ? 'Inactive' : rowdata.status;
        return <span className={`employee ${status}`}>{status}</span>;
      }
    },
    {
      key: 'action',
      width: '60px',
      render: (rowdata: EmployeeDataType) => (
        <ActionCell rowdata={rowdata} reFetchEmployees={reFetchEmployees} />
      )
    }
  ];

  return (
    <Table
      id='employeeTable'
      rowKey='id'
      size='small'
      bordered
      dataSource={[...data]}
      columns={columns}
      pagination={{ hideOnSinglePage: true, pageSize: 15 }}
      scroll={{ y: 380 }}
    />
  );
};

export default EmployeeTable;
