import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { message } from 'antd';
import { useMutation } from '@apollo/client';

import { RESET_PASSWORD } from '../../ApolloClient/mutations/userAction';
import { RegExpEmail } from '../../utils/constants';
import { ValidationsType } from '../../types/main';
import { MessageDuration } from '../../utils/constants';
import { Flex, Input, ConfirmationDialog } from '../../components/core';
import { info, errors as errorMessages } from '../../resources/strings';

type FormData = {
  email: string;
};

interface IProps {
  visible: boolean;
  rejectAction: () => void;
}

const ResendPassDialog: React.FC<IProps> = ({ visible, rejectAction }) => {
  const [resetPassword] = useMutation(RESET_PASSWORD);
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>();

  const onSubmit = (formData: FormData) => {
    const variables = { email: formData.email };
    resetPassword({ variables })
      .then((result) => {
        const resultData = result?.data?.initiateResetPassword;
        if (resultData.result) {
          message.success(info.resetPassword, MessageDuration);
          rejectAction();
          return;
        }
      })
      .catch((e) => {
        if (e) {
          message.error(e.message, MessageDuration);
        }
      });
  };

  const getContent = () => {
    return (
      <Flex align='start-cross-axis'>
        <div className='resendPass-text'>
          {info.insertEmailForPass}
        </div>
        <Controller
          name='email'
          control={control}
          rules={{
            required: true,
            pattern: RegExpEmail
          }}
          render={({ field: { ref, ...otherFieldProps } }) => (
            <Input
              inputRef={ref}
              placeholder={info.email}
              withError={!!errors.email}
              onPressEnter={handleSubmit(onSubmit)}
              {...otherFieldProps}
            />
          )}
        />
        {errors.email?.type === ValidationsType.required && (
          <span className='errorText'>{errorMessages.emailIsRequired}</span>
        )}
        {errors.email?.type === ValidationsType.pattern && (
          <span className='errorText'>{errorMessages.emailIsIncorrect}</span>
        )}
      </Flex>
    );
  };

  return (
    <ConfirmationDialog
      title={'Resend Password'}
      noTitle={'Cancel'}
      yesTitle={'Send'}
      confirmAction={handleSubmit(onSubmit)}
      rejectAction={rejectAction}
      visible={visible}
    >
      {getContent()}
    </ConfirmationDialog>
  );
};

export default ResendPassDialog;
