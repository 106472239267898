import React from 'react';
import { Flex, Button } from '..';
import './Dialog.styles.scss';
import { Modal, ModalProps } from 'antd';

interface IProps extends ModalProps {
  noTitle: string;
  yesTitle: string;
  confirmAction: () => void;
  rejectAction: () => void;
}

const ConfirmationDialog: React.FC<IProps> = ({
  title,
  noTitle,
  yesTitle,
  confirmAction,
  rejectAction,
  visible,
  children,
  ...props
}) => {
  const footer = () => {
    return (
      <Flex className='footer' direction='row' center='main-axis' style={{ marginBottom: 30 }}>
        <Flex align='end-cross-axis'>
          <Button
            colorType={'white'}
            onClick={rejectAction}
            text={noTitle}
            style={{ marginRight: 60, width: 150 }}
          />
        </Flex>
        <Flex align='start-cross-axis'>
          <Button
            colorType={'black'}
            onClick={confirmAction}
            text={yesTitle}
            style={{ width: 150 }}
          />
        </Flex>
      </Flex>
    );
  };

  return (
    <Modal
      wrapClassName='ConfirmationDialog'
      title={title}
      onCancel={rejectAction}
      visible={visible}
      centered={true}
      footer={footer()}
      width={600}
      {...props}
    >
      <Flex className='ConfirmationDialogBody'>{children}</Flex>
    </Modal>
  );
};

export default ConfirmationDialog;
