import * as React from 'react';
import { Pagination, PaginationProps } from 'antd';
import './Pagination.styles.scss';

const Paginations: React.FC<PaginationProps> = ({ ...props }) => {
  return (
    <div id='FD_pagination'>
      <Pagination {...props} />
    </div>
  );
};

export default Paginations;
