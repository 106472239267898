import React from 'react';
import { Controller } from 'react-hook-form';

import { Flex, Label, Input } from '../core';
import { IInputUIProps } from '../core/Input/Input';

interface IProps {
  wrapperStyle?: React.CSSProperties;
  labelName?: string;
  controlName: string;
  control: any;
  inputProps?: IInputUIProps;
}

const ControllerInput: React.FC<IProps> = ({
  wrapperStyle,
  labelName,
  controlName,
  control,
  inputProps
}) => {
  const wrapStyle = wrapperStyle ? wrapperStyle : { marginRight: 20, marginBottom: 3 };
  const props = {
    width: 200,
    required: true,
    autoComplete: 'off',
    ...inputProps
  };

  if (!props.colorType) {
    props.colorType = 'primary';
  }

  return (
    <Flex style={wrapStyle}>
      {labelName && <Label type='small'>{labelName}</Label>}
      <Controller
        name={controlName}
        control={control}
        render={({ field: { ref, ...otherFieldProps } }) => (
          <Input inputRef={ref} {...props} {...otherFieldProps} />
        )}
      />
    </Flex>
  );
};

export default ControllerInput;
