import { ApolloClient, InMemoryCache, from, ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { uploadLink } from './links';

import { mapValues } from 'lodash';
import { message } from 'antd'; // TODO: Need to make this UI lib agnostic

const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors?.map(({ message: errorMessage, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${errorMessage}, Location: ${locations}, Path: ${path}`
      );
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const apiErrorHandlingLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    mapValues(data.data, (value) => {
      if (value.__typename === 'BadRequestError' || value.__typename === 'AuthenticationError') {
        message.destroy();
        message.error(value.message);
      }
    });
    // TODO: Maybe we don't need to return this data
    return data;
  });
});

export const client = new ApolloClient({
  link: from([onErrorLink, apiErrorHandlingLink, uploadLink]),
  cache: new InMemoryCache()
});
