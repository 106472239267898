import { useMemo } from 'react';
import { info } from '../../../resources/strings';
import { RateReportClientType, RateReportDataType } from '../../../types/main';
import { getRoundedTotal } from '../helpers';

const getWidth = (length: number) => {
  switch (length) {
    case 1:
      return 330;
    case 2:
      return 165;
    case 3:
      return 110;
    default:
      return 85;
  }
};
export default function useTableData(
  data: RateReportDataType[],
  conversionRate: number
): {
  columns: any[];
  clients: RateReportClientType[];
  columnsFirstPartLength: number;
} {
  return useMemo(() => {
    const columnsFirstPart = [
      {
        key: 'employee',
        title: info.employee,
        className: 'employeeCell',
        fixed: 'left',
        align: 'center',
        width: 150,
        render: (rowData: RateReportDataType) => (
          <div key={rowData.id}>{rowData.firstName + ' ' + rowData.lastName}</div>
        )
      },
      {
        key: 'employmentStatus',
        title: info.employmentStatus,
        className: 'employmentStatusCell',
        fixed: 'left',
        align: 'center',
        width: 150,
        render: (rowData: RateReportDataType) => (
          <div key={rowData.id}>{rowData.employmentStatus.name}</div>
        )
      },
      {
        key: 'location',
        title: info.location,
        className: 'locationCell',
        fixed: 'left',
        align: 'center',
        width: 150,
        render: (rowData: RateReportDataType) => <div key={rowData.id}>{rowData.location.name}</div>
      },
      {
        key: 'currency',
        title: info.currency,
        className: 'currencyCell',
        fixed: 'left',
        align: 'center',
        width: 100,
        render: (rowData: RateReportDataType) => <div key={rowData.id}>{rowData.currency.name}</div>
      },
      {
        key: 'hourlyRate',
        title: info.ratePH,
        className: 'hourlyRateCell',
        fixed: 'left',
        align: 'center',
        width: 100,
        render: (rowData: RateReportDataType) => {
          return <div key={rowData.id}>{rowData.rate}</div>;
        }
      }
    ];
    const columnsClients: Array<{
      key: string;
      title: string;
      className: string;
      align: string;
      width: number;
      render: (rowData: RateReportDataType) => JSX.Element;
    }> = [];
    const clients: RateReportClientType[] = [];
    data.forEach((item) => {
      item.clients.forEach((client) => {
        if (!clients.find((c) => c.id === client.id)) {
          clients.push(client);
        }
      });
    });
    clients.forEach((client) => {
      columnsClients.push({
        key: 'client ' + client.id,
        title: client.name,
        width: getWidth(clients.length),
        align: 'center',
        className: 'total-clients',
        render: (rowData: RateReportDataType) => {
          const empClient = rowData.clients.find((c) => c.id === client.id);
          return (
            <div key={rowData.id}>
              {empClient
                ? (rowData.currency.name === 'USD'
                    ? empClient.salary * conversionRate
                    : empClient.salary
                  ).toFixed(2)
                : 0}
              {' CAD'}
            </div>
          );
        }
      });
    });
    const columnsLastPart = [
      {
        key: 'totalHours',
        title: info.totalHoursPerEE,
        className: 'total-first',
        fixed: 'right',
        width: 100,
        align: 'center',
        render: (rowData: RateReportDataType) => (
          <div key={rowData.id}>
            {getRoundedTotal(
              rowData.clients.reduce(
                (totalHoursPerEE, { hoursPerDay }) => totalHoursPerEE + hoursPerDay,
                0
              )
            )}
          </div>
        )
      },
      {
        key: 'grandTotalPerEE',
        title: info.grandTotalPerEE,
        className: 'total-first',
        fixed: 'right',
        width: 100,
        align: 'center',
        render: (rowData: RateReportDataType) => (
          <div key={rowData.id}>
            {getRoundedTotal(
              rowData.clients.reduce((totalSalary, { salary }) => totalSalary + salary, 0) *
                (rowData.currency.name === 'USD' ? conversionRate : 1)
            )}
            {' CAD'}
          </div>
        )
      }
    ];
    return {
      columns: [...columnsFirstPart, ...columnsClients, ...columnsLastPart],
      clients,
      columnsFirstPartLength: columnsFirstPart.length
    };
  }, [data, conversionRate]);
}
