import React from 'react';
import moment from 'moment';
import { Checkbox } from 'antd';

import { Flex, Label } from '../../components/core';
import { DaysOfWeekType } from '../../types/main';
import { info } from '../../resources/strings';

import './OneWeek.scss';

const colorOfDays = {
  worker: '#fff',
  dayOff: '#D4D4D4'
};

interface IProps {
  onChange: (data: string[]) => void;
  selectedDays: string[];
  weekDays: DaysOfWeekType[];
}

const OneWeekDays: React.FC<IProps> = ({ onChange, selectedDays, weekDays }) => {
  function onCheckChange(event, item: string) {
    const checked = event.target.checked;
    let list = [...selectedDays];
    if (checked) {
      list.push(item);
    } else {
      list = list.filter((i) => i !== item);
    }
    if (onChange) {
      onChange(list);
    }
  }

  function getBackgroundColor(item: DaysOfWeekType) {
    return item.weekend || item.holiday ? colorOfDays.dayOff : colorOfDays.worker;
  }

  return (
    <Flex direction='column'>
      <Flex direction='row'>
        {weekDays?.map((item, index) => {
          return (
            <Flex direction='column' key={item.name} className='weekDay'>
              <Checkbox
                onChange={(e) => onCheckChange(e, item.date)}
                checked={selectedDays.includes(item.date)}
              >
                <Label ml={18}>{item.name}</Label>
                <div
                  className='dayCard'
                  key={index}
                  style={{ backgroundColor: getBackgroundColor(item) }}
                >
                  {moment(item.date).utc().startOf('day').format('DD')}
                </div>
              </Checkbox>
            </Flex>
          );
        })}
      </Flex>
      <Label mt={5} ml={10} type={'bold'}>
        {info.daysWorked}
      </Label>
    </Flex>
  );
};

export default OneWeekDays;
