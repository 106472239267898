import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { ADD_CLIENT, EDIT_CLIENT } from '../../../ApolloClient/mutations/clientActions';
import { Flex, Button, Label, Input } from '../../../components/core';
import {
  RequestTypeName,
  ValidationsType,
  ClientViewType,
  ClientDataType
} from '../../../types/main';
import { imageRequirement, RegExpName } from '../../../utils/constants';
import { Modal, message, Upload } from 'antd';
import { errors as errorMessages } from '../../../resources/strings';
import { checkImageMaxSize } from '../helpers/helpers';
import { Controller, useForm } from 'react-hook-form';
import { info } from '../../../resources/strings';

const { Dragger } = Upload;

interface IProps {
  type: ClientViewType;
  onCancel: () => void;
  reFetchClients: () => void;
  visible: boolean;
  rowData?: ClientDataType;
}

type FormData = {
  name: string;
};

const getDefaultValues = (val?: ClientDataType) => ({ id: val?.id, name: val?.name });

const getFileList = (val?: ClientDataType) => {
  return val ? [{ url: val.logoUrl }] : [];
};

const AddEditClient: React.FC<IProps> = ({ onCancel, visible, reFetchClients, type, rowData }) => {
  const history = useHistory();
  const [selectedImage, setSelectedImage] = useState(getFileList(rowData) as any);
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>({ defaultValues: getDefaultValues(rowData) });
  const [isImageInvalid, setIsImageInvalid] = useState(false);
  const [addEditClient] = useMutation(type === ClientViewType.create ? ADD_CLIENT : EDIT_CLIENT);

  const handleChange = ({ fileList }) => {
    setSelectedImage(fileList);
  };

  async function beforeUpload(file) {
    const isValidType = imageRequirement.fyleTypes.includes(file.type);
    const hasValidSize = file.size / imageRequirement.bytesInMB < 2;
    const hasValidResolution = await checkImageMaxSize(
      file,
      imageRequirement.maxWidth,
      imageRequirement.maxHeight
    );

    if (!isValidType || !hasValidSize || !hasValidResolution) {
      file.status = 'error';
      setIsImageInvalid(true);
    }
    return false;
  }

  const onSubmit = (fieldData: FormData) => {
    if (isImageInvalid) {
      return;
    }
    const variables = {
      ...fieldData,
      logo: null
    };

    if (selectedImage?.[0]) {
      variables.logo = selectedImage?.[0].originFileObj;
    }

    addEditClient({ variables })
      .then((result) => {
        if (result?.data?.[type].__typename === RequestTypeName.client) {
          onCancel();
          reFetchClients();
          if (type === ClientViewType.create) {
            const clientData = {
              id: result.data.createClient.id,
              name: result.data.createClient.name,
              logoUrl: result.data.createClient.logoUrl,
              status: 'Active'
            };
            const path = `/clients/${clientData.id}`;
            history.push(path, clientData);
          }
          return;
        }
      })
      .catch((e) => {
        if (e) {
          message.error(e.message);
        }
      });
  };

  const content = () => {
    return (
      <Flex className='modalContent'>
        <Flex direction='column' style={{ height: '80px' }}>
          <Label type='small'>{info.clientName}</Label>
          <Controller
            name='name'
            control={control}
            rules={{ required: true, pattern: RegExpName }}
            render={({ field: { ref, ...otherFieldProps } }) => (
              <Input
                autoFocus
                width={'100%'}
                colorType='primary'
                autoComplete={'off'}
                inputRef={ref}
                {...otherFieldProps}
              />
            )}
          />
          {errors.name?.type === ValidationsType.required && (
            <span className='errorText'>{errorMessages.requiredField}</span>
          )}
          {errors.name?.type === ValidationsType.pattern && (
            <span className='errorText'>{errorMessages.namePattern('client')}</span>
          )}
        </Flex>
        <Flex>
          <Dragger
            name={'client_Logo'}
            action=''
            maxCount={1}
            fileList={selectedImage}
            onChange={handleChange}
            onRemove={() => setIsImageInvalid(false)}
            beforeUpload={beforeUpload}
            listType={'picture-card'}
          >
            <div>{info.dragAndDropFileOr}</div>
            <h4>{info.upload}</h4>
            <Flex center='main-axis' className='imageContainer'>
              +
            </Flex>
          </Dragger>
          {isImageInvalid && <span className='errorText'>{errorMessages.uploadImage}</span>}
        </Flex>
      </Flex>
    );
  };

  const footer = () => {
    return (
      <Flex className='footer' direction='row' center='main-axis' style={{ marginBottom: 30 }}>
        <Flex align='end-cross-axis'>
          <Button
            colorType={'white'}
            onClick={onCancel}
            text={info.cancel}
            style={{ marginRight: 60, width: 150 }}
          />
        </Flex>
        <Flex align='start-cross-axis'>
          <Button
            colorType={'black'}
            onClick={handleSubmit(onSubmit)}
            text={type === ClientViewType.create ? info.add : info.save}
            style={{ width: 150 }}
          />
        </Flex>
      </Flex>
    );
  };

  return (
    <div
      onKeyPress={(e) => {
        if (e.code === 'Enter' && visible) {
          handleSubmit(onSubmit)();
        }
      }}
    >
      <Modal
        title={type === ClientViewType.create ? info.addClient : info.editClient}
        wrapClassName='modalContent'
        onCancel={onCancel}
        visible={visible}
        centered={true}
        footer={footer()}
        width={500}
      >
        {content()}
      </Modal>
    </div>
  );
};

export default AddEditClient;
