import { ReportDataType, ClientsType } from '../../../types/main';
const borderStyle = '1px solid #B4B4B4';
const rowHeight = 25;
const borderHeight = 1;

export const getClientStyle = (
  rowData: ReportDataType,
  clientData: ClientsType,
  index: number
): React.CSSProperties => {
  const style: React.CSSProperties = {};
  style.height = clientData.tasks.length * rowHeight + borderHeight;
  if (rowData.clients.length - 1 !== index) {
    style.borderBottom = borderStyle;
  }
  return style;
};

export const getNestedRowStyle = (dataLength: number, index: number): React.CSSProperties => {
  const style: React.CSSProperties = {};
  const isLast = dataLength === 1 || (dataLength > 1 && dataLength - 1 === index);
  if (!isLast) {
    style.borderBottom = borderStyle;
  }
  return style;
};

export const getRowStyle = (
  parentLength: number,
  childeLength: number,
  index: number
): React.CSSProperties => {
  const style: React.CSSProperties = {};
  style.height = childeLength * rowHeight + borderHeight;
  if (parentLength - 1 !== index) {
    style.borderBottom = borderStyle;
  }
  return style;
};

export const getRoundedTotal = (total: number): string => {
  const int = Math.trunc(total);
  const rest = total - int;

  if (rest > 0.75) {
    return (int + 1).toFixed(2);
  }
  if (rest > 0.5) {
    return (int + 0.75).toFixed(2);
  }
  if (rest > 0.25) {
    return (int + 0.5).toFixed(2);
  }
  if (rest > 0) {
    return (int + 0.25).toFixed(2);
  }
  return total.toFixed(2);
};
