import { gql } from '@apollo/client';

export const GET_EMPLOYEES = gql`
  query getEmployees(
    $firstName: String
    $lastName: String
    $locationIds: [Int!]
    $skip: Int
    $statuses: [EmployeeStatus!]
    $take: Int
    $term: String
    $managerId: Int
    $departmentIds: [Int!]
  ) {
    employees(
      filter: {
        firstName: $firstName
        lastName: $lastName
        locationIds: $locationIds
        skip: $skip
        statuses: $statuses
        take: $take
        term: $term
        managerId: $managerId
        departmentIds: $departmentIds
      }
    ) {
      __typename
      ... on Employees {
        count
        entities {
          id
          role
          firstName
          lastName
          email
          job {
            id
            title
          }
          hourlyRates {
            id
            rate
            createdAt
            updatedAt
          }
          employmentStatus {
            id
            name
          }
          location {
            id
            name
          }
          manager {
            id
            firstName
            lastName
          }
          department {
            id
            name
          }
          currency {
            id
            name
          }
          status
        }
      }
      ... on BadRequestError {
        message
      }
      ... on AuthenticationError {
        message
      }
    }
  }
`;

export const GET_MANAGERS = gql`
  query getManagersForEmployee($term: String!, $id: Int!) {
    getManagersForEmployee(term: $term, id: $id) {
      __typename
      ... on Employees {
        count
        entities {
          id
          firstName
          lastName
          status
        }
      }
      ... on BadRequestError {
        message
      }
      ... on AuthenticationError {
        message
      }
    }
  }
`;
