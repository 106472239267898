import produce from 'immer';
import { UserInfoDataType } from '../../types/main';

const ADD_USER_INFO = 'ADD_USER_INFO';

export interface IUserReducerState {
  userInfo: UserInfoDataType;
}

export function addUserInfo(data: UserInfoDataType): { type: string; data: UserInfoDataType } {
  return {
    type: ADD_USER_INFO,
    data
  };
}

export const initialState: IUserReducerState = {
  userInfo: {} as UserInfoDataType
};

type ReducerAction = {
  type: 'ADD_USER_INFO';
  data: UserInfoDataType;
};

const userReducer = (state = initialState, action: ReducerAction): IUserReducerState => {
  return produce(state, (draft: IUserReducerState) => {
    switch (action.type) {
      case ADD_USER_INFO:
        draft.userInfo = action.data;
    }
  });
};

export default userReducer;
