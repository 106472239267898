import React from 'react';
import classNames from 'classnames';
import './Button.styles.scss';
import { Button, ButtonProps } from 'antd';

interface IProps extends ButtonProps {
  colorType?: 'black' | 'white' | 'pink' | 'gray';
  text?: string;
}

const CButton: React.FC<IProps> = (props) => {
  const { type, className, text, shape, colorType, size, ...otherProps } = props;
  const btnClassName = classNames(colorType, className);

  return (
    <Button
      type={type || 'primary'}
      shape={shape || 'round'}
      size={size || 'large'}
      className={btnClassName}
      {...otherProps}
    >
      {text}
    </Button>
  );
};

export default CButton;
