import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { SearchOutlined } from '@ant-design/icons';

import { genericStatus } from '../../../constants/employee';
import { Flex, Label, Select, Button, ControllerInput } from '../../../components/core';
import { initialIndex } from '../../../utils/constants';
import { info } from '../../../resources/strings';

type FormData = {
  name?: string;
  status?: string;
};

interface IProps {
  reFetchEmployees: (data) => void;
}

const FilterSection: React.FC<IProps> = ({ reFetchEmployees }) => {
  const { control, handleSubmit, reset, getValues } = useForm<FormData>();

  const onSubmit = (fieldData: FormData) => {
    const variables = { term: fieldData.name, status: fieldData.status, skip: initialIndex };
    reFetchEmployees(variables);
  };

  const onClear = () => {
    reset();
    reFetchEmployees({
      term: getValues('name'),
      status: getValues('status')
    });
  };

  return (
    <Flex>
      <Flex direction='row' center='cross-axis' style={{ marginTop: 30 }}>
        <ControllerInput labelName={info.clientName} controlName='name' control={control} />
        <Flex style={{ marginRight: 20 }}>
          <Label type='small'>{info.status}</Label>
          <Controller
            name='status'
            control={control}
            render={({ field: { ref, ...otherFieldProps } }) => (
              <Select
                data={genericStatus}
                style={{ width: 200 }}
                dataText='dataText'
                dataValue='dataValue'
                allowClear
                {...otherFieldProps}
              />
            )}
          />
        </Flex>
        <Flex direction='row' style={{ marginRight: 20, marginTop: 10 }}>
          <Button
            style={{ marginRight: 10 }}
            colorType={'white'}
            onClick={onClear}
            text={'Clear X'}
          />
          <Button
            colorType={'black'}
            onClick={handleSubmit(onSubmit)}
            shape='circle'
            icon={<SearchOutlined />}
            style={{ borderRadius: '4px' }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FilterSection;
