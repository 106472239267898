import React, { useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from '@apollo/client';

import { Flex, Button, Label, Input, Spiner } from '../../components/core';
import { MessageDuration } from '../../utils/constants';
import { ValidationsType, RequestTypeName, VerificationPaths } from '../../types/main';
import { Tooltip, message } from 'antd';
import { ACTIVATE_EMPLOYEE, SET_NEW_PASSWORD } from '../../ApolloClient/mutations/userAction';
import { RegExpPassword } from '../../utils/constants';
import { errors as errorMessages, info } from '../../resources/strings';
import logo from '../../icons/Logos/Logo_black.svg';

import './LoginPage.styles.scss';

type FormData = {
  password: string;
  confirmPassword: string;
};

const SetPassword: React.FC = () => {
  const [activateEmployee, { loading }] = useMutation(ACTIVATE_EMPLOYEE);
  const [setNewPassword] = useMutation(SET_NEW_PASSWORD);
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch
  } = useForm<FormData>();
  const password = useRef({});
  password.current = watch('password', '');

  const history = useHistory();
  const { search } = useLocation();
  const url = new URLSearchParams(search);

  const activate = (variables) => {
    activateEmployee({ variables })
      .then((result) => {
        if (result?.data?.activatePendingEmployee.__typename === RequestTypeName.badRequest) {
          message.error(result.data.activatePendingEmployee.message, MessageDuration);
          return;
        }
        if (result?.data?.activatePendingEmployee.__typename === RequestTypeName.employee) {
          message.success(info.activateEmployee, MessageDuration);
          history.push('/login');
          return;
        }
      })
      .catch((e) => {
        if (e) {
          message.error(e.message, MessageDuration);
        }
      });
  };

  const setNewPass = (variables) => {
    setNewPassword({ variables })
      .then((result) => {
        if (result?.data?.resetPassword.__typename === RequestTypeName.badRequest) {
          message.error(result.data.resetPassword.message, MessageDuration);
          return;
        }
        if (result?.data?.resetPassword.__typename === RequestTypeName.Success) {
          history.push('/login');
          return;
        }
      })
      .catch((e) => {
        if (e) {
          message.error(e.message, MessageDuration);
        }
      });
  };

  function getTitle(type: string | undefined) {
    if (type === ValidationsType.required) {
      return `${errorMessages.requiredField}`;
    }
    if (type === ValidationsType.pattern) {
      return `${errorMessages.patternTooltip}`;
    }
    if (type === ValidationsType.validate) {
      return `${errorMessages.passwordNotMatch}`;
    }
  }

  const onSubmit = (formData: FormData) => {
    if (history.location.pathname.includes(VerificationPaths.verify)) {
      const variables = {
        password: formData.password,
        employeeId: Number(url.get('eid')),
        activationToken: url.get('token')
      };
      activate(variables);
    } else {
      const variables = {
        password: formData.password,
        employeeId: Number(url.get('eid')),
        token: url.get('token')
      };
      setNewPass(variables);
    }
  };

  return (
    <Flex className='login_page' center='cross-axis'>
      <Flex direction='column' center='cross-axis'>
        <Flex className='logo_content' space='around' center='cross-axis'>
          <div className='logo'>
            <img src={logo} alt='funday'></img>
          </div>
        </Flex>
        <div className='timesheets'>{info.timesheets}</div>
        <h4 style={{ color: '#fff' }}>{info.setPasswordForLogin}</h4>
        <Flex align='start-cross-axis'>
          <Flex style={{ marginTop: 30, width: 350 }}>
            <Label mb={5} type='small'>
              {'Password'}
            </Label>
            <Controller
              name={'password'}
              control={control}
              rules={{
                required: true,
                pattern: RegExpPassword
              }}
              render={({ field: { ref, ...otherFieldProps } }) => (
                <Tooltip
                  placement='bottomRight'
                  overlayClassName={'tooltip_content'}
                  color={'#fff'}
                  overlayInnerStyle={{ color: '#000' }}
                  title={errorMessages.patternTooltip}
                  visible={!!errors.password}
                >
                  <Input
                    colorType={'black'}
                    type={'password'}
                    inputRef={ref}
                    maxLength={64}
                    withError={!!errors.password}
                    onPressEnter={handleSubmit(onSubmit)}
                    {...otherFieldProps}
                  />
                </Tooltip>
              )}
            />
            <Label mt={5}>{errorMessages.passwordPattern}</Label>
          </Flex>
          <Flex style={{ marginTop: 30, width: 350 }}>
            <Label mt={40} mb={5} type='small'>
              {info.confirmPassword}
            </Label>
            <Controller
              name={'confirmPassword'}
              control={control}
              rules={{
                required: true,
                validate: (value) => value === password.current
              }}
              render={({ field: { ref, ...otherFieldProps } }) => (
                <Tooltip
                  placement='bottomRight'
                  overlayClassName={'tooltip_content'}
                  color={'#fff'}
                  overlayInnerStyle={{ color: '#000' }}
                  title={() => getTitle(errors.confirmPassword?.type)}
                  visible={!!errors.confirmPassword}
                >
                  <Input
                    colorType={'black'}
                    type={'password'}
                    inputRef={ref}
                    maxLength={64}
                    withError={!!errors.confirmPassword}
                    onPressEnter={handleSubmit(onSubmit)}
                    {...otherFieldProps}
                  />
                </Tooltip>
              )}
            />
          </Flex>
        </Flex>
        <Button
          style={{ width: 150, marginTop: 80 }}
          onClick={handleSubmit(onSubmit)}
          text={info.setPassword}
          colorType={'pink'}
        />
      </Flex>
      {loading && <Spiner />}
    </Flex>
  );
};

export default SetPassword;
