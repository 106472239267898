import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Moment } from 'moment';
import { useForm, Controller } from 'react-hook-form';

import { RootState } from '../../../redux/reducers/index';
import { UpdateRateReportFilters, updateRateReportFilters } from '../../../redux/reducers/Reports';
import { Flex, Button, Label, Input } from '../../../components/core';
import DateRangePicker from '../../../components/DateRangePicker';
import DebouncedList from '../../../components/DynamicSelect';
import {
  useClientsQuery,
  EmployeeReportQueryVariables,
  useLocationsQuery,
  useJobsQuery,
  useDepartmentsQuery,
  useEmploymentStatusesQuery
} from '../../../graphql/graphql';
import { info } from '../../../resources/strings';
import { maxTakeCount } from '../../../utils/constants';

const dateFormat = 'YYYY-MM-DD';

type FormData = {
  dateRange: any[];
  conversionRate: number;
  clientIds?: number[];
  employmentStatusIds?: number[];
  locationIds?: number[];
  departmentIds?: number[];
  jobIds?: number[];
};

interface IProps {
  getReport: (data: { variables: EmployeeReportQueryVariables }) => void;
}

const FilterSection: FC<IProps> = ({ getReport }) => {
  const dispatch = useDispatch();
  const reportData = useSelector((state: RootState) => state.reports.rateReport);
  const { control, handleSubmit, setValue, getValues } = useForm<FormData>({
    defaultValues: {
      dateRange: reportData.filters.dateRange,
      conversionRate: reportData.filters.conversionRate,
      jobIds: reportData.filters.jobIds,
      locationIds: reportData.filters.locationIds,
      departmentIds: reportData.filters.departmentIds,
      employmentStatusIds: reportData.filters.employmentStatusIds,
      clientIds: reportData.filters.clientIds
    }
  });

  const updateFilters = (filtersData: UpdateRateReportFilters) => {
    dispatch(updateRateReportFilters(filtersData));
  };

  const onSubmit = (fieldData: FormData) => {
    const [startDate, endDate] = fieldData.dateRange;

    const variables = {
      startDate: startDate.format(dateFormat),
      endDate: endDate.format(dateFormat),
      clientIds: fieldData.clientIds,
      locationIds: fieldData?.locationIds,
      departmentIds: fieldData?.departmentIds,
      employmentStatusIds: fieldData?.employmentStatusIds,
      jobIds: fieldData?.jobIds
    };
    updateFilters({
      dateRange: [startDate, endDate],
      clientIds: fieldData?.clientIds || [],
      locationIds: fieldData?.locationIds || [],
      departmentIds: fieldData?.departmentIds || [],
      employmentStatusIds: fieldData?.employmentStatusIds || [],
      jobIds: fieldData?.jobIds || [],
      conversionRate: fieldData.conversionRate
    });
    getReport({ variables });
  };

  const onClear = () => {
    setValue('clientIds', []);
    setValue('locationIds', []);
    setValue('departmentIds', []);
    setValue('employmentStatusIds', []);
    setValue('jobIds', []);
    const [start, end] = getValues('dateRange');
    const variables = {
      startDate: start.format(dateFormat),
      endDate: end.format(dateFormat),
      clientIds: [],
      locationIds: [],
      departmentIds: [],
      employmentStatusIds: [],
      jobIds: []
    };
    updateFilters({
      dateRange: [start, end],
      clientIds: [],
      locationIds: [],
      departmentIds: [],
      employmentStatusIds: [],
      jobIds: [],
      conversionRate: getValues('conversionRate')
    });
    getReport({ variables });
  };

  return (
    <Flex wrap={true}>
      <Flex direction='column'>
        <Flex
          wrap
          direction='row'
          space='between'
          align='end-cross-axis'
          style={{ marginBottom: 10 }}
        >
          <Flex style={{ width: 250 }}>
            <Controller
              control={control}
              name='dateRange'
              render={({ field }) => (
                <DateRangePicker
                  onDateChange={(date, dateToString) => field.onChange(date, dateToString)}
                  value={[...field.value] as [Moment, Moment]}
                  style={{ marginTop: 2 }}
                  className='dateLabel'
                  customClassName
                  bordered={false}
                />
              )}
            />
          </Flex>
          <Flex direction='column' style={{ width: 250 }}>
            <Label type='small'>USD to CAD Conversion Rate</Label>
            <Controller
              name='conversionRate'
              control={control}
              render={({ field: { ref, ...otherFieldProps } }) => (
                <Input
                  colorType='primary'
                  inputRef={ref}
                  type='number'
                  min={0}
                  autoComplete='off'
                  {...otherFieldProps}
                  onBlur={(e) => otherFieldProps.onChange(Number(e.target.value).toFixed(2))}
                />
              )}
            />
          </Flex>
          <Flex direction='column'>
            <DebouncedList
              control={control}
              mode='multiple'
              maxTagCount='responsive'
              controllerName='jobIds'
              labelText={info.jobTitle}
              dataText='title'
              dataValue='id'
              queryDataKey='jobs'
              query={useJobsQuery}
              otherVarebales={{ take: maxTakeCount }}
              style={{ width: 250 }}
            />
          </Flex>
          <Flex>
            <DebouncedList
              control={control}
              mode='multiple'
              maxTagCount='responsive'
              controllerName='locationIds'
              labelText={info.location}
              dataText='name'
              dataValue='id'
              queryDataKey='locations'
              query={useLocationsQuery}
              otherVarebales={{ take: maxTakeCount }}
              style={{ width: 250 }}
            />
          </Flex>
        </Flex>
        <Flex wrap direction='row' space='between' align='end-cross-axis'>
          <Flex>
            <DebouncedList
              control={control}
              mode='multiple'
              maxTagCount='responsive'
              controllerName='employmentStatusIds'
              labelText={info.employmentStatus}
              dataText='name'
              dataValue='id'
              queryDataKey='employmentStatuses'
              query={useEmploymentStatusesQuery}
              otherVarebales={{ take: maxTakeCount }}
              style={{ width: 250 }}
            />
          </Flex>
          <Flex>
            <DebouncedList
              control={control}
              mode='multiple'
              maxTagCount='responsive'
              controllerName='departmentIds'
              labelText={info.department}
              dataText='name'
              dataValue='id'
              queryDataKey='departments'
              query={useDepartmentsQuery}
              otherVarebales={{ take: maxTakeCount }}
              style={{ width: 250 }}
            />
          </Flex>
          <Flex>
            <DebouncedList
              control={control}
              mode='multiple'
              maxTagCount='responsive'
              controllerName='clientIds'
              labelText={info.client}
              dataText='name'
              dataValue='id'
              queryDataKey='clients'
              query={useClientsQuery}
              otherVarebales={{ take: maxTakeCount }}
              style={{ width: 250 }}
            />
          </Flex>
          <Flex direction='row' space='between' style={{ width: 250 }}>
            <Button colorType='white' onClick={onClear} text={info.clearX} size='middle' />
            <Button
              size='middle'
              colorType='black'
              onClick={handleSubmit(onSubmit)}
              text={info.generateReport}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FilterSection;
