import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Flex, Spiner } from '../../../components/core';
import FilterSection from './FilterSection';
import { useEmployeePastTimeSheetLazyQuery } from '../../../graphql/graphql';
import { RootState } from '../../../redux/reducers';
import { addTimeSheetReport } from '../../../redux/reducers/Reports';
import ReportTimeSheet from './ReportTimeSheet';
import { Steps } from '../../../types/main';
import EditTimeSheet from './EditTimeSheet';
import { setSkipped } from '../../../redux/reducers/TimeSheetLookup';
import { info } from '../../../resources/strings';

import './Report.scss';

const TimeSheetLookup: FC = () => {
  const dispatch = useDispatch();
  const { step } = useSelector((state: RootState) => state.timeSheetLookup);
  const timesheetReport = useSelector((state: RootState) => state.reports.timesheetReport);
  const [getEmployeePastTimeSheet, { data, loading }] = useEmployeePastTimeSheetLazyQuery({
    fetchPolicy: 'no-cache'
  });
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    if (data?.employeePastTimeSheet.__typename === 'TimeSheet') {
      dispatch(addTimeSheetReport(data.employeePastTimeSheet));
      setUpdating(false);
      dispatch(setSkipped(data.employeePastTimeSheet.totalHours === 0));
    } else if (data?.employeePastTimeSheet.__typename) {
      dispatch(addTimeSheetReport(null));
      setUpdating(false);
    }
  }, [data, dispatch]);

  return (
    <Flex className='site-layout-background projectHours'>
      {step === Steps.Step1 && (
        <>
          <Flex>{info.selectTimeFrameAndEmployee}</Flex>
          <FilterSection getReport={getEmployeePastTimeSheet} />
        </>
      )}
      {timesheetReport.fetched && timesheetReport.data && !updating && (
        <>
          {step === Steps.Step1 && (
            <Flex center='both-axis' style={{ marginTop: 80 }}>
              <ReportTimeSheet data={timesheetReport.data} />
            </Flex>
          )}
          {step === Steps.Step2 && (
            <Flex center='both-axis'>
              <EditTimeSheet
                data={timesheetReport.data}
                getReport={getEmployeePastTimeSheet}
                setUpdating={setUpdating}
              />
            </Flex>
          )}
        </>
      )}
      {(loading || updating) && <Spiner />}
    </Flex>
  );
};

export default TimeSheetLookup;
