import React from 'react';
import { Layout } from 'antd';

import NavBar from '../../components/Navbar';

import './MainLayout.styles.scss';

interface IMainLayoutProps {
  children: JSX.Element;
}

const MainLayout: React.FC<IMainLayoutProps> = ({ children }) => {
  return (
    <Layout>
      <NavBar />
      <Layout.Content>{children}</Layout.Content>
    </Layout>
  );
};

export default MainLayout;
