import { FC, useState } from 'react';
import { DeleteFilled } from '@ant-design/icons';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useClientsQuery } from '../../graphql/graphql';
import { addTask, deleteTask, editTask } from '../../redux/reducers/TimeSheetLookup';
import { ClientLookup, TaskLookup } from '../../types/main';
import { Button, Flex, Label } from '../core';
import DebouncedList from '../DynamicSelect';
import Task from './Task';
import './Card.styles.scss';

interface IProps {
  item: ClientLookup;
  itemIndex: number;
  onDelete: (index: number) => void;
  onEdit: (index: number, data: ClientLookup) => void;
  optionDisable?: (data: any) => boolean;
  prevClients: ClientLookup[];
}

const TimeSheetCard: FC<IProps> = ({
  item,
  itemIndex,
  onDelete,
  onEdit,
  optionDisable,
  prevClients
}) => {
  const dispatch = useDispatch();
  const { control } = useForm<{ id: number }>();
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
  const { data: clientsData } = useClientsQuery({
    onCompleted: void 0,
    fetchPolicy: 'network-only'
  });

  const clients = clientsData?.clients.__typename === 'Clients' ? clientsData.clients.entities : [];

  function deleteItem() {
    onDelete(item.UID);
  }

  function editItem(data: ClientLookup) {
    onEdit(itemIndex, data);
  }

  function onClientChange(data: any) {
    const client = clients.find((c) => c.id === data.value);
    editItem({
      ...item,
      id: data?.value,
      name: data?.label,
      status: client?.status
    });
    setIsSubmitSuccessful(true);
  }

  function addNewTask(id: number) {
    dispatch(addTask(id));
  }

  function removeTask(index: number) {
    dispatch(deleteTask({ clientId: item.id, index }));
  }

  function editTaskData(index: number, taskData: TaskLookup) {
    dispatch(editTask({ clientId: item.id, index, taskData }));
  }

  function isOptionDisabled(tk: TaskLookup) {
    return item.tasks.map((task) => task.id).includes(tk.id as number);
  }

  const getTasks = () => {
    return item.tasks.map((tk, index) => (
      <Task
        key={tk.UID}
        item={tk}
        itemIndex={index}
        clientId={item.id}
        onDelete={removeTask}
        onEdit={editTaskData}
        optionDisable={isOptionDisabled}
        isSubmitSuccessful={isSubmitSuccessful}
      />
    ));
  };

  const defaultValueId = { value: item.id > 0 ? item.id : '', label: item.id > 0 ? item.name : '' };
  return (
    <Flex direction='row' center='cross-axis'>
      <Flex
        className={'app-item'}
        direction='row'
        style={{ width: '100%' }}
        center='main-axis'
        space='between'
        wrap={true}
      >
        <Flex direction='row' style={{ width: '50%' }} space='around'>
          <Flex direction='column'>
            {prevClients.find((c) => c.id === item.id) ? (
              <Flex style={{ width: 200 }}>
                <Label type='small' mb={5}>
                  Client
                </Label>
                <h5 className='client-name clientName'>
                  {prevClients.find((c) => c.id === item.id)?.name}
                </h5>
              </Flex>
            ) : (
              <Flex>
                <DebouncedList
                  control={control}
                  style={{ width: 200 }}
                  controllerName='id'
                  dataText='name'
                  dataValue='id'
                  queryDataKey='clients'
                  labelText='Client'
                  labelInValue
                  defaultValueId={defaultValueId}
                  query={useClientsQuery}
                  onChange={onClientChange}
                  optionDisable={optionDisable}
                  fetchPolicy={'cache-and-network'}
                  allowClear={false}
                />
              </Flex>
            )}
          </Flex>
          <Flex direction='column' align='start-cross-axis' style={{ width: 104 }}>
            <Label mb={5} type='small'>
              Client Total Hours
            </Label>
            <h3 className='clientTotalHours'>{item.hours}</h3>
          </Flex>
        </Flex>
        <Flex
          direction='column'
          style={{ width: '50%' }}
          center={getTasks().length === 0 ? 'both-axis' : undefined}
        >
          {getTasks()}
          <Button
            style={getTasks().length > 0 ? { width: 200, marginLeft: 10 } : { width: 200 }}
            onClick={() => addNewTask(item.id)}
            colorType={'gray'}
            text={'Add Task +'}
          />
        </Flex>
      </Flex>
      <Flex style={{ marginLeft: 15 }}>
        <DeleteFilled style={{ color: '#C4C4C4' }} onClick={deleteItem} title={'Remove Client'} />
      </Flex>
    </Flex>
  );
};

export default TimeSheetCard;
