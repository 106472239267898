import React from 'react';
import { Table } from 'antd';

import ActionCell from './ActionCell';
import { TaskDataType } from '../../../types/main';
import { info } from '../../../resources/strings';

interface IProps {
  data: TaskDataType[];
  reFetchTasks: () => void;
}

const TasksTable: React.FC<IProps> = ({ data, reFetchTasks }) => {
  const columns = [
    {
      key: 'summary',
      title: info.taskSummary,
      dataIndex: 'summary'
    },
    {
      key: 'status',
      title: info.status,
      width: '150px',
      render: (rowdata: TaskDataType) => {
        return <span className={`employee ${rowdata.status}`}>{rowdata.status}</span>;
      }
    },
    {
      key: 'action',
      width: '50px',
      render: (rowdata: TaskDataType) => (
        <ActionCell rowdata={rowdata} reFetchTasks={reFetchTasks} />
      )
    }
  ];

  return (
    <Table
      id='tasksTable'
      rowKey='id'
      size='small'
      bordered
      dataSource={data}
      columns={columns}
      pagination={{ hideOnSinglePage: true, pageSize: 15 }}
      scroll={{ y: 400 }}
    />
  );
};

export default TasksTable;
