import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { SearchOutlined } from '@ant-design/icons';

import { genericStatus } from '../../../constants/employee';
import { Flex, Label, Select, Button, ControllerInput } from '../../../components/core';
import { initialIndex } from '../../../utils/constants';
import { info } from '../../../resources/strings';

export type FormData = {
  summary?: string;
  status?: string;
};

interface IProps {
  reFetchTasks: (data) => void;
  clientId: number;
  setFilters: (data: FormData) => void;
  filters: FormData;
}

const FilterSection: React.FC<IProps> = ({ reFetchTasks, clientId, setFilters, filters }) => {
  const { control, handleSubmit, reset, getValues } = useForm<FormData>({ defaultValues: filters });

  const onSubmit = (fieldData: FormData) => {
    setFilters(fieldData);
    const variables = { ...fieldData, skip: initialIndex };
    reFetchTasks(variables);
  };

  const onClear = () => {
    setFilters({ summary: '', status: '' });
    reset();
    reFetchTasks({
      summary: getValues('summary'),
      status: getValues('status'),
      clientId
    });
  };

  return (
    <Flex>
      <Flex direction='row' center='cross-axis' style={{ marginTop: 30 }}>
        <ControllerInput labelName={info.taskSummary} controlName='summary' control={control} />
        <Flex style={{ marginRight: 20 }}>
          <Label type='small'>{info.status}</Label>
          <Controller
            name='status'
            control={control}
            render={({ field: { ref, ...otherFieldProps } }) => (
              <Select
                data={genericStatus}
                style={{ width: 200 }}
                dataText='dataText'
                dataValue='dataValue'
                allowClear
                {...otherFieldProps}
              />
            )}
          />
        </Flex>
        <Flex direction='row' style={{ marginRight: 20, marginTop: 10 }}>
          <Button
            style={{ marginRight: 10 }}
            colorType={'white'}
            onClick={onClear}
            text={info.clearX}
          />
          <Button
            colorType={'black'}
            onClick={handleSubmit(onSubmit)}
            shape='circle'
            icon={<SearchOutlined />}
            style={{ borderRadius: '4px' }}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FilterSection;
