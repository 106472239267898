import { FC } from 'react';
import moment, { Moment } from 'moment';
import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/en_GB';

import { Button, Flex, Label } from '../../components/core';
import { info } from '../../resources/strings';
import { customWeekStartEndFormat } from '../../utils/helpers';

import 'moment/locale/en-gb';
import './Previous.styles.scss';
import { customFormatStartEnd } from './Step2';

interface IProps {
  currentStep: number;
  setStep: (step: number) => void;
  selectedDays: Moment | null;
  setSelectedDays: (days: Moment | null) => void;
  getPrevData: (data) => void;
}

const Step1: FC<IProps> = ({ setSelectedDays, selectedDays, getPrevData }) => {
  const { startDate, endDate } = customFormatStartEnd(selectedDays);
  const onChange = (date: Moment | null) => {
    if (date) {
      setSelectedDays(date);
    } else {
      setSelectedDays(moment());
    }
  };

  const handleClick = () => {
    getPrevData({ variables: { startDate, endDate } });
  };

  return (
    <Flex className='content welcomePage' center='both-axis'>
      <div className='wrapper start'>
        <Flex center='cross-axis'>
          <h1>{info.welcomeFunTimes}</h1>
          <Label>{info.selectTimesheet}</Label>
          <div className='date-picker'>
            <DatePicker
              defaultValue={moment()}
              format={customWeekStartEndFormat}
              picker='week'
              onChange={onChange}
              value={moment(selectedDays)}
              locale={locale}
            />
          </div>
          <Flex style={{ width: 200, margin: 40 }}>
            <Button
              colorType={'black'}
              onClick={handleClick}
              text={info.viewTimesheets}
            />
          </Flex>
        </Flex>
      </div>
    </Flex>
  );
};

export default Step1;
