import React from 'react';
import { message, Popover, Switch } from 'antd';

import { TaskDataType } from '../../../types/main';
import { Flex, Button, Label } from '../../../components/core';
import ActionDialog from './ActionDialog';
import {
  useRemoveTaskMutation,
  useUpdateTaskStatusMutation,
  GenericStatus
} from '../../../graphql/graphql';
import { RequestTypeName, TaskViewType, DialogType } from '../../../types/main';
import { DeleteIcon, EditIcon, BurgerIcon } from '../../../icons';
import { MessageDuration } from '../../../utils/constants';
import AddEditTask from './AddEditTaskPopup';
import { info } from '../../../resources/strings';

const statusMapper = {
  Active: {
    text: 'Deactivate',
    className: 'Inactive'
  },
  Inactive: {
    text: 'Activate',
    className: 'Active'
  }
};

interface IProps {
  rowdata: TaskDataType;
  reFetchTasks: () => void;
}

const ActionCell: React.FC<IProps> = ({ rowdata, reFetchTasks }) => {
  const [removeTask] = useRemoveTaskMutation();
  const [updateTaskStatus] = useUpdateTaskStatusMutation();
  const [popupVisible, setPopupVisible] = React.useState(false);
  const [dialogType, setDialogType] = React.useState('');
  const [visible, setVisible] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);

  function handleRemoveTask() {
    removeTask({ variables: { id: rowdata.id } })
      .then((result) => {
        setVisible(false);
        reFetchTasks();
        if (result?.data?.removeTask.__typename === RequestTypeName.task) {
          message.success(info.taskDeleted, MessageDuration);
          return;
        }
      })
      .catch((e) => {
        if (e) {
          message.error(e.message, MessageDuration);
        }
      });
  }

  function handleStatusChange() {
    const editedStatus =
      rowdata.status === GenericStatus.Active ? GenericStatus.Inactive : GenericStatus.Active;
    updateTaskStatus({ variables: { id: rowdata.id, status: editedStatus } })
      .then((result) => {
        if (result?.data?.updateTaskStatus.__typename === RequestTypeName.task) {
          message.success(info.employeeStatusChange, MessageDuration);
          return;
        }
      })
      .catch((e) => {
        if (e) {
          message.error(e.message, MessageDuration);
        }
      });
    setVisible(false);
  }

  function handleAction(type: string) {
    setVisible(true);
    setDialogType(type);
  }

  const getPopoverContent = () => {
    return (
      <Flex style={{ width: 150 }}>
        <Flex direction='row' align='start-main-axis'>
          <div
            className='actionRow'
            onClick={() => {
              setEditMode(true);
              setPopupVisible(false);
            }}
          >
            <Button type='text' icon={<EditIcon />} shape='circle' size='small' />
            <Label mt={-2} ml={10}>
              {info.edit}
            </Label>
          </div>
        </Flex>
        <Flex direction='row' align='start-main-axis'>
          <div
            className='actionRow'
            onClick={() => {
              handleAction(DialogType.delete);
              setPopupVisible(false);
            }}
          >
            <Button type='text' icon={<DeleteIcon />} shape='circle' size='small' />
            <Label mt={-2} ml={10}>
              {info.delete}
            </Label>
          </div>
        </Flex>
        <Flex direction='row' className='statusContent'>
          <div
            className='actionRow'
            onClick={() => {
              handleAction(DialogType.stausChange);
              setPopupVisible(false);
            }}
          >
            <Switch checked={false} />
            <span className={`task ${statusMapper[rowdata.status].className}`}>
              {statusMapper[rowdata.status].text}
            </span>
          </div>
        </Flex>
      </Flex>
    );
  };

  const confirm = (type: string) => {
    switch (type) {
      case DialogType.delete:
        return handleRemoveTask();
      case DialogType.stausChange:
        return handleStatusChange();
    }
  };

  return (
    <Flex direction='row' space='between' style={{ marginTop: -5 }}>
      <div onClick={(e) => e.stopPropagation()}>
        <Popover
          visible={popupVisible}
          placement='bottomRight'
          content={getPopoverContent}
          trigger='click'
          onVisibleChange={() => setPopupVisible(!popupVisible)}
        >
          <Button
            type='text'
            icon={<BurgerIcon />}
            shape='circle'
            size='large'
            onClick={() => setPopupVisible(!popupVisible)}
          />
        </Popover>
        <ActionDialog
          visible={visible}
          type={dialogType}
          data={rowdata}
          confirmAction={() => confirm(dialogType)}
          rejectAction={() => setVisible(false)}
        />
        {editMode && <AddEditTask
          type={TaskViewType.update}
          onCancel={() => setEditMode(false)}
          visible={true}
          reFetchTasks={reFetchTasks}
          clientId={rowdata.client.id}
          defaultValues={{ ...rowdata }}
        />}
      </div>
    </Flex>
  );
};

export default ActionCell;
