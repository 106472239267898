import React from 'react';
import debounce from 'lodash/debounce';
import { Controller } from 'react-hook-form';

import { Flex, Label, Select } from '../../../components/core';
import { EmployeeStatus, useManagersQuery } from '../../../graphql/graphql';
import { dataCounts, debounceTimeout, initialIndex } from '../../../utils/constants';
import { info } from '../../../resources/strings';
import { ManagerType } from './EditManagersList';

interface IProps {
  control: any;
  width?: string | number;
}

const ManagersList: React.FC<IProps> = ({ control, width = 250 }) => {
  const [managers, setManagers] = React.useState<ManagerType[]>([]);
  const [skip, setSkip] = React.useState(0);
  const { data, refetch } = useManagersQuery({ variables: { term: '', skip } });
  const filteredManagers = managers.filter((m) => m.status === 'Active');
  filteredManagers.unshift({ fullName: ' ', id: 0 } as ManagerType);

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (term: string) => {
      setSkip(initialIndex);
      refetch({ term, skip: initialIndex });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [refetch]);

  const onScrollDebounceFetcher = React.useMemo(() => {
    const loadOptions = (e) => {
      const current = e.target;
      const isScrollEnds = current.offsetHeight + current.scrollTop >= current.scrollHeight;
      if (
        data?.managers.__typename === 'Employees' &&
        isScrollEnds &&
        data.managers.count > managers.length
      ) {
        setSkip(skip + dataCounts);
        refetch({ skip: skip + dataCounts });
      }
    };
    return debounce(loadOptions, debounceTimeout);
  }, [refetch, data, managers, skip]);

  React.useEffect(() => {
    if (data?.managers?.__typename === 'Employees') {
      const result = data.managers.entities;
      const newData = result?.map((item) => {
        const fullName = `${item.firstName} ${item.lastName}`;
        return { ...item, fullName };
      });
      if (!skip && !!newData) {
        setManagers(newData);
      } else {
        setManagers(managers.concat(newData));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, skip]);

  function optionDisable(item: ManagerType) {
    return item.status === EmployeeStatus.Deactivated;
  }

  return (
    <Flex>
      <Label type='small'>{info.manager}</Label>
      <Controller
        name='managerId'
        control={control}
        render={({ field: { ref, ...otherFieldProps } }) => (
          <Select
            id='managersList'
            data={filteredManagers}
            onSearch={debounceFetcher}
            onPopupScroll={onScrollDebounceFetcher}
            optionDisable={optionDisable}
            style={{ width }}
            dataText='fullName'
            dataValue='id'
            allowClear
            filterOption={false}
            showSearch
            {...otherFieldProps}
          />
        )}
      />
    </Flex>
  );
};

export default ManagersList;
