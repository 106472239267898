import React from 'react';
import IconWrapper from './IconWrapper';

interface IProps {
  fill?: string;
  size?: number;
}

const Icon: React.FC<IProps> = ({ fill = '#B4B4B4', size = 14 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.3335 12.4444C2.3335 13.3039 3.02959 14 3.88906 14H10.1113C10.9707 14 11.6668 13.3039 11.6668 12.4444V3.11108H2.3335V12.4444Z'
        fill={fill}
      />
      <path
        d='M9.72233 0.777766L8.94453 0H5.05566L4.27786 0.777766H1.55566V2.33333H12.4445V0.777766H9.72233Z'
        fill={fill}
      />
    </svg>
  );
};

const DeleteIcon: React.FC<IProps> = (props) => {
  return <IconWrapper Icon={() => <Icon {...props} />} />;
};

export default DeleteIcon;
