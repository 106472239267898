export const checkImageMaxSize = (
  file: React.FormEvent<HTMLInputElement>,
  w: number,
  h: number
): boolean => {
  const img = new Image();
  const promise = new Promise((resolve, reject) => {
    img.src = window.URL.createObjectURL(file);
    img.onload = () => resolve(img.width <= w && img.height <= h);
    img.onerror = reject;
  });
  return promise as unknown as boolean;
};
