import { Flex } from '../../components/core';
import { PreviousTimeSheetQuery } from '../../graphql/graphql';
import { info } from '../../resources/strings';
import { EmployeeTimeSheetClientsDataType } from '../../types/main';

export const columns = [
  {
    key: 'client',
    className: 'clientCell',
    title: info.clients,
    render: (rowdata: EmployeeTimeSheetClientsDataType): JSX.Element => (
      <span className='clientName'>{rowdata.name}</span>
    )
  },
  {
    key: 'totalHours',
    title: info.clientTotalHours,
    className: 'totalHoursCell',
    render: (rowdata: EmployeeTimeSheetClientsDataType): JSX.Element => (
      <span>{rowdata.totalHours}</span>
    )
  },
  {
    key: 'tasks',
    title: info.tasks,
    render: (rowdata: EmployeeTimeSheetClientsDataType): JSX.Element[] => {
      return rowdata.tasks.map((task) => {
        return (
          <div key={task.id} className='taskName'>
            {task.name}
          </div>
        );
      });
    }
  },
  {
    key: 'tasksHours',
    title: info.taskHours,
    width: '100px',
    render: (rowdata: EmployeeTimeSheetClientsDataType): JSX.Element[] => {
      return rowdata.tasks.map((task) => {
        return (
          <div className='sumHours bold' key={task.id}>
            {task.hours}
          </div>
        );
      });
    }
  }
];

export const tableFooter = (previousData: PreviousTimeSheetQuery | undefined): JSX.Element => {
  return (
    <Flex direction='row' space={'between'} style={{ width: '100%' }}>
      <div className='bold'>Total Hours</div>
      <div className='totalHours bold'>{`${
        previousData?.previousTimeSheet.__typename === 'TimeSheets'
          ? previousData?.previousTimeSheet.entities.length
            ? previousData?.previousTimeSheet.entities[0].totalHours
            : 0
          : 0
      }h`}</div>
    </Flex>
  );
};
