import React from 'react';
import IconWrapper from './IconWrapper';

interface IProps {
  fill?: string;
  size?: number;
}

const Icon: React.FC<IProps> = ({ fill = '#B4B4B4', size = 14 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.64552 2.35254L0.942732 10.0559C0.903979 10.0947 0.875998 10.1438 0.862673 10.1966L0.008893 13.6235C-0.016646 13.7266 0.0136677 13.8363 0.0889522 13.9116C0.145915 13.9686 0.223532 14.0001 0.302925 14.0001C0.327242 14.0001 0.352115 13.9971 0.376321 13.991L3.80321 13.1371C3.85662 13.1238 3.90515 13.0959 3.9439 13.0572L11.6473 5.35437L8.64552 2.35254Z'
        fill={fill}
      />
      <path
        d='M13.5559 1.30218L12.6985 0.444733C12.1254 -0.12834 11.1266 -0.127784 10.5542 0.444733L9.50391 1.49505L12.5056 4.49677L13.5559 3.44645C13.8422 3.16031 13.9999 2.77944 13.9999 2.37437C13.9999 1.9693 13.8422 1.58844 13.5559 1.30218Z'
        fill={fill}
      />
    </svg>
  );
};

const EditIcon: React.FC<IProps> = (props) => {
  return <IconWrapper Icon={() => <Icon {...props} />} />;
};

export default EditIcon;
