import React from 'react';
import IconWrapper from './IconWrapper';

interface IProps {
  fill: string;
}

const Icon: React.FC<IProps> = ({ fill }) => {
  return (
    <svg width='18' height='17' viewBox='0 0 20 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M20 4.40375V13.3125C20 14.1413 19.6708 14.9362 19.0847 15.5222C18.4987 16.1083 17.7038 16.4375 16.875 16.4375H3.125C2.2962 16.4375 1.50134 16.1083 0.915291 15.5222C0.32924 14.9362 0 14.1413 0 13.3125V4.40375L9.6825 10.1012C9.77869 10.158 9.88833 10.1879 10 10.1879C10.1117 10.1879 10.2213 10.158 10.3175 10.1012L20 4.40375ZM16.875 0.1875C17.6439 0.187379 18.3859 0.470758 18.959 0.983425C19.5321 1.49609 19.8961 2.20205 19.9813 2.96625L10 8.8375L0.0187501 2.96625C0.103948 2.20205 0.467905 1.49609 1.04099 0.983425C1.61408 0.470758 2.35607 0.187379 3.125 0.1875H16.875Z'
        fill={fill}
      />
    </svg>
  );
};

const EmailIcon: React.FC = () => {
  return <IconWrapper Icon={Icon} />;
};

export default EmailIcon;
