import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Table } from 'antd';

import { getNewClientsDataFromLastTimeSheet } from './helpers/helpers';
import { replaceTimeFrame } from '../../redux/reducers/TimeFrameReducer';
import { Flex, BackButton, Button, Spiner } from '../../components/core';
import { useUserLastTimeSheetLazyQuery } from '../../graphql/graphql';
import { ClientTimeFrame, Steps } from '../../types/main';
import { info } from '../../resources/strings';

interface IProps {
  currentStep: number;
  setStep: (step: number) => void;
  setClientStep: (step: number) => void;
  setShowSummaryBackBtn: (value: boolean) => void;
  newTotalHours: number;
  selectedDays: string[];
}

const PreviousTimeSheet: React.FC<IProps> = ({
  currentStep,
  setStep,
  setClientStep,
  setShowSummaryBackBtn,
  newTotalHours,
  selectedDays
}) => {
  const dispatch = useDispatch();
  const [getPrevData, { data: previousData, loading }] = useUserLastTimeSheetLazyQuery({
    fetchPolicy: 'network-only'
  });
  const [dataSource, setDataSource] = useState<ClientTimeFrame[]>([]);

  useEffect(() => {
    getPrevData();
  }, [getPrevData]);

  useEffect(() => {
    if (previousData?.userLastTimeSheet.__typename === 'TimeSheets') {
      if (!previousData?.userLastTimeSheet.count) {
        setStep(currentStep + 1);
      } else {
        const prevData = previousData.userLastTimeSheet;
        const newClientsData = getNewClientsDataFromLastTimeSheet(prevData, newTotalHours);
        setDataSource(newClientsData);
      }
    }
  }, [previousData, newTotalHours, currentStep, setStep]);

  function onNewClick() {
    const data = {
      splitClientHoursEvenly: true,
      totalHours: newTotalHours,
      days: selectedDays,
      clients: [],
      timesheetEditMode: true
    };
    dispatch(replaceTimeFrame(data));
    setStep(currentStep + 1);
  }

  function onEditClick() {
    const data = {
      splitClientHoursEvenly: false,
      totalHours: newTotalHours,
      days: selectedDays,
      clients: dataSource,
      timesheetEditMode: true
    };
    dispatch(replaceTimeFrame(data));
    setStep(Steps.Step7);
    setClientStep(dataSource.length - 1);
    setShowSummaryBackBtn(false);
  }

  const tableFooter = () => {
    return (
      <Flex direction='row' space={'between'} style={{ width: '100%' }}>
        <div className='bold'>Total Hours</div>
        <div className='totalHours bold'>{`${newTotalHours}h`}</div>
      </Flex>
    );
  };

  const columns = [
    {
      key: 'client',
      className: 'clientCell',
      title: info.clients,
      render: (rowdata: ClientTimeFrame) => (
        <span className={`${rowdata.status} clientName`}>{rowdata.name}</span>
      )
    },
    {
      key: 'totalHours',
      title: info.clientTotalHours,
      className: 'totalHoursCell',
      render: (rowdata: ClientTimeFrame) => <span>{rowdata.hours}</span>
    },
    {
      key: 'tasks',
      title: info.tasks,
      render: (rowdata: ClientTimeFrame) => {
        return rowdata.tasks.map((task) => {
          return (
            <div key={task.id} className={`${task.status} taskName`}>
              {task.name}
            </div>
          );
        });
      }
    },
    {
      key: 'tasksHours',
      title: info.taskHours,
      width: '100px',
      render: (rowdata: ClientTimeFrame) => {
        return rowdata.tasks.map((task) => {
          return (
            <div className='sumHours bold' key={task.id}>
              {task.hours}
            </div>
          );
        });
      }
    }
  ];

  return (
    <>
      {loading && <Spiner />}
      {!!dataSource.length && (
        <Flex direction='row' center='main-axis' className='content previousTimeSheet'>
          <Flex direction='row' style={{ width: '1000px' }}>
            <Flex direction='row'>
              <BackButton
                style={{ marginBottom: '8px' }}
                onClick={() => setStep(currentStep - 1)}
                text={info.back}
              />
            </Flex>
            <Flex direction='column' className='dataWrapper'>
              <div className='headerText'>{info.lastWeekSummary}</div>
              <Table
                id='summaryTable'
                rowKey='id'
                size='small'
                dataSource={dataSource}
                columns={columns}
                footer={tableFooter}
                pagination={{ hideOnSinglePage: true, pageSize: 20 }}
              />
              <div className='inActiveDataInfo'>{info.markedInRed}</div>
              <div className='questionText'>{info.startOnLatOrNew}</div>
              <Flex direction='row' center='main-axis'>
                <Flex align='end-cross-axis'>
                  <Button
                    colorType='white'
                    onClick={onNewClick}
                    text={info.new}
                    style={{ marginRight: 60, width: 150 }}
                  />
                </Flex>
                <Flex align='start-cross-axis'>
                  <Button
                    colorType='black'
                    onClick={onEditClick}
                    text={info.edit}
                    style={{ width: 150 }}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default PreviousTimeSheet;
