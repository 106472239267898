import React from 'react';
import classNames from 'classnames';

import styles from './Label.module.scss';

interface IProps {
  mr?: string | number;
  ml?: string | number;
  mb?: string | number;
  mt?: string | number;
  type?: 'small' | 'normal' | 'bold';
  htmlFor?: string;
}

const Label: React.FC<IProps> = ({ children, mr, ml, mt, mb, type = 'normal', htmlFor }) => {
  const labelClass = classNames(`${styles.label} ${styles[type]}`);

  return (
    <label
      htmlFor={htmlFor}
      className={labelClass}
      style={{ marginRight: mr, marginLeft: ml, marginTop: mt, marginBottom: mb }}
    >
      {children}
    </label>
  );
};

export default Label;
