import { gql } from '@apollo/client';

export const SKIP_WEEK = gql`
  mutation skipWeek($weekStart: String!) {
    skipWeek(weekStart: $weekStart) {
        __typename
      ... on TimeSheet {
        id
      }
      ... on BadRequestError {
        message
      }
      ... on AuthenticationError {
        message
      }
    }
}
`;