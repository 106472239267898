import { SearchOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import React, { ChangeEventHandler, useState } from 'react';
import CrossIcon from '../../icons/CrossIcon';
import { Input } from '../core';

interface CurdListSearchInputProps {
  onChange: (value: string) => void;
}

const CrudListSearchInput: React.FC<CurdListSearchInputProps> = (props) => {
  const [focused, setFocused] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const onSearchInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setSearchText(event.target.value);
    props.onChange(event.target.value);
  };
  const onClearAddInput = () => {
    props.onChange('');
    setSearchText('');
  };
  const searchIconStyles = classNames('crudListSearchIcon', { crudListSearchIconFocused: focused });
  const searchFieldStyles = classNames('crudListSearchField', {
    crudListSearchFieldWithClear: searchText.length > 0
  });

  return (
    <Input
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      placeholder='Search'
      value={searchText}
      onChange={onSearchInputChange}
      prefix={<SearchOutlined className={searchIconStyles} />}
      addonAfter={
        searchText.length > 0 ? (
          <div>
            <CrossIcon className='crudListClearItem' onClick={onClearAddInput} />
          </div>
        ) : (
          <div></div>
        )
      }
      className={searchFieldStyles}
    />
  );
};

export default CrudListSearchInput;
