import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';
import { Button, Flex, Spiner } from '../../../components/core';
import TimeSheetCard from '../../../components/TimeSheetCard';
import {
  EmployeePastTimeSheetQueryVariables,
  TimeSheetClientInput,
  useFindActiveClientsWithActiveTasksQuery,
  useUpdateTimeSheetMutation
} from '../../../graphql/graphql';
import { RootState } from '../../../redux/reducers';
import {
  addClient,
  deleteClient,
  editClient,
  setCurrentStep
} from '../../../redux/reducers/TimeSheetLookup';
import { info } from '../../../resources/strings';
import { ClientLookup, TimeSheetReport } from '../../../types/main';
import { maxWeekHours, minTaskHours } from '../../../utils/constants';
import { displayCustomWeekStartEnd } from '../../../utils/helpers';

interface IProps {
  data: TimeSheetReport;
  getReport: (data: { variables: EmployeePastTimeSheetQueryVariables }) => void;
  setUpdating: (updating: boolean) => void;
}

const EditTimeSheet: FC<IProps> = ({ data, getReport, setUpdating }) => {
  const dispatch = useDispatch();
  const reportData = useSelector((state: RootState) => state.reports.timesheetReport);
  const { dateRange: datePickerRange } = useSelector(
    (state: RootState) => state.reports.timesheetReport
  );
  const { selectedEmployeeName } = useSelector((state: RootState) => state.reports.timesheetReport);
  const { clients, step, dates, id } = useSelector((state: RootState) => state.timeSheetLookup);
  const [prevClients, setPrevClients] = useState<ClientLookup[]>([]);
  const [isFilled, setIsFilled] = useState(true);
  const [updateTimeSheet, { loading }] = useUpdateTimeSheetMutation();
  const { data: allClientTasks, loading: allClientTasksLoading } =
    useFindActiveClientsWithActiveTasksQuery({ fetchPolicy: 'network-only' });

  window.onpopstate = () => {
    dispatch(setCurrentStep(1));
  };

  useEffect(() => {
    setPrevClients(clients);
  }, []);

  useEffect(() => {
    const client = clients.find(
      (c) =>
        c.id === -1 || c.tasks.length === 0 || c.tasks.find((t) => t.id === -1 || t.hours === 0)
    );
    if (client || clients.length === 0) {
      setIsFilled(false);
    } else {
      setIsFilled(true);
    }
  }, [clients]);

  function addNewClient() {
    dispatch(addClient());
  }

  function removeClient(uid: number) {
    dispatch(deleteClient(uid));
    const clientId = clients.find((c) => c.UID === uid)?.id;
    const i = prevClients.findIndex((c) => c.id === clientId);
    setPrevClients([...prevClients.slice(0, i), ...prevClients.slice(i + 1)]);
  }

  function editClientData(index: number, clientData: ClientLookup) {
    dispatch(editClient({ index, clientData }));
  }

  function isOptionDisabled(item: any) {
    if (allClientTasks?.findActiveClientsWithActiveTasks?.__typename === 'Tasks') {
      const result = allClientTasks.findActiveClientsWithActiveTasks.entities.some(
        (task) => task.client.id === item.id
      );
      return result ? clients.map((client) => client.id).includes(item.id as number) : true;
    }
    return clients.map((client) => client.id).includes(item.id as number);
  }

  const checkMinHours = (clientList: TimeSheetClientInput[]) => {
    let isLessThanMinHours = false;
    clientList.forEach((client) => {
      client.tasks.forEach((task) => {
        if (task.hours < minTaskHours) {
          isLessThanMinHours = true;
        }
      });
    });
    if (isLessThanMinHours) {
      message.destroy();
      message.warn(info.minTaskHours);
      return true;
    }
    return false;
  };

  const onSubmit = () => {
    let timeSheetNewTotalHours = 0;
    const newClients = clients.map((client) => {
      const newClient = {} as TimeSheetClientInput;
      const clientNewTotalHours = client.tasks.reduce((acc, task) => {
        acc += task.hours;
        return acc;
      }, 0);
      timeSheetNewTotalHours += clientNewTotalHours;
      newClient.id = client.id;
      newClient.totalHours = Math.round(clientNewTotalHours * 100) / 100;
      newClient.tasks = client.tasks.map((task) => ({ id: task.id, hours: task.hours }));
      return newClient;
    });

    const totalHours = newClients.reduce((sum, client) => (sum += client.totalHours), 0);

    if (totalHours > maxWeekHours) {
      message.destroy();
      message.warning(info.maxWeekHours);
      return;
    }

    if (checkMinHours(newClients)) return;

    const variables = {
      startDate: reportData.filters.dateRange[0],
      endDate: reportData.filters.dateRange[1],
      employeeId: reportData.filters.employeeId
    };

    updateTimeSheet({
      variables: {
        id: id as number,
        clients: newClients,
        days: dates,
        totalHours: Math.round(timeSheetNewTotalHours * 100) / 100
      }
    }).then(() => {
      getReport({ variables });
      setUpdating(true);
      dispatch(setCurrentStep(step - 1));
    });
  };

  const getClientsCards = () => {
    return clients.map((item, index) => (
      <TimeSheetCard
        key={item.UID}
        item={item}
        itemIndex={index}
        onDelete={removeClient}
        onEdit={editClientData}
        optionDisable={isOptionDisabled}
        prevClients={prevClients}
      />
    ));
  };

  if (loading || allClientTasksLoading) {
    return <Spiner />;
  }

  return (
    <Flex className='content addClient lookup-edit-container'>
      <Flex direction='column' style={{ width: '100%' }}>
        <Flex direction='row' center='main-axis'>
          <div className='report-edit-header'>
            <span>{info.timesheetFor} </span>
            <span className='headerTextLookup'>{selectedEmployeeName}</span>
            <span> {info.forThePeriodOf} </span>
            <span className='headerTextLookup'>
              {displayCustomWeekStartEnd(datePickerRange[0])}
            </span>
          </div>
        </Flex>
        <Flex className='setClientsContent' style={{ width: 900 }}>
          <Flex direction='column'>
            {getClientsCards()}
            <Flex direction='row' center='both-axis' style={{ marginTop: 10 }}>
              <Button
                style={{ width: 200 }}
                onClick={addNewClient}
                colorType='gray'
                text={info.addClientPlus}
                disabled={false}
              />
            </Flex>
          </Flex>
          <Flex direction='row' space='between' style={{ margin: '20px 32px 0 0' }}>
            <div className='bold'>{info.totalHours}</div>
            <div className=' bold'>{`${data.totalHours}h`}</div>
          </Flex>
        </Flex>
        <Flex direction='row' center='both-axis' style={{ marginTop: 20 }}>
          <Button
            style={{ marginRight: 10, width: 150 }}
            colorType='white'
            onClick={() => dispatch(setCurrentStep(step - 1))}
            text={info.cancel}
          />
          <Button
            colorType='black'
            disabled={!isFilled}
            onClick={onSubmit}
            style={{ width: 150 }}
            text={info.confirm}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default EditTimeSheet;
