import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Moment } from 'moment';
import { useForm, Controller } from 'react-hook-form';

import { RootState } from '../../../redux/reducers/index';
import { selectedEmployees, updateEmployeeReportFilters } from '../../../redux/reducers/Reports';
import { Flex, Button } from '../../../components/core';
import DateRangePicker from '../../../components/DateRangePicker';
import DebouncedList from '../../../components/DynamicSelect';
import {
  useEmployeesQuery,
  EmployeeReportQueryVariables,
  useEmployeesLazyQuery
} from '../../../graphql/graphql';
import { info } from '../../../resources/strings';
import { ResponseDataType } from '../../../components/DynamicSelect/DebouncedList';

const dateFormat = 'YYYY-MM-DD';

type FormData = {
  dateRange: any[];
  employeeIds: number[];
};

interface IProps {
  getReport: (data: { variables: EmployeeReportQueryVariables }) => void;
}

const FilterSection: React.FC<IProps> = ({ getReport }) => {
  const dispatch = useDispatch();
  const reportData = useSelector((state: RootState) => state.reports.employeeReport);
  const [getEmployeesById, { data: selectedEmployeesData }] = useEmployeesLazyQuery();
  const { control, handleSubmit, setValue, getValues } = useForm<FormData>({
    defaultValues: {
      dateRange: reportData.filters.dateRange,
      employeeIds: reportData.filters.employeeIds
    }
  });

  const updateSelectedEmployees = (data: ResponseDataType[]) => {
    dispatch(selectedEmployees(data));
  };

  useEffect(() => {
    if (reportData.filters.employeeIds.length) {
      getEmployeesById({ variables: { ids: reportData.filters.employeeIds } });
    }
  }, [reportData.filters.employeeIds]);

  useEffect(() => {
    if (selectedEmployeesData?.employees.__typename === 'Employees') {
      updateSelectedEmployees(selectedEmployeesData.employees.entities);
    }
  }, [selectedEmployeesData]);

  const updateFilters = (filtersData) => {
    dispatch(updateEmployeeReportFilters(filtersData));
  };

  const onSubmit = (fieldData: FormData) => {
    const [start, end] = fieldData.dateRange;
    const variables = {
      startDate: start.format(dateFormat),
      endDate: end.format(dateFormat),
      employeeIds: fieldData.employeeIds
    };

    updateFilters({
      dateRange: [start, end],
      employeeIds: fieldData.employeeIds
    });
    getReport({ variables });
  };

  const onClear = () => {
    setValue('employeeIds', []);
    const [start, end] = getValues('dateRange');
    const variables = {
      startDate: start.format(dateFormat),
      endDate: end.format(dateFormat),
      employeeIds: []
    };

    updateFilters({
      dateRange: [start, end],
      employeeIds: []
    });

    updateSelectedEmployees([]);
    getReport({ variables });
  };

  const optionRender = (data) => {
    const fullName = data.firstName + ' ' + data.lastName;
    const result = fullName.length > 20 ? fullName.slice(0, 20) + '...' : fullName;
    return result;
  };

  return (
    <Flex>
      <Flex wrap={true} direction='row' align='end-cross-axis' style={{ marginTop: 30 }}>
        <Flex style={{ marginRight: 20 }}>
          <Controller
            control={control}
            name='dateRange'
            render={({ field }) => (
              <DateRangePicker
                onDateChange={(date, dateToString) => field.onChange(date, dateToString)}
                value={[...field.value] as [Moment, Moment]}
              />
            )}
          />
        </Flex>
        <Flex style={{ marginRight: 20 }}>
          <DebouncedList
            control={control}
            mode='multiple'
            maxTagCount='responsive'
            controllerName='employeeIds'
            labelText={info.employee}
            dataText='firstName'
            dataValue='id'
            queryDataKey='employees'
            query={useEmployeesQuery}
            sectionName='EmployeeProjectHours'
            optionRender={optionRender}
            style={{ width: 400 }}
          />
        </Flex>
        <Flex direction='row' style={{ marginRight: 20, marginTop: 10 }}>
          <Button
            style={{ marginRight: 10 }}
            colorType={'white'}
            onClick={onClear}
            text={info.clearX}
          />
          <Button colorType={'black'} onClick={handleSubmit(onSubmit)} text={info.generateReport} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FilterSection;
