import React, { ChangeEventHandler, KeyboardEventHandler, useState } from 'react';
import { Input } from 'antd';

import CrossIcon from '../../icons/CrossIcon';
import { info } from '../../resources/strings';

interface CrudListCreateInputProps {
  addPlaceholder: string;
  onAdd: (input: string) => void;
}

// TODO: Cleanup and optimize
const CrudListCreateInput: React.FC<CrudListCreateInputProps> = (props) => {
  const [name, setName] = useState<string>('');
  const onAddInputChange: ChangeEventHandler<HTMLInputElement> = (event) =>
    setName(event.target.value);
  const onClearAddInput = () => setName('');
  const onAddInputKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter' && name.trim().length > 0) {
      props.onAdd(name);
      onClearAddInput();
    }
  };
  const onAddItem = () => {
    if (name.trim().length > 0) {
      props.onAdd(name);
      onClearAddInput();
    }
  };

  return (
    <Input
      placeholder={info.addNew(props.addPlaceholder)}
      className='crudListCreateField'
      value={name}
      onChange={onAddInputChange}
      onKeyDown={onAddInputKeyDown}
      addonAfter={
        name.length > 0 ? (
          <div>
            <span className='crudListAddItem' onClick={onAddItem}>
              {info.add}
            </span>
            <CrossIcon className='crudListClearItem' onClick={onClearAddInput} />
          </div>
        ) : (
          <div></div>
        )
      }
    />
  );
};

export default CrudListCreateInput;
