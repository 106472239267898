import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ClientsDocument } from '../../../graphql/graphql';
import { Flex, Button, Label } from '../../../components/core';
import ActionDialog from './ActionDialog';
import AddEditClient from './AddEditClientPopup';
import {
  useRemoveClientMutation,
  useUpdateClientStatusMutation,
  UpdateClientStatusDocument,
  GenericStatus
} from '../../../graphql/graphql';
import { RequestTypeName, ClientViewType, ClientDataType, DialogType } from '../../../types/main';
import { DeleteIcon, EditIcon, BurgerIcon } from '../../../icons';
import { message, Popover, Switch } from 'antd';
import { info } from '../../../resources/strings';
import { MessageDuration } from '../../../utils/constants';
import {
  deleteProjectReportFiltersClientId,
  updateRateReportFilters
} from '../../../redux/reducers/Reports';
import { RootState } from '../../../redux/reducers';

const statusMapper = {
  [GenericStatus.Active]: {
    text: 'Deactivate',
    className: 'Inactive'
  },
  [GenericStatus.Inactive]: {
    text: 'Activate',
    className: 'Active'
  }
};

interface IProps {
  rowdata: ClientDataType;
  reFetchClients: () => void;
}

const ActionCell: React.FC<IProps> = ({ rowdata, reFetchClients }) => {
  const dispatch = useDispatch();
  const [deleteClient] = useRemoveClientMutation({
    refetchQueries: [UpdateClientStatusDocument, 'clients']
  });
  const [updateClientStatus] = useUpdateClientStatusMutation();
  const [popupVisible, setPopupVisible] = React.useState(false);
  const [dialogType, setDialogType] = React.useState('');
  const [visible, setVisible] = React.useState(false);
  const [editMode, setEditMode] = React.useState(false);
  const rateReportFilters = useSelector((state: RootState) => state.reports.rateReport.filters);

  function handleDeleteClient() {
    deleteClient({ variables: { id: rowdata.id } })
      .then((result) => {
        setVisible(false);
        reFetchClients();
        dispatch(deleteProjectReportFiltersClientId(rowdata.id));
        dispatch(
          updateRateReportFilters({
            ...rateReportFilters,
            clientIds: rateReportFilters.clientIds.filter((id) => id !== rowdata.id)
          })
        );
        if (result?.data?.removeClient.__typename === RequestTypeName.client) {
          message.success(info.clientDeleted, MessageDuration);
          return;
        }
      })
      .catch((e) => {
        if (e) {
          message.error(e.message, MessageDuration);
        }
      });
  }

  function handleStatusChange() {
    const editedStatus =
      rowdata.status === GenericStatus.Active ? GenericStatus.Inactive : GenericStatus.Active;
    updateClientStatus({
      variables: { id: rowdata.id, status: editedStatus },
      refetchQueries: [{ query: ClientsDocument, variables: { take: 1000, status: 'Active' } }]
    })
      .then((result) => {
        if (result?.data?.updateClientStatus.__typename === RequestTypeName.client) {
          message.success(info.employeeStatusChange, MessageDuration);
          return;
        }
      })
      .catch((e) => {
        if (e) {
          message.error(e.message, MessageDuration);
        }
      });
    setVisible(false);
  }

  function handleAction(type: string) {
    setVisible(true);
    setDialogType(type);
  }

  const getPopoverContent = () => {
    return (
      <Flex style={{ width: 150 }}>
        <Flex direction='row' align='start-main-axis'>
          <div
            className='actionRow'
            onClick={() => {
              setEditMode(true);
              setPopupVisible(false);
            }}
          >
            <Button type='text' icon={<EditIcon />} shape='circle' size='small' />
            <Label mt={-2} ml={10}>
              Edit
            </Label>
          </div>
        </Flex>
        <Flex direction='row' align='start-main-axis'>
          <div
            className='actionRow'
            onClick={() => {
              handleAction(DialogType.delete);
              setPopupVisible(false);
            }}
          >
            <Button type='text' icon={<DeleteIcon />} shape='circle' size='small' />
            <Label mt={-2} ml={10}>
              Delete
            </Label>
          </div>
        </Flex>
        <Flex direction='row' className='statusContent'>
          <div
            className='actionRow'
            onClick={() => {
              handleAction(DialogType.stausChange);
              setPopupVisible(false);
            }}
          >
            <Switch checked={false} />
            <span className={`client ${statusMapper[rowdata.status].className}`}>
              {statusMapper[rowdata.status].text}
            </span>
          </div>
        </Flex>
      </Flex>
    );
  };

  const confirm = (type: string) => {
    switch (type) {
      case DialogType.delete:
        return handleDeleteClient();
      case DialogType.stausChange:
        return handleStatusChange();
    }
  };

  return (
    <Flex direction='row' space='between' style={{ marginTop: -5 }}>
      <div onClick={(e) => e.stopPropagation()}>
        <Popover
          visible={popupVisible}
          placement='bottomRight'
          content={getPopoverContent}
          trigger='click'
          onVisibleChange={() => setPopupVisible(!popupVisible)}
        >
          <Button
            type='text'
            icon={<BurgerIcon />}
            shape='circle'
            size='large'
            onClick={() => setPopupVisible(!popupVisible)}
          />
        </Popover>
        <ActionDialog
          visible={visible}
          type={dialogType}
          data={rowdata}
          confirmAction={() => confirm(dialogType)}
          rejectAction={() => setVisible(false)}
        />
        {editMode && (
          <AddEditClient
            type={ClientViewType.update}
            onCancel={() => setEditMode(false)}
            visible={true}
            reFetchClients={reFetchClients}
            rowData={{ ...rowdata }}
          />
        )}
      </div>
    </Flex>
  );
};

export default ActionCell;
