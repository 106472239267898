import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addEmployeeReport } from '../../../redux/reducers/Reports';
import { RootState } from '../../../redux/reducers/index';
import { useEmployeeReportLazyQuery } from '../../../graphql/graphql';
import { Flex, Spiner } from '../../../components/core';
import FilterSection from './FilterSection';
import ReportTable from './ReportTable';
import ExportCSV from './ExportCSV';
import { info } from '../../../resources/strings';

import './Report.scss';

const EmployeeProjectHours: React.FC = () => {
  const dispatch = useDispatch();
  const [getEmployeeProjectReports, { data, loading }] = useEmployeeReportLazyQuery({
    fetchPolicy: 'no-cache'
  });
  const employeeReport = useSelector((state: RootState) => state.reports.employeeReport);

  useEffect(() => {
    if (data?.employeeReport.__typename === 'EmployeesReport') {
      dispatch(addEmployeeReport(data.employeeReport.entities));
    } else if (data?.employeeReport.__typename) {
      dispatch(addEmployeeReport([]));
    }
  }, [data, dispatch]);

  return (
    <Flex className='site-layout-background projectHours employeeProjectHours-content'>
      <Flex>{info.selectDateRange}</Flex>
      <FilterSection getReport={getEmployeeProjectReports} />
      {employeeReport.fetched && (
        <>
          <ReportTable data={employeeReport.data} />
          <ExportCSV data={employeeReport.data} dates={employeeReport.filters.dateRange} />
        </>
      )}
      {loading && <Spiner />}
    </Flex>
  );
};

export default EmployeeProjectHours;
