import React from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import { message, Popover, Switch } from 'antd';

import { EmployeeDataType, Status } from '../../../types/main';
import { Flex, Button, Label } from '../../../components/core';
import AddEditEmployee from './AddEditEmployee';
import EmployeeActionDialog from './ResendOrDeleteDialog';
import {
  DELETE_EMPLOYEE,
  RESEND_VERIFICATION_EMAIL,
  UPDATE_EMPLOYEE_STATUS
} from '../../../ApolloClient/mutations/employeeAction';
import { RequestTypeName, EmployeeViewType } from '../../../types/main';
import { DeleteIcon, EditIcon, EmailIcon, BurgerIcon } from '../../../icons';
import { MessageDuration } from '../../../utils/constants';
import { info } from '../../../resources/strings';
import {
  deleteEmployeeReportFiltersEmployeeId,
  deleteTimeSheetReportFiltersEmployeeId
} from '../../../redux/reducers/Reports';

export enum DialogType {
  delete = 'delete',
  resend = 'resend',
  stausChange = 'stausChange'
}

interface IProps {
  rowdata: EmployeeDataType;
  reFetchEmployees: () => void;
}

const statusMapper = {
  [Status.Deactivated]: {
    text: 'Activate',
    className: 'Active'
  },
  [Status.Active]: {
    text: 'Deactivate',
    className: 'Inactive'
  },
  [Status.Pending]: {
    text: 'Pending',
    className: 'Pending'
  }
};

const ActionCell: React.FC<IProps> = ({ rowdata, reFetchEmployees }) => {
  const dispatch = useDispatch();
  const [deleteEmployee] = useMutation(DELETE_EMPLOYEE);
  const [resendEmail] = useMutation(RESEND_VERIFICATION_EMAIL);
  const [updateStatus] = useMutation(UPDATE_EMPLOYEE_STATUS);
  const [visible, setVisible] = React.useState(false);
  const [popupVisible, setPopupVisible] = React.useState(false);
  const [dialogType, setDialogType] = React.useState('');
  const [editMode, setEditMode] = React.useState(false);

  function handleDeleteEmployee() {
    deleteEmployee({ variables: { id: rowdata.id } })
      .then((result) => {
        setVisible(false);
        reFetchEmployees();
        dispatch(deleteEmployeeReportFiltersEmployeeId(rowdata.id));
        dispatch(deleteTimeSheetReportFiltersEmployeeId(rowdata.id));
        if (result?.data?.removeEmployee.__typename === RequestTypeName.employee) {
          message.success(info.employeDeleted, MessageDuration);
          return;
        }
      })
      .catch((e) => {
        if (e) {
          message.error(e.message, MessageDuration);
        }
      });
  }

  function handleResendEmail() {
    resendEmail({ variables: { id: rowdata.id } })
      .then((result) => {
        setVisible(false);
        if (result?.data?.resendVerificationEmail.result) {
          message.success(info.emailSent, MessageDuration);
          return;
        }
      })
      .catch((e) => {
        if (e) {
          message.error(e.message, MessageDuration);
        }
      });
  }

  function handleStatusChange() {
    const editedStatus = rowdata.status === Status.Active ? Status.Deactivated : Status.Active;
    updateStatus({ variables: { id: rowdata.id, status: editedStatus } })
      .then((result) => {
        if (result?.data?.updateEmployeeStatus.__typename === RequestTypeName.employee) {
          message.success(info.employeeStatusChange, MessageDuration);
          return;
        }
      })
      .catch((e) => {
        if (e) {
          message.error(e.message, MessageDuration);
        }
      });
    setVisible(false);
  }

  function handleAction(type: string) {
    setVisible(true);
    setDialogType(type);
  }

  const getPopoverContent = () => {
    return (
      <Flex style={{ width: 150 }}>
        <Flex direction='row' align='start-main-axis'>
          <div
            className='actionRow'
            onClick={() => {
              setEditMode(true);
              setPopupVisible(false);
            }}
          >
            <Button type='text' icon={<EditIcon />} shape='circle' size='small' />
            <Label mt={-2} ml={10}>
              {info.edit}
            </Label>
          </div>
        </Flex>
        <Flex direction='row' align='start-main-axis'>
          <div
            className='actionRow'
            onClick={() => {
              handleAction(DialogType.delete);
              setPopupVisible(false);
            }}
          >
            <Button type='text' icon={<DeleteIcon />} shape='circle' size='small' />
            <Label mt={-2} ml={10}>
              {info.delete}
            </Label>
          </div>
        </Flex>
        {rowdata.status === Status.Pending && (
          <Flex direction='row' align='start-main-axis'>
            <div
              className='actionRow'
              onClick={() => {
                handleAction(DialogType.resend);
                setPopupVisible(false);
              }}
            >
              <Button type='text' icon={<EmailIcon />} shape='circle' size='small' />
              <Label mt={-2} ml={10}>
                {info.resendEmail}
              </Label>
            </div>
          </Flex>
        )}
        {rowdata.status !== Status.Pending && (
          <Flex direction='row' className='statusContent'>
            <div
              className='actionRow'
              onClick={() => {
                handleAction(DialogType.stausChange);
                setPopupVisible(false);
              }}
            >
              <Switch checked={false} />
              <span className={`employee ${statusMapper[rowdata.status].className}`}>
                {statusMapper[rowdata.status].text}
              </span>
            </div>
          </Flex>
        )}
      </Flex>
    );
  };

  const confirm = (type: string) => {
    switch (type) {
      case DialogType.delete:
        return handleDeleteEmployee();
      case DialogType.resend:
        return handleResendEmail();
      case DialogType.stausChange:
        return handleStatusChange();
    }
  };

  return (
    <Flex direction='row' space='between' style={{ marginTop: -5 }}>
      <Popover
        visible={popupVisible}
        placement='bottomRight'
        content={getPopoverContent}
        trigger='click'
        onVisibleChange={() => setPopupVisible(!popupVisible)}
      >
        <Button
          type='text'
          icon={<BurgerIcon />}
          shape='circle'
          size='large'
          onClick={() => setPopupVisible(!popupVisible)}
        />
      </Popover>
      <EmployeeActionDialog
        visible={visible}
        type={dialogType}
        data={rowdata}
        confirmAction={() => confirm(dialogType)}
        rejectAction={() => setVisible(false)}
      />
      {editMode && (
        <AddEditEmployee
          type={EmployeeViewType.edit}
          onCancel={() => setEditMode(false)}
          visible={true}
          dataValues={{ ...rowdata }}
          reFetchEmployees={reFetchEmployees}
        />
      )}
    </Flex>
  );
};

export default ActionCell;
