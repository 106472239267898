import React, { useState } from 'react';
import CrudListContent from './CrudListContent';
import CrudListCreateInput from './CrudListCreateInput';
import CrudListEditInput from './CrudListEditInput';
import CrudListHeader from './CrudListHeader';
import CrudListItems, { CrudListItemInterface, EditableCrudListItem } from './CrudListItems';
import CrudListSearchInput from './CrudListSearchInput';
import './styles.scss';

interface CrudListProps {
  title: string;
  items: CrudListItemInterface[];
  onAdd: (input: string) => void;
  onEdit: (item: EditableCrudListItem) => void;
  onSearch: (value: string) => void;
}

const CrudList: React.FC<CrudListProps> = (props) => {
  const [editableItem, setEditableItem] = useState<EditableCrudListItem | null>(null);
  const onEditClick = (item: EditableCrudListItem) => {
    setEditableItem(item);
  };
  const cancelEdit = () => setEditableItem(null);

  return (
    <div>
      <CrudListHeader title={props.title} />
      <CrudListContent>
        <CrudListSearchInput onChange={props.onSearch} />
        <CrudListItems items={props.items} onEdit={onEditClick} />
        {editableItem ? (
          <CrudListEditInput item={editableItem} onEdit={props.onEdit} onCancel={cancelEdit} />
        ) : (
          <CrudListCreateInput addPlaceholder={props.title} onAdd={props.onAdd} />
        )}
      </CrudListContent>
    </div>
  );
};

export default CrudList;
