import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type ActivatePendingEmployeeInput = {
  activationToken: Scalars['String'];
  employeeId: Scalars['Int'];
  password: Scalars['String'];
};

export enum AssignableEmployeeStatus {
  Active = 'Active',
  Deactivated = 'Deactivated'
}

export type AuthTokens = {
  __typename?: 'AuthTokens';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type AuthenticationError = Error & {
  __typename?: 'AuthenticationError';
  message: Scalars['String'];
};

export type BadRequestError = Error & {
  __typename?: 'BadRequestError';
  message: Scalars['String'];
};

export type Client = {
  __typename?: 'Client';
  id: Scalars['Int'];
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status: GenericStatus;
};

export type ClientEmployeeReport = {
  __typename?: 'ClientEmployeeReport';
  firstName: Scalars['String'];
  hourlyRate: Scalars['Float'];
  hoursPerDay: Scalars['Float'];
  id: Scalars['Int'];
  lastName: Scalars['String'];
};

export type ClientReport = {
  __typename?: 'ClientReport';
  id: Scalars['Int'];
  name: Scalars['String'];
  tasks: Array<ClientTaskReport>;
};

export type ClientTaskReport = {
  __typename?: 'ClientTaskReport';
  employees: Array<ClientEmployeeReport>;
  hoursPerDay: Scalars['Float'];
  id: Scalars['Int'];
  summary: Scalars['String'];
};

export type ClientTasksInput = {
  clientIds: Array<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<GenericStatus>;
  summary?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type Clients = {
  __typename?: 'Clients';
  count: Scalars['Int'];
  entities: Array<Client>;
};

export type ClientsFilter = {
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<GenericStatus>;
  take?: InputMaybe<Scalars['Int']>;
  term?: InputMaybe<Scalars['String']>;
};

export type ClientsReport = {
  __typename?: 'ClientsReport';
  entities: Array<ClientReport>;
};

export type CreateClientInput = {
  name: Scalars['String'];
};

export type CreateCurrencyInput = {
  name: Scalars['String'];
};

export type CreateDepartmentInput = {
  name: Scalars['String'];
};

export type CreateEmployeeInput = {
  currencyId?: InputMaybe<Scalars['Int']>;
  departmentId?: InputMaybe<Scalars['Int']>;
  email: Scalars['String'];
  employmentStatusId: Scalars['Int'];
  firstName: Scalars['String'];
  hourlyRate: Scalars['Float'];
  jobId: Scalars['Int'];
  lastName: Scalars['String'];
  locationId: Scalars['Int'];
  manager?: InputMaybe<Scalars['Int']>;
  role: EmployeeRole;
};

export type CreateEmploymentStatusInput = {
  name: Scalars['String'];
};

export type CreateHolidayInput = {
  endDate: Scalars['DateTime'];
  isAnnual: Scalars['Boolean'];
  locationIds: Array<Scalars['Int']>;
  name: Scalars['String'];
  startDate: Scalars['DateTime'];
};

export type CreateJobInput = {
  title: Scalars['String'];
};

export type CreateLocationInput = {
  name: Scalars['String'];
};

export type CreateTaskInput = {
  clientId: Scalars['Int'];
  summary: Scalars['String'];
};

export type CreateTimeSheetInput = {
  clients: Array<TimeSheetClientInput>;
  days: Array<Scalars['DateTime']>;
  totalHours: Scalars['Float'];
};

export type Currencies = {
  __typename?: 'Currencies';
  count: Scalars['Int'];
  entities: Array<Currency>;
};

export type Currency = {
  __typename?: 'Currency';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type Department = {
  __typename?: 'Department';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type Departments = {
  __typename?: 'Departments';
  count: Scalars['Int'];
  entities: Array<Department>;
};

export type Employee = {
  __typename?: 'Employee';
  currency?: Maybe<Currency>;
  department: Department;
  email: Scalars['String'];
  employmentStatus: EmploymentStatus;
  firstName: Scalars['String'];
  hourlyRates: Array<HourlyRate>;
  id: Scalars['Int'];
  job: Job;
  lastName: Scalars['String'];
  location: Location;
  manager?: Maybe<Employee>;
  role: EmployeeRole;
  status: EmployeeStatus;
  subordinate?: Maybe<Array<Employee>>;
};

export type EmployeeReport = {
  __typename?: 'EmployeeReport';
  clients: Array<TimeSheetClientReport>;
  firstName: Scalars['String'];
  hourlyRate: Scalars['Float'];
  id: Scalars['Int'];
  lastName: Scalars['String'];
};

export enum EmployeeRole {
  Admin = 'Admin',
  Employee = 'Employee',
  EngagementManager = 'EngagementManager',
  Manager = 'Manager',
  SuperAdmin = 'SuperAdmin'
}

export enum EmployeeStatus {
  Active = 'Active',
  Deactivated = 'Deactivated',
  Pending = 'Pending'
}

export type Employees = {
  __typename?: 'Employees';
  count: Scalars['Int'];
  entities: Array<Employee>;
};

export type EmployeesFilter = {
  departmentIds?: InputMaybe<Array<Scalars['Int']>>;
  firstName?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  lastName?: InputMaybe<Scalars['String']>;
  locationIds?: InputMaybe<Array<Scalars['Int']>>;
  managerId?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<EmployeeStatus>>;
  take?: InputMaybe<Scalars['Int']>;
  term?: InputMaybe<Scalars['String']>;
};

export type EmployeesReport = {
  __typename?: 'EmployeesReport';
  entities: Array<EmployeeReport>;
};

export type EmploymentStatus = {
  __typename?: 'EmploymentStatus';
  id: Scalars['Float'];
  name: Scalars['String'];
};

export type EmploymentStatuses = {
  __typename?: 'EmploymentStatuses';
  count: Scalars['Int'];
  entities: Array<EmploymentStatus>;
};

export type Error = {
  message: Scalars['String'];
};

export type FilterCurrencyInput = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  term?: InputMaybe<Scalars['String']>;
};

export type FilterDepartmentInput = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  term?: InputMaybe<Scalars['String']>;
};

export type FilterEmploymentStatusInput = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  term?: InputMaybe<Scalars['String']>;
};

export type FilterHolidayInput = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  locationIds?: InputMaybe<Array<Scalars['Int']>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  term?: InputMaybe<Scalars['String']>;
};

export type FilterJobInput = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  term?: InputMaybe<Scalars['String']>;
};

export type FilterLocationsInput = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  term?: InputMaybe<Scalars['String']>;
};

export enum GenericStatus {
  Active = 'Active',
  Inactive = 'Inactive'
}

export type Holiday = {
  __typename?: 'Holiday';
  endDate: Scalars['DateTime'];
  id: Scalars['Int'];
  isAnnual: Scalars['Boolean'];
  locations: Array<Location>;
  name: Scalars['String'];
  startDate: Scalars['DateTime'];
};

export type Holidays = {
  __typename?: 'Holidays';
  count: Scalars['Int'];
  entities: Array<Holiday>;
};

export type HourlyRate = {
  __typename?: 'HourlyRate';
  createdAt: Scalars['DateTime'];
  id: Scalars['Int'];
  rate: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type Job = {
  __typename?: 'Job';
  id: Scalars['Int'];
  title: Scalars['String'];
};

export type Jobs = {
  __typename?: 'Jobs';
  count: Scalars['Int'];
  entities: Array<Job>;
};

export type Location = {
  __typename?: 'Location';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type Locations = {
  __typename?: 'Locations';
  count: Scalars['Int'];
  entities: Array<Location>;
};

export type LoggedUserResult = AuthenticationError | Employee;

export type LoginInput = {
  password: Scalars['String'];
  username: Scalars['String'];
};

export type LoginResult = AuthenticationError | BadRequestError | UserWithCredentials;

export type MaybeClientProtected = AuthenticationError | BadRequestError | Client;

export type MaybeClientsProtected = AuthenticationError | BadRequestError | Clients;

export type MaybeClientsReportProtected = AuthenticationError | BadRequestError | ClientsReport;

export type MaybeCurrenciesProtected = AuthenticationError | BadRequestError | Currencies;

export type MaybeCurrencyProtected = AuthenticationError | BadRequestError | Currency;

export type MaybeDepartmentProtected = AuthenticationError | BadRequestError | Department;

export type MaybeDepartmentsProtected = AuthenticationError | BadRequestError | Departments;

export type MaybeEmployee = BadRequestError | Employee;

export type MaybeEmployeeProtected = AuthenticationError | BadRequestError | Employee;

export type MaybeEmployeesProtected = AuthenticationError | BadRequestError | Employees;

export type MaybeEmployeesReportProtected = AuthenticationError | BadRequestError | EmployeesReport;

export type MaybeEmploymentStatusProtected = AuthenticationError | BadRequestError | EmploymentStatus;

export type MaybeEmploymentStatusesProtected = AuthenticationError | BadRequestError | EmploymentStatuses;

export type MaybeHolidayProtected = AuthenticationError | BadRequestError | Holiday;

export type MaybeHolidaysProtected = AuthenticationError | BadRequestError | Holidays;

export type MaybeJobProtected = AuthenticationError | BadRequestError | Job;

export type MaybeJobsProtected = AuthenticationError | BadRequestError | Jobs;

export type MaybeLocationProtected = AuthenticationError | BadRequestError | Location;

export type MaybeLocationsProtected = AuthenticationError | BadRequestError | Locations;

export type MaybeRatesReportProtected = AuthenticationError | BadRequestError | RatesReport;

export type MaybeSuccess = BadRequestError | Success;

export type MaybeSuccessProtected = AuthenticationError | BadRequestError | Success;

export type MaybeTaskProtected = AuthenticationError | BadRequestError | Task;

export type MaybeTasksProtected = AuthenticationError | BadRequestError | Tasks;

export type MaybeTimeSheetDatesProtected = AuthenticationError | BadRequestError | TimeSheetDateTypes;

export type MaybeTimeSheetProtected = AuthenticationError | BadRequestError | TimeSheet;

export type MaybeTimeSheetsProtected = AuthenticationError | BadRequestError | TimeSheets;

export type Mutation = {
  __typename?: 'Mutation';
  activatePendingEmployee: MaybeEmployee;
  createClient: MaybeClientProtected;
  createCurrency: MaybeCurrencyProtected;
  createDepartment: MaybeDepartmentProtected;
  createEmployee: MaybeEmployeeProtected;
  createEmploymentStatus: MaybeEmploymentStatusProtected;
  createHoliday: MaybeHolidayProtected;
  createJob: MaybeJobProtected;
  createLocation: MaybeLocationProtected;
  createTask: MaybeTaskProtected;
  createTimeSheet: MaybeTimeSheetProtected;
  initiateResetPassword: MaybeSuccess;
  login: LoginResult;
  logout: MaybeSuccessProtected;
  removeClient: MaybeClientProtected;
  removeCurrency: MaybeCurrencyProtected;
  removeDepartment: MaybeDepartmentProtected;
  removeEmployee: MaybeEmployeeProtected;
  removeEmploymentStatus: MaybeEmploymentStatusProtected;
  removeHoliday: MaybeHolidayProtected;
  removeJob: MaybeJobProtected;
  removeLocation: MaybeLocationProtected;
  removeTask: MaybeTaskProtected;
  removeTimeSheet: MaybeTimeSheetProtected;
  resendVerificationEmail: MaybeSuccessProtected;
  resetPassword: MaybeSuccess;
  resetPasswordEmail: MaybeSuccess;
  skipWeek: MaybeTimeSheetProtected;
  updateClient: MaybeClientProtected;
  updateClientStatus: MaybeClientProtected;
  updateCurrency: MaybeCurrencyProtected;
  updateDepartment: MaybeDepartmentProtected;
  updateEmployee: MaybeEmployeeProtected;
  updateEmployeeStatus: MaybeEmployeeProtected;
  updateEmploymentStatus: MaybeEmploymentStatusProtected;
  updateHoliday: MaybeHolidayProtected;
  updateJob: MaybeJobProtected;
  updateLocation: MaybeLocationProtected;
  updateTask: MaybeTaskProtected;
  updateTaskStatus: MaybeTaskProtected;
  updateTimeSheet: MaybeTimeSheetProtected;
};


export type MutationActivatePendingEmployeeArgs = {
  activatePendingEmployeeInput: ActivatePendingEmployeeInput;
};


export type MutationCreateClientArgs = {
  createClientInput: CreateClientInput;
  logo?: InputMaybe<Scalars['Upload']>;
};


export type MutationCreateCurrencyArgs = {
  createCurrencyInput: CreateCurrencyInput;
};


export type MutationCreateDepartmentArgs = {
  createDepartmentInput: CreateDepartmentInput;
};


export type MutationCreateEmployeeArgs = {
  createEmployeeInput: CreateEmployeeInput;
};


export type MutationCreateEmploymentStatusArgs = {
  createEmploymentStatusInput: CreateEmploymentStatusInput;
};


export type MutationCreateHolidayArgs = {
  createHolidayInput: CreateHolidayInput;
};


export type MutationCreateJobArgs = {
  createJobInput: CreateJobInput;
};


export type MutationCreateLocationArgs = {
  createLocationInput: CreateLocationInput;
};


export type MutationCreateTaskArgs = {
  createTaskInput: CreateTaskInput;
};


export type MutationCreateTimeSheetArgs = {
  createTimeSheetInput: CreateTimeSheetInput;
};


export type MutationInitiateResetPasswordArgs = {
  email: Scalars['String'];
};


export type MutationLoginArgs = {
  credentials: LoginInput;
};


export type MutationRemoveClientArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveCurrencyArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveDepartmentArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveEmployeeArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveEmploymentStatusArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveHolidayArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveJobArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveLocationArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveTaskArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveTimeSheetArgs = {
  id: Scalars['Int'];
};


export type MutationResendVerificationEmailArgs = {
  id: Scalars['Int'];
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationResetPasswordEmailArgs = {
  input: ResetPasswordInput;
};


export type MutationSkipWeekArgs = {
  weekStart: Scalars['String'];
};


export type MutationUpdateClientArgs = {
  logo?: InputMaybe<Scalars['Upload']>;
  updateClientInput: UpdateClientInput;
};


export type MutationUpdateClientStatusArgs = {
  id: Scalars['Int'];
  status: GenericStatus;
};


export type MutationUpdateCurrencyArgs = {
  updateCurrencyInput: UpdateCurrencyInput;
};


export type MutationUpdateDepartmentArgs = {
  updateDepartmentInput: UpdateDepartmentInput;
};


export type MutationUpdateEmployeeArgs = {
  updateEmployeeInput: UpdateEmployeeInput;
};


export type MutationUpdateEmployeeStatusArgs = {
  updateEmployeeStatusInput: UpdateEmployeeStatusInput;
};


export type MutationUpdateEmploymentStatusArgs = {
  updateEmploymentStatusInput: UpdateEmploymentStatusInput;
};


export type MutationUpdateHolidayArgs = {
  updateHolidayInput: UpdateHolidayInput;
};


export type MutationUpdateJobArgs = {
  updateJobInput: UpdateJobInput;
};


export type MutationUpdateLocationArgs = {
  updateLocationInput: UpdateLocationInput;
};


export type MutationUpdateTaskArgs = {
  updateTaskInput: UpdateTaskInput;
};


export type MutationUpdateTaskStatusArgs = {
  id: Scalars['Int'];
  status: GenericStatus;
};


export type MutationUpdateTimeSheetArgs = {
  updateTimeSheetInput: UpdateTimeSheetInput;
};

export type Query = {
  __typename?: 'Query';
  client: MaybeClientProtected;
  clientReport: MaybeClientsReportProtected;
  clients: MaybeClientsProtected;
  currencies: MaybeCurrenciesProtected;
  currency: MaybeCurrencyProtected;
  department: MaybeDepartmentProtected;
  departments: MaybeDepartmentsProtected;
  employee: MaybeEmployeeProtected;
  employeePastTimeSheet: MaybeTimeSheetProtected;
  employeeReport: MaybeEmployeesReportProtected;
  employees: MaybeEmployeesProtected;
  employmentStatus: MaybeEmploymentStatusProtected;
  employmentStatuses: MaybeEmploymentStatusesProtected;
  findActiveClientsWithActiveTasks: MaybeTasksProtected;
  findClientTasks: MaybeTasksProtected;
  getManagersForEmployee: MaybeEmployeesProtected;
  holiday: MaybeHolidayProtected;
  holidays: MaybeHolidaysProtected;
  job: MaybeJobProtected;
  jobs: MaybeJobsProtected;
  location: MaybeLocationProtected;
  locations: MaybeLocationsProtected;
  managers: MaybeEmployeesProtected;
  me: LoggedUserResult;
  previousTimeSheet: MaybeTimeSheetsProtected;
  rateReport: MaybeRatesReportProtected;
  refreshTokens: RefreshTokenResult;
  task: MaybeTaskProtected;
  tasks: MaybeTasksProtected;
  timeSheet: MaybeTimeSheetProtected;
  timeSheetDates: MaybeTimeSheetDatesProtected;
  userLastTimeSheet: MaybeTimeSheetsProtected;
};


export type QueryClientArgs = {
  id: Scalars['Int'];
};


export type QueryClientReportArgs = {
  filter: TimeSheetFilterReport;
};


export type QueryClientsArgs = {
  filter?: InputMaybe<ClientsFilter>;
};


export type QueryCurrenciesArgs = {
  filter?: InputMaybe<FilterCurrencyInput>;
};


export type QueryCurrencyArgs = {
  id: Scalars['Int'];
};


export type QueryDepartmentArgs = {
  id: Scalars['Int'];
};


export type QueryDepartmentsArgs = {
  filter?: InputMaybe<FilterDepartmentInput>;
};


export type QueryEmployeeArgs = {
  id: Scalars['Int'];
};


export type QueryEmployeePastTimeSheetArgs = {
  filter: TimeSheetFilter;
};


export type QueryEmployeeReportArgs = {
  filter: TimeSheetFilterReport;
};


export type QueryEmployeesArgs = {
  filter?: InputMaybe<EmployeesFilter>;
};


export type QueryEmploymentStatusArgs = {
  id: Scalars['Int'];
};


export type QueryEmploymentStatusesArgs = {
  filter?: InputMaybe<FilterEmploymentStatusInput>;
};


export type QueryFindClientTasksArgs = {
  input: ClientTasksInput;
};


export type QueryGetManagersForEmployeeArgs = {
  id: Scalars['Int'];
  term?: InputMaybe<Scalars['String']>;
};


export type QueryHolidayArgs = {
  id: Scalars['Int'];
};


export type QueryHolidaysArgs = {
  filter?: InputMaybe<FilterHolidayInput>;
};


export type QueryJobArgs = {
  id: Scalars['Int'];
};


export type QueryJobsArgs = {
  filter?: InputMaybe<FilterJobInput>;
};


export type QueryLocationArgs = {
  id: Scalars['Int'];
};


export type QueryLocationsArgs = {
  filter?: InputMaybe<FilterLocationsInput>;
};


export type QueryManagersArgs = {
  filter?: InputMaybe<EmployeesFilter>;
};


export type QueryPreviousTimeSheetArgs = {
  filter: TimeSheetFilter;
};


export type QueryRateReportArgs = {
  filter: TimeSheetFilterReport;
};


export type QueryRefreshTokensArgs = {
  refreshToken: Scalars['String'];
};


export type QueryTaskArgs = {
  id: Scalars['Int'];
};


export type QueryTasksArgs = {
  filter?: InputMaybe<TaskFilterInput>;
};


export type QueryTimeSheetArgs = {
  id: Scalars['Int'];
};

export type RateClientReport = {
  __typename?: 'RateClientReport';
  dates: Scalars['DateTime'];
  hoursPerDay: Scalars['Float'];
  id: Scalars['Int'];
  name: Scalars['String'];
  salary: Scalars['Float'];
};

export type RateReport = {
  __typename?: 'RateReport';
  clients: Array<RateClientReport>;
  currency: Currency;
  department: Department;
  email: Scalars['String'];
  employmentStatus: EmploymentStatus;
  firstName: Scalars['String'];
  groupedHoursByHourlyRateAndClient: Scalars['String'];
  groupedSalariesByHourlyRateAndClient: Scalars['String'];
  hourlyRates: Array<HourlyRate>;
  id: Scalars['Int'];
  job: Job;
  lastName: Scalars['String'];
  location: Location;
  status: EmployeeStatus;
};

export type RatesReport = {
  __typename?: 'RatesReport';
  entities: Array<RateReport>;
};

export type RefreshTokenResult = AuthTokens | BadRequestError;

export type ResetPasswordInput = {
  employeeId: Scalars['Int'];
  password: Scalars['String'];
  token: Scalars['String'];
};

export type Success = {
  __typename?: 'Success';
  result: Scalars['Boolean'];
};

export type Task = {
  __typename?: 'Task';
  client: Client;
  id: Scalars['Int'];
  status: GenericStatus;
  summary: Scalars['String'];
};

export type TaskFilterInput = {
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<GenericStatus>;
  summary?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type Tasks = {
  __typename?: 'Tasks';
  clients?: Maybe<Array<Client>>;
  count: Scalars['Int'];
  entities: Array<Task>;
};

export type TimeSheet = {
  __typename?: 'TimeSheet';
  clients: Array<TimeSheetClient>;
  dates: Array<Scalars['DateTime']>;
  id: Scalars['Int'];
  totalHours: Scalars['Float'];
};

export type TimeSheetClient = {
  __typename?: 'TimeSheetClient';
  client: Client;
  id: Scalars['Int'];
  tasks: Array<TimeSheetClientTask>;
  totalHours: Scalars['Float'];
};

export type TimeSheetClientInput = {
  id: Scalars['Int'];
  tasks: Array<TimeSheetClientTaskInput>;
  totalHours: Scalars['Float'];
};

export type TimeSheetClientReport = {
  __typename?: 'TimeSheetClientReport';
  hoursPerDay: Scalars['Float'];
  id: Scalars['Int'];
  name: Scalars['String'];
  tasks: Array<TimeSheetClientTaskReport>;
};

export type TimeSheetClientTask = {
  __typename?: 'TimeSheetClientTask';
  hours: Scalars['Float'];
  id: Scalars['Int'];
  task: Task;
};

export type TimeSheetClientTaskInput = {
  hours: Scalars['Float'];
  id: Scalars['Int'];
};

export type TimeSheetClientTaskReport = {
  __typename?: 'TimeSheetClientTaskReport';
  hoursPerDay: Scalars['Float'];
  id: Scalars['Int'];
  summary: Scalars['String'];
};

export type TimeSheetDateType = {
  __typename?: 'TimeSheetDateType';
  date: Scalars['DateTime'];
  holiday: Scalars['Boolean'];
  name: Scalars['String'];
  weekend: Scalars['Boolean'];
};

export type TimeSheetDateTypes = {
  __typename?: 'TimeSheetDateTypes';
  data: Array<TimeSheetDateType>;
};

export type TimeSheetFilter = {
  employeeId?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  skip?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type TimeSheetFilterReport = {
  clientIds?: InputMaybe<Array<Scalars['Int']>>;
  departmentIds?: InputMaybe<Array<Scalars['Int']>>;
  employeeIds?: InputMaybe<Array<Scalars['Int']>>;
  employmentStatusIds?: InputMaybe<Array<Scalars['Int']>>;
  endDate: Scalars['DateTime'];
  jobIds?: InputMaybe<Array<Scalars['Int']>>;
  locationIds?: InputMaybe<Array<Scalars['Int']>>;
  startDate: Scalars['DateTime'];
};

export type TimeSheets = {
  __typename?: 'TimeSheets';
  count: Scalars['Int'];
  entities: Array<TimeSheet>;
};

export type UpdateClientInput = {
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateCurrencyInput = {
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateDepartmentInput = {
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateEmployeeInput = {
  currencyId?: InputMaybe<Scalars['Int']>;
  departmentId?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  employmentStatusId?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  hourlyRate?: InputMaybe<Scalars['Float']>;
  id: Scalars['Int'];
  jobId?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['String']>;
  locationId?: InputMaybe<Scalars['Int']>;
  manager?: InputMaybe<Scalars['Int']>;
  role?: InputMaybe<EmployeeRole>;
};

export type UpdateEmployeeStatusInput = {
  id: Scalars['Int'];
  status: AssignableEmployeeStatus;
};

export type UpdateEmploymentStatusInput = {
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateHolidayInput = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  isAnnual?: InputMaybe<Scalars['Boolean']>;
  locationIds?: InputMaybe<Array<Scalars['Int']>>;
  name?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateJobInput = {
  id: Scalars['Int'];
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateLocationInput = {
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateTaskInput = {
  clientId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  summary?: InputMaybe<Scalars['String']>;
};

export type UpdateTimeSheetInput = {
  clients?: InputMaybe<Array<TimeSheetClientInput>>;
  days?: InputMaybe<Array<Scalars['DateTime']>>;
  id: Scalars['Int'];
  totalHours?: InputMaybe<Scalars['Float']>;
};

export type UserWithCredentials = {
  __typename?: 'UserWithCredentials';
  authTokens: AuthTokens;
  user: Employee;
};

export type CreateClientMutationVariables = Exact<{
  name: Scalars['String'];
  logo?: InputMaybe<Scalars['Upload']>;
}>;


export type CreateClientMutation = { __typename?: 'Mutation', createClient: { __typename: 'AuthenticationError', message: string } | { __typename: 'BadRequestError', message: string } | { __typename: 'Client', id: number, logoUrl?: string | null | undefined, name: string } };

export type UpdateClientMutationVariables = Exact<{
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['Upload']>;
}>;


export type UpdateClientMutation = { __typename?: 'Mutation', updateClient: { __typename: 'AuthenticationError', message: string } | { __typename: 'BadRequestError', message: string } | { __typename: 'Client', id: number, name: string, status: GenericStatus, logoUrl?: string | null | undefined } };

export type RemoveClientMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveClientMutation = { __typename?: 'Mutation', removeClient: { __typename: 'AuthenticationError', message: string } | { __typename: 'BadRequestError', message: string } | { __typename: 'Client', id: number, logoUrl?: string | null | undefined, name: string } };

export type UpdateClientStatusMutationVariables = Exact<{
  id: Scalars['Int'];
  status: GenericStatus;
}>;


export type UpdateClientStatusMutation = { __typename?: 'Mutation', updateClientStatus: { __typename: 'AuthenticationError', message: string } | { __typename: 'BadRequestError', message: string } | { __typename: 'Client', id: number, name: string, status: GenericStatus, logoUrl?: string | null | undefined } };

export type CreateCurrencyMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateCurrencyMutation = { __typename?: 'Mutation', createCurrency: { __typename?: 'AuthenticationError', message: string } | { __typename?: 'BadRequestError', message: string } | { __typename?: 'Currency', id: number, name: string } };

export type RemoveCurrencyMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveCurrencyMutation = { __typename?: 'Mutation', removeCurrency: { __typename?: 'AuthenticationError', message: string } | { __typename?: 'BadRequestError', message: string } | { __typename?: 'Currency', id: number, name: string } };

export type UpdateCurrencyMutationVariables = Exact<{
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
}>;


export type UpdateCurrencyMutation = { __typename?: 'Mutation', updateCurrency: { __typename?: 'AuthenticationError', message: string } | { __typename?: 'BadRequestError', message: string } | { __typename?: 'Currency', id: number, name: string } };

export type CreateDepartmentMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateDepartmentMutation = { __typename?: 'Mutation', createDepartment: { __typename?: 'AuthenticationError', message: string } | { __typename?: 'BadRequestError', message: string } | { __typename?: 'Department', id: number, name: string } };

export type RemoveDepartmentMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveDepartmentMutation = { __typename?: 'Mutation', removeDepartment: { __typename?: 'AuthenticationError', message: string } | { __typename?: 'BadRequestError', message: string } | { __typename?: 'Department', id: number, name: string } };

export type UpdateDepartmentMutationVariables = Exact<{
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
}>;


export type UpdateDepartmentMutation = { __typename?: 'Mutation', updateDepartment: { __typename?: 'AuthenticationError', message: string } | { __typename?: 'BadRequestError', message: string } | { __typename?: 'Department', id: number, name: string } };

export type CreateEmploymentStatusMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateEmploymentStatusMutation = { __typename?: 'Mutation', createEmploymentStatus: { __typename?: 'AuthenticationError', message: string } | { __typename?: 'BadRequestError', message: string } | { __typename?: 'EmploymentStatus', id: number, name: string } };

export type DeleteEmploymentStatusMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteEmploymentStatusMutation = { __typename?: 'Mutation', removeEmploymentStatus: { __typename?: 'AuthenticationError', message: string } | { __typename?: 'BadRequestError', message: string } | { __typename?: 'EmploymentStatus', id: number, name: string } };

export type UpdateEmploymentStatusMutationVariables = Exact<{
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
}>;


export type UpdateEmploymentStatusMutation = { __typename?: 'Mutation', updateEmploymentStatus: { __typename?: 'AuthenticationError', message: string } | { __typename?: 'BadRequestError', message: string } | { __typename?: 'EmploymentStatus', id: number, name: string } };

export type CreateHolidayMutationVariables = Exact<{
  name: Scalars['String'];
  isAnnual: Scalars['Boolean'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  locationIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type CreateHolidayMutation = { __typename?: 'Mutation', createHoliday: { __typename?: 'AuthenticationError', message: string } | { __typename?: 'BadRequestError', message: string } | { __typename?: 'Holiday', id: number, name: string, isAnnual: boolean, startDate: any, endDate: any, locations: Array<{ __typename?: 'Location', id: number, name: string }> } };

export type UpdateHolidayMutationVariables = Exact<{
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  isAnnual?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  locationIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type UpdateHolidayMutation = { __typename?: 'Mutation', updateHoliday: { __typename?: 'AuthenticationError', message: string } | { __typename?: 'BadRequestError', message: string } | { __typename?: 'Holiday', id: number, name: string, isAnnual: boolean, startDate: any, endDate: any, locations: Array<{ __typename?: 'Location', id: number, name: string }> } };

export type RemoveHolidayMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveHolidayMutation = { __typename?: 'Mutation', removeHoliday: { __typename?: 'AuthenticationError', message: string } | { __typename?: 'BadRequestError', message: string } | { __typename?: 'Holiday', id: number, name: string, isAnnual: boolean } };

export type CreateJobMutationVariables = Exact<{
  title: Scalars['String'];
}>;


export type CreateJobMutation = { __typename?: 'Mutation', createJob: { __typename?: 'AuthenticationError', message: string } | { __typename?: 'BadRequestError', message: string } | { __typename?: 'Job', id: number, title: string } };

export type RemoveJobMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveJobMutation = { __typename?: 'Mutation', removeJob: { __typename?: 'AuthenticationError', message: string } | { __typename?: 'BadRequestError', message: string } | { __typename?: 'Job', id: number, title: string } };

export type UpdateJobMutationVariables = Exact<{
  id: Scalars['Int'];
  title?: InputMaybe<Scalars['String']>;
}>;


export type UpdateJobMutation = { __typename?: 'Mutation', updateJob: { __typename?: 'AuthenticationError', message: string } | { __typename?: 'BadRequestError', message: string } | { __typename?: 'Job', id: number, title: string } };

export type CreateLocationMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateLocationMutation = { __typename?: 'Mutation', createLocation: { __typename?: 'AuthenticationError', message: string } | { __typename?: 'BadRequestError', message: string } | { __typename?: 'Location', id: number, name: string } };

export type RemoveLocationMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveLocationMutation = { __typename?: 'Mutation', removeLocation: { __typename?: 'AuthenticationError', message: string } | { __typename?: 'BadRequestError', message: string } | { __typename?: 'Location', id: number, name: string } };

export type UpdateLocationMutationVariables = Exact<{
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
}>;


export type UpdateLocationMutation = { __typename?: 'Mutation', updateLocation: { __typename?: 'AuthenticationError', message: string } | { __typename?: 'BadRequestError', message: string } | { __typename?: 'Location', id: number, name: string } };

export type CreateTaskMutationVariables = Exact<{
  clientId: Scalars['Int'];
  summary: Scalars['String'];
}>;


export type CreateTaskMutation = { __typename?: 'Mutation', createTask: { __typename: 'AuthenticationError', message: string } | { __typename: 'BadRequestError', message: string } | { __typename: 'Task', id: number, status: GenericStatus, summary: string, client: { __typename?: 'Client', id: number, status: GenericStatus, logoUrl?: string | null | undefined, name: string } } };

export type RemoveTaskMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveTaskMutation = { __typename?: 'Mutation', removeTask: { __typename: 'AuthenticationError', message: string } | { __typename: 'BadRequestError', message: string } | { __typename: 'Task', id: number, status: GenericStatus, summary: string } };

export type UpdateTaskMutationVariables = Exact<{
  clientId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  summary: Scalars['String'];
}>;


export type UpdateTaskMutation = { __typename?: 'Mutation', updateTask: { __typename: 'AuthenticationError', message: string } | { __typename: 'BadRequestError', message: string } | { __typename: 'Task', id: number, status: GenericStatus, summary: string, client: { __typename?: 'Client', id: number, status: GenericStatus, logoUrl?: string | null | undefined, name: string } } };

export type UpdateTaskStatusMutationVariables = Exact<{
  id: Scalars['Int'];
  status: GenericStatus;
}>;


export type UpdateTaskStatusMutation = { __typename?: 'Mutation', updateTaskStatus: { __typename: 'AuthenticationError', message: string } | { __typename: 'BadRequestError', message: string } | { __typename: 'Task', id: number, status: GenericStatus, summary: string, client: { __typename?: 'Client', id: number, status: GenericStatus, logoUrl?: string | null | undefined, name: string } } };

export type CreateTimeSheetMutationVariables = Exact<{
  days: Array<Scalars['DateTime']> | Scalars['DateTime'];
  totalHours: Scalars['Float'];
  clients: Array<TimeSheetClientInput> | TimeSheetClientInput;
}>;


export type CreateTimeSheetMutation = { __typename?: 'Mutation', createTimeSheet: { __typename: 'AuthenticationError', message: string } | { __typename: 'BadRequestError', message: string } | { __typename: 'TimeSheet', id: number, dates: Array<any>, totalHours: number, clients: Array<{ __typename?: 'TimeSheetClient', id: number, totalHours: number, tasks: Array<{ __typename?: 'TimeSheetClientTask', id: number, hours: number }> }> } };

export type RemoveTimeSheetMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveTimeSheetMutation = { __typename?: 'Mutation', removeTimeSheet: { __typename: 'AuthenticationError', message: string } | { __typename: 'BadRequestError', message: string } | { __typename: 'TimeSheet', id: number, totalHours: number } };

export type UpdateTimeSheetMutationVariables = Exact<{
  id: Scalars['Int'];
  days: Array<Scalars['DateTime']> | Scalars['DateTime'];
  totalHours: Scalars['Float'];
  clients: Array<TimeSheetClientInput> | TimeSheetClientInput;
}>;


export type UpdateTimeSheetMutation = { __typename?: 'Mutation', updateTimeSheet: { __typename: 'AuthenticationError', message: string } | { __typename: 'BadRequestError', message: string } | { __typename: 'TimeSheet', id: number } };

export type SkipWeekMutationVariables = Exact<{
  weekStart: Scalars['String'];
}>;


export type SkipWeekMutation = { __typename?: 'Mutation', skipWeek: { __typename: 'AuthenticationError', message: string } | { __typename: 'BadRequestError', message: string } | { __typename: 'TimeSheet', id: number } };

export type ClientQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ClientQuery = { __typename?: 'Query', client: { __typename?: 'AuthenticationError', message: string } | { __typename?: 'BadRequestError', message: string } | { __typename?: 'Client', id: number, name: string, status: GenericStatus, logoUrl?: string | null | undefined } };

export type ClientsQueryVariables = Exact<{
  term?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<GenericStatus>;
  take?: InputMaybe<Scalars['Int']>;
}>;


export type ClientsQuery = { __typename?: 'Query', clients: { __typename?: 'AuthenticationError', message: string } | { __typename?: 'BadRequestError', message: string } | { __typename?: 'Clients', count: number, entities: Array<{ __typename?: 'Client', id: number, name: string, status: GenericStatus, logoUrl?: string | null | undefined }> } };

export type CurrenciesQueryVariables = Exact<{
  term?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type CurrenciesQuery = { __typename?: 'Query', currencies: { __typename?: 'AuthenticationError', message: string } | { __typename?: 'BadRequestError', message: string } | { __typename?: 'Currencies', count: number, entities: Array<{ __typename?: 'Currency', id: number, name: string }> } };

export type DepartmentsQueryVariables = Exact<{
  term?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type DepartmentsQuery = { __typename?: 'Query', departments: { __typename?: 'AuthenticationError', message: string } | { __typename?: 'BadRequestError', message: string } | { __typename?: 'Departments', count: number, entities: Array<{ __typename?: 'Department', id: number, name: string }> } };

export type EmployeesQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  locationIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<EmployeeStatus> | EmployeeStatus>;
  take?: InputMaybe<Scalars['Int']>;
  term?: InputMaybe<Scalars['String']>;
  managerId?: InputMaybe<Scalars['Int']>;
  departmentIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type EmployeesQuery = { __typename?: 'Query', employees: { __typename: 'AuthenticationError', message: string } | { __typename: 'BadRequestError', message: string } | { __typename: 'Employees', count: number, entities: Array<{ __typename?: 'Employee', id: number, firstName: string, lastName: string, status: EmployeeStatus }> } };

export type ManagersQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  locationIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<EmployeeStatus> | EmployeeStatus>;
  take?: InputMaybe<Scalars['Int']>;
  term?: InputMaybe<Scalars['String']>;
  managerId?: InputMaybe<Scalars['Int']>;
  departmentIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type ManagersQuery = { __typename?: 'Query', managers: { __typename: 'AuthenticationError', message: string } | { __typename: 'BadRequestError', message: string } | { __typename: 'Employees', count: number, entities: Array<{ __typename?: 'Employee', id: number, firstName: string, lastName: string, status: EmployeeStatus }> } };

export type EmploymentStatusesQueryVariables = Exact<{
  term?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type EmploymentStatusesQuery = { __typename?: 'Query', employmentStatuses: { __typename?: 'AuthenticationError', message: string } | { __typename?: 'BadRequestError', message: string } | { __typename?: 'EmploymentStatuses', count: number, entities: Array<{ __typename?: 'EmploymentStatus', id: number, name: string }> } };

export type HolidayQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type HolidayQuery = { __typename?: 'Query', holiday: { __typename?: 'AuthenticationError', message: string } | { __typename?: 'BadRequestError', message: string } | { __typename?: 'Holiday', id: number, name: string, isAnnual: boolean, locations: Array<{ __typename?: 'Location', id: number, name: string }> } };

export type HolidaysQueryVariables = Exact<{
  startDate?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  locationIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  term?: InputMaybe<Scalars['String']>;
}>;


export type HolidaysQuery = { __typename?: 'Query', holidays: { __typename?: 'AuthenticationError', message: string } | { __typename?: 'BadRequestError', message: string } | { __typename?: 'Holidays', count: number, entities: Array<{ __typename?: 'Holiday', id: number, name: string, isAnnual: boolean, startDate: any, endDate: any, locations: Array<{ __typename?: 'Location', id: number, name: string }> }> } };

export type JobsQueryVariables = Exact<{
  term?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type JobsQuery = { __typename?: 'Query', jobs: { __typename?: 'AuthenticationError', message: string } | { __typename?: 'BadRequestError', message: string } | { __typename?: 'Jobs', count: number, entities: Array<{ __typename?: 'Job', id: number, title: string }> } };

export type LocationsQueryVariables = Exact<{
  term?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type LocationsQuery = { __typename?: 'Query', locations: { __typename?: 'AuthenticationError', message: string } | { __typename?: 'BadRequestError', message: string } | { __typename?: 'Locations', count: number, entities: Array<{ __typename?: 'Location', id: number, name: string }> } };

export type EmployeeReportQueryVariables = Exact<{
  clientIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  employeeIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  employmentStatusIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  jobIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  locationIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type EmployeeReportQuery = { __typename?: 'Query', employeeReport: { __typename?: 'AuthenticationError', message: string } | { __typename?: 'BadRequestError', message: string } | { __typename?: 'EmployeesReport', entities: Array<{ __typename?: 'EmployeeReport', id: number, firstName: string, lastName: string, clients: Array<{ __typename?: 'TimeSheetClientReport', id: number, name: string, hoursPerDay: number, tasks: Array<{ __typename?: 'TimeSheetClientTaskReport', id: number, hoursPerDay: number, summary: string }> }> }> } };

export type ClientReportQueryVariables = Exact<{
  clientIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  employeeIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  employmentStatusIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  jobIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  locationIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type ClientReportQuery = { __typename?: 'Query', clientReport: { __typename?: 'AuthenticationError', message: string } | { __typename?: 'BadRequestError', message: string } | { __typename?: 'ClientsReport', entities: Array<{ __typename?: 'ClientReport', id: number, name: string, tasks: Array<{ __typename?: 'ClientTaskReport', id: number, summary: string, hoursPerDay: number, employees: Array<{ __typename?: 'ClientEmployeeReport', id: number, firstName: string, lastName: string, hoursPerDay: number }> }> }> } };

export type RateReportQueryVariables = Exact<{
  clientIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  employeeIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  employmentStatusIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  jobIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  locationIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  departmentIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type RateReportQuery = { __typename?: 'Query', rateReport: { __typename?: 'AuthenticationError', message: string } | { __typename?: 'BadRequestError', message: string } | { __typename?: 'RatesReport', entities: Array<{ __typename?: 'RateReport', id: number, firstName: string, lastName: string, groupedHoursByHourlyRateAndClient: string, groupedSalariesByHourlyRateAndClient: string, hourlyRates: Array<{ __typename?: 'HourlyRate', id: number, rate: number }>, location: { __typename?: 'Location', id: number, name: string }, employmentStatus: { __typename?: 'EmploymentStatus', id: number, name: string }, currency: { __typename?: 'Currency', id: number, name: string }, clients: Array<{ __typename?: 'RateClientReport', id: number, name: string, hoursPerDay: number, salary: number }> }> } };

export type EmployeePastTimeSheetQueryVariables = Exact<{
  employeeId?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
}>;


export type EmployeePastTimeSheetQuery = { __typename?: 'Query', employeePastTimeSheet: { __typename?: 'AuthenticationError', message: string } | { __typename?: 'BadRequestError', message: string } | { __typename?: 'TimeSheet', id: number, dates: Array<any>, totalHours: number, clients: Array<{ __typename?: 'TimeSheetClient', id: number, totalHours: number, client: { __typename?: 'Client', id: number, name: string, status: GenericStatus }, tasks: Array<{ __typename?: 'TimeSheetClientTask', id: number, hours: number, task: { __typename?: 'Task', id: number, summary: string, status: GenericStatus, client: { __typename?: 'Client', id: number, name: string, status: GenericStatus } } }> }> } };

export type FindClientTasksQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<GenericStatus>;
  take?: InputMaybe<Scalars['Int']>;
  summary?: InputMaybe<Scalars['String']>;
  clientIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type FindClientTasksQuery = { __typename?: 'Query', findClientTasks: { __typename?: 'AuthenticationError', message: string } | { __typename?: 'BadRequestError', message: string } | { __typename?: 'Tasks', count: number, entities: Array<{ __typename?: 'Task', id: number, status: GenericStatus, summary: string, client: { __typename?: 'Client', id: number, status: GenericStatus, logoUrl?: string | null | undefined, name: string } }> } };

export type FindActiveClientsWithActiveTasksQueryVariables = Exact<{ [key: string]: never; }>;


export type FindActiveClientsWithActiveTasksQuery = { __typename?: 'Query', findActiveClientsWithActiveTasks: { __typename?: 'AuthenticationError', message: string } | { __typename?: 'BadRequestError', message: string } | { __typename?: 'Tasks', count: number, entities: Array<{ __typename?: 'Task', id: number, status: GenericStatus, summary: string, client: { __typename?: 'Client', id: number, status: GenericStatus, logoUrl?: string | null | undefined, name: string } }>, clients?: Array<{ __typename?: 'Client', id: number, name: string, status: GenericStatus, logoUrl?: string | null | undefined }> | null | undefined } };

export type TimeSheetDatesQueryVariables = Exact<{ [key: string]: never; }>;


export type TimeSheetDatesQuery = { __typename?: 'Query', timeSheetDates: { __typename?: 'AuthenticationError', message: string } | { __typename?: 'BadRequestError', message: string } | { __typename?: 'TimeSheetDateTypes', data: Array<{ __typename?: 'TimeSheetDateType', date: any, name: string, weekend: boolean, holiday: boolean }> } };

export type PreviousTimeSheetQueryVariables = Exact<{
  startDate?: InputMaybe<Scalars['DateTime']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
}>;


export type PreviousTimeSheetQuery = { __typename?: 'Query', previousTimeSheet: { __typename?: 'AuthenticationError', message: string } | { __typename?: 'BadRequestError', message: string } | { __typename?: 'TimeSheets', count: number, entities: Array<{ __typename?: 'TimeSheet', id: number, totalHours: number, dates: Array<any>, clients: Array<{ __typename?: 'TimeSheetClient', id: number, totalHours: number, tasks: Array<{ __typename?: 'TimeSheetClientTask', id: number, hours: number, task: { __typename?: 'Task', id: number, status: GenericStatus, summary: string } }>, client: { __typename?: 'Client', id: number, name: string, status: GenericStatus } }> }> } };

export type UserLastTimeSheetQueryVariables = Exact<{ [key: string]: never; }>;


export type UserLastTimeSheetQuery = { __typename?: 'Query', userLastTimeSheet: { __typename?: 'AuthenticationError', message: string } | { __typename?: 'BadRequestError', message: string } | { __typename?: 'TimeSheets', count: number, entities: Array<{ __typename?: 'TimeSheet', id: number, totalHours: number, dates: Array<any>, clients: Array<{ __typename?: 'TimeSheetClient', id: number, totalHours: number, tasks: Array<{ __typename?: 'TimeSheetClientTask', id: number, hours: number, task: { __typename?: 'Task', id: number, status: GenericStatus, summary: string } }>, client: { __typename?: 'Client', id: number, name: string, status: GenericStatus } }> }> } };


export const CreateClientDocument = gql`
    mutation createClient($name: String!, $logo: Upload) {
  createClient(createClientInput: {name: $name}, logo: $logo) {
    __typename
    ... on Client {
      id
      logoUrl
      name
    }
    ... on BadRequestError {
      message
    }
    ... on AuthenticationError {
      message
    }
  }
}
    `;
export type CreateClientMutationFn = Apollo.MutationFunction<CreateClientMutation, CreateClientMutationVariables>;

/**
 * __useCreateClientMutation__
 *
 * To run a mutation, you first call `useCreateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientMutation, { data, loading, error }] = useCreateClientMutation({
 *   variables: {
 *      name: // value for 'name'
 *      logo: // value for 'logo'
 *   },
 * });
 */
export function useCreateClientMutation(baseOptions?: Apollo.MutationHookOptions<CreateClientMutation, CreateClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClientMutation, CreateClientMutationVariables>(CreateClientDocument, options);
      }
export type CreateClientMutationHookResult = ReturnType<typeof useCreateClientMutation>;
export type CreateClientMutationResult = Apollo.MutationResult<CreateClientMutation>;
export type CreateClientMutationOptions = Apollo.BaseMutationOptions<CreateClientMutation, CreateClientMutationVariables>;
export const UpdateClientDocument = gql`
    mutation updateClient($id: Int!, $name: String, $logo: Upload) {
  updateClient(updateClientInput: {id: $id, name: $name}, logo: $logo) {
    __typename
    ... on Client {
      id
      name
      status
      logoUrl
    }
    ... on BadRequestError {
      message
    }
    ... on AuthenticationError {
      message
    }
  }
}
    `;
export type UpdateClientMutationFn = Apollo.MutationFunction<UpdateClientMutation, UpdateClientMutationVariables>;

/**
 * __useUpdateClientMutation__
 *
 * To run a mutation, you first call `useUpdateClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientMutation, { data, loading, error }] = useUpdateClientMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      logo: // value for 'logo'
 *   },
 * });
 */
export function useUpdateClientMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClientMutation, UpdateClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClientMutation, UpdateClientMutationVariables>(UpdateClientDocument, options);
      }
export type UpdateClientMutationHookResult = ReturnType<typeof useUpdateClientMutation>;
export type UpdateClientMutationResult = Apollo.MutationResult<UpdateClientMutation>;
export type UpdateClientMutationOptions = Apollo.BaseMutationOptions<UpdateClientMutation, UpdateClientMutationVariables>;
export const RemoveClientDocument = gql`
    mutation removeClient($id: Int!) {
  removeClient(id: $id) {
    __typename
    ... on Client {
      id
      logoUrl
      name
    }
    ... on BadRequestError {
      message
    }
    ... on Error {
      message
    }
  }
}
    `;
export type RemoveClientMutationFn = Apollo.MutationFunction<RemoveClientMutation, RemoveClientMutationVariables>;

/**
 * __useRemoveClientMutation__
 *
 * To run a mutation, you first call `useRemoveClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeClientMutation, { data, loading, error }] = useRemoveClientMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveClientMutation(baseOptions?: Apollo.MutationHookOptions<RemoveClientMutation, RemoveClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveClientMutation, RemoveClientMutationVariables>(RemoveClientDocument, options);
      }
export type RemoveClientMutationHookResult = ReturnType<typeof useRemoveClientMutation>;
export type RemoveClientMutationResult = Apollo.MutationResult<RemoveClientMutation>;
export type RemoveClientMutationOptions = Apollo.BaseMutationOptions<RemoveClientMutation, RemoveClientMutationVariables>;
export const UpdateClientStatusDocument = gql`
    mutation updateClientStatus($id: Int!, $status: GenericStatus!) {
  updateClientStatus(id: $id, status: $status) {
    __typename
    ... on Client {
      id
      name
      status
      logoUrl
    }
    ... on BadRequestError {
      message
    }
    ... on AuthenticationError {
      message
    }
  }
}
    `;
export type UpdateClientStatusMutationFn = Apollo.MutationFunction<UpdateClientStatusMutation, UpdateClientStatusMutationVariables>;

/**
 * __useUpdateClientStatusMutation__
 *
 * To run a mutation, you first call `useUpdateClientStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClientStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClientStatusMutation, { data, loading, error }] = useUpdateClientStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateClientStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClientStatusMutation, UpdateClientStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClientStatusMutation, UpdateClientStatusMutationVariables>(UpdateClientStatusDocument, options);
      }
export type UpdateClientStatusMutationHookResult = ReturnType<typeof useUpdateClientStatusMutation>;
export type UpdateClientStatusMutationResult = Apollo.MutationResult<UpdateClientStatusMutation>;
export type UpdateClientStatusMutationOptions = Apollo.BaseMutationOptions<UpdateClientStatusMutation, UpdateClientStatusMutationVariables>;
export const CreateCurrencyDocument = gql`
    mutation createCurrency($name: String!) {
  createCurrency(createCurrencyInput: {name: $name}) {
    ... on Currency {
      id
      name
    }
    ... on AuthenticationError {
      message
    }
    ... on BadRequestError {
      message
    }
    ... on Error {
      message
    }
  }
}
    `;
export type CreateCurrencyMutationFn = Apollo.MutationFunction<CreateCurrencyMutation, CreateCurrencyMutationVariables>;

/**
 * __useCreateCurrencyMutation__
 *
 * To run a mutation, you first call `useCreateCurrencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCurrencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCurrencyMutation, { data, loading, error }] = useCreateCurrencyMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateCurrencyMutation(baseOptions?: Apollo.MutationHookOptions<CreateCurrencyMutation, CreateCurrencyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCurrencyMutation, CreateCurrencyMutationVariables>(CreateCurrencyDocument, options);
      }
export type CreateCurrencyMutationHookResult = ReturnType<typeof useCreateCurrencyMutation>;
export type CreateCurrencyMutationResult = Apollo.MutationResult<CreateCurrencyMutation>;
export type CreateCurrencyMutationOptions = Apollo.BaseMutationOptions<CreateCurrencyMutation, CreateCurrencyMutationVariables>;
export const RemoveCurrencyDocument = gql`
    mutation removeCurrency($id: Int!) {
  removeCurrency(id: $id) {
    ... on Currency {
      id
      name
    }
    ... on AuthenticationError {
      message
    }
    ... on BadRequestError {
      message
    }
    ... on Error {
      message
    }
  }
}
    `;
export type RemoveCurrencyMutationFn = Apollo.MutationFunction<RemoveCurrencyMutation, RemoveCurrencyMutationVariables>;

/**
 * __useRemoveCurrencyMutation__
 *
 * To run a mutation, you first call `useRemoveCurrencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCurrencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCurrencyMutation, { data, loading, error }] = useRemoveCurrencyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCurrencyMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCurrencyMutation, RemoveCurrencyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCurrencyMutation, RemoveCurrencyMutationVariables>(RemoveCurrencyDocument, options);
      }
export type RemoveCurrencyMutationHookResult = ReturnType<typeof useRemoveCurrencyMutation>;
export type RemoveCurrencyMutationResult = Apollo.MutationResult<RemoveCurrencyMutation>;
export type RemoveCurrencyMutationOptions = Apollo.BaseMutationOptions<RemoveCurrencyMutation, RemoveCurrencyMutationVariables>;
export const UpdateCurrencyDocument = gql`
    mutation updateCurrency($id: Int!, $name: String) {
  updateCurrency(updateCurrencyInput: {id: $id, name: $name}) {
    ... on Currency {
      id
      name
    }
    ... on BadRequestError {
      message
    }
    ... on AuthenticationError {
      message
    }
    ... on Error {
      message
    }
  }
}
    `;
export type UpdateCurrencyMutationFn = Apollo.MutationFunction<UpdateCurrencyMutation, UpdateCurrencyMutationVariables>;

/**
 * __useUpdateCurrencyMutation__
 *
 * To run a mutation, you first call `useUpdateCurrencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrencyMutation, { data, loading, error }] = useUpdateCurrencyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateCurrencyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCurrencyMutation, UpdateCurrencyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCurrencyMutation, UpdateCurrencyMutationVariables>(UpdateCurrencyDocument, options);
      }
export type UpdateCurrencyMutationHookResult = ReturnType<typeof useUpdateCurrencyMutation>;
export type UpdateCurrencyMutationResult = Apollo.MutationResult<UpdateCurrencyMutation>;
export type UpdateCurrencyMutationOptions = Apollo.BaseMutationOptions<UpdateCurrencyMutation, UpdateCurrencyMutationVariables>;
export const CreateDepartmentDocument = gql`
    mutation createDepartment($name: String!) {
  createDepartment(createDepartmentInput: {name: $name}) {
    ... on Department {
      id
      name
    }
    ... on AuthenticationError {
      message
    }
    ... on BadRequestError {
      message
    }
    ... on Error {
      message
    }
  }
}
    `;
export type CreateDepartmentMutationFn = Apollo.MutationFunction<CreateDepartmentMutation, CreateDepartmentMutationVariables>;

/**
 * __useCreateDepartmentMutation__
 *
 * To run a mutation, you first call `useCreateDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDepartmentMutation, { data, loading, error }] = useCreateDepartmentMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateDepartmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateDepartmentMutation, CreateDepartmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDepartmentMutation, CreateDepartmentMutationVariables>(CreateDepartmentDocument, options);
      }
export type CreateDepartmentMutationHookResult = ReturnType<typeof useCreateDepartmentMutation>;
export type CreateDepartmentMutationResult = Apollo.MutationResult<CreateDepartmentMutation>;
export type CreateDepartmentMutationOptions = Apollo.BaseMutationOptions<CreateDepartmentMutation, CreateDepartmentMutationVariables>;
export const RemoveDepartmentDocument = gql`
    mutation removeDepartment($id: Int!) {
  removeDepartment(id: $id) {
    ... on Department {
      id
      name
    }
    ... on AuthenticationError {
      message
    }
    ... on BadRequestError {
      message
    }
    ... on Error {
      message
    }
  }
}
    `;
export type RemoveDepartmentMutationFn = Apollo.MutationFunction<RemoveDepartmentMutation, RemoveDepartmentMutationVariables>;

/**
 * __useRemoveDepartmentMutation__
 *
 * To run a mutation, you first call `useRemoveDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeDepartmentMutation, { data, loading, error }] = useRemoveDepartmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveDepartmentMutation(baseOptions?: Apollo.MutationHookOptions<RemoveDepartmentMutation, RemoveDepartmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveDepartmentMutation, RemoveDepartmentMutationVariables>(RemoveDepartmentDocument, options);
      }
export type RemoveDepartmentMutationHookResult = ReturnType<typeof useRemoveDepartmentMutation>;
export type RemoveDepartmentMutationResult = Apollo.MutationResult<RemoveDepartmentMutation>;
export type RemoveDepartmentMutationOptions = Apollo.BaseMutationOptions<RemoveDepartmentMutation, RemoveDepartmentMutationVariables>;
export const UpdateDepartmentDocument = gql`
    mutation updateDepartment($id: Int!, $name: String) {
  updateDepartment(updateDepartmentInput: {id: $id, name: $name}) {
    ... on Department {
      id
      name
    }
    ... on BadRequestError {
      message
    }
    ... on AuthenticationError {
      message
    }
    ... on Error {
      message
    }
  }
}
    `;
export type UpdateDepartmentMutationFn = Apollo.MutationFunction<UpdateDepartmentMutation, UpdateDepartmentMutationVariables>;

/**
 * __useUpdateDepartmentMutation__
 *
 * To run a mutation, you first call `useUpdateDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDepartmentMutation, { data, loading, error }] = useUpdateDepartmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateDepartmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDepartmentMutation, UpdateDepartmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDepartmentMutation, UpdateDepartmentMutationVariables>(UpdateDepartmentDocument, options);
      }
export type UpdateDepartmentMutationHookResult = ReturnType<typeof useUpdateDepartmentMutation>;
export type UpdateDepartmentMutationResult = Apollo.MutationResult<UpdateDepartmentMutation>;
export type UpdateDepartmentMutationOptions = Apollo.BaseMutationOptions<UpdateDepartmentMutation, UpdateDepartmentMutationVariables>;
export const CreateEmploymentStatusDocument = gql`
    mutation createEmploymentStatus($name: String!) {
  createEmploymentStatus(createEmploymentStatusInput: {name: $name}) {
    ... on EmploymentStatus {
      id
      name
    }
    ... on AuthenticationError {
      message
    }
    ... on BadRequestError {
      message
    }
    ... on Error {
      message
    }
  }
}
    `;
export type CreateEmploymentStatusMutationFn = Apollo.MutationFunction<CreateEmploymentStatusMutation, CreateEmploymentStatusMutationVariables>;

/**
 * __useCreateEmploymentStatusMutation__
 *
 * To run a mutation, you first call `useCreateEmploymentStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmploymentStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmploymentStatusMutation, { data, loading, error }] = useCreateEmploymentStatusMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateEmploymentStatusMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmploymentStatusMutation, CreateEmploymentStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEmploymentStatusMutation, CreateEmploymentStatusMutationVariables>(CreateEmploymentStatusDocument, options);
      }
export type CreateEmploymentStatusMutationHookResult = ReturnType<typeof useCreateEmploymentStatusMutation>;
export type CreateEmploymentStatusMutationResult = Apollo.MutationResult<CreateEmploymentStatusMutation>;
export type CreateEmploymentStatusMutationOptions = Apollo.BaseMutationOptions<CreateEmploymentStatusMutation, CreateEmploymentStatusMutationVariables>;
export const DeleteEmploymentStatusDocument = gql`
    mutation deleteEmploymentStatus($id: Int!) {
  removeEmploymentStatus(id: $id) {
    ... on EmploymentStatus {
      id
      name
    }
    ... on AuthenticationError {
      message
    }
    ... on BadRequestError {
      message
    }
    ... on Error {
      message
    }
  }
}
    `;
export type DeleteEmploymentStatusMutationFn = Apollo.MutationFunction<DeleteEmploymentStatusMutation, DeleteEmploymentStatusMutationVariables>;

/**
 * __useDeleteEmploymentStatusMutation__
 *
 * To run a mutation, you first call `useDeleteEmploymentStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmploymentStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmploymentStatusMutation, { data, loading, error }] = useDeleteEmploymentStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEmploymentStatusMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEmploymentStatusMutation, DeleteEmploymentStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEmploymentStatusMutation, DeleteEmploymentStatusMutationVariables>(DeleteEmploymentStatusDocument, options);
      }
export type DeleteEmploymentStatusMutationHookResult = ReturnType<typeof useDeleteEmploymentStatusMutation>;
export type DeleteEmploymentStatusMutationResult = Apollo.MutationResult<DeleteEmploymentStatusMutation>;
export type DeleteEmploymentStatusMutationOptions = Apollo.BaseMutationOptions<DeleteEmploymentStatusMutation, DeleteEmploymentStatusMutationVariables>;
export const UpdateEmploymentStatusDocument = gql`
    mutation updateEmploymentStatus($id: Int!, $name: String) {
  updateEmploymentStatus(updateEmploymentStatusInput: {id: $id, name: $name}) {
    ... on EmploymentStatus {
      id
      name
    }
    ... on BadRequestError {
      message
    }
    ... on AuthenticationError {
      message
    }
    ... on Error {
      message
    }
  }
}
    `;
export type UpdateEmploymentStatusMutationFn = Apollo.MutationFunction<UpdateEmploymentStatusMutation, UpdateEmploymentStatusMutationVariables>;

/**
 * __useUpdateEmploymentStatusMutation__
 *
 * To run a mutation, you first call `useUpdateEmploymentStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmploymentStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmploymentStatusMutation, { data, loading, error }] = useUpdateEmploymentStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateEmploymentStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmploymentStatusMutation, UpdateEmploymentStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEmploymentStatusMutation, UpdateEmploymentStatusMutationVariables>(UpdateEmploymentStatusDocument, options);
      }
export type UpdateEmploymentStatusMutationHookResult = ReturnType<typeof useUpdateEmploymentStatusMutation>;
export type UpdateEmploymentStatusMutationResult = Apollo.MutationResult<UpdateEmploymentStatusMutation>;
export type UpdateEmploymentStatusMutationOptions = Apollo.BaseMutationOptions<UpdateEmploymentStatusMutation, UpdateEmploymentStatusMutationVariables>;
export const CreateHolidayDocument = gql`
    mutation createHoliday($name: String!, $isAnnual: Boolean!, $startDate: DateTime!, $endDate: DateTime!, $locationIds: [Int!]!) {
  createHoliday(
    createHolidayInput: {name: $name, isAnnual: $isAnnual, startDate: $startDate, endDate: $endDate, locationIds: $locationIds}
  ) {
    ... on Holiday {
      id
      name
      isAnnual
      startDate
      endDate
      locations {
        id
        name
      }
    }
    ... on BadRequestError {
      message
    }
    ... on AuthenticationError {
      message
    }
  }
}
    `;
export type CreateHolidayMutationFn = Apollo.MutationFunction<CreateHolidayMutation, CreateHolidayMutationVariables>;

/**
 * __useCreateHolidayMutation__
 *
 * To run a mutation, you first call `useCreateHolidayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHolidayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHolidayMutation, { data, loading, error }] = useCreateHolidayMutation({
 *   variables: {
 *      name: // value for 'name'
 *      isAnnual: // value for 'isAnnual'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      locationIds: // value for 'locationIds'
 *   },
 * });
 */
export function useCreateHolidayMutation(baseOptions?: Apollo.MutationHookOptions<CreateHolidayMutation, CreateHolidayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateHolidayMutation, CreateHolidayMutationVariables>(CreateHolidayDocument, options);
      }
export type CreateHolidayMutationHookResult = ReturnType<typeof useCreateHolidayMutation>;
export type CreateHolidayMutationResult = Apollo.MutationResult<CreateHolidayMutation>;
export type CreateHolidayMutationOptions = Apollo.BaseMutationOptions<CreateHolidayMutation, CreateHolidayMutationVariables>;
export const UpdateHolidayDocument = gql`
    mutation updateHoliday($id: Int!, $name: String, $isAnnual: Boolean, $startDate: DateTime, $endDate: DateTime, $locationIds: [Int!]) {
  updateHoliday(
    updateHolidayInput: {id: $id, name: $name, isAnnual: $isAnnual, startDate: $startDate, endDate: $endDate, locationIds: $locationIds}
  ) {
    ... on Holiday {
      id
      name
      isAnnual
      startDate
      endDate
      locations {
        id
        name
      }
    }
    ... on BadRequestError {
      message
    }
    ... on AuthenticationError {
      message
    }
  }
}
    `;
export type UpdateHolidayMutationFn = Apollo.MutationFunction<UpdateHolidayMutation, UpdateHolidayMutationVariables>;

/**
 * __useUpdateHolidayMutation__
 *
 * To run a mutation, you first call `useUpdateHolidayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHolidayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHolidayMutation, { data, loading, error }] = useUpdateHolidayMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      isAnnual: // value for 'isAnnual'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      locationIds: // value for 'locationIds'
 *   },
 * });
 */
export function useUpdateHolidayMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHolidayMutation, UpdateHolidayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateHolidayMutation, UpdateHolidayMutationVariables>(UpdateHolidayDocument, options);
      }
export type UpdateHolidayMutationHookResult = ReturnType<typeof useUpdateHolidayMutation>;
export type UpdateHolidayMutationResult = Apollo.MutationResult<UpdateHolidayMutation>;
export type UpdateHolidayMutationOptions = Apollo.BaseMutationOptions<UpdateHolidayMutation, UpdateHolidayMutationVariables>;
export const RemoveHolidayDocument = gql`
    mutation removeHoliday($id: Int!) {
  removeHoliday(id: $id) {
    ... on Holiday {
      id
      name
      isAnnual
    }
    ... on BadRequestError {
      message
    }
    ... on AuthenticationError {
      message
    }
  }
}
    `;
export type RemoveHolidayMutationFn = Apollo.MutationFunction<RemoveHolidayMutation, RemoveHolidayMutationVariables>;

/**
 * __useRemoveHolidayMutation__
 *
 * To run a mutation, you first call `useRemoveHolidayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveHolidayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeHolidayMutation, { data, loading, error }] = useRemoveHolidayMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveHolidayMutation(baseOptions?: Apollo.MutationHookOptions<RemoveHolidayMutation, RemoveHolidayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveHolidayMutation, RemoveHolidayMutationVariables>(RemoveHolidayDocument, options);
      }
export type RemoveHolidayMutationHookResult = ReturnType<typeof useRemoveHolidayMutation>;
export type RemoveHolidayMutationResult = Apollo.MutationResult<RemoveHolidayMutation>;
export type RemoveHolidayMutationOptions = Apollo.BaseMutationOptions<RemoveHolidayMutation, RemoveHolidayMutationVariables>;
export const CreateJobDocument = gql`
    mutation createJob($title: String!) {
  createJob(createJobInput: {title: $title}) {
    ... on Job {
      id
      title
    }
    ... on AuthenticationError {
      message
    }
    ... on BadRequestError {
      message
    }
    ... on Error {
      message
    }
  }
}
    `;
export type CreateJobMutationFn = Apollo.MutationFunction<CreateJobMutation, CreateJobMutationVariables>;

/**
 * __useCreateJobMutation__
 *
 * To run a mutation, you first call `useCreateJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJobMutation, { data, loading, error }] = useCreateJobMutation({
 *   variables: {
 *      title: // value for 'title'
 *   },
 * });
 */
export function useCreateJobMutation(baseOptions?: Apollo.MutationHookOptions<CreateJobMutation, CreateJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateJobMutation, CreateJobMutationVariables>(CreateJobDocument, options);
      }
export type CreateJobMutationHookResult = ReturnType<typeof useCreateJobMutation>;
export type CreateJobMutationResult = Apollo.MutationResult<CreateJobMutation>;
export type CreateJobMutationOptions = Apollo.BaseMutationOptions<CreateJobMutation, CreateJobMutationVariables>;
export const RemoveJobDocument = gql`
    mutation removeJob($id: Int!) {
  removeJob(id: $id) {
    ... on Job {
      id
      title
    }
    ... on AuthenticationError {
      message
    }
    ... on BadRequestError {
      message
    }
    ... on Error {
      message
    }
  }
}
    `;
export type RemoveJobMutationFn = Apollo.MutationFunction<RemoveJobMutation, RemoveJobMutationVariables>;

/**
 * __useRemoveJobMutation__
 *
 * To run a mutation, you first call `useRemoveJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeJobMutation, { data, loading, error }] = useRemoveJobMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveJobMutation(baseOptions?: Apollo.MutationHookOptions<RemoveJobMutation, RemoveJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveJobMutation, RemoveJobMutationVariables>(RemoveJobDocument, options);
      }
export type RemoveJobMutationHookResult = ReturnType<typeof useRemoveJobMutation>;
export type RemoveJobMutationResult = Apollo.MutationResult<RemoveJobMutation>;
export type RemoveJobMutationOptions = Apollo.BaseMutationOptions<RemoveJobMutation, RemoveJobMutationVariables>;
export const UpdateJobDocument = gql`
    mutation updateJob($id: Int!, $title: String) {
  updateJob(updateJobInput: {id: $id, title: $title}) {
    ... on Job {
      id
      title
    }
    ... on BadRequestError {
      message
    }
    ... on AuthenticationError {
      message
    }
    ... on Error {
      message
    }
  }
}
    `;
export type UpdateJobMutationFn = Apollo.MutationFunction<UpdateJobMutation, UpdateJobMutationVariables>;

/**
 * __useUpdateJobMutation__
 *
 * To run a mutation, you first call `useUpdateJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobMutation, { data, loading, error }] = useUpdateJobMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useUpdateJobMutation(baseOptions?: Apollo.MutationHookOptions<UpdateJobMutation, UpdateJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateJobMutation, UpdateJobMutationVariables>(UpdateJobDocument, options);
      }
export type UpdateJobMutationHookResult = ReturnType<typeof useUpdateJobMutation>;
export type UpdateJobMutationResult = Apollo.MutationResult<UpdateJobMutation>;
export type UpdateJobMutationOptions = Apollo.BaseMutationOptions<UpdateJobMutation, UpdateJobMutationVariables>;
export const CreateLocationDocument = gql`
    mutation createLocation($name: String!) {
  createLocation(createLocationInput: {name: $name}) {
    ... on Location {
      id
      name
    }
    ... on AuthenticationError {
      message
    }
    ... on BadRequestError {
      message
    }
    ... on Error {
      message
    }
  }
}
    `;
export type CreateLocationMutationFn = Apollo.MutationFunction<CreateLocationMutation, CreateLocationMutationVariables>;

/**
 * __useCreateLocationMutation__
 *
 * To run a mutation, you first call `useCreateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationMutation, { data, loading, error }] = useCreateLocationMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateLocationMutation(baseOptions?: Apollo.MutationHookOptions<CreateLocationMutation, CreateLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLocationMutation, CreateLocationMutationVariables>(CreateLocationDocument, options);
      }
export type CreateLocationMutationHookResult = ReturnType<typeof useCreateLocationMutation>;
export type CreateLocationMutationResult = Apollo.MutationResult<CreateLocationMutation>;
export type CreateLocationMutationOptions = Apollo.BaseMutationOptions<CreateLocationMutation, CreateLocationMutationVariables>;
export const RemoveLocationDocument = gql`
    mutation removeLocation($id: Int!) {
  removeLocation(id: $id) {
    ... on Location {
      id
      name
    }
    ... on AuthenticationError {
      message
    }
    ... on BadRequestError {
      message
    }
    ... on Error {
      message
    }
  }
}
    `;
export type RemoveLocationMutationFn = Apollo.MutationFunction<RemoveLocationMutation, RemoveLocationMutationVariables>;

/**
 * __useRemoveLocationMutation__
 *
 * To run a mutation, you first call `useRemoveLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeLocationMutation, { data, loading, error }] = useRemoveLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveLocationMutation(baseOptions?: Apollo.MutationHookOptions<RemoveLocationMutation, RemoveLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveLocationMutation, RemoveLocationMutationVariables>(RemoveLocationDocument, options);
      }
export type RemoveLocationMutationHookResult = ReturnType<typeof useRemoveLocationMutation>;
export type RemoveLocationMutationResult = Apollo.MutationResult<RemoveLocationMutation>;
export type RemoveLocationMutationOptions = Apollo.BaseMutationOptions<RemoveLocationMutation, RemoveLocationMutationVariables>;
export const UpdateLocationDocument = gql`
    mutation updateLocation($id: Int!, $name: String) {
  updateLocation(updateLocationInput: {id: $id, name: $name}) {
    ... on Location {
      id
      name
    }
    ... on BadRequestError {
      message
    }
    ... on AuthenticationError {
      message
    }
    ... on Error {
      message
    }
  }
}
    `;
export type UpdateLocationMutationFn = Apollo.MutationFunction<UpdateLocationMutation, UpdateLocationMutationVariables>;

/**
 * __useUpdateLocationMutation__
 *
 * To run a mutation, you first call `useUpdateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationMutation, { data, loading, error }] = useUpdateLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLocationMutation, UpdateLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLocationMutation, UpdateLocationMutationVariables>(UpdateLocationDocument, options);
      }
export type UpdateLocationMutationHookResult = ReturnType<typeof useUpdateLocationMutation>;
export type UpdateLocationMutationResult = Apollo.MutationResult<UpdateLocationMutation>;
export type UpdateLocationMutationOptions = Apollo.BaseMutationOptions<UpdateLocationMutation, UpdateLocationMutationVariables>;
export const CreateTaskDocument = gql`
    mutation createTask($clientId: Int!, $summary: String!) {
  createTask(createTaskInput: {clientId: $clientId, summary: $summary}) {
    __typename
    ... on Task {
      client {
        id
        status
        logoUrl
        name
      }
      id
      status
      summary
    }
    ... on BadRequestError {
      message
    }
    ... on AuthenticationError {
      message
    }
  }
}
    `;
export type CreateTaskMutationFn = Apollo.MutationFunction<CreateTaskMutation, CreateTaskMutationVariables>;

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      summary: // value for 'summary'
 *   },
 * });
 */
export function useCreateTaskMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaskMutation, CreateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(CreateTaskDocument, options);
      }
export type CreateTaskMutationHookResult = ReturnType<typeof useCreateTaskMutation>;
export type CreateTaskMutationResult = Apollo.MutationResult<CreateTaskMutation>;
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<CreateTaskMutation, CreateTaskMutationVariables>;
export const RemoveTaskDocument = gql`
    mutation removeTask($id: Int!) {
  removeTask(id: $id) {
    __typename
    ... on Task {
      id
      status
      summary
    }
    ... on BadRequestError {
      message
    }
    ... on Error {
      message
    }
  }
}
    `;
export type RemoveTaskMutationFn = Apollo.MutationFunction<RemoveTaskMutation, RemoveTaskMutationVariables>;

/**
 * __useRemoveTaskMutation__
 *
 * To run a mutation, you first call `useRemoveTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTaskMutation, { data, loading, error }] = useRemoveTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveTaskMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTaskMutation, RemoveTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTaskMutation, RemoveTaskMutationVariables>(RemoveTaskDocument, options);
      }
export type RemoveTaskMutationHookResult = ReturnType<typeof useRemoveTaskMutation>;
export type RemoveTaskMutationResult = Apollo.MutationResult<RemoveTaskMutation>;
export type RemoveTaskMutationOptions = Apollo.BaseMutationOptions<RemoveTaskMutation, RemoveTaskMutationVariables>;
export const UpdateTaskDocument = gql`
    mutation updateTask($clientId: Int, $id: Int!, $summary: String!) {
  updateTask(updateTaskInput: {clientId: $clientId, id: $id, summary: $summary}) {
    __typename
    ... on Task {
      client {
        id
        status
        logoUrl
        name
      }
      id
      status
      summary
    }
    ... on BadRequestError {
      message
    }
    ... on AuthenticationError {
      message
    }
  }
}
    `;
export type UpdateTaskMutationFn = Apollo.MutationFunction<UpdateTaskMutation, UpdateTaskMutationVariables>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      id: // value for 'id'
 *      summary: // value for 'summary'
 *   },
 * });
 */
export function useUpdateTaskMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaskMutation, UpdateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(UpdateTaskDocument, options);
      }
export type UpdateTaskMutationHookResult = ReturnType<typeof useUpdateTaskMutation>;
export type UpdateTaskMutationResult = Apollo.MutationResult<UpdateTaskMutation>;
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<UpdateTaskMutation, UpdateTaskMutationVariables>;
export const UpdateTaskStatusDocument = gql`
    mutation updateTaskStatus($id: Int!, $status: GenericStatus!) {
  updateTaskStatus(id: $id, status: $status) {
    __typename
    ... on Task {
      client {
        id
        status
        logoUrl
        name
      }
      id
      status
      summary
    }
    ... on BadRequestError {
      message
    }
    ... on AuthenticationError {
      message
    }
  }
}
    `;
export type UpdateTaskStatusMutationFn = Apollo.MutationFunction<UpdateTaskStatusMutation, UpdateTaskStatusMutationVariables>;

/**
 * __useUpdateTaskStatusMutation__
 *
 * To run a mutation, you first call `useUpdateTaskStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskStatusMutation, { data, loading, error }] = useUpdateTaskStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateTaskStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaskStatusMutation, UpdateTaskStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaskStatusMutation, UpdateTaskStatusMutationVariables>(UpdateTaskStatusDocument, options);
      }
export type UpdateTaskStatusMutationHookResult = ReturnType<typeof useUpdateTaskStatusMutation>;
export type UpdateTaskStatusMutationResult = Apollo.MutationResult<UpdateTaskStatusMutation>;
export type UpdateTaskStatusMutationOptions = Apollo.BaseMutationOptions<UpdateTaskStatusMutation, UpdateTaskStatusMutationVariables>;
export const CreateTimeSheetDocument = gql`
    mutation createTimeSheet($days: [DateTime!]!, $totalHours: Float!, $clients: [TimeSheetClientInput!]!) {
  createTimeSheet(
    createTimeSheetInput: {days: $days, clients: $clients, totalHours: $totalHours}
  ) {
    __typename
    ... on TimeSheet {
      id
      dates
      totalHours
      clients {
        id
        totalHours
        tasks {
          id
          hours
        }
      }
    }
    ... on BadRequestError {
      message
    }
    ... on AuthenticationError {
      message
    }
  }
}
    `;
export type CreateTimeSheetMutationFn = Apollo.MutationFunction<CreateTimeSheetMutation, CreateTimeSheetMutationVariables>;

/**
 * __useCreateTimeSheetMutation__
 *
 * To run a mutation, you first call `useCreateTimeSheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTimeSheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTimeSheetMutation, { data, loading, error }] = useCreateTimeSheetMutation({
 *   variables: {
 *      days: // value for 'days'
 *      totalHours: // value for 'totalHours'
 *      clients: // value for 'clients'
 *   },
 * });
 */
export function useCreateTimeSheetMutation(baseOptions?: Apollo.MutationHookOptions<CreateTimeSheetMutation, CreateTimeSheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTimeSheetMutation, CreateTimeSheetMutationVariables>(CreateTimeSheetDocument, options);
      }
export type CreateTimeSheetMutationHookResult = ReturnType<typeof useCreateTimeSheetMutation>;
export type CreateTimeSheetMutationResult = Apollo.MutationResult<CreateTimeSheetMutation>;
export type CreateTimeSheetMutationOptions = Apollo.BaseMutationOptions<CreateTimeSheetMutation, CreateTimeSheetMutationVariables>;
export const RemoveTimeSheetDocument = gql`
    mutation removeTimeSheet($id: Int!) {
  removeTimeSheet(id: $id) {
    __typename
    ... on TimeSheet {
      id
      totalHours
    }
    ... on BadRequestError {
      message
    }
    ... on AuthenticationError {
      message
    }
  }
}
    `;
export type RemoveTimeSheetMutationFn = Apollo.MutationFunction<RemoveTimeSheetMutation, RemoveTimeSheetMutationVariables>;

/**
 * __useRemoveTimeSheetMutation__
 *
 * To run a mutation, you first call `useRemoveTimeSheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTimeSheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTimeSheetMutation, { data, loading, error }] = useRemoveTimeSheetMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveTimeSheetMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTimeSheetMutation, RemoveTimeSheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTimeSheetMutation, RemoveTimeSheetMutationVariables>(RemoveTimeSheetDocument, options);
      }
export type RemoveTimeSheetMutationHookResult = ReturnType<typeof useRemoveTimeSheetMutation>;
export type RemoveTimeSheetMutationResult = Apollo.MutationResult<RemoveTimeSheetMutation>;
export type RemoveTimeSheetMutationOptions = Apollo.BaseMutationOptions<RemoveTimeSheetMutation, RemoveTimeSheetMutationVariables>;
export const UpdateTimeSheetDocument = gql`
    mutation updateTimeSheet($id: Int!, $days: [DateTime!]!, $totalHours: Float!, $clients: [TimeSheetClientInput!]!) {
  updateTimeSheet(
    updateTimeSheetInput: {id: $id, days: $days, clients: $clients, totalHours: $totalHours}
  ) {
    __typename
    ... on TimeSheet {
      id
    }
    ... on BadRequestError {
      message
    }
    ... on AuthenticationError {
      message
    }
  }
}
    `;
export type UpdateTimeSheetMutationFn = Apollo.MutationFunction<UpdateTimeSheetMutation, UpdateTimeSheetMutationVariables>;

/**
 * __useUpdateTimeSheetMutation__
 *
 * To run a mutation, you first call `useUpdateTimeSheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTimeSheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTimeSheetMutation, { data, loading, error }] = useUpdateTimeSheetMutation({
 *   variables: {
 *      id: // value for 'id'
 *      days: // value for 'days'
 *      totalHours: // value for 'totalHours'
 *      clients: // value for 'clients'
 *   },
 * });
 */
export function useUpdateTimeSheetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTimeSheetMutation, UpdateTimeSheetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTimeSheetMutation, UpdateTimeSheetMutationVariables>(UpdateTimeSheetDocument, options);
      }
export type UpdateTimeSheetMutationHookResult = ReturnType<typeof useUpdateTimeSheetMutation>;
export type UpdateTimeSheetMutationResult = Apollo.MutationResult<UpdateTimeSheetMutation>;
export type UpdateTimeSheetMutationOptions = Apollo.BaseMutationOptions<UpdateTimeSheetMutation, UpdateTimeSheetMutationVariables>;
export const SkipWeekDocument = gql`
    mutation skipWeek($weekStart: String!) {
  skipWeek(weekStart: $weekStart) {
    __typename
    ... on TimeSheet {
      id
    }
    ... on BadRequestError {
      message
    }
    ... on AuthenticationError {
      message
    }
  }
}
    `;
export type SkipWeekMutationFn = Apollo.MutationFunction<SkipWeekMutation, SkipWeekMutationVariables>;

/**
 * __useSkipWeekMutation__
 *
 * To run a mutation, you first call `useSkipWeekMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSkipWeekMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [skipWeekMutation, { data, loading, error }] = useSkipWeekMutation({
 *   variables: {
 *      weekStart: // value for 'weekStart'
 *   },
 * });
 */
export function useSkipWeekMutation(baseOptions?: Apollo.MutationHookOptions<SkipWeekMutation, SkipWeekMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SkipWeekMutation, SkipWeekMutationVariables>(SkipWeekDocument, options);
      }
export type SkipWeekMutationHookResult = ReturnType<typeof useSkipWeekMutation>;
export type SkipWeekMutationResult = Apollo.MutationResult<SkipWeekMutation>;
export type SkipWeekMutationOptions = Apollo.BaseMutationOptions<SkipWeekMutation, SkipWeekMutationVariables>;
export const ClientDocument = gql`
    query client($id: Int!) {
  client(id: $id) {
    ... on Client {
      id
      name
      status
      logoUrl
    }
    ... on BadRequestError {
      message
    }
    ... on AuthenticationError {
      message
    }
  }
}
    `;

/**
 * __useClientQuery__
 *
 * To run a query within a React component, call `useClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClientQuery(baseOptions: Apollo.QueryHookOptions<ClientQuery, ClientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientQuery, ClientQueryVariables>(ClientDocument, options);
      }
export function useClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientQuery, ClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientQuery, ClientQueryVariables>(ClientDocument, options);
        }
export type ClientQueryHookResult = ReturnType<typeof useClientQuery>;
export type ClientLazyQueryHookResult = ReturnType<typeof useClientLazyQuery>;
export type ClientQueryResult = Apollo.QueryResult<ClientQuery, ClientQueryVariables>;
export const ClientsDocument = gql`
    query clients($term: String, $skip: Int, $status: GenericStatus, $take: Int) {
  clients(filter: {term: $term, skip: $skip, status: $status, take: $take}) {
    ... on Clients {
      count
      entities {
        id
        name
        status
        logoUrl
      }
    }
    ... on BadRequestError {
      message
    }
    ... on AuthenticationError {
      message
    }
  }
}
    `;

/**
 * __useClientsQuery__
 *
 * To run a query within a React component, call `useClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientsQuery({
 *   variables: {
 *      term: // value for 'term'
 *      skip: // value for 'skip'
 *      status: // value for 'status'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useClientsQuery(baseOptions?: Apollo.QueryHookOptions<ClientsQuery, ClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientsQuery, ClientsQueryVariables>(ClientsDocument, options);
      }
export function useClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientsQuery, ClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientsQuery, ClientsQueryVariables>(ClientsDocument, options);
        }
export type ClientsQueryHookResult = ReturnType<typeof useClientsQuery>;
export type ClientsLazyQueryHookResult = ReturnType<typeof useClientsLazyQuery>;
export type ClientsQueryResult = Apollo.QueryResult<ClientsQuery, ClientsQueryVariables>;
export const CurrenciesDocument = gql`
    query currencies($term: String, $take: Int, $skip: Int) {
  currencies(filter: {term: $term, take: $take, skip: $skip}) {
    ... on Currencies {
      entities {
        id
        name
      }
      count
    }
    ... on AuthenticationError {
      message
    }
    ... on BadRequestError {
      message
    }
    ... on Error {
      message
    }
  }
}
    `;

/**
 * __useCurrenciesQuery__
 *
 * To run a query within a React component, call `useCurrenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrenciesQuery({
 *   variables: {
 *      term: // value for 'term'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useCurrenciesQuery(baseOptions?: Apollo.QueryHookOptions<CurrenciesQuery, CurrenciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrenciesQuery, CurrenciesQueryVariables>(CurrenciesDocument, options);
      }
export function useCurrenciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrenciesQuery, CurrenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrenciesQuery, CurrenciesQueryVariables>(CurrenciesDocument, options);
        }
export type CurrenciesQueryHookResult = ReturnType<typeof useCurrenciesQuery>;
export type CurrenciesLazyQueryHookResult = ReturnType<typeof useCurrenciesLazyQuery>;
export type CurrenciesQueryResult = Apollo.QueryResult<CurrenciesQuery, CurrenciesQueryVariables>;
export const DepartmentsDocument = gql`
    query departments($term: String, $take: Int, $skip: Int) {
  departments(filter: {term: $term, take: $take, skip: $skip}) {
    ... on Departments {
      entities {
        id
        name
      }
      count
    }
    ... on AuthenticationError {
      message
    }
    ... on BadRequestError {
      message
    }
    ... on Error {
      message
    }
  }
}
    `;

/**
 * __useDepartmentsQuery__
 *
 * To run a query within a React component, call `useDepartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartmentsQuery({
 *   variables: {
 *      term: // value for 'term'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useDepartmentsQuery(baseOptions?: Apollo.QueryHookOptions<DepartmentsQuery, DepartmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DepartmentsQuery, DepartmentsQueryVariables>(DepartmentsDocument, options);
      }
export function useDepartmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DepartmentsQuery, DepartmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DepartmentsQuery, DepartmentsQueryVariables>(DepartmentsDocument, options);
        }
export type DepartmentsQueryHookResult = ReturnType<typeof useDepartmentsQuery>;
export type DepartmentsLazyQueryHookResult = ReturnType<typeof useDepartmentsLazyQuery>;
export type DepartmentsQueryResult = Apollo.QueryResult<DepartmentsQuery, DepartmentsQueryVariables>;
export const EmployeesDocument = gql`
    query employees($ids: [Int!], $firstName: String, $lastName: String, $locationIds: [Int!], $skip: Int, $statuses: [EmployeeStatus!], $take: Int, $term: String, $managerId: Int, $departmentIds: [Int!]) {
  employees(
    filter: {ids: $ids, firstName: $firstName, lastName: $lastName, locationIds: $locationIds, skip: $skip, statuses: $statuses, take: $take, term: $term, managerId: $managerId, departmentIds: $departmentIds}
  ) {
    __typename
    ... on Employees {
      count
      entities {
        id
        firstName
        lastName
        status
      }
    }
    ... on BadRequestError {
      message
    }
    ... on AuthenticationError {
      message
    }
  }
}
    `;

/**
 * __useEmployeesQuery__
 *
 * To run a query within a React component, call `useEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      locationIds: // value for 'locationIds'
 *      skip: // value for 'skip'
 *      statuses: // value for 'statuses'
 *      take: // value for 'take'
 *      term: // value for 'term'
 *      managerId: // value for 'managerId'
 *      departmentIds: // value for 'departmentIds'
 *   },
 * });
 */
export function useEmployeesQuery(baseOptions?: Apollo.QueryHookOptions<EmployeesQuery, EmployeesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeesQuery, EmployeesQueryVariables>(EmployeesDocument, options);
      }
export function useEmployeesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeesQuery, EmployeesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeesQuery, EmployeesQueryVariables>(EmployeesDocument, options);
        }
export type EmployeesQueryHookResult = ReturnType<typeof useEmployeesQuery>;
export type EmployeesLazyQueryHookResult = ReturnType<typeof useEmployeesLazyQuery>;
export type EmployeesQueryResult = Apollo.QueryResult<EmployeesQuery, EmployeesQueryVariables>;
export const ManagersDocument = gql`
    query managers($ids: [Int!], $firstName: String, $lastName: String, $locationIds: [Int!], $skip: Int, $statuses: [EmployeeStatus!], $take: Int, $term: String, $managerId: Int, $departmentIds: [Int!]) {
  managers(
    filter: {ids: $ids, firstName: $firstName, lastName: $lastName, locationIds: $locationIds, skip: $skip, statuses: $statuses, take: $take, term: $term, managerId: $managerId, departmentIds: $departmentIds}
  ) {
    __typename
    ... on Employees {
      count
      entities {
        id
        firstName
        lastName
        status
      }
    }
    ... on BadRequestError {
      message
    }
    ... on AuthenticationError {
      message
    }
  }
}
    `;

/**
 * __useManagersQuery__
 *
 * To run a query within a React component, call `useManagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useManagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagersQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      locationIds: // value for 'locationIds'
 *      skip: // value for 'skip'
 *      statuses: // value for 'statuses'
 *      take: // value for 'take'
 *      term: // value for 'term'
 *      managerId: // value for 'managerId'
 *      departmentIds: // value for 'departmentIds'
 *   },
 * });
 */
export function useManagersQuery(baseOptions?: Apollo.QueryHookOptions<ManagersQuery, ManagersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ManagersQuery, ManagersQueryVariables>(ManagersDocument, options);
      }
export function useManagersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ManagersQuery, ManagersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ManagersQuery, ManagersQueryVariables>(ManagersDocument, options);
        }
export type ManagersQueryHookResult = ReturnType<typeof useManagersQuery>;
export type ManagersLazyQueryHookResult = ReturnType<typeof useManagersLazyQuery>;
export type ManagersQueryResult = Apollo.QueryResult<ManagersQuery, ManagersQueryVariables>;
export const EmploymentStatusesDocument = gql`
    query employmentStatuses($term: String, $take: Int, $skip: Int) {
  employmentStatuses(filter: {term: $term, take: $take, skip: $skip}) {
    ... on EmploymentStatuses {
      entities {
        id
        name
      }
      count
    }
    ... on AuthenticationError {
      message
    }
    ... on BadRequestError {
      message
    }
    ... on Error {
      message
    }
  }
}
    `;

/**
 * __useEmploymentStatusesQuery__
 *
 * To run a query within a React component, call `useEmploymentStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmploymentStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmploymentStatusesQuery({
 *   variables: {
 *      term: // value for 'term'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useEmploymentStatusesQuery(baseOptions?: Apollo.QueryHookOptions<EmploymentStatusesQuery, EmploymentStatusesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmploymentStatusesQuery, EmploymentStatusesQueryVariables>(EmploymentStatusesDocument, options);
      }
export function useEmploymentStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmploymentStatusesQuery, EmploymentStatusesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmploymentStatusesQuery, EmploymentStatusesQueryVariables>(EmploymentStatusesDocument, options);
        }
export type EmploymentStatusesQueryHookResult = ReturnType<typeof useEmploymentStatusesQuery>;
export type EmploymentStatusesLazyQueryHookResult = ReturnType<typeof useEmploymentStatusesLazyQuery>;
export type EmploymentStatusesQueryResult = Apollo.QueryResult<EmploymentStatusesQuery, EmploymentStatusesQueryVariables>;
export const HolidayDocument = gql`
    query holiday($id: Int!) {
  holiday(id: $id) {
    ... on Holiday {
      id
      name
      isAnnual
      locations {
        id
        name
      }
    }
    ... on BadRequestError {
      message
    }
    ... on AuthenticationError {
      message
    }
  }
}
    `;

/**
 * __useHolidayQuery__
 *
 * To run a query within a React component, call `useHolidayQuery` and pass it any options that fit your needs.
 * When your component renders, `useHolidayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHolidayQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHolidayQuery(baseOptions: Apollo.QueryHookOptions<HolidayQuery, HolidayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HolidayQuery, HolidayQueryVariables>(HolidayDocument, options);
      }
export function useHolidayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HolidayQuery, HolidayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HolidayQuery, HolidayQueryVariables>(HolidayDocument, options);
        }
export type HolidayQueryHookResult = ReturnType<typeof useHolidayQuery>;
export type HolidayLazyQueryHookResult = ReturnType<typeof useHolidayLazyQuery>;
export type HolidayQueryResult = Apollo.QueryResult<HolidayQuery, HolidayQueryVariables>;
export const HolidaysDocument = gql`
    query holidays($startDate: DateTime, $endDate: DateTime, $locationIds: [Int!], $term: String) {
  holidays(
    filter: {startDate: $startDate, endDate: $endDate, locationIds: $locationIds, term: $term}
  ) {
    ... on Holidays {
      count
      entities {
        id
        name
        isAnnual
        startDate
        endDate
        locations {
          id
          name
        }
      }
    }
    ... on BadRequestError {
      message
    }
    ... on AuthenticationError {
      message
    }
  }
}
    `;

/**
 * __useHolidaysQuery__
 *
 * To run a query within a React component, call `useHolidaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useHolidaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHolidaysQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      locationIds: // value for 'locationIds'
 *      term: // value for 'term'
 *   },
 * });
 */
export function useHolidaysQuery(baseOptions?: Apollo.QueryHookOptions<HolidaysQuery, HolidaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HolidaysQuery, HolidaysQueryVariables>(HolidaysDocument, options);
      }
export function useHolidaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HolidaysQuery, HolidaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HolidaysQuery, HolidaysQueryVariables>(HolidaysDocument, options);
        }
export type HolidaysQueryHookResult = ReturnType<typeof useHolidaysQuery>;
export type HolidaysLazyQueryHookResult = ReturnType<typeof useHolidaysLazyQuery>;
export type HolidaysQueryResult = Apollo.QueryResult<HolidaysQuery, HolidaysQueryVariables>;
export const JobsDocument = gql`
    query jobs($term: String, $take: Int, $skip: Int) {
  jobs(filter: {term: $term, take: $take, skip: $skip}) {
    ... on Jobs {
      entities {
        id
        title
      }
      count
    }
    ... on AuthenticationError {
      message
    }
    ... on BadRequestError {
      message
    }
    ... on Error {
      message
    }
  }
}
    `;

/**
 * __useJobsQuery__
 *
 * To run a query within a React component, call `useJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobsQuery({
 *   variables: {
 *      term: // value for 'term'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useJobsQuery(baseOptions?: Apollo.QueryHookOptions<JobsQuery, JobsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobsQuery, JobsQueryVariables>(JobsDocument, options);
      }
export function useJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobsQuery, JobsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobsQuery, JobsQueryVariables>(JobsDocument, options);
        }
export type JobsQueryHookResult = ReturnType<typeof useJobsQuery>;
export type JobsLazyQueryHookResult = ReturnType<typeof useJobsLazyQuery>;
export type JobsQueryResult = Apollo.QueryResult<JobsQuery, JobsQueryVariables>;
export const LocationsDocument = gql`
    query locations($term: String, $take: Int, $skip: Int) {
  locations(filter: {term: $term, take: $take, skip: $skip}) {
    ... on Locations {
      entities {
        id
        name
      }
      count
    }
    ... on AuthenticationError {
      message
    }
    ... on BadRequestError {
      message
    }
    ... on Error {
      message
    }
  }
}
    `;

/**
 * __useLocationsQuery__
 *
 * To run a query within a React component, call `useLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsQuery({
 *   variables: {
 *      term: // value for 'term'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useLocationsQuery(baseOptions?: Apollo.QueryHookOptions<LocationsQuery, LocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationsQuery, LocationsQueryVariables>(LocationsDocument, options);
      }
export function useLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationsQuery, LocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationsQuery, LocationsQueryVariables>(LocationsDocument, options);
        }
export type LocationsQueryHookResult = ReturnType<typeof useLocationsQuery>;
export type LocationsLazyQueryHookResult = ReturnType<typeof useLocationsLazyQuery>;
export type LocationsQueryResult = Apollo.QueryResult<LocationsQuery, LocationsQueryVariables>;
export const EmployeeReportDocument = gql`
    query employeeReport($clientIds: [Int!], $employeeIds: [Int!], $employmentStatusIds: [Int!], $endDate: DateTime!, $startDate: DateTime!, $jobIds: [Int!], $locationIds: [Int!]) {
  employeeReport(
    filter: {clientIds: $clientIds, employeeIds: $employeeIds, employmentStatusIds: $employmentStatusIds, endDate: $endDate, startDate: $startDate, jobIds: $jobIds, locationIds: $locationIds}
  ) {
    ... on EmployeesReport {
      entities {
        id
        firstName
        lastName
        clients {
          id
          name
          hoursPerDay
          tasks {
            id
            hoursPerDay
            summary
          }
        }
      }
    }
    ... on BadRequestError {
      message
    }
    ... on AuthenticationError {
      message
    }
  }
}
    `;

/**
 * __useEmployeeReportQuery__
 *
 * To run a query within a React component, call `useEmployeeReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeReportQuery({
 *   variables: {
 *      clientIds: // value for 'clientIds'
 *      employeeIds: // value for 'employeeIds'
 *      employmentStatusIds: // value for 'employmentStatusIds'
 *      endDate: // value for 'endDate'
 *      startDate: // value for 'startDate'
 *      jobIds: // value for 'jobIds'
 *      locationIds: // value for 'locationIds'
 *   },
 * });
 */
export function useEmployeeReportQuery(baseOptions: Apollo.QueryHookOptions<EmployeeReportQuery, EmployeeReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeeReportQuery, EmployeeReportQueryVariables>(EmployeeReportDocument, options);
      }
export function useEmployeeReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeReportQuery, EmployeeReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeeReportQuery, EmployeeReportQueryVariables>(EmployeeReportDocument, options);
        }
export type EmployeeReportQueryHookResult = ReturnType<typeof useEmployeeReportQuery>;
export type EmployeeReportLazyQueryHookResult = ReturnType<typeof useEmployeeReportLazyQuery>;
export type EmployeeReportQueryResult = Apollo.QueryResult<EmployeeReportQuery, EmployeeReportQueryVariables>;
export const ClientReportDocument = gql`
    query clientReport($clientIds: [Int!], $employeeIds: [Int!], $employmentStatusIds: [Int!], $endDate: DateTime!, $startDate: DateTime!, $jobIds: [Int!], $locationIds: [Int!]) {
  clientReport(
    filter: {clientIds: $clientIds, employeeIds: $employeeIds, employmentStatusIds: $employmentStatusIds, endDate: $endDate, startDate: $startDate, jobIds: $jobIds, locationIds: $locationIds}
  ) {
    ... on ClientsReport {
      entities {
        id
        name
        tasks {
          id
          summary
          hoursPerDay
          employees {
            id
            firstName
            lastName
            hoursPerDay
          }
        }
      }
    }
    ... on BadRequestError {
      message
    }
    ... on AuthenticationError {
      message
    }
  }
}
    `;

/**
 * __useClientReportQuery__
 *
 * To run a query within a React component, call `useClientReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientReportQuery({
 *   variables: {
 *      clientIds: // value for 'clientIds'
 *      employeeIds: // value for 'employeeIds'
 *      employmentStatusIds: // value for 'employmentStatusIds'
 *      endDate: // value for 'endDate'
 *      startDate: // value for 'startDate'
 *      jobIds: // value for 'jobIds'
 *      locationIds: // value for 'locationIds'
 *   },
 * });
 */
export function useClientReportQuery(baseOptions: Apollo.QueryHookOptions<ClientReportQuery, ClientReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientReportQuery, ClientReportQueryVariables>(ClientReportDocument, options);
      }
export function useClientReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientReportQuery, ClientReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientReportQuery, ClientReportQueryVariables>(ClientReportDocument, options);
        }
export type ClientReportQueryHookResult = ReturnType<typeof useClientReportQuery>;
export type ClientReportLazyQueryHookResult = ReturnType<typeof useClientReportLazyQuery>;
export type ClientReportQueryResult = Apollo.QueryResult<ClientReportQuery, ClientReportQueryVariables>;
export const RateReportDocument = gql`
    query rateReport($clientIds: [Int!], $employeeIds: [Int!], $employmentStatusIds: [Int!], $endDate: DateTime!, $startDate: DateTime!, $jobIds: [Int!], $locationIds: [Int!], $departmentIds: [Int!]) {
  rateReport(
    filter: {clientIds: $clientIds, employeeIds: $employeeIds, employmentStatusIds: $employmentStatusIds, endDate: $endDate, startDate: $startDate, jobIds: $jobIds, locationIds: $locationIds, departmentIds: $departmentIds}
  ) {
    ... on RatesReport {
      entities {
        id
        firstName
        lastName
        hourlyRates {
          id
          rate
        }
        location {
          id
          name
        }
        employmentStatus {
          id
          name
        }
        currency {
          id
          name
        }
        clients {
          id
          name
          hoursPerDay
          salary
        }
        groupedHoursByHourlyRateAndClient
        groupedSalariesByHourlyRateAndClient
      }
    }
    ... on BadRequestError {
      message
    }
    ... on AuthenticationError {
      message
    }
  }
}
    `;

/**
 * __useRateReportQuery__
 *
 * To run a query within a React component, call `useRateReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useRateReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRateReportQuery({
 *   variables: {
 *      clientIds: // value for 'clientIds'
 *      employeeIds: // value for 'employeeIds'
 *      employmentStatusIds: // value for 'employmentStatusIds'
 *      endDate: // value for 'endDate'
 *      startDate: // value for 'startDate'
 *      jobIds: // value for 'jobIds'
 *      locationIds: // value for 'locationIds'
 *      departmentIds: // value for 'departmentIds'
 *   },
 * });
 */
export function useRateReportQuery(baseOptions: Apollo.QueryHookOptions<RateReportQuery, RateReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RateReportQuery, RateReportQueryVariables>(RateReportDocument, options);
      }
export function useRateReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RateReportQuery, RateReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RateReportQuery, RateReportQueryVariables>(RateReportDocument, options);
        }
export type RateReportQueryHookResult = ReturnType<typeof useRateReportQuery>;
export type RateReportLazyQueryHookResult = ReturnType<typeof useRateReportLazyQuery>;
export type RateReportQueryResult = Apollo.QueryResult<RateReportQuery, RateReportQueryVariables>;
export const EmployeePastTimeSheetDocument = gql`
    query employeePastTimeSheet($employeeId: Int, $endDate: DateTime, $startDate: DateTime, $skip: Int, $take: Int) {
  employeePastTimeSheet(
    filter: {employeeId: $employeeId, endDate: $endDate, startDate: $startDate, skip: $skip, take: $take}
  ) {
    ... on TimeSheet {
      id
      dates
      totalHours
      clients {
        id
        totalHours
        client {
          id
          name
          status
        }
        tasks {
          id
          hours
          task {
            id
            summary
            status
            client {
              id
              name
              status
            }
          }
        }
      }
    }
    ... on BadRequestError {
      message
    }
    ... on AuthenticationError {
      message
    }
  }
}
    `;

/**
 * __useEmployeePastTimeSheetQuery__
 *
 * To run a query within a React component, call `useEmployeePastTimeSheetQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeePastTimeSheetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeePastTimeSheetQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      endDate: // value for 'endDate'
 *      startDate: // value for 'startDate'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useEmployeePastTimeSheetQuery(baseOptions?: Apollo.QueryHookOptions<EmployeePastTimeSheetQuery, EmployeePastTimeSheetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeePastTimeSheetQuery, EmployeePastTimeSheetQueryVariables>(EmployeePastTimeSheetDocument, options);
      }
export function useEmployeePastTimeSheetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeePastTimeSheetQuery, EmployeePastTimeSheetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeePastTimeSheetQuery, EmployeePastTimeSheetQueryVariables>(EmployeePastTimeSheetDocument, options);
        }
export type EmployeePastTimeSheetQueryHookResult = ReturnType<typeof useEmployeePastTimeSheetQuery>;
export type EmployeePastTimeSheetLazyQueryHookResult = ReturnType<typeof useEmployeePastTimeSheetLazyQuery>;
export type EmployeePastTimeSheetQueryResult = Apollo.QueryResult<EmployeePastTimeSheetQuery, EmployeePastTimeSheetQueryVariables>;
export const FindClientTasksDocument = gql`
    query findClientTasks($skip: Int, $status: GenericStatus, $take: Int, $summary: String, $clientIds: [Int!]!) {
  findClientTasks(
    input: {skip: $skip, status: $status, take: $take, summary: $summary, clientIds: $clientIds}
  ) {
    ... on Tasks {
      count
      entities {
        id
        status
        summary
        client {
          id
          status
          logoUrl
          name
        }
      }
    }
    ... on BadRequestError {
      message
    }
    ... on AuthenticationError {
      message
    }
  }
}
    `;

/**
 * __useFindClientTasksQuery__
 *
 * To run a query within a React component, call `useFindClientTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindClientTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindClientTasksQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      status: // value for 'status'
 *      take: // value for 'take'
 *      summary: // value for 'summary'
 *      clientIds: // value for 'clientIds'
 *   },
 * });
 */
export function useFindClientTasksQuery(baseOptions: Apollo.QueryHookOptions<FindClientTasksQuery, FindClientTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindClientTasksQuery, FindClientTasksQueryVariables>(FindClientTasksDocument, options);
      }
export function useFindClientTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindClientTasksQuery, FindClientTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindClientTasksQuery, FindClientTasksQueryVariables>(FindClientTasksDocument, options);
        }
export type FindClientTasksQueryHookResult = ReturnType<typeof useFindClientTasksQuery>;
export type FindClientTasksLazyQueryHookResult = ReturnType<typeof useFindClientTasksLazyQuery>;
export type FindClientTasksQueryResult = Apollo.QueryResult<FindClientTasksQuery, FindClientTasksQueryVariables>;
export const FindActiveClientsWithActiveTasksDocument = gql`
    query findActiveClientsWithActiveTasks {
  findActiveClientsWithActiveTasks {
    ... on Tasks {
      count
      entities {
        id
        status
        summary
        client {
          id
          status
          logoUrl
          name
        }
      }
      clients {
        id
        name
        status
        logoUrl
      }
    }
    ... on BadRequestError {
      message
    }
    ... on AuthenticationError {
      message
    }
  }
}
    `;

/**
 * __useFindActiveClientsWithActiveTasksQuery__
 *
 * To run a query within a React component, call `useFindActiveClientsWithActiveTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindActiveClientsWithActiveTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindActiveClientsWithActiveTasksQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindActiveClientsWithActiveTasksQuery(baseOptions?: Apollo.QueryHookOptions<FindActiveClientsWithActiveTasksQuery, FindActiveClientsWithActiveTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindActiveClientsWithActiveTasksQuery, FindActiveClientsWithActiveTasksQueryVariables>(FindActiveClientsWithActiveTasksDocument, options);
      }
export function useFindActiveClientsWithActiveTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindActiveClientsWithActiveTasksQuery, FindActiveClientsWithActiveTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindActiveClientsWithActiveTasksQuery, FindActiveClientsWithActiveTasksQueryVariables>(FindActiveClientsWithActiveTasksDocument, options);
        }
export type FindActiveClientsWithActiveTasksQueryHookResult = ReturnType<typeof useFindActiveClientsWithActiveTasksQuery>;
export type FindActiveClientsWithActiveTasksLazyQueryHookResult = ReturnType<typeof useFindActiveClientsWithActiveTasksLazyQuery>;
export type FindActiveClientsWithActiveTasksQueryResult = Apollo.QueryResult<FindActiveClientsWithActiveTasksQuery, FindActiveClientsWithActiveTasksQueryVariables>;
export const TimeSheetDatesDocument = gql`
    query timeSheetDates {
  timeSheetDates {
    ... on TimeSheetDateTypes {
      data {
        date
        name
        weekend
        holiday
      }
    }
    ... on BadRequestError {
      message
    }
    ... on AuthenticationError {
      message
    }
  }
}
    `;

/**
 * __useTimeSheetDatesQuery__
 *
 * To run a query within a React component, call `useTimeSheetDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTimeSheetDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTimeSheetDatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTimeSheetDatesQuery(baseOptions?: Apollo.QueryHookOptions<TimeSheetDatesQuery, TimeSheetDatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimeSheetDatesQuery, TimeSheetDatesQueryVariables>(TimeSheetDatesDocument, options);
      }
export function useTimeSheetDatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimeSheetDatesQuery, TimeSheetDatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimeSheetDatesQuery, TimeSheetDatesQueryVariables>(TimeSheetDatesDocument, options);
        }
export type TimeSheetDatesQueryHookResult = ReturnType<typeof useTimeSheetDatesQuery>;
export type TimeSheetDatesLazyQueryHookResult = ReturnType<typeof useTimeSheetDatesLazyQuery>;
export type TimeSheetDatesQueryResult = Apollo.QueryResult<TimeSheetDatesQuery, TimeSheetDatesQueryVariables>;
export const PreviousTimeSheetDocument = gql`
    query previousTimeSheet($startDate: DateTime, $endDate: DateTime, $skip: Int, $take: Int) {
  previousTimeSheet(
    filter: {startDate: $startDate, endDate: $endDate, skip: $skip, take: $take}
  ) {
    ... on TimeSheets {
      count
      entities {
        id
        totalHours
        dates
        clients {
          id
          totalHours
          tasks {
            id
            hours
            task {
              id
              status
              summary
            }
          }
          client {
            id
            name
            status
          }
        }
      }
    }
    ... on BadRequestError {
      message
    }
    ... on AuthenticationError {
      message
    }
  }
}
    `;

/**
 * __usePreviousTimeSheetQuery__
 *
 * To run a query within a React component, call `usePreviousTimeSheetQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreviousTimeSheetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreviousTimeSheetQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function usePreviousTimeSheetQuery(baseOptions?: Apollo.QueryHookOptions<PreviousTimeSheetQuery, PreviousTimeSheetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreviousTimeSheetQuery, PreviousTimeSheetQueryVariables>(PreviousTimeSheetDocument, options);
      }
export function usePreviousTimeSheetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreviousTimeSheetQuery, PreviousTimeSheetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreviousTimeSheetQuery, PreviousTimeSheetQueryVariables>(PreviousTimeSheetDocument, options);
        }
export type PreviousTimeSheetQueryHookResult = ReturnType<typeof usePreviousTimeSheetQuery>;
export type PreviousTimeSheetLazyQueryHookResult = ReturnType<typeof usePreviousTimeSheetLazyQuery>;
export type PreviousTimeSheetQueryResult = Apollo.QueryResult<PreviousTimeSheetQuery, PreviousTimeSheetQueryVariables>;
export const UserLastTimeSheetDocument = gql`
    query userLastTimeSheet {
  userLastTimeSheet {
    ... on TimeSheets {
      count
      entities {
        id
        totalHours
        dates
        clients {
          id
          totalHours
          tasks {
            id
            hours
            task {
              id
              status
              summary
            }
          }
          client {
            id
            name
            status
          }
        }
      }
    }
    ... on BadRequestError {
      message
    }
    ... on AuthenticationError {
      message
    }
  }
}
    `;

/**
 * __useUserLastTimeSheetQuery__
 *
 * To run a query within a React component, call `useUserLastTimeSheetQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserLastTimeSheetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserLastTimeSheetQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserLastTimeSheetQuery(baseOptions?: Apollo.QueryHookOptions<UserLastTimeSheetQuery, UserLastTimeSheetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserLastTimeSheetQuery, UserLastTimeSheetQueryVariables>(UserLastTimeSheetDocument, options);
      }
export function useUserLastTimeSheetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserLastTimeSheetQuery, UserLastTimeSheetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserLastTimeSheetQuery, UserLastTimeSheetQueryVariables>(UserLastTimeSheetDocument, options);
        }
export type UserLastTimeSheetQueryHookResult = ReturnType<typeof useUserLastTimeSheetQuery>;
export type UserLastTimeSheetLazyQueryHookResult = ReturnType<typeof useUserLastTimeSheetLazyQuery>;
export type UserLastTimeSheetQueryResult = Apollo.QueryResult<UserLastTimeSheetQuery, UserLastTimeSheetQueryVariables>;