import React, { useEffect, useState } from 'react';
import { useQuery, NetworkStatus } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Alert } from 'antd';

import { GET_CLIENT_TASKS } from '../../../ApolloClient/queries/clientsQueries';
import { Flex, Spiner, Paginations, GoBack } from '../../../components/core';
import FilterSection from './FilterSection';
import TasksTable from './TasksTable';
import ActionSection from './ActionSection';
import { dataCounts, initialIndex } from '../../../utils/constants';
import default_logo from '../../../icons/Logos/Default_Client_Logo.svg';
import { info } from '../../../resources/strings';
import { Client, useClientQuery } from '../../../graphql/graphql';
import { FormData } from './FilterSection';

import './Tasks.styles.scss';

const TasksView: React.FC = () => {
  const { id } = useParams() as { id: string };
  const clientId = Number(id);
  const [showAlert, setShowAlert] = useState(false);
  const [filters, setFilters] = useState<FormData>({});
  const [client, setClient] = useState<Client>();
  const { data: clientData, loading: clientLoading } = useClientQuery({
    variables: { id: clientId }
  });
  const {
    loading: clientTasksLoading,
    data,
    refetch,
    networkStatus
  } = useQuery(GET_CLIENT_TASKS, {
    variables: { clientIds: clientId, skip: initialIndex },
    notifyOnNetworkStatusChange: true
  });
  const imageUrl =
    clientData?.client.__typename === 'Client' && clientData.client.logoUrl
      ? clientData.client.logoUrl
      : default_logo;

  function handlePageChange(page: number) {
    refetch({ skip: (page - 1) * dataCounts, clientIds: clientId });
  }

  useEffect(() => {
    data?.findClientTasks?.count === 0 && !filters.status && !filters.summary
      ? setShowAlert(true)
      : setShowAlert(false);
  }, [data, filters]);

  useEffect(() => {
    if (clientData?.client.__typename === 'Client') {
      setClient(clientData.client);
    }
  }, [clientData]);

  if (clientLoading) return <Spiner />;

  if (clientData?.client.__typename === 'BadRequestError')
    return <Flex className='site-layout-background tasks-content'></Flex>;

  return (
    <Flex className='site-layout-background tasks-content'>
      {showAlert && !clientTasksLoading && networkStatus !== NetworkStatus.refetch && (
        <Flex center='both-axis'>
          <Alert message={info.addTaskWarning} type='warning' showIcon closable />
        </Flex>
      )}
      <GoBack backText={info.backToClient} backLink='/clients' />
      <Flex direction='row'>
        <Flex className='left-section' direction='column'>
          <div className='imageWrapper' style={{ backgroundImage: `url(${imageUrl})` }} />
          <div className='clientName bold'>{client?.name}</div>
          <div className={`status ${client?.status}`}>{client?.status}</div>
        </Flex>
        <Flex className='right-section'>
          <ActionSection reFetchTasks={refetch} clientId={clientId} />
          <FilterSection
            reFetchTasks={refetch}
            clientId={clientId}
            setFilters={setFilters}
            filters={filters}
          />
          <TasksTable data={data?.findClientTasks?.entities} reFetchTasks={refetch} />
          <Flex direction='row' align='end-main-axis' style={{ marginTop: 20 }}>
            <Paginations
              defaultCurrent={1}
              defaultPageSize={15}
              total={data?.findClientTasks.count}
              onChange={handlePageChange}
              showSizeChanger={false}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default TasksView;
