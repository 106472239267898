import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import jwtDecode from 'jwt-decode';

import { REFRESH_TOKENS } from './ApolloClient/queries/userQueries';
import { RootState } from './redux/reducers/index';
import Routes from './Routes';
import ErrorBoundary from './components/ErrorBoundary';

const App: React.FC = () => {
  const client = useApolloClient();
  const userInfo = useSelector((state: RootState) => state.user.userInfo);

  const refreshTokenOnExpire = useCallback(() => {
    const refreshToken = localStorage.getItem('refreshToken');
    const accessToken = localStorage.getItem('accessToken');

    if (userInfo.id && accessToken) {
      const parsedToken: { exp: number } = jwtDecode(accessToken);
      const timeOutDate = parsedToken.exp * 1000 - Date.now();

      setTimeout(() => {
        client
          .query({
            query: REFRESH_TOKENS,
            variables: { refreshToken }
          })
          .then(({ data }) => {
            localStorage.setItem('refreshToken', data?.refreshTokens.refreshToken);
            localStorage.setItem('accessToken', data?.refreshTokens.accessToken);
            refreshTokenOnExpire();
          })
          .catch(() => {
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('accessToken');
          });
      }, timeOutDate);
    }
  }, [client, userInfo]);

  React.useEffect(() => {
    refreshTokenOnExpire();
  }, [refreshTokenOnExpire, userInfo]);

  return (
    <BrowserRouter>
      <ErrorBoundary>
        <Routes />
      </ErrorBoundary>
    </BrowserRouter>
  );
};

export default App;
