import produce from 'immer';
import {
  splitTimeFramesEvenly,
  getDefaultTaskParams
} from '../../modules/TimeSheet/helpers/helpers';
import { ClientTimeFrame, TaskTimeFrame } from '../../types/main';

const START_OVER = 'START_OVER';
const ADD_TOTAL_HOURS = 'ADD_TOTAL_HOURS';
const UPDATE_WEEKDAYS = 'UPDATE_WEEKDAYS';
const ADD_CLIENT = 'ADD_CLIENT';
const DELETE_CLIENT = 'DELETE_CLIENT';
const DELETE_CLIET_BY_ID = 'DELETE_CLIET_BY_ID';
const SPLIT_CLIENTS_HOURS = 'SPLIT_CLIENTS_HOURS';
const SET_CLIENT_HOURS_EVENLY = 'SET_CLIENT_HOURS_EVENLY';
const EDIT_CLIENT = 'EDIT_CLIENT';
const ADD_TASK = 'ADD_TASK';
const DELETE_TASK = 'DELETE_TASK';
const EDIT_TASK = 'EDIT_TASK';
const SPLIT_TASKS_HOURS = 'SPLIT_TASKS_HOURS';
const SET_TASK_HOURS_EVENLY = 'SET_TASK_HOURS_EVENLY';
const REPLACE_TIME_FRAME = 'REPLACE_TIME_FRAME';
const CHANGE_TIMESHEET_EDIT_MODE = 'CHANGE_TIMESHEET_EDIT_MODE';
const CASCADE_UPDATE_TOTAL_HOURS = 'CASCADE_UPDATE_TOTAL_HOURS';
const CASCADE_UPDATE_CLIENT_HOURS = 'CASCADE_UPDATE_CLIENT_HOURS';

export const UID = {
  uid: 0
};

type EditeActionDataType = {
  index: number;
  clientData: ClientTimeFrame;
};

type ClientHoursType = {
  index: number;
  hours: number;
};

type EditeTaskDataType = {
  clientId: number;
  index: number;
  editableTask: TaskTimeFrame;
};

export interface IAppReducerState {
  splitClientHoursEvenly: boolean;
  totalHours: number;
  days: string[];
  clients: ClientTimeFrame[];
  timesheetEditMode: boolean;
}

export function addTotalHours(data: number): { type: string; data: number } {
  return {
    type: ADD_TOTAL_HOURS,
    data
  };
}

export function updateWeekDays(data: string[]): { type: string; data: string[] } {
  return {
    type: UPDATE_WEEKDAYS,
    data
  };
}

export function addClient(data?: ClientTimeFrame): { type: string; data?: ClientTimeFrame } {
  return {
    type: ADD_CLIENT,
    data
  };
}

export function deleteClient(data: number): { type: string; data: number } {
  return {
    type: DELETE_CLIENT,
    data
  };
}

export function deleteClientById(data: number): { type: string; data: number } {
  return {
    type: DELETE_CLIET_BY_ID,
    data
  };
}

export function editClientData(data: EditeActionDataType): {
  type: string;
  data: EditeActionDataType;
} {
  return {
    type: EDIT_CLIENT,
    data
  };
}

export function addTask(data: number): { type: string; data: number } {
  return {
    type: ADD_TASK,
    data
  };
}

export function deleteTask(data: { clientId: number; index: number }): {
  type: string;
  data: { clientId: number; index: number };
} {
  return {
    type: DELETE_TASK,
    data
  };
}

export function editTaskData(data: EditeTaskDataType): { type: string; data: EditeTaskDataType } {
  return {
    type: EDIT_TASK,
    data
  };
}

export function startOver(days: string[]): { type: string; data: string[] } {
  return {
    type: START_OVER,
    data: days
  };
}

export function splitClientsHoursEvenly(): { type: string } {
  return {
    type: SPLIT_CLIENTS_HOURS
  };
}

export function setClientHoursEvenly(data: boolean): { type: string; data: boolean } {
  return {
    type: SET_CLIENT_HOURS_EVENLY,
    data
  };
}

export function splitTasksHoursEvenly(data: ClientTimeFrame): {
  type: string;
  data: ClientTimeFrame;
} {
  return {
    type: SPLIT_TASKS_HOURS,
    data
  };
}

export function setTaskHoursEvenly(data: { checked: boolean; clientId: number }): {
  type: string;
  data: { checked: boolean; clientId: number };
} {
  return {
    type: SET_TASK_HOURS_EVENLY,
    data
  };
}

export function replaceTimeFrame(data: IAppReducerState): { type: string; data: IAppReducerState } {
  return {
    type: REPLACE_TIME_FRAME,
    data
  };
}

export function changeTimesheetEditMode(data: boolean): { type: string; data: boolean } {
  return {
    type: CHANGE_TIMESHEET_EDIT_MODE,
    data
  };
}

export function cascadeUpdateTotalHours(data: number): { type: string; data: number } {
  return {
    type: CASCADE_UPDATE_TOTAL_HOURS,
    data
  };
}

export function cascadeUpdateClientHours(data: ClientHoursType): {
  type: string;
  data: ClientHoursType;
} {
  return {
    type: CASCADE_UPDATE_CLIENT_HOURS,
    data
  };
}

export const initialState: IAppReducerState = {
  splitClientHoursEvenly: true,
  totalHours: 40,
  days: [],
  clients: [],
  timesheetEditMode: false
};

type ReducerAction =
  | { type: 'ADD_TOTAL_HOURS'; data: number }
  | { type: 'UPDATE_WEEKDAYS'; data: string[] }
  | { type: 'ADD_CLIENT'; data: ClientTimeFrame }
  | { type: 'DELETE_CLIENT'; data: number }
  | { type: 'DELETE_CLIET_BY_ID'; data: number }
  | { type: 'EDIT_CLIENT'; data: { index: number; clientData: ClientTimeFrame } }
  | { type: 'SPLIT_CLIENTS_HOURS'; data: ClientTimeFrame[] }
  | { type: 'SET_CLIENT_HOURS_EVENLY'; data: boolean }
  | { type: 'ADD_TASK'; data: number }
  | { type: 'DELETE_TASK'; data: { clientId: number; index: number } }
  | { type: 'EDIT_TASK'; data: EditeTaskDataType }
  | { type: 'SPLIT_TASKS_HOURS'; data: ClientTimeFrame }
  | { type: 'SET_TASK_HOURS_EVENLY'; data: { checked: boolean; clientId: number } }
  | { type: 'START_OVER'; data: string[] }
  | { type: 'REPLACE_TIME_FRAME'; data: IAppReducerState }
  | { type: 'CHANGE_TIMESHEET_EDIT_MODE'; data: boolean }
  | { type: 'CASCADE_UPDATE_TOTAL_HOURS'; data: number }
  | { type: 'CASCADE_UPDATE_CLIENT_HOURS'; data: ClientHoursType };

const timeFrameReducer = (state = initialState, action: ReducerAction): IAppReducerState => {
  return produce(state, (draft: IAppReducerState) => {
    switch (action.type) {
      case ADD_TOTAL_HOURS:
        draft.totalHours = Number(action.data.toFixed(2));
        break;
      case CASCADE_UPDATE_TOTAL_HOURS:
        const oldTotalHours = draft.totalHours;
        draft.totalHours = action.data;
        draft.clients.forEach(
          (c) => (c.hours = Number(((c.hours * draft.totalHours) / oldTotalHours).toFixed(2)))
        );
        break;
      case CASCADE_UPDATE_CLIENT_HOURS: {
        draft.splitClientHoursEvenly = false;
        const { hours, index } = action.data;
        const client = draft.clients[index];
        const oldHours = client.hours;
        client.hours = Number(hours.toFixed(2));
        client.tasks?.forEach(
          (t) => (t.hours = Number(((t.hours * client.hours) / oldHours).toFixed(2)))
        );
        break;
      }
      case UPDATE_WEEKDAYS:
        draft.days = action.data;
        break;
      case ADD_CLIENT: {
        draft.clients.push({
          id: action.data?.id || -1,
          name: action.data?.name || '',
          hours: 0,
          UID: ++UID.uid,
          splitTaskHoursEvenly: true,
          tasks: [getDefaultTaskParams(++UID.uid)]
        });
        draft.splitClientHoursEvenly && splitTimeFramesEvenly(draft.clients, draft.totalHours);
        break;
      }
      case DELETE_CLIENT: {
        const deleteIndex = draft.clients.findIndex((c) => c.UID === action.data);
        draft.clients.splice(deleteIndex, 1);
        draft.splitClientHoursEvenly && splitTimeFramesEvenly(draft.clients, draft.totalHours);
        break;
      }
      case DELETE_CLIET_BY_ID: {
        const deleteIndex = draft.clients.findIndex((c) => c.id === action.data);
        draft.clients.splice(deleteIndex, 1);
        draft.splitClientHoursEvenly && splitTimeFramesEvenly(draft.clients, draft.totalHours);
        break;
      }
      case EDIT_CLIENT: {
        const { clientData: data, index } = action.data;
        const client = draft.clients[index];

        if (draft.splitClientHoursEvenly) {
          draft.splitClientHoursEvenly = data.hours === client.hours;
        }

        if (data.id !== client.id) {
          data.tasks = [getDefaultTaskParams(++UID.uid)];
        }

        draft.clients[index] = data;
        break;
      }
      case SPLIT_CLIENTS_HOURS: {
        splitTimeFramesEvenly(draft.clients, draft.totalHours);
        break;
      }
      case SET_CLIENT_HOURS_EVENLY: {
        action.data && splitTimeFramesEvenly(draft.clients, draft.totalHours);
        draft.splitClientHoursEvenly = action.data;
        break;
      }
      case ADD_TASK: {
        const client = draft.clients.find((elem) => elem.id == action.data) as ClientTimeFrame;
        client.tasks.push(getDefaultTaskParams(++UID.uid));

        if (client.splitTaskHoursEvenly) {
          splitTimeFramesEvenly(client.tasks, client.hours);
        }
        break;
      }
      case DELETE_TASK: {
        const client = draft.clients.find(
          (elem) => elem.id == action.data.clientId
        ) as ClientTimeFrame;
        client.tasks.splice(action.data.index, 1);

        if (client.splitTaskHoursEvenly) {
          splitTimeFramesEvenly(client.tasks, client.hours);
        }
        break;
      }
      case EDIT_TASK: {
        const { clientId, editableTask, index } = action.data;
        const client = draft.clients.find((c) => c.id == clientId) as ClientTimeFrame;

        if (client?.splitTaskHoursEvenly) {
          client.splitTaskHoursEvenly = client.tasks[index].hours === editableTask.hours;
        }

        client.tasks[index] = editableTask;
        break;
      }
      case SPLIT_TASKS_HOURS: {
        const client = draft.clients.find((c) => c.id === action.data.id) as ClientTimeFrame;
        splitTimeFramesEvenly(client.tasks, action.data.hours);
        break;
      }
      case SET_TASK_HOURS_EVENLY: {
        const { checked, clientId } = action.data;
        const client = draft.clients.find((c) => c.id === clientId) as ClientTimeFrame;
        client.splitTaskHoursEvenly = checked;
        checked && splitTimeFramesEvenly(client.tasks, client.hours);
        break;
      }
      case CHANGE_TIMESHEET_EDIT_MODE: {
        draft.timesheetEditMode = action.data;
        break;
      }
      case START_OVER:
        return { ...initialState, days: action.data };
      case REPLACE_TIME_FRAME: {
        return { ...action.data };
      }
    }
  });
};

export default timeFrameReducer;
