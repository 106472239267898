import React from 'react';
import { Table } from 'antd';

import { Flex } from '../../../components/core';
import { ReportDataType } from '../../../types/main';
import { getRowStyle, getNestedRowStyle, getRoundedTotal } from '../helpers';
import { info } from '../../../resources/strings';

interface IProps {
  data: ReportDataType[];
}

const ReportTable: React.FC<IProps> = ({ data }) => {
  const columns = [
    {
      key: 'employee',
      title: info.employee,
      className: 'employeeCell',
      ellipsis: true,
      render: (rowData: ReportDataType) => (
        <div
          key={rowData.id}
          className='ellipsis'
        >{`${rowData.firstName} ${rowData.lastName}`}</div>
      )
    },
    {
      key: 'client',
      title: info.client,
      className: 'clientCell',
      ellipsis: true,
      render: (rowData: ReportDataType) => {
        return rowData.clients.map((client, index) => {
          return (
            <div
              key={client.id}
              title={client.name}
              className='perClientRow ellipsis'
              style={getRowStyle(rowData.clients.length, client.tasks.length, index)}
            >
              {client.name}
            </div>
          );
        });
      }
    },
    {
      key: 'task',
      title: info.task,
      className: 'taskCell',
      ellipsis: true,
      render: (rowData: ReportDataType) => {
        return rowData.clients.map((client, index) => {
          return (
            <div key={client.id} style={getNestedRowStyle(rowData.clients.length, index)}>
              {client.tasks.map((task) => {
                return (
                  <div key={task.id} title={task.summary} className={'taskRow ellipsis'}>
                    {task.summary}
                  </div>
                );
              })}
            </div>
          );
        });
      }
    },
    {
      key: 'HoursPerTask',
      title: info.hoursPerTask,
      className: 'taskCell',
      width: 150,
      render: (rowData: ReportDataType) => {
        return rowData.clients.map((client, index) => {
          return (
            <div key={client.id} style={getNestedRowStyle(rowData.clients.length, index)}>
              {client.tasks.map((task) => {
                return (
                  <div key={task.id} className={'taskRow ellipsis'}>
                    {task.hoursPerDay}
                  </div>
                );
              })}
            </div>
          );
        });
      }
    },
    {
      key: 'HoursPerClient',
      title: info.hoursPerClient,
      className: 'clientHourscell',
      width: 150,
      render: (rowData: ReportDataType) => {
        return rowData.clients.map((client, index) => {
          return (
            <div
              key={client.id}
              style={getRowStyle(rowData.clients.length, client.tasks.length, index)}
            >
              {client.hoursPerDay}
            </div>
          );
        });
      }
    },
    {
      key: 'EmployeeTotal',
      title: info.employeeGrandTotal,
      className: 'employeeTotalCell',
      render: (rowData: ReportDataType) => {
        const grandTotal = rowData.clients.reduce((sum, client) => {
          return sum + client.hoursPerDay;
        }, 0);
        return <div key={rowData.id}>{getRoundedTotal(grandTotal)}</div>;
      }
    }
  ];

  return (
    <Flex style={{ marginTop: 20 }}>
      <Table
        id='emProjreportTable'
        rowKey='id'
        size='small'
        bordered
        dataSource={data}
        columns={columns}
        pagination={false}
        scroll={{ y: '420px' }}
      />
    </Flex>
  );
};

export default ReportTable;
