import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from 'antd';

import { Flex, Spiner } from '../../../components/core';
import { useRateReportLazyQuery } from '../../../graphql/graphql';
import { RootState } from '../../../redux/reducers';
import { addRateReport } from '../../../redux/reducers/Reports';
import FilterSection from './FilterSection';
import ReportTable from './ReportTable';
import ExportCSV from './ExportCSV';
import { info } from '../../../resources/strings';
import { changeDataForDisplayingInSeparateRows } from '../../../utils/helpers';

import './Report.scss';

const { Text } = Typography;

const RateReport: FC = () => {
  const dispatch = useDispatch();
  const rateReport = useSelector((state: RootState) => state.reports.rateReport);

  const [getRateReports, { data, loading }] = useRateReportLazyQuery({
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (data?.rateReport.__typename === 'RatesReport') {
      const newData = changeDataForDisplayingInSeparateRows(data);
      dispatch(addRateReport(newData));
    } else if (data?.rateReport.__typename) {
      dispatch(addRateReport([]));
    }
  }, [data, dispatch]);

  return (
    <Flex className='site-layout-background rate-report-content'>
      <FilterSection getReport={getRateReports} />
      {rateReport.fetched &&
        (rateReport.data.length !== 0 ? (
          <>
            <ReportTable data={rateReport.data} />
            <ExportCSV data={rateReport.data} dates={rateReport.filters.dateRange} />
          </>
        ) : (
          <Flex center='both-axis' style={{ marginTop: 50 }}>
            <h1>{info.dataNotFound}</h1>
          </Flex>
        ))}
      {loading && <Spiner />}
    </Flex>
  );
};

export default RateReport;
