import React from 'react';

import { Flex, Button } from '../../../components/core';
import { ClientViewType } from '../../../types/main';
import AddEditClient from './AddEditClientPopup';
import { info } from '../../../resources/strings';

interface IProps {
  reFetchClients: () => void;
}

const ActionSection: React.FC<IProps> = ({ reFetchClients }) => {
  const [visible, setVisible] = React.useState(false);

  return (
    <Flex direction='column' style={{ width: '100%' }}>
      <Flex align='start-cross-axis'>
        <Button
          colorType={'white'}
          onClick={() => setVisible(true)}
          text={info.addClientPlus}
        />
      </Flex>
      {visible && <AddEditClient
        type={ClientViewType.create}
        onCancel={() => setVisible(false)}
        visible={true}
        reFetchClients={reFetchClients}
      />}
    </Flex>
  );
};

export default ActionSection;
