import { EmployeeRole } from '../graphql/graphql';

export enum ValidationsType {
  required = 'required',
  pattern = 'pattern',
  validate = 'validate'
}

export enum VerificationPaths {
  verify = 'verify',
  resetPassword = 'resetPassword'
}

export enum EmployeeViewType {
  add = 'add',
  edit = 'edit'
}

export enum HolidayViewType {
  add = 'add',
  edit = 'edit'
}

export enum TaskViewType {
  create = 'createTask',
  update = 'updateTask'
}

export enum ClientViewType {
  create = 'createClient',
  update = 'updateClient'
}

export enum DialogType {
  delete = 'delete',
  stausChange = 'stausChange'
}

export enum RequestTypeName {
  badRequest = 'BadRequestError',
  employee = 'Employee',
  client = 'Client',
  task = 'Task',
  AuthenticationError = 'AuthenticationError',
  Success = 'Success',
  TimeSheet = 'TimeSheet'
}

export enum InputKeyType {
  Delete = 'Delete',
  Backspace = 'Backspace'
}

export enum SalaryOrHourType {
  salary = 'salary',
  hoursPerDay = 'hoursPerDay'
}
export interface LoginUserResponse {
  data?: {
    login: {
      authTokens: {
        accessToken: string;
        refreshToken: string;
      };
      user: EmployeeDataType;
      message?: string;
      __typename: LoginRequestTypeName;
    };
  };
}

export interface UserInfoDataType extends EmployeeDataType {
  message?: string;
  __typename: LoginRequestTypeName;
}

export interface ActivatePendingUserResponse {
  data?: {
    activatePendingEmployee: {
      __typename: RequestTypeName;
    };
  };
}

export enum LoginRequestTypeName {
  BadRequest = 'BadRequestError',
  AuthenticationError = 'AuthenticationError',
  UserWithCredentials = 'UserWithCredentials'
}

export enum Status {
  Active = 'Active',
  Pending = 'Pending',
  Deactivated = 'Deactivated'
}

export enum Steps {
  Step1 = 1,
  Step2 = 2,
  Step3 = 3,
  Step4 = 4,
  Step5 = 5,
  Step6 = 6,
  Step7 = 7
}

interface OnEmployee extends EmployeeDataType {
  message?: string;
  __typename: RequestTypeName;
}

export interface AddEployeeResponse {
  data?: {
    createEmployee: OnEmployee;
  };
}

export interface Job {
  id: number;
  title: string;
}

export interface Location {
  id: number;
  name: string;
}

export interface Department {
  id: number;
  name: string;
}

export interface Currency {
  id: number;
  name: string;
}

export interface HourlyRate {
  id: number;
  rate: number;
  createdAt: string;
  updatedAt: string;
}

export interface EmploymentStatus {
  id: number;
  name: string;
}

export interface UpdateEployeeResponse {
  data?: {
    updateEmployee: OnEmployee;
  };
}

export interface ManagerDataType {
  id: number;
  firstName: string;
  lastName: string;
  fullName?: string;
}

export interface EmployeeDataType {
  id: number;
  firstName: string;
  lastName: string;
  fullName?: string;
  email: string;
  job: Job;
  hourlyRates: HourlyRate[];
  employmentStatus: EmploymentStatus;
  location: Location;
  manager: ManagerDataType | null;
  status: string;
  role: EmployeeRole;
  department: Department;
  currency: Currency;
}

export interface WithId {
  id: number;
}
export interface ClientDataType {
  id: number;
  name: string;
  logoUrl: string;
  status: string;
}

export interface TaskDataType {
  id: number;
  summary: string;
  status: string;
  client: ClientDataType;
}

export interface ClientTimeFrame {
  id: number;
  name: string;
  status?: string;
  hours: number;
  UID: number;
  splitTaskHoursEvenly: boolean;
  tasks: TaskTimeFrame[];
}

export interface TaskTimeFrame {
  hours: number;
  id: number;
  name: string;
  UID: number;
  status?: string;
}

export interface LabeledValue {
  key?: string;
  value: number | string;
  label: React.ReactNode;
}

export interface DaysOfWeekType {
  date: string;
  name: string;
  weekend: boolean;
  holiday: boolean;
}

interface TaskType {
  id: number;
  summary: string;
  hoursPerDay: number;
}

export interface ClientsType {
  id: number;
  name: string;
  hoursPerDay: number;
  tasks: TaskType[];
}

export interface ReportDataType {
  id: number;
  firstName: string;
  lastName: string;
  clients: ClientsType[];
}

export interface ProjectReportDataType {
  id: number;
  name: string;
  tasks: ProjectReportTaskType[];
}

export interface ProjectReportTaskType {
  id: number;
  summary: string;
  hoursPerDay: number;
  employees: ProjectReportEmployeeType[];
}

interface ProjectReportEmployeeType {
  id: number;
  lastName: string;
  firstName: string;
  hoursPerDay: number;
}

export interface RateReportDataType {
  id: number;
  firstName: string;
  lastName: string;
  employmentStatus: EmploymentStatus;
  location: Location;
  currency: Currency;
  hourlyRates: Pick<HourlyRate, 'id' | 'rate'>[];
  clients: RateReportClientType[];
  groupedHoursByHourlyRateAndClient: string;
  groupedSalariesByHourlyRateAndClient: string;
  rate?: number;
}

export interface RateReportClientType {
  id: number;
  name: string;
  hoursPerDay: number;
  salary: number;
}

export interface EmployeeTimeSheetClientsDataType {
  id: number;
  totalHours: number;
  name: string;
  status: string;
  tasks: EmployeeTimeSheetClientsTask[];
}

interface EmployeeTimeSheetClientsTask {
  id: number;
  hours: number;
  name: string;
  status: string;
}
export interface EmployeeTimeSheetDataType {
  id: number;
  clients: EmployeeTimeSheetClient[];
  totalHours: number;
  dates: string[];
}

export interface EmployeeTimeSheetClient {
  id: number;
  totalHours: number;
  client: Omit<ClientDataType, 'logoUrl'>;
  tasks: EmployeeTimeSheetTask[];
}

export interface EmployeeTimeSheetTask {
  id: number;
  hours: number;
  task: Omit<TaskDataType, 'client'>;
}

export type TimeSheetReport = EmployeeTimeSheetDataType;
export type TimeSheetReportClient = EmployeeTimeSheetClient;
export type TimeSheetReportTask = EmployeeTimeSheetTask;

export interface ClientLookup {
  id: number;
  name: string;
  status?: string;
  hours: number;
  UID: number;
  tasks: TaskLookup[];
}

export interface TaskLookup {
  id: number;
  name: string;
  hours: number;
  UID: number;
  status?: string;
}
