import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';
import { useApolloClient } from '@apollo/client';

import Step1 from './Step1';
import Step2 from './Step2';
import PreviousTimeSheet from './PreviousTimeSheet';
import ChooseClient from './ChooseClient';
import AddClient from './AddClient';
import AddTasks from './AddTasks';
import SummaryView from './SummaryView';
import { updateWeekDays } from '../../redux/reducers/TimeFrameReducer';
import { Steps, DaysOfWeekType } from '../../types/main';
import { useTimeSheetDatesQuery } from '../../graphql/graphql';
import { RootState } from '../../redux/reducers/index';
import { initialClientStep } from '../../utils/constants';
import { SKIP_WEEK } from '../../ApolloClient/mutations/timeSheetAction';
import { MessageDuration } from '../../utils/constants';

import './Current.styles.scss';

const TimeSheet: React.FC = () => {
  const client = useApolloClient();
  const timeFrame = useSelector((state: RootState) => state.timeFrame);
  const [step, setStep] = useState(Steps.Step1);
  const [clientStep, setClientStep] = useState(initialClientStep);
  const [showSummaryBackBtn, setShowSummaryBackBtn] = useState(true);
  const [initialSelectedDays, setInitialSelectedDays] = useState<string[]>([]);
  const { data, loading, refetch, networkStatus } = useTimeSheetDatesQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only'
  });
  const [weekDays, setWeekDays] = useState<DaysOfWeekType[] | any>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data?.timeSheetDates.__typename === 'TimeSheetDateTypes') {
      const selectedDays = data.timeSheetDates.data
        .filter((day) => !day.weekend && !day.holiday)
        .map((day) => day.date);
      setWeekDays(data.timeSheetDates.data);
      setInitialSelectedDays(selectedDays);
      dispatch(updateWeekDays(selectedDays));
    }
  }, [data, dispatch, networkStatus]);

  const skipWeek = (weekStart: string) => {
    client
      .mutate({
        mutation: SKIP_WEEK,
        variables: { weekStart }
      })
      .then(() => {
        refetch();
        message.success('Skipped');
      })
      .catch((e) => e && message.error(e.message, MessageDuration));
  };

  return (
    <div className='site-layout-background'>
      {step === Steps.Step1 && (
        <Step1
          skipWeek={skipWeek}
          currentStep={step}
          setStep={setStep}
          weekDays={weekDays}
          fetching={loading}
        />
      )}
      {step === Steps.Step2 && (
        <Step2
          currentStep={step}
          setStep={setStep}
          hours={timeFrame.totalHours}
          selectedDays={timeFrame.days}
          weekDays={weekDays}
        />
      )}
      {step === Steps.Step3 && (
        <PreviousTimeSheet
          currentStep={step}
          setStep={setStep}
          setClientStep={setClientStep}
          setShowSummaryBackBtn={setShowSummaryBackBtn}
          newTotalHours={timeFrame.totalHours}
          selectedDays={timeFrame.days}
        />
      )}
      {step === Steps.Step4 && (
        <ChooseClient currentStep={step} setStep={setStep} clientList={timeFrame.clients} />
      )}
      {step === Steps.Step5 && (
        <AddClient
          currentStep={step}
          setStep={setStep}
          totalHours={timeFrame.totalHours}
          clientList={timeFrame.clients}
          splitEvenly={timeFrame.splitClientHoursEvenly}
        />
      )}
      {step === Steps.Step6 && (
        <AddTasks
          currentStep={step}
          setStep={setStep}
          clientList={timeFrame.clients}
          clientStep={clientStep}
          setClientStep={setClientStep}
          setShowSummaryBackBtn={setShowSummaryBackBtn}
        />
      )}
      {step === Steps.Step7 && (
        <SummaryView
          currentStep={step}
          setStep={setStep}
          setClientStep={setClientStep}
          clientList={timeFrame.clients}
          totalHours={timeFrame.totalHours}
          days={timeFrame.days}
          refetch={refetch}
          weekDays={weekDays}
          showSummaryBackBtn={showSummaryBackBtn}
          initialSelectedDays={initialSelectedDays}
        />
      )}
    </div>
  );
};

export default TimeSheet;
