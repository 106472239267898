import React, { useState } from 'react';
import { Moment } from 'moment';
import { CSVLink } from 'react-csv';

import { Flex, Button } from '../../../components/core';
import { ReportDataType } from '../../../types/main';
import { getRoundedTotal } from '../helpers';
import { info } from '../../../resources/strings';

const headers = [
  { label: info.employee, key: 'EmployeeFullName' },
  { label: info.client, key: 'clientName' },
  { label: info.task, key: 'taskName' },
  { label: info.hoursPerTask, key: 'hoursPerTask' },
  { label: info.hoursPerClient, key: 'hoursPerClient' },
  { label: info.employeeGrandTotal, key: 'grandTotal' }
];

type DataForCSV = {
  EmployeeFullName: string;
  clientName: string;
  taskName: string;
  hoursPerTask: number;
  hoursPerClient: number;
  grandTotal: string;
};

interface IProps {
  data: ReportDataType[];
  dates: Moment[];
}

const ExportCSV: React.FC<IProps> = ({ data, dates }) => {
  const [csvData, setCsvData] = useState<DataForCSV[]>([]);

  const csvReport = React.useMemo(() => {
    return {
      data: csvData,
      headers: headers,
      filename: `${info.employeeProjectHours}-${dates[0].format('YYYY/MM/DD')}-${dates[1].format(
        'YYYY/MM/DD'
      )}.csv`
    };
  }, [csvData, dates]);

  const handleClick = () => {
    const dataForCSV: DataForCSV[] = [];
    for (let i = 0; i < data.length; i++) {
      if (!data[i].clients.length) {
        const rowData = {} as DataForCSV;
        rowData.EmployeeFullName = `${data[i].firstName} ${data[i].lastName}`;
        rowData.grandTotal = '0';
        dataForCSV.push(rowData);
      } else {
        const total = data[i].clients.reduce((sum, current) => sum + current.hoursPerDay, 0);
        const rowForGrandTotal = {
          EmployeeFullName: `${data[i].firstName} ${data[i].lastName}`,
          grandTotal: getRoundedTotal(total)
        } as DataForCSV;
        dataForCSV.push(rowForGrandTotal);

        for (let j = 0; j < data[i].clients.length; j++) {
          for (let k = 0; k < data[i].clients[j].tasks.length; k++) {
            const rowData = {
              taskName: data[i].clients[j].tasks[k].summary,
              hoursPerTask: data[i].clients[j].tasks[k].hoursPerDay,
              EmployeeFullName: `${data[i].firstName} ${data[i].lastName}`,
              clientName: data[i].clients[j].name
            } as DataForCSV;
            if (k == 0) {
              rowData.hoursPerClient = data[i].clients[j].hoursPerDay;
            }
            dataForCSV.push(rowData);
          }
        }
      }
    }
    setCsvData(dataForCSV);
  };

  return (
    <Flex direction='row' style={{ marginTop: 20 }}>
      <CSVLink {...csvReport}>
        <Button
          colorType={'black'}
          onClick={handleClick}
          text={info.export}
          style={{ width: '150px' }}
        />
      </CSVLink>
    </Flex>
  );
};

export default ExportCSV;
