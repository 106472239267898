import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, useHistory, useLocation, Route, Redirect } from 'react-router-dom';

import { RootState } from './redux/reducers/index';
import Previous from './modules/Previous';
import TimeSheet from './modules/TimeSheet';
import Employees from './modules/Admin/Employees';
import Clients from './modules/Admin/Clients';
import Tasks from './modules/Admin/Tasks';
import Login from './modules/Login/Login';
import SetPassword from './modules/Login/SetPassword';
import Holidays from './modules/Holidays';
import ProtectedRoute from './components/AdminChecker';
import Administrative from './modules/Admin/Administrative';
import ProjectHours from './modules/Reports/ProjectHours/index';
import EmployeeProjectHours from './modules/Reports/EmployeeProjectHours/index';
import PublicRoute from './components/Routes/PublicRoute';
import { ConfirmationDialog } from './components/core';
import RateReport from './modules/Reports/RateReport';
import TimeSheetLookup from './modules/Reports/TimeSheetLookup';
import MainLayout from './containers/MainLayout';
import { EmployeeRole } from './graphql/graphql';
import { changeTimesheetEditMode, startOver } from './redux/reducers/TimeFrameReducer';
import { info } from './resources/strings';

const Routes: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [redirectedTo, setRedirectTo] = useState('');
  const { userInfo, timesheetEditMode } = useSelector((state: RootState) => ({
    userInfo: state.user.userInfo,
    timesheetEditMode: state.timeFrame.timesheetEditMode
  }));
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((data) => {
      if (data.pathname === '/login') return;

      if (timesheetEditMode && data.pathname !== '/currentTime') {
        history.push('/currentTime');
        setShowModal(true);
        setRedirectTo(data.pathname);
      }
    });

    if (!timesheetEditMode && redirectedTo) {
      history.push(redirectedTo);
      setRedirectTo('');
    }
    return () => unlisten();
  }, [timesheetEditMode]);

  React.useEffect(() => {
    if (location.pathname === '/') {
      history.push('/login');
    }
  });

  return (
    <>
      <Switch>
        <PublicRoute path='/login' component={Login} />
        <PublicRoute path='/verify' component={SetPassword} />
        <PublicRoute path='/resetPassword' component={SetPassword} />
        {!localStorage.getItem('accessToken') && (
          <Route path='/*'>
            <Redirect to='/login' />
          </Route>
        )}

        <MainLayout>
          <div>
            <Route path='/currentTime'>
              <TimeSheet />
            </Route>
            <Route path='/pastTime'>
              <Previous />
            </Route>
            <ProtectedRoute
              exact
              path='/employees'
              allowedRoles={[EmployeeRole.Admin]}
              role={userInfo.role}
              component={Employees}
            />
            <ProtectedRoute
              exact
              path='/clients'
              allowedRoles={[EmployeeRole.Admin]}
              role={userInfo.role}
              component={Clients}
            />
            <ProtectedRoute
              exact
              path='/holidays'
              allowedRoles={[EmployeeRole.Admin]}
              role={userInfo.role}
              component={Holidays}
            />
            <ProtectedRoute
              exact
              path='/clients/:id'
              allowedRoles={[EmployeeRole.Admin]}
              role={userInfo.role}
              component={Tasks}
            />
            <ProtectedRoute
              exact
              path='/administrative'
              allowedRoles={[EmployeeRole.Admin]}
              role={userInfo.role}
              component={Administrative}
            />
            <ProtectedRoute
              exact
              path='/projectHours'
              allowedRoles={[
                EmployeeRole.Admin,
                EmployeeRole.Manager,
                EmployeeRole.EngagementManager
              ]}
              role={userInfo.role}
              component={ProjectHours}
            />
            <ProtectedRoute
              exact
              path='/employeeProjectHours'
              allowedRoles={[EmployeeRole.Admin, EmployeeRole.Manager]}
              role={userInfo.role}
              component={EmployeeProjectHours}
            />
            <ProtectedRoute
              exact
              path='/rateReport'
              allowedRoles={[EmployeeRole.Admin]}
              role={userInfo.role}
              component={RateReport}
            />
            <ProtectedRoute
              exact
              path='/timeSheetLookup'
              allowedRoles={[EmployeeRole.Admin]}
              role={userInfo.role}
              component={TimeSheetLookup}
            />
          </div>
        </MainLayout>
      </Switch>
      <ConfirmationDialog
        title={info.warning}
        noTitle={info.no}
        yesTitle={info.yes}
        confirmAction={() => {
          dispatch(changeTimesheetEditMode(false));
          dispatch(startOver([]));
          setShowModal(false);
        }}
        rejectAction={() => setShowModal(false)}
        visible={showModal}
      >
        {info.wantToLeave}
      </ConfirmationDialog>
    </>
  );
};

export default Routes;
