import React, { useState } from 'react';
import produce from 'immer';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Table, message, InputNumber } from 'antd';

import { RootState } from '../../redux/reducers/index';
import {
  startOver,
  IAppReducerState,
  replaceTimeFrame
} from '../../redux/reducers/TimeFrameReducer';
import { useCreateTimeSheetMutation, GenericStatus } from '../../graphql/graphql';
import { ClientTimeFrame, DaysOfWeekType, RequestTypeName, Steps } from '../../types/main';
import { Flex, Button, BackButton, ConfirmationDialog, Spiner } from '../../components/core';
import CongratulationPopup from './CongratulationPopup';
import { initialClientStep, maxWeekHours, minTaskHours } from '../../utils/constants';
import { info } from '../../resources/strings';

interface IProps {
  currentStep: number;
  setStep: (step: number) => void;
  setClientStep: (step: number) => void;
  clientList: ClientTimeFrame[];
  totalHours: number;
  days: string[];
  refetch: () => void;
  weekDays: DaysOfWeekType[] | any;
  showSummaryBackBtn?: boolean;
  initialSelectedDays: string[];
}

interface TaskInputs {
  hours: number;
  clientId: number;
}

type FormData = {
  [key: string]: any;
};

const SummaryView: React.FC<IProps> = ({
  refetch,
  currentStep,
  setStep,
  setClientStep,
  clientList,
  totalHours,
  days,
  weekDays,
  showSummaryBackBtn,
  initialSelectedDays
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    getValues,
    reset
  } = useForm<FormData>();
  const [editMode, setEditMode] = useState(false);
  const [startOverDialogOpen, setStartOverDialogOpen] = useState(false);
  const [createTimeSheetMutation, { loading }] = useCreateTimeSheetMutation();
  const dispatch = useDispatch();
  const timeSheetData: IAppReducerState = useSelector((state: RootState) => state.timeFrame);

  function onBack() {
    setStep(currentStep - 1);
  }

  function onStartOver() {
    dispatch(startOver(initialSelectedDays));
    setStep(Steps.Step1);
    setClientStep(initialClientStep);
  }

  const onSubmit = (fieldData: FormData) => {
    const newTimeSheetData = produce(timeSheetData, (draft) => {
      let newTotalHours = 0;
      fieldData.clientsRow.map((tasks: TaskInputs[]) => {
        let newSumHours = 0;
        let editableClient = {} as ClientTimeFrame;
        tasks.map((item, taskIndex) => {
          newSumHours += +item.hours.toFixed(2);
          editableClient = draft.clients.find(
            (client) => client.id === +item.clientId
          ) as ClientTimeFrame;
          editableClient.tasks[taskIndex].hours = +item.hours.toFixed(2);
        });
        newTotalHours += newSumHours;
        editableClient.hours = +newSumHours.toFixed(2);
        editableClient.splitTaskHoursEvenly = false;
      });
      draft.totalHours = +newTotalHours.toFixed(2);
      draft.splitClientHoursEvenly = false;
    });
    dispatch(replaceTimeFrame(newTimeSheetData));
    setTimeout(() => {
      reset();
      setEditMode(false);
    }, 0);
  };

  function onConfirm() {
    const variables = {
      totalHours: totalHours,
      days,
      clients: clientList.map((cl) => {
        return {
          totalHours: cl.hours,
          id: cl.id,
          tasks: cl.tasks.map((task) => {
            return {
              id: task.id,
              hours: task.hours
            };
          })
        };
      })
    };

    createTimeSheetMutation({ variables })
      .then((result) => {
        if (result?.data?.createTimeSheet.__typename === RequestTypeName.TimeSheet) {
          setDialogOpen(true);
          return;
        }
      })
      .catch((e) => {
        if (e) {
          message.error(e.message);
        }
      });
  }

  function updateWeek() {
    setStep(Steps.Step1);
    dispatch(startOver(initialSelectedDays));
    setClientStep(initialClientStep);
    refetch();
  }

  const tableFooter = () => {
    return (
      <Flex direction='row' space={'between'} style={{ width: '100%' }}>
        <div className='bold'>{info.totalHours}</div>
        <div className='totalHours bold'>{`${totalHours}h`}</div>
      </Flex>
    );
  };

  const onClientClick = (rowdata: ClientTimeFrame) => {
    if (!editMode) {
      return;
    }
    const clientStep = clientList.findIndex((client) => client.id === rowdata.id);
    setClientStep(clientStep);
    setStep(Steps.Step6);
  };

  const onTitleClientsClick = () => {
    if (!editMode) {
      return;
    }
    setStep(Steps.Step5);
    setClientStep(initialClientStep);
  };

  const checkMinHours = () => {
    let isLessThanMinHours = false;
    clientList.forEach((client) => {
      client.tasks.forEach((task) => {
        if (task.hours < minTaskHours) {
          isLessThanMinHours = true;
        }
      });
    });
    if (isLessThanMinHours) {
      message.destroy();
      message.warn(info.minTaskHours);
      return true;
    }
    return false;
  };

  const isDisable = React.useMemo(() => {
    if (editMode) {
      return false;
    }
    let isSomeClientOrTaskInactive = false;
    clientList.forEach((client) => {
      if (client.status === GenericStatus.Inactive) {
        isSomeClientOrTaskInactive = true;
        return;
      }
      client.tasks.forEach((task) => {
        if (task.status === GenericStatus.Inactive) {
          isSomeClientOrTaskInactive = true;
          return;
        }
      });
    });
    return isSomeClientOrTaskInactive;
  }, [clientList, editMode]);

  const getEditTaskHours = (rowData: ClientTimeFrame, clientIndex: number) => {
    return rowData.tasks.map((task, index) => {
      return (
        <div className='sumHours editableRow' key={task.id}>
          <input
            {...register(`clientsRow.${clientIndex}.${index}.clientId`)}
            type='hidden'
            value={rowData.id}
          />
          <Controller
            name={`clientsRow.${clientIndex}.${index}.hours`}
            control={control}
            defaultValue={task.hours}
            rules={{
              validate: () => getValues(`clientsRow.${clientIndex}.${index}.hours`) > 0
            }}
            render={({ field: { ref, onChange, ...otherFieldProps } }) => (
              <InputNumber
                min={0}
                ref={ref}
                onChange={(v) => (v ? onChange(v) : onChange(task.hours))}
                className={errors?.clientsRow?.[clientIndex]?.[index]?.hours?.type ? 'error' : ''}
                {...otherFieldProps}
              />
            )}
          />
        </div>
      );
    });
  };

  const columns = [
    {
      key: 'client',
      className: 'clientCell',
      title: () => {
        return (
          <span className={`${editMode ? 'editMode' : ''}`} onClick={onTitleClientsClick}>
            {info.clients}
          </span>
        );
      },
      render: (rowdata: ClientTimeFrame) => {
        return (
          <span
            className={`${editMode ? 'editMode' : ''} ${rowdata.status} clientName`}
            onClick={() => onClientClick(rowdata)}
          >
            {rowdata.name}
          </span>
        );
      }
    },
    {
      key: 'totalHours',
      title: info.clientTotalHours,
      className: 'totalHoursCell',
      render: (rowdata: ClientTimeFrame) => {
        return <span>{rowdata.hours}</span>;
      }
    },
    {
      key: 'tasks',
      title: info.tasks,
      render: (rowdata: ClientTimeFrame) => {
        return rowdata.tasks.map((task) => {
          return (
            <div key={task.id} className={`${task.status} taskName`}>
              {task.name}
            </div>
          );
        });
      }
    },
    {
      key: 'tasksHours',
      title: info.taskHours,
      width: '100px',
      render: (rowdata: ClientTimeFrame, _, index: number) => {
        if (!editMode) {
          return rowdata.tasks.map((task) => {
            return (
              <div className='sumHours bold' key={task.id}>
                {task.hours}
              </div>
            );
          });
        }
        return getEditTaskHours(rowdata, index);
      }
    }
  ];

  return (
    <Flex direction='row' center='main-axis' className='content summaryView'>
      <Flex direction='row' style={{ width: '1000px' }}>
        <Flex direction='row'>
          {!editMode && showSummaryBackBtn && (
            <BackButton style={{ marginBottom: '8px' }} onClick={onBack} text={info.back} />
          )}
        </Flex>
        <Flex direction='column' className='dataWrapper'>
          <Flex direction='row' space={'between'} style={{ width: '100%' }}>
            <div className='headerText'>{info.summary}</div>
            <div className='headerText pointer' onClick={() => setStartOverDialogOpen(true)}>
              {info.startOver}
            </div>
          </Flex>
          {loading && <Spiner local className='small' />}
          <Table
            id='summaryTable'
            rowKey='id'
            size='small'
            dataSource={clientList}
            columns={columns}
            footer={tableFooter}
            pagination={{ hideOnSinglePage: true, pageSize: 20 }}
            style={{ marginTop: loading ? -20 : 16 }}
          />
          {isDisable && <div className='inActiveDataInfo warning'>{info.cantSubmitInactive}</div>}
          <Flex direction='row' center='main-axis' style={{ marginTop: 20 }}>
            <Flex align='end-cross-axis'>
              <Button
                colorType='white'
                onClick={() => {
                  reset();
                  setEditMode(!editMode);
                }}
                text={editMode ? info.cancel : info.edit}
                style={{ marginRight: 60, width: 150 }}
              />
            </Flex>
            <Flex align='start-cross-axis'>
              <Button
                colorType='pink'
                onClick={() => {
                  if (editMode) {
                    handleSubmit(onSubmit)();
                  } else {
                    if (checkMinHours()) return;
                    if (totalHours > maxWeekHours) {
                      message.destroy();
                      message.warning(info.maxWeekHours);
                    } else {
                      onConfirm();
                    }
                  }
                }}
                text={editMode ? info.done : info.confirm}
                style={{ width: 150 }}
                disabled={isDisable}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <CongratulationPopup
        visible={dialogOpen}
        onCancel={updateWeek}
        confirmAction={updateWeek}
        weekDays={weekDays}
      />
      <ConfirmationDialog
        title={info.startOver}
        noTitle={info.no}
        yesTitle={info.yes}
        confirmAction={onStartOver}
        rejectAction={() => setStartOverDialogOpen(false)}
        visible={startOverDialogOpen}
      >
        {info.wantToStartOver}
      </ConfirmationDialog>
    </Flex>
  );
};

export default SummaryView;
