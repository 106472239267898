import React from 'react';
import IconWrapper from './IconWrapper';

interface IProps {
  fill: string;
}

const Icon: React.FC<IProps> = ({ fill }) => {
  return (
    <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='32' height='32' rx='2' fill='fill' />
      <circle cx='16' cy='7' r='2' fill={fill} />
      <circle cx='16' cy='16' r='2' fill={fill} />
      <circle cx='16' cy='25' r='2' fill={fill} />
    </svg>
  );
};

const BurgerIcon: React.FC = () => {
  return <IconWrapper Icon={Icon} />;
};

export default BurgerIcon;
