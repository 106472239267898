import React from 'react';
import { Controller } from 'react-hook-form';

import { Label, Select } from '../core';
import { ResponseDataType, IDebouncedListProps } from './DebouncedList';

interface IProps extends IDebouncedListProps {
  data: ResponseDataType[];
  debounceFetcher: (term: string) => void;
  onScrollDebounceFetcher: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const DynamicList: React.FC<IProps> = ({
  control,
  style,
  withLabel = true,
  labelText = '',
  controllerName,
  dataText,
  dataValue,
  labelInValue,
  onChange,
  defaultValueId,
  data,
  debounceFetcher,
  onScrollDebounceFetcher,
  optionDisable,
  optionRender,
  mode,
  maxTagCount,
  disabled,
  allowClear,
  placeholder,
  sectionName
}) => {
  return (
    <>
      {withLabel && <Label type='small'>{labelText}</Label>}
      <Controller
        name={controllerName}
        control={control}
        render={({ field: { ref, onChange: nativeChange, ...otherFieldProps } }) => (
          <Select
            id='list'
            data={data}
            mode={mode}
            maxTagCount={maxTagCount}
            defaultValue={defaultValueId}
            onSearch={debounceFetcher}
            onPopupScroll={onScrollDebounceFetcher}
            optionDisable={optionDisable}
            optionRender={optionRender}
            style={style}
            dataText={dataText}
            dataValue={dataValue}
            labelInValue={labelInValue}
            filterOption={false}
            showSearch
            allowClear={allowClear}
            disabled={disabled}
            placeholder={placeholder}
            sectionName={sectionName}
            {...otherFieldProps}
            onChange={(event) => {
              onChange?.(event);
              nativeChange(event);
            }}
          />
        )}
      />
    </>
  );
};

export default DynamicList;
