import React from 'react';
import { useMutation } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import { Modal, message } from 'antd';

import { CREATE_TASK, EDIT_TASK } from '../../../ApolloClient/mutations/clientActions';
import { Flex, Button, Label, Input } from '../../../components/core';
import { RequestTypeName, ValidationsType, TaskDataType, TaskViewType } from '../../../types/main';
import { info, errors as errorMessages } from '../../../resources/strings';
import { RegExpName } from '../../../utils/constants';

interface IProps {
  type: TaskViewType;
  onCancel: () => void;
  reFetchTasks: () => void;
  visible: boolean;
  clientId: number;
  defaultValues?: TaskDataType;
}

type FormData = {
  summary: string;
};

const AddEditTask: React.FC<IProps> = ({
  onCancel,
  visible,
  reFetchTasks,
  clientId,
  type,
  defaultValues
}) => {
  const [addOrEditTask] = useMutation(type === TaskViewType.create ? CREATE_TASK : EDIT_TASK);
  const getDefaultValues = () => {
    const data = {
      summary: defaultValues?.summary,
      id: defaultValues?.id
    };
    return data;
  };
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<FormData>({ defaultValues: getDefaultValues() });

  const onSubmit = (fieldData: FormData) => {
    const variables = { ...fieldData, clientId };
    addOrEditTask({ variables })
      .then((result) => {
        if (result?.data?.[type].__typename === RequestTypeName.task) {
          onCancel();
          reFetchTasks();
          return;
        }
      })
      .catch((e) => {
        if (e) {
          message.error(e.message);
        }
      });
  };

  const content = () => {
    return (
      <Flex className='modalContent'>
        <Flex direction='column' style={{ width: '100%' }}>
          <Label type='small'>{'Summary'}</Label>
          <Controller
            name='summary'
            control={control}
            rules={{ required: true, pattern: RegExpName }}
            render={({ field: { ref, ...otherFieldProps } }) => (
              <Input
                autoFocus
                width={'100%'}
                colorType='primary'
                autoComplete={'off'}
                inputRef={ref}
                {...otherFieldProps}
              />
            )}
          />
          {errors.summary?.type === ValidationsType.required && (
            <span className='errorText'>{errorMessages.requiredField}</span>
          )}
          {errors.summary?.type === ValidationsType.pattern && (
            <span className='errorText'>{errorMessages.namePattern('task')}</span>
          )}
        </Flex>
      </Flex>
    );
  };

  const footer = () => {
    return (
      <Flex className='footer' direction='row' center='main-axis' style={{ marginBottom: 30 }}>
        <Flex align='end-cross-axis'>
          <Button
            colorType={'white'}
            onClick={onCancel}
            text={info.cancel}
            style={{ marginRight: 60, width: 150 }}
          />
        </Flex>
        <Flex align='start-cross-axis'>
          <Button
            colorType={'black'}
            onClick={handleSubmit(onSubmit)}
            text={type === TaskViewType.create ? info.add : info.save}
            style={{ width: 150 }}
          />
        </Flex>
      </Flex>
    );
  };

  return (
    <div
      onKeyPress={(e) => {
        if (e.code === 'Enter' && visible) {
          handleSubmit(onSubmit)();
        }
      }}
    >
      <Modal
        title={type === TaskViewType.create ? info.addTask : info.editTask}
        wrapClassName='modalContent'
        onCancel={onCancel}
        visible={visible}
        centered={true}
        footer={footer()}
        width={500}
      >
        {content()}
      </Modal>
    </div>
  );
};

export default AddEditTask;
