export const RegExpEmail = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,10}$/;
export const RegExpPassword = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*]).{8,}$/;
export const RegExpName = /.*[^ ].*/;
export const RegexpEmployeeName = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;
export const MessageDuration = 3;
export const dataCounts = 15;
export const initialIndex = 0;
export const debounceTimeout = 500;
export const maxTakeCount = 10000;
export const initialClientStep = 0;
export const maxWeekHours = 168;
export const minTaskHours = 0.04;

export const imageRequirement = {
  bytesInMB: 1048576,
  maxWidth: 500,
  maxHeight: 500,
  fyleTypes: ['image/jpeg', 'image/png']
};

export const weekdays = ['Mo', 'To', 'We', 'Th', 'Fr'];
export const weekend = ['St', 'Sn'];
export const correctionForRound = 100;
