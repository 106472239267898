import { gql } from '@apollo/client';

export const ADD_EMPLOYEE = gql`
  mutation addEmployee(
    $firstName: String!
    $lastName: String!
    $manager: Int
    $role: EmployeeRole!
    $email: String!
    $locationId: Int!
    $employmentStatusId: Int!
    $jobId: Int!
    $hourlyRate: Float!
    $currencyId: Int
    $departmentId: Int!
  ) {
    createEmployee(
      createEmployeeInput: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        role: $role
        locationId: $locationId
        employmentStatusId: $employmentStatusId
        jobId: $jobId
        hourlyRate: $hourlyRate
        manager: $manager
        departmentId: $departmentId
        currencyId: $currencyId
      }
    ) {
      __typename
      ... on Employee {
        id
        firstName
        lastName
        email
        location {
          name
        }
        employmentStatus {
          id
          name
        }
        job {
          title
        }
        hourlyRates {
          id
          rate
          createdAt
          updatedAt
        }
        manager {
          id
          firstName
          lastName
        }
        department {
          id
          name
        }
        currency {
          id
          name
        }
      }
      ... on BadRequestError {
        message
      }
      ... on Error {
        message
      }
    }
  }
`;

export const EDIT_EMPLOYEE = gql`
  mutation editEmployee(
    $id: Int!
    $firstName: String
    $lastName: String
    $manager: Int
    $role: EmployeeRole
    $email: String
    $locationId: Int!
    $employmentStatusId: Int!
    $jobId: Int!
    $currencyId: Int
    $departmentId: Int!
    $hourlyRate: Float
  ) {
    updateEmployee(
      updateEmployeeInput: {
        email: $email
        firstName: $firstName
        hourlyRate: $hourlyRate
        id: $id
        lastName: $lastName
        locationId: $locationId
        employmentStatusId: $employmentStatusId
        jobId: $jobId
        manager: $manager
        role: $role
        departmentId: $departmentId
        currencyId: $currencyId
      }
    ) {
      __typename
      ... on Employee {
        id
        firstName
        lastName
        email
        status
        hourlyRates {
          id
          rate
          createdAt
          updatedAt
        }
        location {
          name
        }
        employmentStatus {
          id
          name
        }
        job {
          title
        }
        manager {
          id
          firstName
          lastName
        }
        role
        department {
          id
          name
        }
        currency {
          id
          name
        }
      }
      ... on BadRequestError {
        message
      }
      ... on Error {
        message
      }
    }
  }
`;

export const DELETE_EMPLOYEE = gql`
  mutation deleteEmployee($id: Int!) {
    removeEmployee(id: $id) {
      __typename
      ... on Employee {
        id
      }
      ... on BadRequestError {
        message
      }
      ... on Error {
        message
      }
    }
  }
`;

export const RESEND_VERIFICATION_EMAIL = gql`
  mutation resendVerificationEmail($id: Int!) {
    resendVerificationEmail(id: $id) {
      __typename
      ... on Success {
        result
      }
      ... on BadRequestError {
        message
      }
      ... on AuthenticationError {
        message
      }
    }
  }
`;

export const UPDATE_EMPLOYEE_STATUS = gql`
  mutation updateEmployeeStatus($id: Int!, $status: AssignableEmployeeStatus!) {
    updateEmployeeStatus(updateEmployeeStatusInput: { id: $id, status: $status }) {
      __typename
      ... on Employee {
        id
        firstName
        lastName
        email
        status
        hourlyRates {
          id
          rate
          createdAt
          updatedAt
        }
        location {
          id
          name
        }
        employmentStatus {
          id
          name
        }
        job {
          id
          title
        }
        manager {
          id
          firstName
          lastName
        }
        role
      }
      ... on BadRequestError {
        message
      }
      ... on AuthenticationError {
        message
      }
    }
  }
`;
