import { FC, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Checkbox, Tooltip } from 'antd';

import { addClient, deleteClientById } from '../../redux/reducers/TimeFrameReducer';
import { Flex, BackButton, Button, Spiner } from '../../components/core';
import { useFindActiveClientsWithActiveTasksQuery } from '../../graphql/graphql';
import { ClientDataType, ClientTimeFrame, Steps } from '../../types/main';
import { info } from '../../resources/strings';

interface IProps {
  currentStep: number;
  setStep: (step: number) => void;
  clientList: ClientTimeFrame[];
}

const ChooseClient: FC<IProps> = ({ currentStep, setStep, clientList }) => {
  const dispatch = useDispatch();
  const { data: allClientTasks, loading: allClientTasksLoading } =
    useFindActiveClientsWithActiveTasksQuery({ fetchPolicy: 'network-only' });

  const onCheckChange = useCallback(
    (event, item) => {
      const checked = event.target.checked;
      if (checked) {
        dispatch(addClient(item));
      } else {
        dispatch(deleteClientById(item.id));
      }
    },
    [dispatch]
  );

  const hasTasks = (clientId: number) => {
    if (allClientTasks?.findActiveClientsWithActiveTasks.__typename === 'Tasks') {
      const result = allClientTasks.findActiveClientsWithActiveTasks.entities.find(
        (task) => task.client.id === clientId
      );
      return result ? undefined : true;
    }
  };

  const getClients = useMemo(() => {
    if (allClientTasks?.findActiveClientsWithActiveTasks?.__typename === 'Tasks') {
      const clientsData = allClientTasks.findActiveClientsWithActiveTasks
        .clients as ClientDataType[];
      return clientsData.map((client) => (
        <div key={client.id} className='clientCard'>
          <Checkbox
            onChange={(e) => onCheckChange(e, client)}
            checked={clientList.map((cl) => cl.id).includes(client.id)}
            disabled={hasTasks(client.id)}
          >
            {hasTasks(client.id) ? (
              <Tooltip title={info.clientTaskInfo} placement='bottom'>
                <Flex direction='column' center='cross-axis' className='infoContent'>
                  <div
                    className='imageWrapper'
                    style={{ backgroundImage: `url(${client.logoUrl})` }}
                  />
                  <div className={`clientName ${client.name.length > 29 && 'minimize'}`}>
                    {client.name}
                  </div>
                </Flex>
              </Tooltip>
            ) : (
              <Flex direction='column' center='cross-axis' className='infoContent'>
                <div
                  className='imageWrapper'
                  style={{ backgroundImage: `url(${client.logoUrl})` }}
                />
                <div className={`clientName ${client.name.length > 29 && 'minimize'}`}>
                  {client.name}
                </div>
              </Flex>
            )}
          </Checkbox>
        </div>
      ));
    }
  }, [clientList, onCheckChange, allClientTasks]);

  if (allClientTasksLoading) return <Spiner />;

  return (
    <Flex className='content chooseClient'>
      <Flex direction='column' style={{ width: '100%' }}>
        <Flex direction='row' center='cross-axis'>
          <BackButton
            style={{ marginBottom: '8px' }}
            onClick={() => setStep(Steps.Step2)} //ChooseClient is in step 4, why we go to 2, not 3
            text={'Back'}
          />
          <div>{info.chooseClientWorkedWith}</div>
        </Flex>
        <div className='chooseClientContent'>{getClients}</div>
        <Flex direction='row' center='both-axis' style={{ margin: 30 }}>
          <Button
            style={{ width: 150 }}
            onClick={() => setStep(currentStep + 1)}
            colorType='black'
            text={info.next}
            disabled={!clientList.length}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ChooseClient;
