import React from 'react';
import { COLORS } from './Colors';

interface IProps {
  Icon: React.FunctionComponent<{ fill: string }>;
}

const IconWrapper: React.FC<IProps> = ({ Icon }: IProps) => {
  const [fill, setFill] = React.useState(COLORS.gray);
  return (
    <div onMouseEnter={() => setFill(COLORS.black)} onMouseLeave={() => setFill(COLORS.gray)}>
      <Icon fill={fill} />
    </div>
  );
};

export default IconWrapper;
