import React from 'react';

import { Button } from 'antd';
/* eslint-disable import/no-extraneous-dependencies */
import { ArrowLeftOutlined } from '@ant-design/icons';

interface IProps {
  disabled?: boolean;
  onClick: (event) => void;
  text: string;
  style?: React.CSSProperties;
}

const BackButton: React.FC<IProps> = ({ disabled, onClick, text, style }) => {
  const newStyle = {
    ...style,
    width: 100,
    fontWeight: 700,
    fontSize: 18
  };

  return (
    <Button style={newStyle} type='text' disabled={disabled} onClick={onClick} block>
      <ArrowLeftOutlined />
      {text}
    </Button>
  );
};

export default BackButton;
