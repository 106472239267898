import React, { useState, useEffect } from 'react';
import { InputNumber } from 'antd';

import './OverlappedNumber.styles.scss';

interface IProps {
  formatter?: (value: number | undefined) => string,
  onChange: ((value: number) => void),
  value: number,
  label: string,
  inputClassName?: string;
  textClassName?: string;
}

const OverlappedNumber: React.FC<IProps> = ({
  formatter,
  onChange,
  value,
  label,
  inputClassName,
  textClassName
}) => {
  const [editMode, setEditMode] = useState(false);
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const onEdit = () => {
    onChange(internalValue);
    setEditMode(false);
  };

  return editMode ? (
    <InputNumber
      min={0}
      step={1}
      size='small'
      autoFocus
      formatter={formatter}
      onChange={(v) => setInternalValue(v)}
      onBlur={onEdit}
      onPressEnter={onEdit}
      value={value}
      className={inputClassName}
    />
  ) : (
    <h3 onClick={() => setEditMode(true)} className={`${textClassName} label`}>
      {label}
    </h3>
  );
};

export default OverlappedNumber;
