import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Slider } from 'antd';

import { Flex, Label } from '../../components/core';
import DebouncedList from '../DynamicSelect/DebouncedList';
import { useFindClientTasksQuery, GenericStatus } from '../../graphql/graphql';
import { TaskTimeFrame } from '../../types/main';
import { info } from '../../resources/strings';
import OverlappedNumber from '../core/OverlappedNumber';

import './Card.styles.scss';

interface IProps {
  item: TaskTimeFrame;
  itemIndex: number;
  totalHours: number;
  clientId: number;
  max?: number;
  onEdit: (index: number, data: TaskTimeFrame) => void;
  onDelete: (index: number) => void;
  optionDisable?: (data) => boolean;
}

const TaskCard: React.FC<IProps> = ({
  totalHours,
  onDelete,
  onEdit,
  item,
  itemIndex,
  clientId,
  optionDisable
}) => {
  const { control } = useForm<{ id: number }>();
  const [noTask, setNoTask] = useState(false);

  function deleteItem() {
    onDelete(itemIndex);
  }

  function editItem(data: TaskTimeFrame) {
    onEdit(itemIndex, data);
  }

  function handleSliderChange(value: number) {
    editItem({ ...item, hours: +((totalHours / 100) * value).toFixed(2) });
  }

  function onTaskChange(data) {
    editItem({ ...item, id: data?.value, name: data?.label });
  }

  function onCompleted(data) {
    if (!data.findClientTasks.count) {
      setNoTask(true);
    }
  }

  const handlePercentChange = (value: any) => {
    if (value > 100) {
      value = 100;
    }
    handleSliderChange(value);
  };

  const handlePercentFormat = (value: number | undefined) => {
    if (value && value > 100) {
      return '100';
    }
    return value ? value.toString() : '';
  };

  const handleHoursChange = (value: any) => {
    if (value) {
      editItem({ ...item, hours: +value.toFixed(2) });
    }
  };

  const handleHoursFormat = (value: number | undefined) => {
    if (value) {
      return value.toString();
    }
    return '';
  };

  const defaultValueId = { value: item.id > 0 ? item.id : '', label: item.id > 0 ? item.name : '' };
  return (
    <Flex className={`app-item ${item.status}`}>
      <Flex direction='row' center='cross-axis' space='between' wrap>
        <Flex style={{ marginBottom: '30px', width: 350 }}>
          <DebouncedList
            control={control}
            controllerName='id'
            dataText='summary'
            dataValue='id'
            defaultValueId={defaultValueId}
            queryDataKey='findClientTasks'
            labelInValue
            query={useFindClientTasksQuery}
            onChange={onTaskChange}
            otherVarebales={{ clientIds: clientId, status: GenericStatus.Active }}
            onCompleted={onCompleted}
            optionDisable={optionDisable}
            fetchPolicy='network-only'
          />
        </Flex>
        <Flex>
          <Label mt={-20} type='small'>
            {info.timePercentage}
          </Label>
          <Flex direction='row' style={{ width: '300px' }}>
            <Slider
              tooltipVisible={false}
              onChange={handleSliderChange}
              value={(item.hours * 100) / totalHours}
              step={5}
            />
            <OverlappedNumber
              formatter={handlePercentFormat}
              onChange={handlePercentChange}
              value={Number(((item.hours * 100) / totalHours).toFixed(2))}
              label={`${((item.hours * 100) / totalHours).toFixed(2)}%`}
              textClassName='edit-percentage'
              inputClassName='edit-percentage-input'
            />
          </Flex>
        </Flex>
        <Flex direction='column' align='end-cross-axis' className='task-card-hours'>
          <Label type='small'>{info.totalHours}</Label>
          <OverlappedNumber
            formatter={handleHoursFormat}
            onChange={handleHoursChange}
            value={item.hours}
            label={`${item.hours}h`}
            inputClassName='edit-hours-input'
            textClassName='edit-hours'
          />
        </Flex>
        <Flex direction='column' align='end-cross-axis'>
          <div onClick={deleteItem} className='removeClientBtn' title={'Remove Task'}>
            X
          </div>
        </Flex>
      </Flex>
      {noTask && <div className='info'>{info.clinetNoActiveTasks}</div>}
    </Flex>
  );
};

export default TaskCard;
