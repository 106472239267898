/* eslint-disable */
import { combineReducers } from 'redux';

import userReducer, { initialState as userInitialState } from './UserReducer';
import timeFrameReducer, { initialState as timeFrameInitialState } from './TimeFrameReducer';
import holidayReducer, { initialState as holidaysInitialState } from './HolidaysReducer';
import reportsReducer, { initialState as reportsInitialState } from './Reports';
import timeSheetLookupReducer, {
  initialState as timeSheetLookupInitialState
} from './TimeSheetLookup';

const LOGOUT = 'LOGOUT';

export function logout() {
  return { type: LOGOUT };
}

const appReducer = combineReducers({
  user: userReducer,
  timeFrame: timeFrameReducer,
  timeSheetLookup: timeSheetLookupReducer,
  reports: reportsReducer,
  holidays: holidayReducer
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    return {
      user: userInitialState,
      timeFrame: timeFrameInitialState,
      timeSheetLookup: timeSheetLookupInitialState,
      reports: reportsInitialState,
      holidays: holidaysInitialState
    };
  }

  return appReducer(state, action);
};

export type RootState = ReturnType<typeof appReducer>;

export default rootReducer;
